import React, { Component } from "react";
import messages from "../../Messages/Messages";
import componentsStrings from "../../Messages/Components";
import Col from "react-bootstrap/Col";
import connections from "../../connections"
import { Button, Modal, Overlay, OverlayTrigger, Popover, Row, Tooltip } from "react-bootstrap";
import "../../css/general.css"
import "../../css/eye_input.css"
import "../../css/colors.css"
import "../../css/texts.css"
import EyeInput from "./eyeInput";
import DiseasesList from "./diseasesList";
import uuid from "react-uuid";
import errorcodes from "../errorcodes";
import errormessages from "../../Messages/ErrorMessages";
import ExamButtons from "./examButtons";
import texts from "./Texts";
import { Redirect } from "react-router";


class Image {
  name;
  type;
  content;
  src;
  background;
  iiq;
}

const Eye = {
  none: "none",
	right: "Right Eye",
	left: "Left Eye",
	both: "Both Eyes"
}


class ExamCreationForm extends Component {
  
  controller = new AbortController();

  state = {
    timer: null,
    localDate: '',
    diagnosticdetails: null,
    aeyetabledata: null,
    archived: false,
    examid: '',
    image_r: null,
    image_l: null,
    image_last: null,
    identicalnames : false,
    showIdenticalnamesMsg : false,
    startUploadExam : false,
    canUpload : false,
    imageId: 0,

    // Necessary to uploadImages request
    fullname: '',
    patientEmail: '',
    patientSex: -1,
    patientIdent: '',
  
    iiqEye: Eye.none,
    is_drop_operation: false,
    displayUploadFatalMessage: false,
    displayUploadErrorMessage: false,
    displayUploadErrorMessageMsg: '',
    displayUploadErrorCloseToMain: false,
    displayUploadImagesSpinner: false,
    displayIIQ_ImageMessageMsg: false,
    displayRecordResultMessage: false,
    displayResetMessageMsg: false,
    displayCancelMessageMsg: false,
    displayWrongImageTypeR: false,
    displayLoginFaildMessage: false, 
    displayWrongImageTypeL: false, 
    displayWrongImageSideR: false,
    displayWrongImageSideL: false,
    displayWrongImageTypeMsg: '',
    displayWrongImageSideMsg: "",
    toastMessageText: '',
    displayPopup: false,
    redirectToLogin: false
  };

  componentDidMount() {

    var date = new Date();
    var options = { formatMatcher: "best fit", weekday: "short", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: false };
    var localDate = date.toLocaleDateString("en", options)
    localDate = localDate.split(',');

    this.initializeImages();
    
    this.setState({ localDate: localDate })
  }

  initializeImages() {

    let image_r = new Image();
    image_r.background = '/eye_right.png';
    image_r.src = '';
    image_r.name = '';

    let image_l = new Image();
    image_l.background = '/eye_left.png';
    image_l.src = '';
    image_l.name = '';

    this.setState({ image_r: image_r, image_l: image_l, image_last: null })
  }

  handleExamIDChange = event => {

    if(this.lettersAndSpaceCheck(event.target.value) === true) {

      this.setState({ examid: event.target.value, patientIdent: event.target.value }, () => { this.canUpload() });
    }
    else {
      this.setState({ displayPopup: true });

      if(this.state.timer === null) {
        let timer = setInterval(() => { this.clearPopupMessageText() }, 5000);
        this.setState({ timer: timer });
      }
    }
  };

  clearPopupMessageText() {

    this.setState({ displayPopup: false});

    if(this.state.timer != null) {
      clearTimeout(this.state.timer);
      this.setState({ timer: null });
    }
  }

  lettersAndSpaceCheck(name)
  {
     var regEx = /^[a-zA-Z0-9_.-\s]*$/;

     if(name.match(regEx) || name === '')
       {
        return true;
       }
     else
       {
       return false;
       }
  }  

  arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  checkFileNameCorrection = (dropopr) => {
      let identicalnames = false;
      if(this.state.image_r && this.state.image_l && this.state.image_r.name != '' && this.state.image_r.name == this.state.image_l.name) {

          identicalnames = true;
      }

      this.setState({ identicalnames: identicalnames, showIdenticalnamesMsg: identicalnames });
  }

  onUploadRight = event => {

    let file;
    if(event.target && event.target.files && event.target.files.length > 0) {
      file = event.target.files[0];
    }
    else if(event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0 && event.dataTransfer.files[0]) {
      file = event.dataTransfer.files[0];
    }

    if(file) {
      let type = file.type;
      if(type == "image/jpeg" || type == "image/jpg" || type == "image/png") {
          let image = new Image();
          image.name = file.name;
          image.type = type;
          event.target.value = '';

          if(image.name.includes('.jfif') ) {
            this.setState({displayWrongImageTypeR: true, displayWrongImageTypeMsg: messages.uploadpatient_wrongimagetype_body});
            return;
          }

          if(image.name.includes("OS") == true || image.name.toLowerCase().includes("left") == true || image.name.toLowerCase().includes("_l")) {
            this.setState({image_last: this.state.image_r, displayWrongImageSideL: true, displayWrongImageSideMsg: messages.uploadpatient_wrongimageside_left_body});
          }
          
          var reader = new FileReader();
          reader.onload = function(event) {
              var content = this.arrayBufferToBase64(event.target.result);
              image.content = content;              
              image.src = 'data:' + type + ';base64, ' + image.content;

              if(this.state.iiqEye === Eye.right) {
                let iiqEye = Eye.none;
                this.setState({ iiqEye: iiqEye }); 
              }
              
              if(this.state.iiqEye === Eye.both) {
                let iiqEye = Eye.left;
                this.setState({ iiqEye: iiqEye }); 
              }

              this.setState({ image_r: image }); 
              this.checkFileNameCorrection();
              this.canUpload();
          }.bind(this);

          reader.readAsArrayBuffer(file);  
      } else {
          event.target.value = '';
          this.setState({displayWrongImageTypeR: true, displayWrongImageTypeMsg: messages.uploadpatient_wrongimagetype_body});
      }
    }
  }; 

  onUploadLeft = event => {

    let file;
    if(event.target && event.target.files && event.target.files.length > 0) {
      file = event.target.files[0];
    }
    else if(event.dataTransfer.files && event.dataTransfer.files.length > 0 && event.dataTransfer.files[0]) {
      file = event.dataTransfer.files[0];
    }

    if(file) {
      let type = file.type;
      if(type == "image/jpeg" || type == "image/jpg" || type == "image/png") {
          let image = new Image();
          image.name = file.name;
          image.type = type;
          event.target.value = '';

          if(image.name.includes('.jfif') ) {
            this.setState({displayWrongImageTypeL: true, displayWrongImageTypeMsg: messages.uploadpatient_wrongimagetype_body});
            return;
          }

          if(image.name.includes("OD") == true || image.name.toLowerCase().includes("right") == true || image.name.toLowerCase().includes("_r")) {
            this.setState({image_last: this.state.image_l, displayWrongImageSideR: true, displayWrongImageSideMsg: messages.uploadpatient_wrongimageside_right_body});
          }

          var reader = new FileReader();
          reader.onload = function(event) {
              var content = this.arrayBufferToBase64(event.target.result);
              image.content = content;              
              image.src = 'data:' + type + ';base64, ' + image.content;

              if(this.state.iiqEye === Eye.left) {
                let iiqEye = Eye.none;
                this.setState({ iiqEye: iiqEye }); 
              }
              
              if(this.state.iiqEye === Eye.both) {
                let iiqEye = Eye.right;
                this.setState({ iiqEye: iiqEye }); 
              }

              this.setState({ image_l: image }); 
              this.checkFileNameCorrection();
              this.canUpload();
          }.bind(this);
          
          reader.readAsArrayBuffer(file);  
      } else {
        event.target.value = '';
        this.setState({displayWrongImageTypeL: true, displayWrongImageTypeMsg: messages.uploadpatient_wrongimagetype_body});
      }
    }
  }; 

  onDragOver (event) {
    event.preventDefault();
  }

  onDragStartEye = (event, fileImg) => {
    event.dataTransfer.setData("fileImg", fileImg);
  }

  onDropEye = (event, eye) => {

    let fileImg = event.dataTransfer.getData("fileImg");

    if(fileImg !== '') {

      let iiqEye = this.state.iiqEye;
    
      if(iiqEye === Eye.right) {
  
        iiqEye = Eye.left;
      }
      else if(iiqEye === Eye.left) {
  
          iiqEye = Eye.right;
      }
  
      
      let image_r = this.state.image_r;
      let image_l = this.state.image_l;

      if(!image_r.content) {
        image_r.background = '/eye_left.png';
  
        if(document.getElementById("fileinputleft")) {
          document.getElementById("fileinputleft").value = "";
        }
      }
  
      if(!image_l.content) {
        image_l.background = '/eye_right.png';
  
        if(document.getElementById("fileinputright")) {
          document.getElementById("fileinputright").value = "";
        }
      }
  
      this.setState({ image_r: image_l, image_l: image_r, iiqEye: iiqEye });

      if(image_l.name.includes("OS") == true || image_l.name.toLowerCase().includes("left") == true || image_l.name.toLowerCase().includes("_l")) {
        this.setState({ displayWrongImageSideL: true, displayWrongImageSideMsg: messages.uploadpatient_wrongimageside_left_body, is_drop_operation: true });
      } else if(image_r.name.includes("OD") == true || image_r.name.toLowerCase().includes("right") == true || image_r.name.toLowerCase().includes("_r")) {
        this.setState({ displayWrongImageSideR: true, displayWrongImageSideMsg: messages.uploadpatient_wrongimageside_right_body, is_drop_operation: true });
      }

    }
    else if (event.dataTransfer.files.length > 0) {
      event.preventDefault();
      
      if(eye == 'fileImgRight') {

        this.onUploadRight(event);
      }

      if(eye == 'fileImgLeft') {

        this.onUploadLeft(event);
      }
    }

  }   

  cancelUpload = () => {
    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v3/Images/cancelUpload", {
            method: "POST",
            body: JSON.stringify({
                session: this.props.session,
                logonid: this.props.username,
                currentuploadid: this.currentuploadid
            })
        }).then(response => response.json()).then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
            }

            this.setState({ startUploadExam: false });

            if (data.errorcode !== errorcodes.errorOK) {
            } else {

              this.setState({ displayUploadImagesSpinner: false, toastMessageText: "Exam Upload Canceled" });
              let timer = setInterval(() => { this.clearToastMessageText() }, 1500);
              this.setState({ timer: timer });
            }
        })
    }
  }

  clearToastMessageText() {

    this.setState({ toastMessageText: ""});

    if(this.state.timer != null) {
      clearTimeout(this.state.timer);
      this.setState({ timer: null });
    }
  }

  resetExam = () => {

    if(this.state.examid != '' || (this.state.image_r && this.state.image_r.content) || (this.state.image_l && this.state.image_l.content) ) {
      this.setState({ displayResetMessageMsg: true });
    }
    
  }

  resetExamCancel = () => {
    this.setState({displayResetMessageMsg: false})
  }

  resetExamReset = () => {
    
    this.initializeImages();
    this.setState({ examid: '', patientIdent: '', canUpload: false, displayResetMessageMsg: false, identicalnames: false, showIdenticalnamesMsg: false, iiqEye: Eye.none });

    if(document.getElementById("fileinputright")) {
      document.getElementById("fileinputright").value = "";
    }

    if(document.getElementById("fileinputleft")) {
      document.getElementById("fileinputleft").value = "";
    }

  }

  cancelExam = () => {

    if(this.state.displayUploadImagesSpinner === false) {
      this.setState({ displayCancelMessageMsg: true });
    } else {
      this.setState({ toastMessageText: "Canceling Exam Upload..." });
      this.controller.abort();
      this.cancelUpload();
    }
    
  }

  cancelExamCancel = () => {
    this.setState({displayCancelMessageMsg: false})
  }

  cancelExamOk = () => {

    this.props.onCancelCreateExam();
  }

  canUpload() {
    var result = true;

    if(this.state.examid == '') {
      result = false;
    }

    if(this.state.identicalnames == true) {
      result = false;
    }

    if(this.state.image_r == null) {
      result = false;
    }
    else {
      if(!this.state.image_r.content) {
        result = false;
      }
    }

    if(this.state.image_l == null) {
        result = false;
    }
    else {
      if(!this.state.image_l.content) {
        result = false;
      }
    }

    this.setState({ canUpload: result })
  }

  uploadPatient = () => {
    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {

        if(this.state.canUpload && this.state.startUploadExam === false) {
            this.currentuploadid = uuid();
            this.controller = new AbortController();
            const { signal } = this.controller;

            this.setState({displayUploadImagesSpinner: true, startUploadExam: true, displayUploadImagesMessageTitle: messages.uploadpatient_uploadimages_pleasewait_title, displayUploadImagesMessageBody: messages.uploadpatient_uploadimages_pleasewait_body})
            this.props.onStartUploadingExam();
            
            var image_id = uuid();
            let url = connections.sdkurl;
            let patientdateofbirth = null;

            fetch(url + "/AEYEAppServer/v3/Images/uploadImages", {
                signal,
                method: "POST",
                body: JSON.stringify({
                    session: sessionStorage.getItem('session'),
                    logonid: this.props.sessionData.username,
                    userdbid: this.props.sessionData.userdbid,
                    patientexamid: this.state.examid,                    
                    image_id: image_id,
                    image_source: "AEYE",
                    machineNumber: 0,
                    fileContentLeft: this.state.image_l.content,
                    fileContentRight: this.state.image_r.content,
                    fileNameLeft: this.state.image_l.name,
                    fileNameRight: this.state.image_r.name,
                    currentuploadid: this.currentuploadid,
                    
                    patientClinic: this.props.sessionData.userdbid,
                    patientdateofbirth: patientdateofbirth,
                    patientEmail: this.state.patientEmail,
                    patientSex: this.state.patientSex,
                    patientId: this.state.fullname,
                    patientIdent: this.state.patientIdent
                })
            }).then(response => response.json()).then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                if (data.errorcode !== errorcodes.errorOK) {

                    this.setState({displayUploadImagesSpinner: false, startUploadExam: false})

                    if (data.errorcode == errorcodes.AEYEHealthPatientInvalidID) {
                        this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: texts.uploadpatient_addNewImagesPatientInvalidID });
                    } else if (data.errorcode == errorcodes.AEYEHealthPatientInvalidName) {
                        this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: texts.uploadpatient_addNewImagesPatientInvalidName });
                    }  else if (data.errorcode == errorcodes.AEYEHealthFailedToFilterImages) {
                        this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: texts.uploadpatient_addNewImagesPatientDiagnosesFailed });
                    } else if (data.errorcode == errorcodes.AEYEHealthUploadedFailedToPublishImages) {
                        this.setState({ displayUploadErrorCloseToMain: true, displayUploadErrorMessageMsg: texts.uploadpatient_addNewImagesPatientUploadedDiagnosesFailed });
                    } else if (data.errorcode == errorcodes.AEYEHealthUploadedFailedTimeout) {
                        this.setState({ displayUploadErrorCloseToMain: true, displayUploadErrorMessageMsg: texts.uploadpatient_addNewImagesPatientUploadedDiagnosesTimeout });
                    } else if (data.errorcode == errorcodes.AEYEHealthUploadedFailedNoDiseaseIsSet) {
                        this.setState({ displayUploadErrorCloseToMain: true, displayUploadErrorMessageMsg: texts.uploadpatient_NoDiseaseWasSet });
                    } else if (data.errorcode == errorcodes.AEYEHealthUploadedFailedToCheckIIQ) {
                        this.setState({ displayUploadErrorCloseToMain: true, displayUploadErrorMessageMsg: texts.uploadpatient_FailToCheckFilters });
                    } else if (data.errorcode == errorcodes.AEYEHealthPatientExist) {
                      this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: texts.uploadpatient_examid_exist_body });
                    } else if(data.errorcode == errorcodes.policyImagesNoWritePermission) {
                      this.setState({ displayLoginFaildMessage: true });
                    } else {
                        this.setState({ displayUploadFatalMessage: true });
                    }
                } else {

                  if(data.bad_l == true || data.bad_r == true) {

                    let image_r = this.state.image_r;
                    image_r.iiq = data.bad_r;
                    let image_l = this.state.image_l;
                    image_l.iiq = data.bad_l;

                    let iiqEye = Eye.none;
                    if(data.bad_r == true) {

                      iiqEye = Eye.right;
                      if(data.bad_l == true) {
                        iiqEye = Eye.both;
                      }
                    }
                    else {
                      if(data.bad_l == true) {
                        iiqEye = Eye.left;
                      }
                    }

                    this.setState({ image_r: image_r, image_l: image_l, imageId: data.imageid, displayIIQ_ImageMessageMsg: true, iiqEye: iiqEye, displayUploadImagesSpinner: false, startUploadExam: false});                      
                  }
                  else{
                      this.props.onDoneUploadingExam();
                      this.setState({displayUploadImagesSpinner: false})
                  }
                }
            }).catch(e => {
                if( e.name != "AbortError") {
                    this.setState({ displayUploadImagesSpinner: false, displayUploadFatalMessage: true, startUploadExam: false });
                }
            });
        }
    }
  }

  onDoneDiagnoseExam = () => {

    if(this.state.displayUploadImagesSpinner === true && this.props.diagnosisIsOver === true) {
      this.setState({displayUploadImagesSpinner: false})
    }
    
  }

  recordResults = () => {
    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {
        this.controller = new AbortController();
        const { signal } = this.controller;

        this.setState({displayUploadImagesSpinner: true, displayIIQ_ImageMessageMsg: false})
        var image_id = uuid();
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v3/Images/recordResults", {
            signal,
            method: "POST",
            body: JSON.stringify({
                session: this.props.session,
                logonid: this.props.username,
                imageid: this.state.imageId,
                accepted: true
            })
        }).then(response => response.json()).then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
            }

            this.setState({displayUploadImagesSpinner: false})

            if (data.errorcode !== errorcodes.errorOK) {
                this.setState({ displayRecordResultMessage: true });
            } else {
                this.props.onDoneUploadingExam();
            }
        }).catch(e => {
            if( e.name != "AbortError") {
                this.setState({ displayRecordResultMessage: true });
            }
        });
    }
  }

  iiqImageReplaceImage = () => {
    this.setState({displayIIQ_ImageMessageMsg: false})
  }

  uploadFatalMessageBox() {
    return(
      <Modal show={this.state.displayUploadFatalMessage} centered dialogClassName={"iiq-popup"}>
          
        <Modal.Header>

          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>

        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {messages.uploadpatient_uploadimages_fatal_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{messages.uploadpatient_uploadimages_fatal_body}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleUploadFatalMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>  
    );
  }

  handleUploadFatalMessageBox = event => {
    this.setState({displayUploadFatalMessage: false})
  }

  uploadErrorMessageBox() {
    return(
      <Modal show={this.state.displayUploadErrorMessage} centered dialogClassName={"iiq-popup"}>
            
        <Modal.Header>

          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>

        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {errormessages.uploadpatient_addNewImagesPatient_error_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{this.state.displayUploadErrorMessageMsg}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleUploadErrorMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>      
    );
  }

  handleUploadErrorMessageBox = event => {
    this.setState({displayUploadErrorMessage: false})
  }

  loginFaildErrorMessageBox() {
    return(
      <Modal show={this.state.displayLoginFaildMessage} centered dialogClassName={"iiq-popup"}>
            
        <Modal.Header>

          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>

        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {errormessages.loginform_externalloginfailed} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{texts.uploadpatient_failToLogin_body}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleLoginFaildErrorMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>      
    );
  }

  handleLoginFaildErrorMessageBox = () => {
    this.setState({displayLoginFaildMessage: false, redirectToLogin: true})
  }

  uploadErrorCloseToMainMessageBox() {
    return(
      <Modal show={this.state.displayUploadErrorCloseToMain} centered dialogClassName={"iiq-popup"}>
              
        <Modal.Header>

          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>

        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {errormessages.uploadpatient_addNewImagesPatient_error_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{this.state.displayUploadErrorMessageMsg}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleUploadErrorCloseToMainMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>  
    );
  }
    
  handleUploadErrorCloseToMainMessageBox = event => {
    this.setState({ displayUploadErrorCloseToMain: false })
  }

  iiqImageMessageBox() {
    return(
    <Modal show={this.state.displayIIQ_ImageMessageMsg} centered dialogClassName={"iiq-popup"}>
        
        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {componentsStrings.label_image_iiq} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>

          {<div>                
              <span className="red">{this.state.iiqEye} - </span> {texts.messagebody_iiq}
            </div>}

        </Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col xs={6} style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.recordResults}>
            {texts.messagebody_submit_anyway}
          </Col>
        
          <Col xs={6} style={{ padding: '2%', cursor: 'default' }} onClick={this.iiqImageReplaceImage}>
            {texts.messagebody_replace_image}
          </Col>

        </Row>

    </Modal>        
    );
  }

  recordResultsFatalMessageBox() {
    return(

      <Modal show={this.state.displayRecordResultMessage} centered dialogClassName={"iiq-popup"}>
                
        <Modal.Header>

          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>

        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {messages.uploadpatient_recordresults_fatal_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{texts.uploadpatient_recordresults_fatal_body}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleRecordResultFatalMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>  

    );
  }

  resetExamMessageBox() {
    return(
    <Modal show={this.state.displayResetMessageMsg} centered dialogClassName={"iiq-popup"}>
        
        <Modal.Header>
          <Modal.Title>
            <img src={"/trash.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {texts.messagebody_reset_images_label} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>

          {<div>                
              {texts.messagebody_reset_images_body}
            </div>}

        </Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col xs={6} style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.resetExamCancel}>
            {componentsStrings.button_cancel}
          </Col>
        
          <Col xs={6} style={{ padding: '2%', cursor: 'default' }} onClick={this.resetExamReset}>
            {componentsStrings.button_reset}
          </Col>

        </Row>

    </Modal>        
    );
  }

  cancelExamMessageBox() {
    return(
    <Modal show={this.state.displayCancelMessageMsg} centered dialogClassName={"iiq-popup"}>
        
        <Modal.Header>
          <Modal.Title>
            <img src={"/trash.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {texts.messagebody_cancel_images_label} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>

          {<div>                
              {texts.messagebody_cancel_images_body}
            </div>}

        </Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col xs={6} style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.cancelExamCancel}>
            {componentsStrings.button_no}
          </Col>
        
          <Col xs={6} style={{ padding: '2%', cursor: 'default' }} onClick={this.cancelExamOk}>
            {componentsStrings.button_yes}
          </Col>

        </Row>

    </Modal>        
    );
  }

  handleRecordResultFatalMessageBox = event => {
    this.setState({displayRecordResultMessage: false})
  }

  displayWrongImageSideMessageBox() {
    return(
    <Modal show={(this.state.displayWrongImageSideR || this.state.displayWrongImageSideL)} centered dialogClassName={"iiq-popup"}>

        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header align="center">

          <Modal.Title> {messages.uploadpatient_wrongimageside_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{this.state.displayWrongImageSideMsg}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleWrongSideYesMessageBox}>
            {componentsStrings.button_yes}
          </Col>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleWrongSideNoMessageBox}>
            {componentsStrings.button_no}
          </Col>

        </Row>

    </Modal>        
    );
  }

  handleWrongSideYesMessageBox = () => {
    this.setState({displayWrongImageSideR: false, displayWrongImageSideL: false, displayWrongImageSideMsg: "", is_drop_operation: false}, () => { this.checkFileNameCorrection() } );
  }

  handleWrongSideNoMessageBox = () => {
    if(this.state.is_drop_operation === true) {

      let image_r = this.state.image_l;
      let image_l = this.state.image_r;

      if(!image_r.content) {
        image_r.background = '/eye_right.png';
      }

      if(!image_l.content) {
        image_l.background = '/eye_left.png';
      }

      this.setState({image_r: image_r, image_l: image_l}, () => { this.checkFileNameCorrection() } );
    }
    else {

      if(this.state.displayWrongImageSideR == true) {

        let image = new Image();
        image.background = '/eye_left.png';
        image.src = '';
        image.name = '';

        this.setState({image_l: image}, () => { this.checkFileNameCorrection() } );
      }

      if(this.state.displayWrongImageSideL == true) {

          let image = new Image();
          image.background = '/eye_right.png';
          image.src = '';
          image.name = '';

          this.setState({image_r: image}, () => { this.checkFileNameCorrection() } );
      }

    }

    this.setState({displayWrongImageSideR: false, displayWrongImageSideL: false, displayWrongImageSideMsg: "", is_drop_operation: false});
  }

  displayImagesIdenticalMessageBox() {
    return(
    <Modal show={this.state.showIdenticalnamesMsg && !this.state.displayWrongImageSideR && !this.state.displayWrongImageSideL} centered dialogClassName={"iiq-popup"}>
        
        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {messages.uploadpatient_identicalimage_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{messages.uploadpatient_identicalimage_body}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleIdenticalOKMessageBox}>
            {componentsStrings.button_ok}
          </Col>

        </Row>

    </Modal>        
    );
  }

  handleIdenticalOKMessageBox =() => {
    this.setState({showIdenticalnamesMsg: false});
  }

  displayWrongImagTypeMessageBox() {
    return(
      <Modal show={this.state.displayWrongImageTypeR || this.state.displayWrongImageTypeL} centered dialogClassName={"iiq-popup"}>
          
        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {messages.uploadpatient_wrongimagetype_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>

          {<div>                
            {this.state.displayWrongImageTypeMsg}
          </div>}

        </Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleWrongTypeOKMessageBox}>
            {componentsStrings.button_ok}
          </Col>

        </Row>

      </Modal>   
    );
  }

  handleWrongTypeOKMessageBox =() => {
    this.setState({displayWrongImageTypeR: false, displayWrongImageTypeL: false});
  }

  displayToastMessageBox() {
    return(
    <Modal show={this.state.toastMessageText !== ''} centered dialogClassName={"toast-popup"}>

      <Modal.Body>{this.state.toastMessageText}</Modal.Body>

    </Modal>        
    );
  }

  render() {

    if(this.state.redirectToLogin) {
      return <Redirect to={"/"} />
    }

    let zIndex = "";

    if(this.state.displayUploadImagesSpinner === true) {
      zIndex = "9999";
    }

    let icon = 'default';

    if(this.state.canUpload) {
      icon = 'pointer';
    }

    return (
        <React.Fragment>

          {this.resetExamMessageBox()}
          {this.cancelExamMessageBox()}
          {this.recordResultsFatalMessageBox()}
          {this.iiqImageMessageBox()}
          {this.uploadFatalMessageBox()}
          {this.uploadErrorMessageBox()}
          {this.uploadErrorCloseToMainMessageBox()}
          {this.displayImagesIdenticalMessageBox()}          
          {this.displayWrongImagTypeMessageBox()}
          {this.displayToastMessageBox()}
          {this.displayWrongImageSideMessageBox()}
          {this.loginFaildErrorMessageBox()}
          
          {this.onDoneDiagnoseExam()}


          {this.state.displayUploadImagesSpinner === true && <div class="backdrop"></div>}


          <Col className="bg-black scroll exam-scroll">
            
            <div className="exam-container">

              {/* date */}
              <Row>
                
                <Col>
                  <div className="fs16 text gray_3 mt-4 mb-3">
                    {this.state.localDate}
                  </div>
                </Col>

              </Row>

              {/* download/send/archive buttons */}
              <Row>

                <Col>
                  <ExamButtons handleDownloadDiagnosisReport={null} handleSendDiagnosisReport={null} handleArchiveUnarchiveDiagnosis={null}>
                  </ExamButtons>
                </Col>
                
              </Row>
              
              {/* exam id + buttons*/}
              <Row>

                <Col>
                  <div className="d-flex align-items-center pt-2">

                      <input id="uploadexam_id" className="examid-input lh22 fs18 text white pl-2 mr-2" type="text" 
                            placeholder={componentsStrings.uploadpatient_examid} onChange={this.handleExamIDChange} 
                            value={this.state.examid}>
                      </input>

                      <Button className="btn btn-primary button mr-2 ml-1" style={{ cursor: icon}} id="uploadpatient_upload" onClick={this.uploadPatient} disabled={!this.state.canUpload} variant="primary">{componentsStrings.uploadpatient_submitexam}</Button> 
                      
                      <Button className="btn btn-dark button mr-2 ml-1" id="uploadpatient_reset" onClick={this.resetExam}>
                        {componentsStrings.button_reset}
                      </Button> 
                      
                      <Button className="btn btn-dark button mr-2 ml-1" id="uploadpatient_cancel" onClick={this.cancelExam} style={{zIndex: zIndex}}>
                        {componentsStrings.button_cancel}
                      </Button> 

                  </div>
                </Col>

              </Row>


              {/* eyes */}
              <Row>

                <Col>

                  {this.state.displayPopup && <Popover placement="bottom" className="ml-3 p-1">
                    {texts.messagebody_popup_characters}
                  </Popover>}

                  <div className="d-flex justify-content-between align-items-start mt-4 pt-5 mb-3">

                    <EyeInput id="uploadpatient_righteye" onUpload={this.onUploadRight} onDragOver={(e)=>this.onDragOver(e)} 
                              onDrop={(e)=>this.onDropEye(e, "fileImgRight")} onDragStart = {(e) => this.onDragStartEye(e, "fileImgRight")} inputid={"fileinputright"} 
                              image={this.state.image_r} label={componentsStrings.uploadpatient_righteye} fileName={this.state.image_r_name}
                              identicalnames={this.state.identicalnames} displayUploadImagesSpinner={this.state.displayUploadImagesSpinner} isDisplayMode={false} 
                              hasIiqImage={this.state.iiqEye != Eye.none} isIiqEye={this.state.iiqEye == Eye.both || this.state.iiqEye == Eye.right} colStyle={"mt-3 pt-1 pl-0 pr-0 mb-5"}>
                    </EyeInput>
                    
                    <EyeInput id="uploadpatient_lefteye" onUpload={this.onUploadLeft} onDragOver={(e)=>this.onDragOver(e)} 
                              onDrop={(e)=>this.onDropEye(e, "fileImgLeft")} onDragStart = {(e) => this.onDragStartEye(e, "fileImgLeft")} inputid={"fileinputleft"}
                              image={this.state.image_l} label={componentsStrings.uploadpatient_lefteye} fileName={this.state.image_l_name}
                              identicalnames={this.state.identicalnames} displayUploadImagesSpinner={this.state.displayUploadImagesSpinner} isDisplayMode={false}
                              hasIiqImage={this.state.iiqEye != Eye.none} isIiqEye={this.state.iiqEye == Eye.both || this.state.iiqEye == Eye.left} colStyle={"mt-3 pt-1 pr-0 pl-0 mb-5 text-align-webkit-right"}>
                    </EyeInput>

                  </div>
                </Col>

              </Row>

            </div>

          </Col>

          <Col className="bg-black p-0 scroll diseases-scroll left-border" style={{maxWidth: "22.5%", height: '-webkit-fill-available'}}>
            <DiseasesList session={this.props.session} sessionData={this.props.sessionData}
                        username={this.props.username} clinicid={this.props.clinicid}
                        aeyetabledata={this.state.aeyetabledata} imagedbid={this.state.imagedbid} 
                        diagstate={this.state.diagstate} diagnosticdetails={this.state.diagnosticdetails}
                        >

            </DiseasesList>

          </Col>

        </React.Fragment>
    );

  }

}

export default ExamCreationForm;