import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class LogoutForm extends Component {
  state = {
    };
  
  componentDidMount() {

    }

  render() {
    if (this.props.redirectToRoot) {
        return <Redirect to={this.props.redirectToRoot} />
      }        
    }
}

export default LogoutForm;