import React, { Component, Fragment} from "react";
import Button from 'react-bootstrap/Button'
import DialogBox from 'react-modeless'
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import componentsStrings from "../../Messages/Components";


const dialogStyle = {
  width: '30vw',
  boxShadow: 'rgba(0,0,0,.3) 0 0.3rem 1rem',
  background: '#f2f2f2'
}

class AddCameraToOperator extends Component {
  state = {
    barcodenumber: "",
      handleSelectTheCamera: null,
      handleCancleTheCamera: null,
      barcodenumberCorrect: false
    };
  constructor() {
    super();
  }
  componentDidMount() {
    this.setState({handleSelectTheCamera: this.props.onSelectCamera, handleCancleTheCamera: this.props.onCancleCamera});
  }
  componentDidUpdate () {
    if(document.getElementById("addcameratooperator_cancel") != null && this.state.barcodenumberCorrect == false) {
      document.getElementById("addcameratooperator_cancel").focus();
    }
  }
  handleEnterBarcode = event => {
    let barcodenumberCorrect = false;
    if(event.target.value.length > 0) {
      barcodenumberCorrect = true;
    }
    this.setState({ barcodenumber: event.target.value, barcodenumberCorrect: barcodenumberCorrect });
  };
  selectCameraCancel = () => {
    this.state.handleCancleTheCamera();
  }
  selectCameraOK = () => {
    this.state.handleSelectTheCamera(this.state.barcodenumber);
  }
  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.selectCameraOK()
    }
  }
  render() {
      return (
        <React.Fragment>

          <DialogBox isOpen={this.props.openCameraSelectDialog} onClose={this.selectCameraCancel} style={dialogStyle} containerClassName={'container'} noBackdrop={true} clickBackdropToClose={false}>
              <Row style={{}}>

                <Col className='m-4'>
                  <div >
                      <Form>
                    
                          <Form.Group controlId="addcameratooprlable">
                              <Form.Label style={{fontSize: 40, paddingBottom: '10px'}}>{componentsStrings.addcameratooperator_enterbarcode_caution_label}</Form.Label>
                              <Form.Label>{componentsStrings.addcameratooperator_enterbarcode_label}</Form.Label>
                          </Form.Group>

                          <Form.Group controlId="addcameratooprbutton" style={{}}>
                            <Form.Control style={{marginBottom: '10px'}} autocomplete="off" type="text" placeholder={componentsStrings.addcameratooperator_enterbarcode_placeholder} onChange={this.handleEnterBarcode} onKeyPress={this.handleKeyPress}/>
                            <Button style={{float: 'right', marginLeft: '10px'}} id="addcameratooperator_ok" disabled={!this.state.barcodenumberCorrect} variant="primary" type="button" onClick={() => { this.selectCameraOK() }} onKeyPress={this.handleKeyPress}>{componentsStrings.button_enter}</Button>
                            <Button style={{float: 'right'}} id="addcameratooperator_cancel" variant="primary" type="button" onClick={() => { this.selectCameraCancel() }}>{componentsStrings.button_cancel}</Button>
                          </Form.Group>
                      </Form>
                  </div>
                </Col>

              </Row>
          </DialogBox> 

        </React.Fragment>
      );
    }
}

export default AddCameraToOperator;