import React, { Component } from "react";
import "../../css/general.css"
import "../../css/colors.css"
import "../../css/texts.css"


class DiseasCard extends Component {

  state = {

  };

  componentDidMount() {

  }


  render() {

    let classname = 'diseas-card bg-black bottom-border d-flex flex-column pt-3 pb-3';

    return (
      <React.Fragment>

          <div className={classname + ' ' + this.props.healtyOrSickClassname} id={this.props.diseasId}>

            <div id={this.props.diseasId} className="lh32 fs26 text gray_3" >
              {this.props.diseasName}
            </div>

            {this.props.nodiagnosticdetails == false && this.props.diseasDiagnosis != '' && <div id={this.props.diagnosisId} className="lh24 fs20 text gray_3">
              {this.props.diseasDiagnosis}
            </div>}
            
        </div>
      </React.Fragment>
    );
  }

}

export default DiseasCard;
