import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import {CookiesProvider} from "react-cookie";
import createBrowserHistory from 'history/createBrowserHistory'

const history = createBrowserHistory()
ReactDOM.render(<CookiesProvider><App history={history}/></CookiesProvider>, document.getElementById("root"));
