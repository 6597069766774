import React, { Component} from "react";
import { Card, Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { format } from 'react-string-format';
import componentsStrings from "../../Messages/Components";


class AEYEResearchDiagnosedDetails extends Component {
    
    state = {
    };
    
    componentDidMount() {

    }

    getDiseasesDescription(diseases, bad) {
        let glaucoma = componentsStrings.button_notanalyze;
        let armd = componentsStrings.button_notanalyze;
        let mtmdr = componentsStrings.button_notanalyze;
        let hr = componentsStrings.button_notanalyze;
        let p = componentsStrings.button_notanalyze;
        let rvo = componentsStrings.button_notanalyze;
        let dme = componentsStrings.button_notanalyze;
        let other = componentsStrings.button_notanalyze;
        let cataract = componentsStrings.button_notanalyze;

        let chAge = '';
        let chGender = '';
        let chFieled = '';

        if(diseases && bad == false) {
            for (const [index, disease] of diseases.entries()) {
                switch(disease.diseasename) {
                    case "diagAgeRelatedMacularDegeneration":
                        if(disease.diagnosis == 5) {
                            armd = componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            armd = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                    case "diagDiabeticRetinopathy":
                        if(disease.diagnosis == 5) {
                            mtmdr = componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            mtmdr = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                    case "diagHypertensiveRetinopathy":
                        if(disease.diagnosis == 5) {
                            hr = componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            hr = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                    case "diagPapilledema":
                        if(disease.diagnosis == 5) {
                            p = componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            p = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                    case "diagRetinalVascularOcclusion":
                        if(disease.diagnosis == 5) {
                            rvo = componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            rvo = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                    case "diagDME":
                        if(disease.diagnosis == 5) {
                            dme = componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            dme = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                    case "diagGlaucoma":
                        if(disease.diagnosis == 5) {
                            glaucoma =componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            glaucoma = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                    case "diagAge":
                        if(disease.chresults != null) {
                            const chjsonresults = JSON.parse(disease.chresults);
                            let theage = parseFloat(chjsonresults.age_prediction).toFixed(1)
                            chAge = theage;
                        }
                        break;
                    case "diagGender":
                        if(disease.chresults != null) {
                            const chjsonresults = JSON.parse(disease.chresults);
                            chGender = chjsonresults.gender_prediction;
                        }
                        break;
                    case "diagField":
                        if(disease.chresults != null) {
                            const thefieled = JSON.parse(disease.chresults);
                            chFieled = thefieled;
                        }
                        break;
                    case "diagOther":
                        if(disease.diagnosis == 5) {
                            other = componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            other = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                    case "diagCataract":
                        if(disease.diagnosis == 5) {
                            cataract = componentsStrings.aeyedetailstable_failedtodiagnose;
                        } else {
                            cataract = (disease.diagnosis == 1) ? componentsStrings.button_yes : componentsStrings.button_no;
                        }
                        break;
                }
            }
        }
      
        return [chFieled, chAge, chGender, glaucoma, armd, mtmdr, hr, p, rvo, other, cataract, dme];
    }

    render() {
        let glaucomaL = false;
        let glaucomaR = false;
        let armdL = false;
        let armdR = false;
        let mtmdrL = false;
        let mtmdrR = false;
        let hrL = false;
        let hrR = false;
        let pL = false;
        let pR = false;
        let rvoL = false;
        let rvoR = false;
        let dmeL = false;
        let dmeR = false;
        let cataractL = false;
        let cataractR = false;
        let otherL = false;
        let otherR = false;
        let age = false;
        let gender = false;

        let glaucomaL_d = false;
        let glaucomaR_d = false;
        let armdL_d = false;
        let armdR_d = false;
        let mtmdrL_d = false;
        let mtmdrR_d = false;
        let hrL_d = false;
        let hrR_d = false;
        let pL_d = false;
        let pR_d = false;
        let rvoL_d = false;
        let rvoR_d = false;
        let dmeL_d = false;
        let dmeR_d = false;
        let cataractL_d = false;
        let cataractR_d = false;
        let otherL_d = false;
        let otherR_d = false;

        let button_no = componentsStrings.button_no;

        let chAgeL = -1, chAgeR = -1, chAgeP = -1, chAgeL_d = -1, chAgeR_d = -1, chAgeP_two = -1;
        let chGenderL = -1, chGenderR = -1, chGenderP = -1, chGenderL_d = -1, chGenderR_d = -1, chGenderP_two = -1;
        let chFieledL = null, chFieledR = null, chFieledP = null, chFieledL_d = null, chFieledR_d = null, chFieledP_two = null;
        if(this.props.diagnosticdetails) {

            [chFieledL, chAgeL, chGenderL, glaucomaL, armdL, mtmdrL, hrL, pL, rvoL, otherL, cataractL, dmeL] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesL, this.props.bad_l);
            [chFieledR, chAgeR, chGenderR, glaucomaR, armdR, mtmdrR, hrR, pR, rvoR, otherR, cataractR, dmeR] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesR, this.props.bad_r);
            [chFieledP, chAgeP, chGenderP] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesP, this.props.bad_p);
          
            {this.props.diagnoseby == 'Algorithm' && chAgeP != -1 && this.props.setAge(chAgeP)}
            {this.props.diagnoseby == 'Algorithm' && chGenderP != -1 && this.props.setGender(chGenderP)}

            if(this.props.discexist == true) {

                [chFieledL_d, chAgeL_d, chGenderL_d, glaucomaL_d, armdL_d, mtmdrL_d, hrL_d, pL_d, rvoL_d, otherL_d, cataractL_d, dmeL_d] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesL_d, this.props.bad_l_d);
                [chFieledR_d, chAgeR_d, chGenderR_d, glaucomaR_d, armdR_d, mtmdrR_d, hrR_d, pR_d, rvoR_d, otherR_d, cataractR_d, dmeR_d] = this.getDiseasesDescription(this.props.diagnosticdetails.diseasesR_d, this.props.bad_r_d);
            }
        }

        let msg = "";
        if(chFieledP != null) {
            msg = format(componentsStrings.aeyedetailstable_field_title, chFieledP.image_field_1);
        }
        return (
            <React.Fragment>

                <Container id="aeyedetailstable_results" className="pl-4" fluid="xxl">
                    
                    <Row>
                        <Col md="6"> 
                            {componentsStrings.diagnosetool_righteye}
                        </Col>

                        <Col md="6">
                            {componentsStrings.diagnosetool_lefteye}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            
                            <Row>

                                <Col md="6">
                                    <Row className="mt-2"><img src={this.props.srcR} style={{height: '90%', width: '90%'}}/></Row>
                                    
                                    {this.props.diagnoseby == 'Algorithm' && chFieledP != '' && chFieledP != null &&
                                        <div>
                                            <p></p>
                                            <Row style={{textAlign: 'center', fontSize: 'small'}}>
                                                {<p style={{margin: 'auto', color: '#ccc'}}>
                                                    {msg}
                                                </p>}
                                            </Row>
                                            
                                            <Row style={{textAlign: 'center', fontSize: 'small'}}>
                                                {<p style={{margin: 'auto', color: '#ccc'}}>
                                                    {componentsStrings.aeyedetailstable_side_title + chFieledP.image_side_1}
                                                </p>}
                                            </Row>
                                        </div>
                                    }
                                </Col>

                                <Col md="6" style={{paddingLeft: '0px'}}>

                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_dr}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_drR">
                                            {mtmdrR}
                                        </Col>
                                    </Row>

                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_glaucoma}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_gR">
                                            {glaucomaR}
                                        </Col>
                                    </Row>  

                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_arm}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_armdR">
                                            {armdR}
                                        </Col>
                                    </Row>  

                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_hr}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_hrR">
                                            {hrR}
                                        </Col>
                                    </Row>  

                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_p}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_pR">
                                            {pR}
                                        </Col>
                                    </Row>  
                                   
                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_rvo}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_rvoR">
                                            {rvoR}
                                        </Col>
                                    </Row>  

                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_dme}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_dmeR">
                                            {dmeR}
                                        </Col>
                                    </Row>  

                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_cataract}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_catR">
                                            {cataractR}
                                        </Col>
                                    </Row>  

                                    <Row> 
                                        <Col md="6">
                                            {componentsStrings.aeyedetailstable_other}
                                        </Col>
                                        <Col md="6" id="diagnosetool_summary_oR">
                                            {otherR}
                                        </Col>
                                    </Row>  

                                </Col>

                            </Row>

                        </Col>

                        <Col>

                            <Row>
                        
                                    <Col md="6">
                                        <Row className="mt-2"><img src={this.props.srcL} style={{height: '90%', width: '90%'}}/></Row>

                                        {this.props.diagnoseby == 'Algorithm' && chFieledP != '' && chFieledP != null &&
                                        <div>
                                            <p></p>
                                            <Row style={{textAlign: 'center', fontSize: 'small'}}>
                                                {<p style={{margin: 'auto', color: '#ccc'}}>
                                                    {componentsStrings.aeyedetailstable_field_title + chFieledP.image_field_2 + componentsStrings.aeyedetailstable_field_title_centered}
                                                </p>}
                                            </Row>
                                            
                                            <Row style={{textAlign: 'center', fontSize: 'small'}}>
                                                {<p style={{margin: 'auto', color: '#ccc'}}>
                                                    {componentsStrings.aeyedetailstable_side_title + chFieledP.image_side_2}
                                                </p>}
                                            </Row>
                                        </div>
                                    }
                                    </Col>

                                    <Col md="6" style={{paddingLeft: '0px'}}>

                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_dr}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_drL">
                                                {mtmdrL}
                                            </Col>
                                        </Row>

                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_glaucoma}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_gL">
                                                {glaucomaL}
                                            </Col>
                                        </Row>  

                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_arm}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_armdL">
                                                {armdL}
                                            </Col>
                                        </Row>  

                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_hr}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_hrL">
                                                {hrL}
                                            </Col>
                                        </Row>  

                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_p}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_pL">
                                                {pL}
                                            </Col>
                                        </Row>  
                                    
                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_rvo}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_rvoL">
                                                {rvoL}
                                            </Col>
                                        </Row>  

                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_dme}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_dmeL">
                                                {dmeL}
                                            </Col>
                                        </Row>  

                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_cataract}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_catL">
                                                {cataractL}
                                            </Col>
                                        </Row>  

                                        <Row> 
                                            <Col md="6">
                                                {componentsStrings.aeyedetailstable_other}
                                            </Col>
                                            <Col md="6" id="diagnosetool_summary_oL">
                                                {otherL}
                                            </Col>
                                        </Row>  

                                    </Col>

                            </Row>

                        </Col>
                    </Row>

                    <Row style={{paddingTop: '20px'}}></Row>
                    
                    {this.props.diagnosticdetails && this.props.diagnosticdetails.comments != "" && <Row>
                        <Col> 
                            <div className="m-2">Comments</div>

                            <Card style={{backgroundColor: '#fff8e4', borderRadius: '1.25rem'}}>
                                <Card.Body>
                                    <Card.Text id="diagnosetool_summary_comments">{this.props.diagnosticdetails.comments}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>}

                </Container>

            </React.Fragment>
        );
    }

}

export default AEYEResearchDiagnosedDetails;