export default class errorcodes {
  static errorOK = 100000;
  static AEYEHealthSDKFatal = 100001;

  static AEYEHealthSDKFailedToAddNewUserAlreadyExist = 100009;
	static AEYEHealthSDKFailedToLogin = 100003;
  static AEYEHealthPatientInvalidID = 110004;
	static AEYEHealthPatientInvalidName = 110005;
  static AEYEHealthSDKSessionTimeout = 100011;
	static AEYEHealthPatientExist = 110007;
	static AEYEHealthSDKSoonLoginBlocked = 100017;
	static AEYEHealthSDKSoonLoginWillBeBlocked = 100018;

  static AEYEHealthOperatorFailedToGetSelectedCamera = 130000;
  static AEYEHealthOperatorNoCameraSelected = 130001;
  
  static AEYEHealthServiceCameraNotExist = 140000;
  static AEYEHealthFailedToUploadImages = 140001;
	static AEYEHealthUploadedFailedToPublishImages = 14002;
	static AEYEHealthUploadedFailedNoDiseaseIsSet = 14003;
  static AEYEHealthUploadedFailedTimeout = 14004;
  static AEYEHealthUploadedFailedToCheckIIQ = 14005;
  
  static AEYEHealthFailedToFilterImages = 150000;
	static AEYEHealthFailedToCheckMTMDR = 150001;
	static AEYEHealthFailedToCheckGlaucoma = 150002;

  static AEYEHealthServiceExamOldExamInProcess = 170002;

  static loginFailed = 200006;
  static policyImagesNoWritePermission = 200007;
}
