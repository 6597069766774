import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import "../../css/aeyehealth.css"
import errorcodes from "../errorcodes";
import uuid from 'react-uuid'
import Modal from 'react-bootstrap/Modal'
import { Redirect } from "react-router-dom";
import connections from "../../connections";
import DatePicker from "react-datepicker";
import DropdownButton from 'react-bootstrap/DropdownButton'
import AddCameraToOperator from '../Dialogs/AddCameraToOperator'
import sdkvars from "../sdkVariables";
import Spinner from 'react-bootstrap/Spinner'
import errormessages from "../../Messages/ErrorMessages";
import messages from "../../Messages/Messages";
import componentsStrings from "../../Messages/Components";
import Container from "react-bootstrap/Container";
import Loader from "react-loader-spinner";
import { format } from 'react-string-format';

class untagedImage {
    imagetype = null;
    image = null;
    imagecameraid = 0;
    badimage = false;
    imageid = 0;
    imagename = "";
}

const centerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '20%'
}

const timer = null;

class UploadPatient extends Component {
    controller = new AbortController();
    currentuploadid = null;
    state = {
        username: "",
        session: "",
        usertype: "",
        fullname: "",
        examPatientName: "",
        patientIdent: "",
        patientexamid: "",
        patientexamiddisabled: false,
        userdbid: 0,
        patientEmail: "",
        selectedFileRight: null,
        selectedFileLeft: null,
        patientSex: -1,
        fileContentLeft: null,
        fileContentRight: null,
        fileImgLeft: '/upload_left.png',
        fileImgRight: '/upload_right.png',
        fileImgLeftDBID: 0,
        fileImgRightDBID: 0,
        showGetCertificateDomain: true,
        displayErrorMessage: false,
        displayFatalErrorMessage: false,
        displayExamProcessErrorMessage: false,
        displayExamProcessFailedMessage: false,
        displayExamProcessCriticalMessage: false,
        displayRecordResultProcessMessage: false,
        displayUploadImagesMessage: false,
        displayUploadImagesMessageTitle: "",
        displayUploadImagesMessageBody: "",
        displayGetOldExamMessage: false,
        displayUploadFatalMessage: false,
        displayRecordResultMessage: false,
        displayUploadErrorMessage: false,
        displayUploadErrorCloseToMain: false,
        displayUploadErrorMessageMsg: "",
        displayUploadResults: false,
        displayNeedToSelectCameraFirst: false,
        displayCameraSessionAborted: false,
        operatornameSessionAborted: "",
        displayExamProcessErrorMessageTitle: "",
        displayExamProcessErrorMessageBody: "",
        displayExamProcessCriticalMessageTitle: "",
        displayExamProcessCriticalMessageBody: "",
        displayExamProcessFailedMessageTitle: "",
        displayExamProcessFailedMessageBody: "",
        redirectToMain: null,
        redirectToUploadResults: null,
        displayUploadSuccessMsg: false,
        displayIIQMsg: false,
        examfailedretry: false,
        patientdateofbirth: null,
        showCameras: true,
        allcameras: [],
        fetchcameras: true,
        cameraselected: '',
        selectedbarcode: "",
        selectedtype: "",
        openCameraSelectDialog: false,
        stopcheckimages: false,
        browsefile: "file",
        waitForImageDialog: false,
        waitForImage: false,
        showAllCameras: false,
        fullnamedisabled: false,
        cameraselectiondisabled: false,
        patientidentdisabled: false,
        allUntagedImages: [],
        waitForCameraImageMsg: "",
        waitForSubmitionDialog: false,
        closeSubmitionDialog: false,
        displayWrongImageSideR: false,
        displayWrongImageSideL: false,
        displayWrongImageTypeR: false,
        displayWrongImageTypeL: false,
        displayWrongImageSideMsg: "",
        displayWrongImageTypeMsg: "",
        waitForSubmitionMsg: "",
        bad_l: false,
        bad_r: false,
        qualitytext_l: "",
        qualitytext_r: "",
        fileNameLeft: "",
        fileNameRight: "",
        bad_l_show: 'container-drag',
        bad_r_show: 'container-drag',
        currentimageid: 0,
        identicalnames: false,
        examreadytosubmit: false,
        tasks: [
            {name:"Learn Angular",category:"wip", bgcolor: "yellow"},
            {name:"React", category:"wip", bgcolor:"pink"},
            {name:"Vue", category:"complete", bgcolor:"skyblue"}
          ] ,
        currentlyawaitingphoto: 'stage',
        camerawasselected: false,
        showabortbutton: true,
        checkforimage: false,
        checkforsubmit: false,
        displayTimeoutMsg: false,
        displaynointernetconnection : false,

        LAST_allUntagedImages: [],
        LAST_fileImgLeft: null,
        LAST_fileContentLeft: null,
        LAST_fileImgLeftDBID: null,
        LAST_fileNameLeft: null,
        LAST_bad_l: null,
        LAST_bad_l_show: null,

        LAST_fileImgRight: null,
        LAST_fileContentRight: null,
        LAST_fileImgRightDBID: null,
        LAST_filenameRight: null,
        LAST_bad_r: null,
        LAST_bad_r_show: null,

        LAST_images_update_eye: null 
    };

    

    componentDidMount() {

        if(this.props.examdbid != 0) {
            let theUrl = "https://" + this.props.dispatcherurl + "/getpatient/" + this.props.oauth2state
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.open( "GET", theUrl, false ); // false for synchronous request
            // xmlHttp.send( null );
            // let patient = JSON.parse(xmlHttp.responseText);
            let patient = JSON.parse('{"patientgiven":"aa", "patienfullgender":"rr", "patientdatebirth":"33", "mrn":"44"}');

            //this.setState({ examPatientName: patient.patientgiven + " " + patient.patientfamily });
            
            const {cookies} = this.props;

            if(cookies) {
                cookies.set('patientname'+this.props.examdbid, patient.patientgiven + " " + patient.patientfamily, { path: '/', maxAge: 3600 });
                cookies.set('patientgender'+this.props.examdbid, patient.patienfullgender, { path: '/', maxAge: 3600 });
                cookies.set('patientdatebirth'+this.props.examdbid, patient.patientdatebirth, { path: '/', maxAge: 3600 });
                cookies.set('patientmrn'+this.props.examdbid, patient.mrn, { path: '/', maxAge: 3600 });
                document.getElementById("uploadexampatientname_id").innerHTML = cookies.get('patientname'+this.props.examdbid);
                document.getElementById("uploadexampatientgender_id").innerHTML = cookies.get('patientgender'+this.props.examdbid);
                document.getElementById("uploadexampatientdatebirth_id").innerHTML = cookies.get('patientdatebirth'+this.props.examdbid);
                document.getElementById("uploadexampatientmrn_id").innerHTML = cookies.get('patientmrn'+this.props.examdbid);
            }
        }

        var fileContentL = '/upload_left.png';
        var fileContentR = '/upload_right.png';

        this.toDataURL(this.state.fileImgLeft, this.setLeftBase64)

        this.toDataURL(this.state.fileImgRight, this.setRightBase64)

        let browsefile = "file";
        let showAllCameras = false;
        if(this.props.examdbid != 0) {
            browsefile = "";
            showAllCameras = true;
            this.getallcameras();
        }
        let fullname = "";
        let fullnamedisabled = false;
        if(this.props.patientname != null) {
            fullname = this.props.patientname;
            fullnamedisabled = true;
        }
        let patientIdent = "";
        let patientidentdisabled = false;
        if(this.props.patientid != null) {
            patientIdent = this.props.patientid;
            patientidentdisabled = true;
        }
        let patientexamiddisabled = false;
        let patientexamid = "";
        if(this.props.examdbid > 0) {
            patientexamiddisabled = true;
            patientexamid = this.props.examid;
        }

        if(this.props.newexam == false) {
            this.prepareOldExamData();
        }
        else {
            if(this.props.examdbid > 0) {

                let allUntagedImages = this.state.allUntagedImages;
                
                let untagedimage = new untagedImage();
                untagedimage.imageid = 0;
                untagedimage.imagetype = "image/png";
                untagedimage.image = fileContentR;
                untagedimage.imagecameraid = 0;
                untagedimage.badimage = false;
                allUntagedImages.push(untagedimage);

                this.setState({allUntagedImages: allUntagedImages});
            }
        }

        if(this.props.examdbid > 0) {
            document.getElementById("dropdown-basic-button").focus();
        }

        this.setState({patientexamid: patientexamid, patientexamiddisabled: patientexamiddisabled, 
                        patientidentdisabled: patientidentdisabled, fullnamedisabled: fullnamedisabled, 
                        showAllCameras: showAllCameras, username: this.props.username, 
                        session: this.props.session, usertype: this.props.usertype, userdbid: this.props.userdbid, 
                        browsefile: browsefile, fullname: fullname, patientIdent: patientIdent});
    }
    
    componentWillUnmount() {
    }
    
    toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
            callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    setRightBase64 = (dataurl) => {

        this.setState({ fileImgRight: dataurl });
    }

    setLeftBase64 = (dataurl) => {

        this.setState({ fileImgLeft: dataurl });
    }

    onDragOver (event, index) {
        event.preventDefault();
    }
    
    onDragStartUntaged = (ev, image) => {
        ev.dataTransfer.setData("index", image);
        this.saveLastData();
    }

    onDragStartEye = (ev, fileImg) => {
        ev.dataTransfer.setData("fileImg", fileImg);
        this.saveLastData();
    }

    onDropEye = (ev, eye) => {
        let index = ev.dataTransfer.getData("index");
        if(index === '') {
            let fileImg = ev.dataTransfer.getData("fileImg");
            if(fileImg !== '') {
                
                let fileImg = ev.dataTransfer.getData("fileImg");
                
                if(fileImg === "fileImgRight") {
                    let fileImgLeft = this.state.fileImgLeft;
                    let fileImgLeftDBID = this.state.fileImgLeftDBID;
                    let fileContentLeft = this.state.fileContentLeft;
                    let bad_l = this.state.bad_l;
                    let bad_l_show = this.state.bad_l_show;
                    let fileNameLeft = this.state.fileNameLeft;
                    let selectedFileLeft = this.state.selectedFileLeft
                    
                    if(this.state.fileImgLeft === '/upload_left.png') {
                        fileImgLeft = '/upload_right.png';
                        fileImgLeftDBID = 0;
                        fileContentLeft = null;
                        selectedFileLeft = null;
                    }

                    let fileImgRight = this.state.fileImgRight;
                    let fileContentRight = this.state.fileContentRight;
                    let fileImgRightDBID = this.state.fileImgRightDBID;
                    let bad_r = this.state.bad_r;
                    let bad_r_show = this.state.bad_r_show;
                    let fileNameRight = this.state.fileNameRight;
                    let selectedFileRight = this.state.selectedFileRight
                    
                    if(this.props.examdbid != 0) {

                        let images = new Array();
                        images[0] = {imageid: this.state.fileImgRightDBID, eye: sdkvars.waitForMaculaL};
    
                        if(this.state.fileImgLeftDBID > 0) {
                            images[1] = {imageid: this.state.fileImgLeftDBID, eye: sdkvars.waitForMaculaR};
                        }

                        this.setState({ LAST_images_update_eye: images });
                    }
                    

                    this.setState({fileContentRight: fileContentLeft, fileContentLeft: fileContentRight, 
                                    fileImgRight: fileImgLeft, fileImgLeft: fileImgRight, fileImgRightDBID: fileImgLeftDBID, 
                                    fileImgLeftDBID: fileImgRightDBID, bad_l: bad_r, bad_r: bad_l, bad_r_show: bad_l_show, bad_l_show: bad_r_show,
                                    fileNameLeft: fileNameRight, fileNameRight: fileNameLeft,
                                    selectedFileLeft: selectedFileRight, selectedFileRight: selectedFileLeft});
                    this.checkFileNameCorrection(false, true);
                } else if(fileImg === "fileImgLeft") {
                    let fileImgRight = this.state.fileImgRight;
                    let fileImgRightDBID = this.state.fileImgRightDBID;
                    let fileContentRight = this.state.fileContentRight;
                    let bad_r = this.state.bad_r;
                    let bad_r_show = this.state.bad_r_show;
                    let fileNameRight = this.state.fileNameRight;
                    let selectedFileRight = this.state.selectedFileRight
                    
                    if(this.state.fileImgRight === '/upload_right.png') {
                        fileImgRight = '/upload_left.png';
                        fileImgRightDBID = 0;
                        fileContentRight = null;
                        selectedFileRight = null;
                    }

                    let fileImgLeft = this.state.fileImgLeft;
                    let fileImgLeftDBID = this.state.fileImgLeftDBID;
                    let fileContentLeft = this.state.fileContentLeft;
                    let bad_l = this.state.bad_l;
                    let bad_l_show = this.state.bad_l_show;
                    let fileNameLeft = this.state.fileNameLeft;
                    let selectedFileLeft = this.state.selectedFileLeft

                    if(this.props.examdbid != 0) {

                        let images = new Array();
                        images[0] = {imageid: this.state.fileImgLeftDBID, eye: sdkvars.waitForMaculaR};
    
                        if(this.state.fileImgRightDBID > 0) {
                            images[1] = {imageid: this.state.fileImgRightDBID, eye: sdkvars.waitForMaculaL};
                        }

                        this.setState({ LAST_images_update_eye: images });
                    }

                    this.setState({fileContentRight: fileContentLeft, fileContentLeft: fileContentRight, 
                                    fileImgRight: fileImgLeft, fileImgLeft: fileImgRight, fileImgRightDBID: fileImgLeftDBID, 
                                    fileImgLeftDBID: fileImgRightDBID, bad_l: bad_r, bad_r: bad_l, bad_r_show: bad_l_show, bad_l_show: bad_r_show,
                                    fileNameLeft: fileNameRight, fileNameRight: fileNameLeft,
                                    selectedFileLeft: selectedFileRight, selectedFileRight: selectedFileLeft});
                    this.checkFileNameCorrection(true, true);
                }
            } else if (ev.dataTransfer.files.length > 0) {
                ev.preventDefault();
                if(eye == 'fileImgRight') {
                    let righttype = ev.dataTransfer.files[0].type;
                    if(righttype == "image/jpeg" || righttype == "image/jpg" || righttype == "image/png") {

                        this.setState({ selectedFileRight: ev.dataTransfer.files[0], bad_r_show: 'container-drag' }); 
                
                        var reader = new FileReader();
                        reader.onload = function(event) {
                            var fileContentRight = this.arrayBufferToBase64(event.target.result);
                            this.setState({ fileImgRight: 'data:' + righttype + ';base64, ' + fileContentRight, bad_r: false, fileContentRight: fileContentRight });

                            this.checkFileNameCorrection(true, false);
                        }.bind(this);
                        
                        reader.readAsArrayBuffer(ev.dataTransfer.files[0]);  
                        this.state.fileNameRight = ev.dataTransfer.files[0].name;   
                    } else {
                        this.setState({displayWrongImageTypeR: true, displayWrongImageTypeMsg: messages.uploadpatient_wrongimagetype_body});
                    }
                } else if(eye == 'fileImgLeft') {
                    let lefttype = ev.dataTransfer.files[0].type;
                    if(lefttype == "image/jpeg" || lefttype == "image/jpg" || lefttype == "image/png") {
                        this.setState({ selectedFileLeft: ev.dataTransfer.files[0], bad_l_show: 'container-drag' }); 
            
                        var reader = new FileReader();
                        reader.onload = function(event) {
                            var fileContentLeft = this.arrayBufferToBase64(event.target.result);
                            this.setState({ fileImgLeft: 'data:' + lefttype + ';base64, ' + fileContentLeft, bad_l: false, fileContentLeft: fileContentLeft }); 

                            this.checkFileNameCorrection(false, false);
                        }.bind(this);
                        
                        reader.readAsArrayBuffer(ev.dataTransfer.files[0]);  
                        this.state.fileNameLeft = ev.dataTransfer.files[0].name;   
                    } else {
                        this.setState({displayWrongImageTypeL: true, displayWrongImageTypeMsg: messages.uploadpatient_wrongimagetype_body});
                    }
                }
            }
        } else {
            let row = this.state.allUntagedImages[index]
            const {imagetype, image, imagecameraid, badimage, imageid, imagename} = row;

                let fileImg = 'data:' + imagetype + ';base64, ' + image;
                let indexint = parseInt(index);
                this.state.allUntagedImages.splice(indexint, 1);
                let allUntagedImages = this.state.allUntagedImages;

                if(eye === "fileImgRight") {

                    if(this.state.fileContentRight != null) {

                        let rightimage = new untagedImage();
                        rightimage.imageid = this.state.fileImgRightDBID;
                        rightimage.imagetype = imagetype;
                        rightimage.image = this.state.fileContentRight;
                        rightimage.badimage = this.state.bad_r;
                        rightimage.imagename = this.state.fileNameRight;

                        allUntagedImages.splice(1, 0, rightimage);
                    }
                    let bad_r_show = badimage ? 'container-drag iiqborder' : 'container-drag'
                    let images = new Array();
                    images[0] = {imageid: imageid, eye: sdkvars.waitForMaculaR};

                    if(this.state.fileImgRightDBID > 0) {
                        images[1] = {imageid: this.state.fileImgRightDBID, eye: sdkvars.waitForUnknown};
                    }
                   

                    this.setState({fileImgRight: fileImg, fileContentRight: image, allUntagedImages: allUntagedImages, 
                        fileImgRightDBID: imageid, bad_r: badimage, fileNameRight: imagename, bad_r_show: bad_r_show, LAST_images_update_eye: images },
                        
                        // after change check submit
                        () => { this.readyToSubmitExam(); 
                                this.checkFileNameCorrection(true, false);
                            }
                    );
                } else if(eye === "fileImgLeft") {

                    if(this.state.fileContentLeft != null) {

                        let leftimage = new untagedImage();
                        leftimage.imageid =  this.state.fileImgLeftDBID;
                        leftimage.imagetype = imagetype;
                        leftimage.image = this.state.fileContentLeft;
                        leftimage.badimage = this.state.bad_l;
                        leftimage.imagename = this.state.fileNameLeft;

                        allUntagedImages.splice(1, 0, leftimage);
                    }
                    let bad_l_show = badimage ? 'container-drag iiqborder' : 'container-drag'
                    let images = new Array();
                    images[0] = {imageid: imageid, eye: sdkvars.waitForMaculaL};

                    if(this.state.fileImgLeftDBID > 0) {
                        images[1] = {imageid: this.state.fileImgLeftDBID, eye: sdkvars.waitForUnknown};
                    }
                   
                    this.setState({fileImgLeft: fileImg, fileContentLeft: image, allUntagedImages: this.state.allUntagedImages, 
                        fileImgLeftDBID: imageid, bad_l: badimage, fileNameLeft: imagename, bad_l_show: bad_l_show, LAST_images_update_eye: images },
                        
                        // after change check submit
                        () => { this.readyToSubmitExam(); 
                                this.checkFileNameCorrection(false, false);
                            }
                    );                    
                }

        }
    }    
     
    saveLastData = date => {
        let allUntagedImages = [];

        this.state.allUntagedImages.forEach((row, index) => {
            allUntagedImages.push(row);
        });

        this.setState({ LAST_allUntagedImages: allUntagedImages, LAST_fileImgLeft: this.state.fileImgLeft, LAST_fileContentLeft: this.state.fileContentLeft, 
            LAST_fileImgLeftDBID: this.state.fileImgLeftDBID, LAST_bad_l: this.state.bad_l, LAST_bad_l_show: this.state.bad_l_show, LAST_fileNameLeft: this.state.fileNameLeft,
            
            LAST_fileImgRight: this.state.fileImgRight, LAST_fileContentRight: this.state.fileContentRight, 
            LAST_fileImgRightDBID: this.state.fileImgRightDBID, LAST_bad_r: this.state.bad_r, LAST_bad_r_show: this.state.bad_r_show, LAST_filenameRight: this.state.fileNameRight
        });
    }

    handleDateOfBirthChange = date => {
        this.setState({
            patientdateofbirth: date
        });
    };  
    
    handleGetCertDomainsNext = event => {
        event.preventDefault();

        this.setState({
        showGetCertificateDomain: false
        });
    };
    
    handleFullnameChange = event => {
        this.setState({ fullname: event.target.value });
    };
    
    handlePatientIdentChange = event => {
        this.setState({ patientIdent: event.target.value });
    };
    
    handlePatientExamIDChange = event => {

        if(this.lettersAndSpaceCheck(event.target.value) === true) {

            this.setState({ patientIdent: event.target.value, patientexamid: event.target.value });
        }        
    };

    lettersAndSpaceCheck(name)
    {
       var regEx = /^[a-zA-Z0-9_.-\s]*$/;
       if(name.match(regEx) || name === '')
         {
          return true;
         }
       else
         {
         return false;
         }
    }  

    readyToSubmitExam() {
        if(this.props.examdbid != 0) {
            let examreadytosubmit = true;
            if (this.state.patientexamid == "") {
                examreadytosubmit = false;
            }
            if(this.state.fileContentLeft == null) {
                examreadytosubmit = false;
            } else {
                if(this.state.bad_l == true) {
                    examreadytosubmit = false;
                }
            }
            if(this.state.fileContentRight == null) {
                examreadytosubmit = false;
            }  else {
                if(this.state.bad_r == true) {
                    examreadytosubmit = false;
                }
            }
            this.setState({ examreadytosubmit: examreadytosubmit });
        }
    }
    
    arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    };
    
    handleClickLeft = event => {
        const { target = {} } = event || {};
        target.value = "";
        if(this.props.examdbid != 0) {
            if(this.state.cameraselected == 'None') {
                this.setState({displayNeedToSelectCameraFirst: true})
            } else {
                let slot = 'left';
                if(this.state.currentlyawaitingphoto === 'left') {
                    return;
                }
                this.waitForEyeImage(sdkvars.waitForMaculaL)
                let waitForCameraImageMsg = messages.uploadpatient_checkforimage_waitleftimage;
                this.setState({ waitForImage: true, waitForCameraImageMsg: waitForCameraImageMsg, currentlyawaitingphoto: slot});
            }
        }
    };
    
    checkFileNameCorrection = (imagesideright, dropopr) => {
        if(dropopr == true) {
            if(imagesideright == true && (this.state.fileNameLeft.toLowerCase().includes("_l") == true || this.state.fileNameLeft.toLowerCase().includes("left") == true || this.state.fileNameLeft.startsWith("OS") == true)) {
                this.setState({displayWrongImageSideR: true, displayWrongImageSideMsg: messages.uploadpatient_wrongimageside_left_body});
            } else if(imagesideright == false && (this.state.fileNameRight.toLowerCase().includes("_r") == true || this.state.fileNameRight.toLowerCase().includes("right") == true || this.state.fileNameRight.startsWith("OD") == true)) {
                this.setState({displayWrongImageSideL: true, displayWrongImageSideMsg: messages.uploadpatient_wrongimageside_right_body});
            } else {
                this.updateEyeOfImage(this.state.LAST_images_update_eye);
            }
        } else {
            if(imagesideright == true && (this.state.fileNameRight.toLowerCase().includes("_l") == true || this.state.fileNameRight.toLowerCase().includes("left") == true || this.state.fileNameRight.startsWith("OS") == true)) {
                this.setState({displayWrongImageSideR: true, displayWrongImageSideMsg: messages.uploadpatient_wrongimageside_left_body});
            } else if(imagesideright == false && (this.state.fileNameLeft.toLowerCase().includes("_r") == true || this.state.fileNameLeft.toLowerCase().includes("right") == true || this.state.fileNameLeft.startsWith("OD") == true)) {
                this.setState({displayWrongImageSideL: true, displayWrongImageSideMsg: messages.uploadpatient_wrongimageside_right_body});
            } else {
                this.updateEyeOfImage(this.state.LAST_images_update_eye);
            }
        }
        if(dropopr == false) {
            if(this.state.fileNameRight == this.state.fileNameLeft) {
                this.setState({bad_l_show: 'container-drag dupborder', bad_r_show: 'container-drag dupborder', identicalnames: true});
            }
            else{
                let bad_l_show = 'container-drag';
                let bad_r_show = 'container-drag';
                if(this.state.bad_r == true) {
                    bad_r_show = 'container-drag iiqborder';
                }
                if(this.state.bad_l == true) {
                    bad_l_show = 'container-drag iiqborder';
                }
                this.setState({bad_l_show: bad_l_show, bad_r_show: bad_r_show, identicalnames: false});
            }
        }
        // If images are replaced (after the test return IIQ) and now all are not IIQ, hide the record button
        if(this.state.bad_l == false && this.state.bad_r == false) {
            this.setState({currentimageid: 0});
        }
    }
    
    handleNeedToSelectCameraFirstOK = () => {
        this.setState({displayNeedToSelectCameraFirst: false});
    }

    handleCameraSessionAbortedOK = () => {
        let cameraselected = 'None';
        this.setState({ cameraselected: cameraselected, displayCameraSessionAborted: false });    
    }

    handleCloseNoInternetConnection = () => {
        this.setState({ displaynointernetconnection: false });    
    }

    handleWrongSideYesMessageBox = () => {
        let s = this.state.fileContentRight;
        this.setState({displayWrongImageSideR: false, displayWrongImageSideL: false, displayWrongImageSideMsg: ""});
        this.updateEyeOfImage(this.state.LAST_images_update_eye);
    }
    
    handleWrongSideNoMessageBox = () => {

        this.setState({displayWrongImageSideR: false, displayWrongImageSideL: false, displayWrongImageSideMsg: "", selectedFileRight: null, 
                        allUntagedImages: this.state.LAST_allUntagedImages, fileImgLeft: this.state.LAST_fileImgLeft, 
                        fileContentLeft: this.state.LAST_fileContentLeft, fileImgLeftDBID: this.state.LAST_fileImgLeftDBID, 
                        bad_l: this.state.LAST_bad_l, bad_l_show: this.state.LAST_bad_l_show, fileNameLeft: this.state.LAST_fileNameLeft, 
                        fileImgRight: this.state.LAST_fileImgRight, fileContentRight: this.state.LAST_fileContentRight, 
                        fileImgRightDBID: this.state.LAST_fileImgRightDBID, bad_r: this.state.LAST_bad_r, bad_r_show: this.state.LAST_bad_r_show, 
                        fileNameRight: this.state.LAST_filenameRight});
    }
    
    handleIdenticalOKMessageBox =() => {
        this.setState({identicalnames: false});
    }
    
    handleWrongTypeOKMessageBox =() => {
        this.setState({displayWrongImageTypeR: false, displayWrongImageTypeL: false});
    }
    
    onUploadLeft = event => { 
        let lefttype = event.target.files[0].type;
        if(lefttype == "image/jpeg" || lefttype == "image/jpg" || lefttype == "image/png") {
            this.setState({ selectedFileLeft: event.target.files[0], bad_l_show: 'container-drag' }); 

            var reader = new FileReader();
            reader.onload = function(event) {
                var fileContentLeft = this.arrayBufferToBase64(event.target.result);
                this.setState({ fileContentLeft: fileContentLeft }); 

                this.setState({ fileImgLeft: 'data:' + lefttype + ';base64, ' + fileContentLeft, bad_l: false });
                this.checkFileNameCorrection(false, false);
            }.bind(this);
            
            reader.readAsArrayBuffer(event.target.files[0]);     
            this.state.fileNameLeft = event.target.files[0].name;
        }
        else {
            this.setState({displayWrongImageTypeL: true, displayWrongImageTypeMsg: messages.uploadpatient_wrongimagetype_body});
        }         
    }; 
    
    handleClickRight = event => {
        const { target = {} } = event || {};
        target.value = "";
        if(this.props.examdbid != 0) {
            if(this.state.cameraselected == 'None') {
                this.setState({displayNeedToSelectCameraFirst: true})
            } else {
                let slot = 'right';
                if(this.state.currentlyawaitingphoto === 'right') {
                    return;
                }
                this.waitForEyeImage(sdkvars.waitForMaculaR)
                let waitForCameraImageMsg = messages.uploadpatient_checkforimage_waitrightimage;
                this.setState({waitForImage: true, waitForCameraImageMsg: waitForCameraImageMsg, currentlyawaitingphoto: slot});
            }
        }
    };
    
    onUploadRight = event => { 
        let righttype = event.target.files[0].type;
        if(righttype == "image/jpeg" || righttype == "image/jpg" || righttype == "image/png") {
            this.setState({ selectedFileRight: event.target.files[0], bad_r_show: 'container-drag' }); 

            var reader = new FileReader();
            reader.onload = function(event) {
                var fileContentRight = this.arrayBufferToBase64(event.target.result);
                this.setState({ fileContentRight: fileContentRight }); 

                this.setState({ fileImgRight: 'data:' + righttype + ';base64, ' + fileContentRight, bad_r: false }); 
                this.checkFileNameCorrection(true, false);
            }.bind(this);
            
            reader.readAsArrayBuffer(event.target.files[0]);  
            this.state.fileNameRight = event.target.files[0].name;
        } else {
            this.setState({displayWrongImageTypeR: true, displayWrongImageTypeMsg: messages.uploadpatient_wrongimagetype_body});
        }
    }; 
    
    handleClickStage = event => {
        const { target = {} } = event || {};
        target.value = "";
        if(this.props.examdbid != 0) {
            if(this.state.cameraselected == 'None') {
                this.setState({displayNeedToSelectCameraFirst: true})
            } else {
                this.waitForEyeImage(sdkvars.waitForUnknown)
                this.setState({waitForImage: true, currentlyawaitingphoto: 'stage'});
            }
        }
    };

    handleSelectMale = event => {
        this.setState({ patientSex: 1 }); 
    }
    
    handleSelectFemale = event => {
        this.setState({ patientSex: 0 }); 
    }
    
    handleSelectOther = event => {
        this.setState({ patientSex: 2 }); 
    }
    
    handleCloseWaitForImageDialog = event => {
        this.setState({waitForImage: false, waitForImageDialog: false})
    }
    
    handleCloseUploadSuccessMsgBox = event => {
        this.setState({displayUploadSuccessMsg: false, fullname: "", patientexamid: "", patientIdent: "", patientEmail: "", selectedFileRight: null, selectedFileLeft: null, patientSex: -1, fileContentLeft: null, fileContentRight: null, fileImgLeft: '/upload_left.png', fileImgRight: '/upload_right.png'})
    }
    
    handleCloseIIQMsgBox = event => {
        this.setState({displayIIQMsg: false})
    }
    
    handleCloseMessageBox = event => {
        this.setState({displayErrorMessage: false})
    }
    handleCloseFatalMessageBox = event => {
        this.setState({displayFatalErrorMessage: false, cameraselectiondisabled: true})
    }
    handleCloseErrorExamProcessMessageBox = event => {
        this.setState({displayExamProcessErrorMessage: false})
    }
    handleCloseCriticalExamProcessMessageBox = event => {
        this.setState({displayExamProcessCriticalMessage: false, redirectToMain: "/legacy/close"})
    }
    
    handleUploadImageCancelMessageBox = event => {
        this.controller.abort();
        this.cancelUpload();
        this.setState({displayUploadImagesMessage: false})
    }

    handleGetOldExamCancelMessageBox = event => {
        this.setState({displayGetOldExamMessage: false})
    }
    
    handleRecordResultsCancelMessageBox = event => {
        this.controller.abort();
        this.setState({displayRecordResultProcessMessage: false})
    }
    
    handleUploadFatalMessageBox = event => {
        this.setState({displayUploadFatalMessage: false})
    }
    
    handleRecordResultFatalMessageBox = event => {
        this.setState({displayRecordResultMessage: false})
    }
    
    handleUploadErrorMessageBox = event => {
        this.setState({displayUploadErrorMessage: false})
    }
    
    handleUploadErrorCloseToMainMessageBox = event => {
        this.setState({redirectToMain: "/legacy/main", displayUploadErrorCloseToMain: false})
    }

    displayTimeoutMessageBox = () => {
        this.setState({displayTimeoutMsg: true})
    }

    handleCloseTimeoutMessageBox = () => {
        this.setState({displayTimeoutMsg: false})
        clearTimeout(this.timer);
        this.timer = setTimeout(this.displayTimeoutMessageBox, 300000, this);
    }
    

    waitForEyeImage = (eyeside) => {
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v11/Exams/waitForImage", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            examdbid: this.props.examdbid,
            userdbid: this.props.userdbid,
            eye: eyeside
          })
        })
          .then(response => response.json())
          .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
            }

            if (data.errorcode == errorcodes.errorOK) {

                this.setState({ checkforimage: true });
                setTimeout(this.checkForEyeImage, 1000, this, eyeside);
                clearTimeout(this.timer);
                this.timer = setTimeout(this.displayTimeoutMessageBox, 300000, this);                
            }
        });
    }
    
    checkForEyeImage = (parentobj, eyeside) => {

        if(parentobj.state.checkforimage === true) {

            let url = connections.sdkurl;
            fetch(url + "/AEYEAppServer/v11/Exams/checkForImage", {
                method: "POST",
                body: JSON.stringify({
                logonid: parentobj.props.username,
                session: parentobj.props.session,
                examdbid: parentobj.props.examdbid,
                userdbid: parentobj.props.userdbid,
                eye: eyeside,
                camerabarcode: parentobj.state.selectedbarcode,
                cameratype: parentobj.state.selectedtype
                })
            })
            .then(response => response.json())
            .then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                if (data.errorcode == errorcodes.errorOK) {

                    if(eyeside === 1 && this.state.currentlyawaitingphoto !== 'right') {

                        return;
                    }

                    if(eyeside === 2 && this.state.currentlyawaitingphoto !== 'left') {

                        return;
                    }
                    
                    if(eyeside === 5 && this.state.currentlyawaitingphoto !== 'stage') {

                        return;
                    }


                    // After the service will load an image the state is changed. Show a message that an image is loaded and processed
                    if(data.imagesfile != null) {

                        let callagain = false;
                        let counter = 0;
                        let images = new Array();
                        let imagescounter = 0;
                        let lastImgIdRight = parentobj.state.fileImgRightDBID;
                        let lastImgIdLeft = parentobj.state.fileImgLeftDBID;
                        let image_exist = false;

                        for (let index =0; index < data.imagesfile.length; index++) {

                            let imagefile = data.imagesfile[index];
                            if(imagefile.imagestate == sdkvars.cameraStateNew || imagefile.imagestate == sdkvars.cameraStateUploaded || imagefile.imagestate == sdkvars.cameraStateFiltering) {
                                let waitForCameraImageMsg = messages.uploadpatient_checkforimage_processing;
                                if(imagefile.imagestate == sdkvars.cameraStateNew) {
                                    waitForCameraImageMsg = messages.uploadpatient_checkforimage_newimageuploading;
                                } else if(imagefile.imagestate == sdkvars.cameraStateUploaded) {
                                    waitForCameraImageMsg = messages.uploadpatient_checkforimage_imageuploaded;
                                } else if(imagefile.imagestate == sdkvars.cameraStateFiltering) {
                                    waitForCameraImageMsg = messages.uploadpatient_checkforimage_cehckforiiq;
                                }
                                parentobj.setState({ waitForImageDialog: true, waitForCameraImageMsg: waitForCameraImageMsg}); 
                                if(parentobj.state.waitForImage == true) {
                                    callagain = true;
                                }
                            } else if(imagefile.imagestate == sdkvars.cameraStateFiltered) {
                                let imagetype = imagefile.imagetype;
                                let fileContent = imagefile.image;
                                let imagecameraid = imagefile.imagecameraid;
                                let bad_image = imagefile.bad_image;
                                let imagename = imagefile.imagename;
                                image_exist = false;

                                if(lastImgIdRight !== imagecameraid && lastImgIdLeft !== imagecameraid) {

                                    this.state.allUntagedImages.forEach((row, index) => {
                                        const {imageid} = row;
                                        
                                        if(imagecameraid === imageid) {
                                            image_exist = true;
                                        }
                                    });
                                }
                                else{
                                    image_exist = true;
                                }

                                if(!image_exist) {

                                    if(eyeside == sdkvars.waitForMaculaR) {
                                        
                                        if(lastImgIdRight !== 0) {
    
                                            let rightimage = new untagedImage();
                                            rightimage.imageid = lastImgIdRight;
                                            rightimage.image = parentobj.state.fileContentRight;
                                            rightimage.badimage = parentobj.state.bad_r;
                    
                                            let allUntagedImages = parentobj.state.allUntagedImages;
                                            allUntagedImages.splice(1, 0, rightimage); 
                                            parentobj.setState({ allUntagedImages: allUntagedImages });
    
                                            images[imagescounter] = {imageid: lastImgIdRight, eye: sdkvars.waitForUnknown};
                                            imagescounter++;
                                        }
    
                                        lastImgIdRight = imagecameraid;
                                        parentobj.setState({ fileImgRight: 'data:' + imagetype + ';base64, ' + fileContent, fileContentRight: fileContent, fileImgRightDBID: imagecameraid, fileNameRight: imagename, bad_r: bad_image, bad_r_show: bad_image ? 'container-drag iiqborder' : 'container-drag' }); 
                                 
                                        counter++;
                                    } else if(eyeside == sdkvars.waitForMaculaL) {
                
                                        if(lastImgIdLeft !== 0) {
            
                                            let leftimage = new untagedImage();
                                            leftimage.imageid = lastImgIdLeft;
                                            leftimage.image = parentobj.state.fileContentLeft;
                                            leftimage.badimage = parentobj.state.bad_l;
            
                                            let allUntagedImages = parentobj.state.allUntagedImages;
                                            allUntagedImages.splice(1, 0, leftimage); 
                                            parentobj.setState({ allUntagedImages: allUntagedImages });
    
                                            images[imagescounter] = {imageid: lastImgIdLeft, eye: sdkvars.waitForUnknown};
                                            imagescounter++;
                                        }
    
                                        lastImgIdLeft = imagecameraid;
                                        parentobj.setState({ fileImgLeft: 'data:' + imagetype + ';base64, ' + fileContent, fileContentLeft: fileContent, fileImgLeftDBID: imagecameraid, fileNameLeft: imagename, bad_l: bad_image, bad_l_show: bad_image ? 'container-drag iiqborder' : 'container-drag' }); 

                                        counter++;
                                    } else if(eyeside == sdkvars.waitForUnknown) {
    
                                            let untagedimage = new untagedImage();
                                            untagedimage.imageid = imagefile.imagecameraid;
                                            untagedimage.imagetype = imagefile.imagetype;
                                            untagedimage.image = imagefile.image;
                                            untagedimage.imagecameraid = imagefile.imagecameraid;
                                            untagedimage.badimage = imagefile.bad_image;
                                            untagedimage.imagename = imagefile.imagename;

                                            let allUntagedImages = parentobj.state.allUntagedImages;
                                            allUntagedImages.splice(1, 0, untagedimage);
    
                                            parentobj.setState({allUntagedImages: allUntagedImages, currentlyawaitingphoto: 'stage'})
                                            counter++;
    
                                            if(parentobj.state.waitForImage == true) {
                                                callagain = true;
                                            }
                                    }
                                }

                                parentobj.readyToSubmitExam();
                            } else {
                                if(parentobj.state.waitForImage == true) {
                                    callagain = true;
                                }
                            }
                        }
                        
                        if(images.length > 0) {
                            parentobj.updateEyeOfImage(images);
                        }

                        if(counter === data.imagesfile.length) {
                            parentobj.waitForEyeImage(sdkvars.waitForUnknown)
                            parentobj.setState({ waitForImage: true, currentlyawaitingphoto: 'stage' });
                        }

                        if(callagain === true) {
                            setTimeout(parentobj.checkForEyeImage, 2000, parentobj, eyeside);
                        }

                        if(parentobj.state.displayTimeoutMsg === true) {
                            parentobj.setState({displayTimeoutMsg: false})
                        }
                    }
                    else {
                        
                        if(parentobj.state.waitForImageDialog === true) {
                            parentobj.setState({waitForImageDialog: false})
                        }
                        
                        if(parentobj.state.waitForImage == true) {
                            setTimeout(parentobj.checkForEyeImage, 2000, parentobj, eyeside);
                        } 
                    }
                }
                else {
                    if (data.errorcode == errorcodes.AEYEHealthOperatorNoCameraSelected && this.state.openCameraSelectDialog === false) {
                        parentobj.setState({displayCameraSessionAborted: true, operatornameSessionAborted: data.operatorname});
                    }
                }

                if(this.state.displaynointernetconnection === true) {

                    this.setState({ displaynointernetconnection: false });
                }
            })
            .catch(e => {

                fetch(url + "/AEYEAppServer/v2/Configuration/isalive", {
                    mode: 'no-cors',
                })
                .then(() => {
                }).catch(exception => { 

                    if(this.state.displaynointernetconnection === false) {

                        this.setState({ displaynointernetconnection: true });
                    }
                    
                    setTimeout(parentobj.checkForEyeImage, 2000, parentobj, eyeside);
                })
            });
        }
    }    
    
    canUpload() {
        var canupload = true;

        if(this.state.fileContentLeft == null && this.state.fileContentRight == null) {
            this.setState({displayErrorMessage: true})
            canupload = false;
        }

        if(this.props.usertype == "R") {
            if(this.state.fullname == "") {
                this.setState({displayErrorMessage: true})
                canupload = false;
            }
            if(this.state.patientIdent == "") {
                this.setState({displayErrorMessage: true})
                canupload = false;
            }
        } else {
            if(this.state.patientexamid == "") {
                this.setState({displayErrorMessage: true})
                canupload = false;
            }
        }
        return canupload;
    }
    
    resetPatient = () => {
        if(this.props.examdbid == 0) {
            this.setState({ patientexamid: ""});
        }
        else{
            let allUntagedImages = this.state.allUntagedImages;
            let imagetype;
            let leftimage = new untagedImage();
            leftimage.imageid =  this.state.fileImgLeftDBID;
            leftimage.imagetype = imagetype;
            leftimage.image = this.state.fileContentLeft;
            leftimage.badimage = this.state.bad_l;
            allUntagedImages.push(leftimage);
            
            let rightimage = new untagedImage();
            rightimage.imageid = this.state.fileImgRightDBID;
            rightimage.imagetype = imagetype;
            rightimage.image = this.state.fileContentRight;
            rightimage.badimage = this.state.bad_r;
            allUntagedImages.push(rightimage);

            this.setState({ allUntagedImages: allUntagedImages});
        }
        this.setState({ patientIdent: "", fullname: "", patientEmail: "", 
            patientSex: -1, patientdateofbirth: null, fileContentLeft: null, fileContentRight: null, 
            selectedFileLeft: null, selectedFileRight: null, fileImgLeft: '/upload_left.png', 
            fileImgRight: '/upload_right.png', qualitytext_r:'', qualitytext_l:'', 
            fileNameRight:'', fileNameLeft:'', bad_l_show: 'container-drag', bad_r_show: 'container-drag',
            bad_r: false, bad_l: false});
    }
    
    saveAndClosePatient = () => {
        if(this.props.examdbid != 0) {
            this.DeselectTheCamera(this.state.selectedbarcode);

            this.setState({checkforimage: false})
            this.setState({redirectToMain: "/legacy/close"})
        }        
    }

    cancelAndClosePatient = () => {
        this.cancelExam();
        this.DeselectTheCamera(this.state.selectedbarcode);
    }
    abortPatientSubmit = () => {
        this.abortExam();
    }
    cancelExam() {

        if(this.props.examdbid != 0) {
            let url = connections.sdkurl;
            fetch(url + "/AEYEAppServer/v11/Exams/cancelExam", {
                method: "POST",
                body: JSON.stringify({
                logonid: this.props.username,
                session: this.props.session,
                examdbid: this.props.examdbid,
                userdbid: this.props.userdbid
                })
            })
            .then(response => response.json())
            .then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                if (data.errorcode == errorcodes.errorOK) {
                    
                    this.setState({ checkforimage: false })

                    if(this.state.displayExamProcessErrorMessage === true) {
                        this.setState({displayExamProcessErrorMessage: false})
                    }
                    this.setState({redirectToMain: "/legacy/close"})
                }
            });
        }        
    }
    abortExam() {

        if(this.props.examdbid != 0) {
            let url = connections.sdkurl;
            fetch(url + "/AEYEAppServer/v11/Exams/abortExam", {
                method: "POST",
                body: JSON.stringify({
                logonid: this.props.username,
                session: this.props.session,
                examdbid: this.props.examdbid,
                userdbid: this.props.userdbid
                })
            })
            .then(response => response.json())
            .then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                if (data.errorcode == errorcodes.errorOK) {

                    this.setState({ waitForSubmitionDialog: false, abortingDialog: true });
                }
            });
        }        
    }

    recordResults = () => {
        if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {
            this.controller = new AbortController();
            const { signal } = this.controller;

            this.setState({displayRecordResultProcessMessage: true})
            var image_id = uuid();
            let url = connections.sdkurl;
            fetch(url + "/AEYEAppServer/v3/Images/recordResults", {
                signal,
                method: "POST",
                body: JSON.stringify({
                    session: this.props.session,
                    logonid: this.props.username,
                    imageid: this.state.currentimageid,
                    accepted: true
                })
            }).then(response => response.json()).then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                this.setState({displayRecordResultProcessMessage: false})
                if (data.errorcode !== errorcodes.errorOK) {
                    this.setState({ displayRecordResultMessage: true });
                } else {
                    this.setState({ redirectToMain: "/legacy/main" });
                }
            }).catch(e => {
                if( e.name != "AbortError") {
                    this.setState({ displayRecordResultMessage: true });
                }
            });
        }
    }

    recordExam = () => {
        if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {
            this.controller = new AbortController();
            const { signal } = this.controller;

            this.setState({displayRecordResultProcessMessage: true})
            var image_id = uuid();
            let url = connections.sdkurl;
            fetch(url + "/AEYEAppServer/v11/Exams/recordExam", {
                signal,
                method: "POST",
                body: JSON.stringify({
                    session: this.props.session,
                    logonid: this.props.username,
                    userdbid: this.props.userdbid,
                    examdbid: this.props.examdbid,
                    examid: this.props.examid
                })
            }).then(response => response.json()).then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                this.setState({displayRecordResultProcessMessage: false})
                if (data.errorcode !== errorcodes.errorOK) {
                    this.setState({ displayRecordResultMessage: true });
                } else {
                    this.setState({ waitForSubmitionDialog: true, showabortbutton: false, waitForSubmitionMsg: messages.uploadpatient_examreadytowritetoepic_submit_body}); 
                    setTimeout(this.checkForEndOfSubmition, 5000, this);

                }
            }).catch(e => {
                if( e.name != "AbortError") {
                    this.setState({ displayRecordResultMessage: true });
                }
            });
        }
    }
    
    cancelUpload = () => {
        if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {
            let url = connections.sdkurl;
            fetch(url + "/AEYEAppServer/v3/Images/cancelUpload", {
                method: "POST",
                body: JSON.stringify({
                    session: this.props.session,
                    logonid: this.props.username,
                    currentuploadid: this.currentuploadid
                })
            }).then(response => response.json()).then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                if (data.errorcode !== errorcodes.errorOK) {
                } else {
                }
            })
        }
    }
    
    prepareOldExamData = () => {
        this.setState({displayGetOldExamMessage: true})
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v11/Exams/getOldExamData", {
            method: "POST",
            body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            examdbid: this.props.examdbid,
            examid: this.props.examid,
            userdbid: this.props.userdbid,
            epicdepid: this.props.epicdepid
            })
        })
            .then(response => response.json())
            .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
            }

            if (data.errorcode == errorcodes.errorOK) {
                let fileContentRight = null;
                let fileContentLeft = null;
                let bad_r = false;
                let bad_l = false;
                let filetype_r = "";
                let filetype_l = "";
                let bad_r_show = 'container-drag';
                let bad_l_show = 'container-drag';
                let fileImgRightDBID = 0;
                let fileImgLeftDBID = 0;
                let cameraname = "None";
                let selectedbarcode = "";
                let allUntagedImages = this.state.allUntagedImages;
                let fileImgRight = '/upload_right.png';
                let fileImgLeft = '/upload_left.png';
                let fileNameRight = "";
                let fileNameLeft = "";

                let untagedimage = new untagedImage();
                untagedimage.imageid = 0;
                untagedimage.imagetype = "image/png";
                untagedimage.image = this.state.fileImgRight;
                untagedimage.imagecameraid = 0;
                untagedimage.badimage = false;
                allUntagedImages.push(untagedimage);

                if(data.oldexamdata.length > 0) {
                    for(let x = 0 ; x < data.oldexamdata.length ; x++) {

                        let examdata = data.oldexamdata[x]
                        if(examdata.cameraname != null) {
                            cameraname = examdata.cameraname;
                        }    
                        selectedbarcode = examdata.camerabarcode;

                        if(examdata.eye == 1) {
                            fileContentRight = examdata.filecontent;
                            bad_r = examdata.bad_image;
                            bad_r_show = bad_r ? 'container-drag iiqborder' : 'container-drag'
                            filetype_r = "image/" + examdata.imagetype
                            fileImgRightDBID = examdata.id;
                            fileImgRight = 'data:' + filetype_r + ';base64, ' + fileContentRight;
                            fileNameRight = examdata.imagename;
                        } else if(examdata.eye == 2) {
                            fileContentLeft = examdata.filecontent;
                            bad_l = examdata.bad_image;
                            bad_l_show = bad_l ? 'container-drag iiqborder' : 'container-drag'
                            filetype_l = "image/" + examdata.imagetype
                            fileImgLeftDBID = examdata.id;
                            fileImgLeft = 'data:' + filetype_l + ';base64, ' + fileContentLeft;
                            fileNameLeft = examdata.imagename;
                        } else if(examdata.eye == 5) {

                            let untagedimage = new untagedImage();
                            untagedimage.imageid = examdata.id;
                            untagedimage.imagetype = examdata.imagetype;
                            untagedimage.image = examdata.filecontent;
                            untagedimage.imagecameraid = examdata.cameraid;
                            untagedimage.badimage = examdata.bad_image;
                            untagedimage.imagename = examdata.imagename;
                            allUntagedImages.push(untagedimage);
                        }
                    }

                    let camerawasselected = cameraname != 'None';
                    this.setState({ allUntagedImages: allUntagedImages, selectedbarcode: selectedbarcode, cameraselected: cameraname, 
                                    fileImgRightDBID: fileImgRightDBID, fileImgLeftDBID: fileImgLeftDBID, bad_r_show: bad_r_show, 
                                    bad_l_show: bad_l_show, fileImgRight: fileImgRight, bad_r: bad_r, fileImgLeft: fileImgLeft, bad_l: bad_l, 
                                    fileContentRight: fileContentRight, fileContentLeft: fileContentLeft, camerawasselected: camerawasselected,
                                    fileNameRight: fileNameRight, fileNameLeft: fileNameLeft});
                }
                this.selectTheCamera(selectedbarcode, false)
                this.setState({displayGetOldExamMessage: false})
                this.readyToSubmitExam();
            } else {
                this.setState({displayGetOldExamMessage: false, displayFatalErrorMessage: true})
            }
        });
    }
    
    uploadPatient = () => {
        if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {

            if(this.canUpload() == true) {
                this.currentuploadid = uuid();
                this.controller = new AbortController();
                const { signal } = this.controller;

                this.setState({displayUploadImagesMessage: true, displayUploadImagesMessageTitle: messages.uploadpatient_uploadimages_pleasewait_title, displayUploadImagesMessageBody: messages.uploadpatient_uploadimages_pleasewait_body})
                var image_id = uuid();
                let url = connections.sdkurl;
                let patientdateofbirth = null;
                if(this.state.patientdateofbirth != null) {
                    patientdateofbirth = this.state.patientdateofbirth.getMonth() + "/" + this.state.patientdateofbirth.getDate() + "/" + this.state.patientdateofbirth.getFullYear();
                }
                fetch(url + "/AEYEAppServer/v3/Images/uploadImages", {
                    signal,
                    method: "POST",
                    body: JSON.stringify({
                        session: this.props.session,
                        logonid: this.props.username,
                        userdbid: this.state.userdbid,
                        patientId: this.state.fullname,
                        patientIdent: this.state.patientIdent,
                        patientexamid: this.state.patientexamid,
                        patientClinic: this.props.userdbid,
                        patientEmail: this.state.patientEmail,
                        patientSex: this.state.patientSex,
                        patientdateofbirth: patientdateofbirth,
                        image_id: image_id,
                        image_source: "AEYE",
                        machineNumber: 0,
                        fileContentLeft: this.state.fileContentLeft,
                        fileContentRight: this.state.fileContentRight,
                        fileNameLeft: this.state.selectedFileLeft.name,
                        fileNameRight: this.state.selectedFileRight.name,
                        currentuploadid: this.currentuploadid
                    })
                }).then(response => response.json()).then(data => {

                    if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                        this.props.sessionData.showsessiontimeout();
                        return;
                    }

                    this.setState({displayUploadImagesMessage: false})
                    if (data.errorcode !== errorcodes.errorOK) {
                        if (data.errorcode == errorcodes.AEYEHealthPatientInvalidID) {
                            this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_addNewImagesPatientInvalidID });
                        } else if (data.errorcode == errorcodes.AEYEHealthPatientInvalidName) {
                            this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_addNewImagesPatientInvalidName });
                        }  else if (data.errorcode == errorcodes.AEYEHealthFailedToFilterImages) {
                            this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_addNewImagesPatientDiagnosesFailed });
                        } else if (data.errorcode == errorcodes.AEYEHealthUploadedFailedToPublishImages) {
                            this.setState({ displayUploadErrorCloseToMain: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_addNewImagesPatientUploadedDiagnosesFailed });
                        } else if (data.errorcode == errorcodes.AEYEHealthUploadedFailedTimeout) {
                            this.setState({ displayUploadErrorCloseToMain: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_addNewImagesPatientUploadedDiagnosesTimeout });
                        } else if (data.errorcode == errorcodes.AEYEHealthUploadedFailedNoDiseaseIsSet) {
                            this.setState({ displayUploadErrorCloseToMain: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_NoDiseaseWasSet });
                        } else if (data.errorcode == errorcodes.AEYEHealthUploadedFailedToCheckIIQ) {
                            this.setState({ displayUploadErrorCloseToMain: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_FailToCheckFilters });
                        } else {
                            this.setState({ displayUploadFatalMessage: true });
                        }
                    } else {
                        if(this.props.usertype == 'U') {
                            this.setState({ displayUploadSuccessMsg: true });
                        } else {
                            if(data.bad_l == true || data.bad_r == true) {

                                this.setState({ bad_l: data.bad_l, bad_r: data.bad_r, displayIIQMsg: true,
                                                    qualitytext_r: data.bad_r ? ' - ' + messages.uploadpatient_uploadimages_iiq_title : ' - ' + messages.uploadpatient_uploadimages_q_title,
                                                    qualitytext_l: data.bad_l ? ' - ' + messages.uploadpatient_uploadimages_iiq_title : ' - ' + messages.uploadpatient_uploadimages_q_title,
                                                    bad_l_show: data.bad_l ? 'container-drag iiqborder' : 'container-drag',
                                                    bad_r_show: data.bad_r ? 'container-drag iiqborder' : 'container-drag',
                                                    currentimageid: data.imageid
                                                });
                            } else {
                                this.setState({ redirectToMain: "/legacy/main" });
                            }
                        }
                    }
                }).catch(e => {
                    if( e.name != "AbortError") {
                        this.setState({ displayUploadFatalMessage: true });
                    }
                });
            }
        }
    }
    
    uploadExamPatientRetry = () => {
        if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {
            if(this.canUpload() == true) {
                this.setState({ examfailedretry: false, waitForSubmitionDialog: true, waitForSubmitionMsg: messages.uploadpatient_examreadytowritetoepic_submit_body}); 
                let url = connections.sdkurl;
                fetch(url + "/AEYEAppServer/v11/Exams/setExamDispatcherState", {
                    method: "POST",
                    body: JSON.stringify({
                    logonid: this.props.username,
                    session: this.props.session,
                    examid: this.props.examid,
                    examdbid: this.props.examdbid,
                    userdbid: this.props.userdbid,
                    state: sdkvars.DispacherStateNew
                    })
                }).then(response => response.json()).then(data => {

                    if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                        this.props.sessionData.showsessiontimeout();
                        return;
                    }

                    this.setState({checkforsubmit: true, displayUploadImagesMessage: false})
                    if (data.errorcode !== errorcodes.errorOK) {
                        this.setState({ displayUploadFatalMessage: true, waitForSubmitionDialog: false });
                    } else {
                        setTimeout(this.checkForEndOfSubmition, 5000, this);
                    }
                }).catch(e => {
                    if( e.name != "AbortError") {
                        this.setState({ waitForSubmitionDialog: true });
                    }
                });
            }
        }
    }

    recordExamPatient = () => {
        if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {
            this.uploadExamPatient(true);
        }
    }
    uploadExamPatient = (recordexam) => {
        if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimagewrite) {
            if(this.canUpload() == true) {
                this.controller = new AbortController();
                const { signal } = this.controller;

                this.setState({displayUploadImagesMessage: true, waitForImage: false, displayUploadImagesMessageTitle: messages.uploadpatient_examprocess_pleasewait_title, displayUploadImagesMessageBody: messages.uploadpatient_examprocess_pleasewait_body})
                var image_id = uuid();
                let url = connections.sdkurl;
                let patientdateofbirth = null;
                if(this.state.patientdateofbirth != null) {
                    patientdateofbirth = this.state.patientdateofbirth.getMonth() + "/" + this.state.patientdateofbirth.getDate() + "/" + this.state.patientdateofbirth.getFullYear();
                }
                let examdismiss = false;
                if(recordexam && recordexam == true) {
                    examdismiss = true;
                }
                fetch(url + "/AEYEAppServer/v3/Images/uploadExamImages", {
                    signal,
                    method: "POST",
                    body: JSON.stringify({
                        session: this.props.session,
                        logonid: this.props.username,
                        patientId: this.state.fullname,
                        patientIdent: this.state.patientIdent,
                        patientexamid: this.state.patientexamid,
                        patientClinic: this.props.userdbid,
                        userdbid:  this.props.userdbid,
                        patientEmail: this.state.patientEmail,
                        patientSex: this.state.patientSex,
                        patientdateofbirth: patientdateofbirth,
                        image_id: image_id,
                        image_source: "AEYE",
                        machineNumber: 0,
                        examdbid: this.props.examdbid,
                        examdismiss: examdismiss
                    })
                }).then(response => response.json()).then(data => {

                    if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                        this.props.sessionData.showsessiontimeout();
                        return;
                    }

                    this.setState({displayUploadImagesMessage: false})
                    if (data.errorcode !== errorcodes.errorOK) {
                        if (data.errorcode == errorcodes.AEYEHealthPatientInvalidID) {
                            this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_addNewImagesPatientInvalidID });
                        } else if (data.errorcode == errorcodes.AEYEHealthPatientInvalidName) {
                            this.setState({ displayUploadErrorMessage: true, displayUploadErrorMessageMsg: errormessages.uploadpatient_addNewImagesPatientInvalidName });
                        } else {
                            this.setState({ displayUploadFatalMessage: true });
                        }
                    } else {
                        this.setState({ checkforsubmit: true, waitForSubmitionDialog: true, waitForSubmitionMsg: messages.uploadpatient_uploadexamimages_submit_body, checkforimage: false}); 
                        setTimeout(this.checkForEndOfSubmition, 5000, this);
                    }
                }).catch(e => {
                    if( e.name != "AbortError") {
                        this.setState({ displayUploadFatalMessage: true });
                    }
                });
            }
        }
    }

    updateEyeOfImage = (images) => {
        let url = connections.sdkurl;

        fetch(url + "/AEYEAppServer/v3/Images/updateEyeOfImage", {
            method: "POST",
            body: JSON.stringify({
                session: this.props.session,
                logonid: this.props.username,
                userdbid: this.props.userdbid,
                images: images
            })
        }).then(response => response.json()).then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
            }

            if (data.errorcode !== errorcodes.errorOK) {

            } else {

            }
        }).catch(e => {
            if( e.name != "AbortError") {
                this.setState({ displayUploadFatalMessage: true });
            }
        });
        
    }
    
    
    checkForEndOfSubmition(parentobj) {

        if(parentobj.state.checkforsubmit === true) {

            let url = connections.sdkurl;
            fetch(url + "/AEYEAppServer/v11/Exams/checkForEndSubmit", {
                method: "POST",
                body: JSON.stringify({
                logonid: parentobj.props.username,
                session: parentobj.props.session,
                examdbid: parentobj.props.examdbid,
                userdbid: parentobj.props.userdbid
                })
            })
            .then(response => response.json())
            .then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                if (data.errorcode == errorcodes.errorOK) {
                    if(data.examstate == sdkvars.ExamStateEnd || data.examstate == sdkvars.ExamStateDismiss) {
                        if(data.dispatcherstate == sdkvars.DispacherStateEnd) {
                            parentobj.setState({ waitForSubmitionDialog: false, displayExamProcessErrorMessage: false, closeSubmitionDialog: true}); 
                            if(data.examstate == sdkvars.ExamStateDismiss) {
                                parentobj.DeselectTheCamera(parentobj.state.selectedbarcode);

                                parentobj.setState({redirectToMain: "/legacy/close"})
                            } else {
                                parentobj.DeselectTheCamera(parentobj.state.selectedbarcode);

                                let redirectprop = '{pathname: "/uploadresults", state: { examdbid: ' + parentobj.props.examdbid + '}';
                                parentobj.setState({redirectToUploadResults: redirectprop}); 
                            }
                        } else if(data.dispatcherstate == sdkvars.DispacherStateExpired) {
                            parentobj.setState({ waitForSubmitionDialog: false, displayExamProcessErrorMessage: true, displayExamProcessErrorMessageBody: messages.uploadpatient_examprocessexpired_submit_body, displayExamProcessErrorMessageTitle: messages.uploadpatient_examprocessstoped_title}); 
                        } else if(data.dispatcherstate == sdkvars.DispacherStateFailed) {
                            parentobj.setState({ examfailedretry: true, waitForSubmitionDialog: false, displayExamProcessErrorMessage: true, displayExamProcessErrorMessageBody: messages.uploadpatient_examprocessfailed_submit_body, displayExamProcessErrorMessageTitle: messages.uploadpatient_examprocessstoped_title}); 
                        } else if(data.dispatcherstate == sdkvars.DispacherStateCritical) {
                            parentobj.setState({ examfailedretry: false, waitForSubmitionDialog: false, displayExamProcessCriticalMessage: true, displayExamProcessCriticalMessageBody: messages.uploadpatient_examprocesscritical_submit_body, displayExamProcessCriticalMessageTitle: messages.uploadpatient_examprocesscritical_submit_title}); 
                        } else {
                            parentobj.setState({ waitForSubmitionDialog: true, showabortbutton: false, waitForSubmitionMsg: messages.uploadpatient_examreadytowritetoepic_submit_body}); 
                            setTimeout(parentobj.checkForEndOfSubmition, 5000, parentobj);
                        }
                    } 
                    else if(data.examstate == sdkvars.ExamStateMqFinished) {
                        parentobj.setState({ checkforsubmit: false, waitForImage: true, abortingDialog: false });
                        parentobj.waitForEyeImage(sdkvars.waitForUnknown)
                    } else if(data.examstate == sdkvars.ExamStateDismiss && data.dispatcherstate == sdkvars.DispacherStateEnd) {

                        parentobj.setState({ checkforsubmit: false, waitForImage: true, abortingDialog: false });
                        parentobj.waitForEyeImage(sdkvars.waitForUnknown)
                    }
                    else {
                        setTimeout(parentobj.checkForEndOfSubmition, 5000, parentobj);
                    }
                }  else if(data.examstate == sdkvars.ExamStateFailed) {
                    parentobj.setState({ waitForSubmitionDialog: false, displayExamProcessFailedMessage: true, displayExamProcessFailedMessageBody: messages.uploadpatient_examprocessexamfailed_submit_body, displayExamProcessFailedMessageTitle: messages.uploadpatient_examprocessexamfailed_submit_title}); 
                } else {
                    let r = 3;
                }
            });
        }
    }    
    
    getallcameras() {
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v9/Cameras/getCameras", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            examdbid: this.props.examdbid,
            userdbid: this.props.userdbid,
            fromindex: 0,
            pagesize: -1,
            search: ""
          })
        })
          .then(response => response.json())
          .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
            }

            if (data.errorcode == errorcodes.errorOK) {
                let cameraselected = "None";
                let showCameras = true;
                let allcameras = [];
                let openCameraSelectDialog = false;
                allcameras.push({name: "None", barcode: "", type: ''});
                if(data && data.cameras) {
                    data.cameras.map((rowdata, index) => {
                        let name = rowdata.name;
                        let barcode = rowdata.barcodenumber;
                        let type = rowdata.type;
                        allcameras.push({name: name, barcode: barcode, type: type});
                    });
                }
                if(allcameras.length == 1) {
                    showCameras = false;
                }
                this.setState({allcameras: allcameras, showCameras: showCameras, cameraselected: cameraselected});
            }
          });
    }
    
    selectcamera = event => {
        let selectedbarcode = event.target.attributes["barcode"].value;
        let selectedtype = event.target.attributes["type"].value;
        let cameraselected = event.target.text;
        if(cameraselected == 'None') {
            this.DeselectTheCamera(this.state.selectedbarcode);
        } else {

            this.selectTheCamera(selectedbarcode, false); 
        }
        this.setState({selectedbarcode: selectedbarcode, selectedtype: selectedtype, cameraselected: cameraselected});
    }
    
    handleCancleTheCamera = event => {
        let cameraselected = 'None';
        document.getElementById("dropdown-basic-button").focus();
        this.setState({ openCameraSelectDialog: false, cameraselected: cameraselected });    
    }
    
    handleSelectTheCamera = barcodenumber => {
        this.setState({ openCameraSelectDialog: false });  
        this.selectTheCamera(barcodenumber, true);
    }
    
    selectTheCamera(barcodenumber, forceselect) {
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v9/Cameras/setOperatorSelectCamera", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            userdbid: this.props.userdbid,
            barcodenumber: barcodenumber,
            forceselect: forceselect
          })
        })
        .then(response => response.json())
        .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
            }

            if (data.errorcode == errorcodes.errorOK ) {
                // You cant just select the camera you need the barcode
                if(data.cameradbid == -1) {
                    if(this.state.cameraselected != 'None') {
                        this.setState({openCameraSelectDialog: true, camerawasselected: false});
                    }
                    else {
                        this.setState({checkforimage: false});
                    }
                } else {
                    if(this.state.checkforimage === false) {
                        this.setState({ waitForImage: true, camerawasselected: true});
                        this.waitForEyeImage(sdkvars.waitForUnknown);
                    }
                }
            } else {
            }
        });
    }
    DeselectTheCamera(selectedbarcode) {
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v9/Cameras/setOperatorDisselectCamera", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            userdbid: this.props.userdbid,
            barcodenumber: selectedbarcode
          })
        })
        .then(response => response.json())
        .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
            }
            
            if (data.errorcode == errorcodes.errorOK ) {
                this.setState({checkforimage: false});
            } else {
            }
        });
    }

    render() {
        var tasks = {
            wip: [],
            complete: []
        }

        this.state.tasks.forEach ((t) => {
            tasks[t.category].push(
                <div key={t.name} 
                    onDragStart = {(e) => this.onDragStart(e, t.name)}
                    draggable
                    className="draggable"
                    style = {{backgroundColor: t.bgcolor}}
                >
                    {t.name}
                </div>
            );
        });

        if (this.state.redirectToMain) {
            return <Redirect to={this.state.redirectToMain} />
        }  

        if (this.state.redirectToUploadResults) {
            return <Redirect to={{pathname: "/uploadresults", state: { examdbid: this.props.examdbid }}}/>
        }  

        if(this.props.examdbid !== 0) {

            return(
                this.getCameraDisplay()
            );
        }
        else {

            return(
                
                this.getUploadDisplay()
            );
        }
    }

    getUploadDisplay() {

        if(this.state.displayUploadResults == true) {
            return (
                <div style={{padding: '30px'}}>
                </div>
            );
        } else { 

            return (
                <div style={{padding: '30px'}}>
                    {this.errorMessageBox()}
                    {this.errorFatalMessageBox()}
                    {this.errorExamProcessMessageBox()}
                    {this.examProcessFailedMessageBox()}
                    {this.criticalExamProcessMessageBox()}
                    {this.uploadImagesPleaseWaitMessageBox()}
                    {this.getOldExamPleaseWaitMessageBox()}
                    {this.recordResultsPleaseWaitMessageBox()}
                    {this.recordResultsFatalMessageBox()}
                    {this.uploadFatalMessageBox()}
                    {this.uploadErrorMessageBox()}
                    {this.uploadErrorCloseToMainMessageBox()}
                    {this.uploadSuccessMsgBox()}
                    {this.waitForImageDialogBox()}
                    {this.waitForSubmitionDialogBox()}
                    {this.uploadIIQMsgBox()}
                    {this.displayWrongImageSideMessageBox()}
                    {this.displayWrongImagTypeMessageBox()}
                    {this.displayImagesIdenticalMessageBox()}
                    {this.displayNeedToSelectCameraFirstMessageBox()}
                    {this.displayCameraSessionAbortedMessageBox()}
                    {this.abortingMessageBox()}
                    {this.displayNoInternetConnectionMessageBox()}

                    <AddCameraToOperator  sessionData={this.props.sessionData} selectedbarcode={this.state.selectedbarcode} onSelectCamera={this.handleSelectTheCamera} onCancleCamera={this.handleCancleTheCamera} openCameraSelectDialog={this.state.openCameraSelectDialog}></AddCameraToOperator>
                    
                    <Container fluid="xl" style={{maxWidth: '1500px'}}>

                        <Row>
                            {this.props.usertype != "R" && this.props.examdbid != 0 && <Col md={2} style={{backgroundColor: '#e0eef7'}}>
                                <Col md={12}>
                                    <label style={{width: '100%', height: '0px', margin: '0px', fontSize: '20px'}}>{componentsStrings.uploadpatient_fullname}</label>
                                    <label id="uploadexampatientname_id" style={{width: '100%', height: '50px', margin: '0px', fontSize: '18px'}}></label>
                                    <label style={{width: '100%', height: '0px', margin: '0px', fontSize: '20px'}}>{componentsStrings.uploadpatient_gender}</label>
                                    <label id="uploadexampatientgender_id" style={{width: '100%', height: '50px', margin: '0px', fontSize: '18px'}}></label>
                                    <label style={{width: '100%', height: '0px', margin: '0px', fontSize: '20px'}}>{componentsStrings.uploadpatient_dateofbirth}</label>
                                    <label id="uploadexampatientdatebirth_id" style={{width: '100%', height: '50px', margin: '0px', fontSize: '18px'}}></label>
                                    <label style={{width: '100%', height: '0px', margin: '0px', fontSize: '20px'}}>{componentsStrings.uploadpatient_mrn}</label>
                                    <label id="uploadexampatientmrn_id" style={{width: '100%', height: '50px', margin: '0px', fontSize: '18px'}}></label>
                                </Col>
                            </Col>}

                            <Col md={2}> </Col>

                            <Col md={10}>
                                
                                <Row>
                                    <Col md={10}>          
                                        <Row>
                                            {this.props.usertype == "R" && <Col md={3}>
                                                <Row>
                                                    <Col md={12}>
                                                        <label style={{width: '100%', fontSize: '20px'}}>{componentsStrings.uploadpatient_patientname}</label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <input id="uploadpatient_fullname" style={{width: '100%', height: '50px', margin: '0px', fontSize: '20px'}} type="text" placeholder={componentsStrings.uploadpatient_fullname} onChange={this.handleFullnameChange} value={this.state.fullname} disabled={this.state.fullnamedisabled}></input>
                                                    </Col>
                                                </Row>
                                            </Col>}
                                            <Col md={3}>
                                                <Row>
                                                    {this.props.usertype != "R" && <Col md={12}>
                                                        <label>{componentsStrings.uploadpatient_examid}</label>
                                                        <input id="uploadexam_id" style={{width: '100%', height: '50px', margin: '0px', fontSize: '20px'}} type="text" placeholder={componentsStrings.uploadpatient_examid} onChange={this.handlePatientExamIDChange} value={this.state.patientexamid} disabled={this.state.patientexamiddisabled}></input>
                                                    </Col>}
                                                    {this.props.usertype == "R" && <Col md={12}>
                                                        <input id="uploadpatient_id" style={{width: '100%', height: '50px', margin: '0px', fontSize: '20px'}} type="text" placeholder={componentsStrings.uploadpatient_id} onChange={this.handlePatientIdentChange} value={this.state.patientIdent} disabled={this.state.patientidentdisabled}></input>
                                                    </Col>}
                                                </Row>
                                            </Col>
                                            <Col md={3}>
                                                <Row>
                                                    {this.props.usertype != "R" && this.state.patientexamiddisabled && <Col md={12}>
                                                        <label>{componentsStrings.uploadpatient_oprname}</label>
                                                        <input id="operator_name" style={{width: '100%', height: '50px', margin: '0px', fontSize: '20px'}} type="text" value={this.props.username} disabled={this.state.patientexamiddisabled}></input>
                                                    </Col>}
                                                </Row>
                                            </Col>
                                            {this.props.usertype == "R" && <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <label style={{fontSize: '20px', marginLeft: '0px'}}>{componentsStrings.uploadpatient_dateofbirth}</label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <DatePicker disabled={false} selected={this.state.patientdateofbirth}  dateFormat={"MM/dd/yyyy"} onChange={this.handleDateOfBirthChange} />
                                                    </Col>
                                                </Row>
                                            </Col>}
                                            {this.props.usertype == "R" && <Col md={2}>
                                                <Row>
                                                    <Col md={3}>
                                                        <label style={{fontSize: '20px'}}>{componentsStrings.uploadpatient_gender}</label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={3}>
                                                        <Form.Check name="selectSex" type="radio" label={componentsStrings.uploadpatient_gender_male} defaultChecked={false} onClick={this.handleSelectMale}/>
                                                        <Form.Check name="selectSex" type="radio" label={componentsStrings.uploadpatient_gender_female} defaultChecked={false} onClick={this.handleSelectFemale}/>
                                                        <Form.Check name="selectSex" type="radio" label={componentsStrings.uploadpatient_gender_other} defaultChecked={false} onClick={this.handleSelectOther}/>
                                                    </Col>
                                                </Row>
                                            </Col>}
                                            {this.state.showAllCameras && <Col md={9}>
                                            </Col>}
                                        </Row>            
                                    </Col>
                                </Row>
                                
                                <Row style={{height: "30px"}}></Row>

                                <Row  >
                                    <Col md={8}>
                                        <Row >
                                            <Col md={6}>
                                                <div style={{textAlign: "center"}}>
                                                    <label style={{fontSize: '20px'}}>{componentsStrings.uploadpatient_righteye}</label>
                                                </div>
                                                <label style={{width: '100%', height: '100%'}} >
                                                    <div className={this.state.bad_r_show} style={{cursor: 'pointer', textAlign:'center'}} id="uploadpatient_righteye">
                                                        <div className="droppable square" style={{position:'relative'}} onDragOver={(e)=>this.onDragOver(e)} onDrop={(e)=>this.onDropEye(e, "fileImgRight")} onDragStart = {(e) => this.onDragStartEye(e, "fileImgRight")}>
                                                        {this.state.currentlyawaitingphoto === 'right' && this.cameraIsSelected() === true && <div style={centerStyle}>                                                    
                                                                    <div >{componentsStrings.uploadpatient_waitforimage}</div>  
                                                                    <Loader type="ThreeDots" color="#000000" height={20} width={20}></Loader>
                                                            </div>
                                                        }
                                                        <img style={{width: '100%', height: '100%'}} src={this.state.fileImgRight}/>                                                            
                                                        </div>
                                                    </div>

                                                    <div className="filewrapper">
                                                        <b>
                                                            <a style={{color: '#3366BB'}}>{componentsStrings.uploadpatient_browse}</a>
                                                        </b> {componentsStrings.uploadpatient_browse_for}
                                                        <input style={{display: 'none'}} type={this.state.browsefile} name="fileinputl" onInput={this.onUploadRight} onClick={this.handleClickRight} id="fileinputright" accept="image/*" />
                                    
                                                        <div style={{fontSize: '16px', overflowWrap: 'break-word'}}>
                                                            {this.state.fileNameRight + this.state.qualitytext_r}
                                                        </div>

                                                    </div>

                                                </label>
                                            </Col>
                                            <Col md={6}>
                                                <div style={{textAlign: "center"}}>
                                                    <label style={{fontSize: '20px'}}>{componentsStrings.uploadpatient_lefteye}</label>
                                                </div>
                                                <label style={{width: '100%', height: '100%'}} >
                                                    <div className={this.state.bad_l_show} style={{cursor: 'pointer', textAlign:'center'}} id="uploadpatient_lefteye">
                                                        <div className="droppable square" style={{position:'relative'}} onDragOver={(e)=>this.onDragOver(e)} onDrop={(e)=>this.onDropEye(e, "fileImgLeft")} onDragStart = {(e) => this.onDragStartEye(e, "fileImgLeft")}>
                                                        
                                                            {this.state.currentlyawaitingphoto === 'left' && this.cameraIsSelected() === true && <div style={centerStyle}>                                                    
                                                                    <div >{componentsStrings.uploadpatient_waitforimage}</div>  
                                                                    <Loader type="ThreeDots" color="#000000" height={20} width={20}></Loader>
                                                                </div>
                                                            }
                                                            <img style={{width: '100%', height: '100%'}} src={this.state.fileImgLeft}/>
                                                        </div>
                                                    </div>

                                                    <div className="filewrapper">
                                                        <b>
                                                            <a style={{color: '#3366BB'}}>{componentsStrings.uploadpatient_browse}</a>
                                                        </b> {componentsStrings.uploadpatient_browse_for}
                                                        <input style={{display: 'none'}} type={this.state.browsefile} name="fileinputl" id="fileinputleft" onInput={this.onUploadLeft} onClick={this.handleClickLeft} accept="image/*" />

                                                        <div style={{fontSize: '16px', overflowWrap: 'break-word'}}>
                                                            {this.state.fileNameLeft + this.state.qualitytext_l}
                                                        </div>
                                                        
                                                    </div>

                                                </label>
                                            </Col>
                                        </Row>            
                                    </Col>

                                    {this.state.showAllCameras && <Col md={4} style={{ flexDirection: 'column', display: 'flex', flexWrap: 'wrap'}}> 
                                    
                                        <Form.Group>

                                            <Row style={{ justifyContent: 'center'}}>
                                                <label style={{fontSize: '20px'}}>{componentsStrings.uploadpatient_cameras}</label>
                                            </Row>

                                            <Row style={{ justifyContent: 'center'}}>
                                                <DropdownButton disabled={this.state.cameraselectiondisabled} id="dropdown-basic-button" title={this.state.cameraselected}>
                                                    {this.getallcameraslist()}
                                                </DropdownButton>
                                            </Row> 

                                            <Row style={{ justifyContent: 'center'}}>
                                                {this.cameraIsSelected() === true && <div style={{marginLeft: "5px"}}> pending image capture </div>}
                                            
                                            </Row>
                                        </Form.Group>
                                        
                                        <div style={{ overflowY: 'scroll', maxHeight: "300px"}}>
                                            {this.getUnknownEyeImagesRow()}
                                        </div> 
                                        
                                    </Col>}
                                </Row>   
                                
                                <Row>
                                    <Col md={10}>
                                        <Row>
                                            <Col md={5}>
                                                <div align="left">
                                                    {true && (this.state.fileContentLeft != null || this.state.fileContentRight != null 
                                                            || this.state.patientexamid != '') && <Button id="uploadpatient_reset" onClick={this.resetPatient} variant="primary">{componentsStrings.uploadpatient_reset}</Button>} 
                                                    {(this.state.fileContentLeft == null && this.state.fileContentRight == null 
                                                            && this.state.patientexamid == '') && <Button id="uploadpatient_reset" onClick={this.resetPatient} disabled variant="secondary">{componentsStrings.uploadpatient_reset}</Button>}
                                                </div>

                                            </Col>
                                            <Col md={5}>
                                                <div align="right">
                                                    {this.state.currentimageid != 0 && <Button style={{marginRight: '15px'}} id="uploadpatient_recordresults" onClick={this.recordResults} variant="primary">{componentsStrings.uploadpatient_submitaccept}</Button>}
                                                    {(this.state.fileContentLeft == null || this.state.fileContentRight == null || this.state.patientexamid == ''  
                                                                                || this.state.identicalnames || this.state.bad_r_show === 'container-drag dupborder' || this.state.bad_l_show === 'container-drag dupborder' || this.state.bad_r_show === 'container-drag iiqborder'
                                                                                || this.state.bad_l_show === 'container-drag iiqborder') && <Button id="uploadpatient_upload" disabled variant="secondary">{componentsStrings.uploadpatient_submitexam}</Button>}
                                                    {this.state.fileContentLeft != null && this.state.fileContentRight != null && this.state.patientexamid != '' 
                                                                            && !this.state.identicalnames && this.state.bad_r_show === 'container-drag' && this.state.bad_l_show === 'container-drag'
                                                                            && <Button id="uploadpatient_upload" onClick={this.uploadPatient} variant="primary">
                                                                                {componentsStrings.uploadpatient_submitexam}
                                                                                </Button>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>                            
                                    <Col md={2}>
                                    </Col>
                                </Row>

                                <Row>

                                    <Col md={8}> 
                                    </Col>

                                    <Col md={4}> 
                                    
                                    </Col>
                                    
                                </Row>

                            </Col>
                        </Row>  

                    </Container>
                </div>
            );
        }
    }

    getCameraDisplay() {

        let cameraselectedtitle = this.state.cameraselected;
        if(this.state.cameraselected == "None") {
            cameraselectedtitle = componentsStrings.button_select_camera;
        }
        if(this.state.displayUploadResults == true) {
            return (
                <div style={{paddingTop: '30px'}}>
                </div>
            );
        } else { 

            return (
                <div style={{height: '100%'}}>
                    {this.errorMessageBox()}
                    {this.errorFatalMessageBox()}
                    {this.errorExamProcessMessageBox()}
                    {this.examProcessFailedMessageBox()}
                    {this.criticalExamProcessMessageBox()}
                    {this.uploadImagesPleaseWaitMessageBox()}
                    {this.getOldExamPleaseWaitMessageBox()}
                    {this.recordResultsPleaseWaitMessageBox()}
                    {this.recordResultsFatalMessageBox()}
                    {this.uploadFatalMessageBox()}
                    {this.uploadErrorMessageBox()}
                    {this.uploadErrorCloseToMainMessageBox()}
                    {this.uploadSuccessMsgBox()}
                    {this.waitForImageDialogBox()}
                    {this.waitForSubmitionDialogBox()}
                    {this.uploadIIQMsgBox()}
                    {this.displayWrongImageSideMessageBox()}
                    {this.displayWrongImagTypeMessageBox()}
                    {this.displayImagesIdenticalMessageBox()}
                    {this.displayNeedToSelectCameraFirstMessageBox()}
                    {this.displayCameraSessionAbortedMessageBox()}
                    {this.abortingMessageBox()}
                    {this.timeoutMessageBox()}                    
                    {this.displayNoInternetConnectionMessageBox()}

                    <AddCameraToOperator selectedbarcode={this.state.selectedbarcode} onSelectCamera={this.handleSelectTheCamera} onCancleCamera={this.handleCancleTheCamera} openCameraSelectDialog={this.state.openCameraSelectDialog}></AddCameraToOperator>
                    
                    {this.state.cameraselected != '' && this.state.cameraselected != 'None' && <Row style={{justifyContent: 'center', position: 'absolute', right: '50%', marginTop: '-1.5%'}}> 
                                    <div style={{backgroundColor: 'white', padding: '7%', boxShadow:'rgb(0 0 0 / 60%) 5px 5px 5px 0px', borderRadius: '0.25rem'}}>
                                        <div className='btn-shine'>
                                            Pending Image Capture
                                        </div>
                                    </div>
                            </Row>}

                    <Container fluid="xl" style={{height: 'inherit'}}>
                    
                        <Col md={12} style={{height: 'inherit'}}>
                            
                            <Row style={{height: 'inherit', marginTop: "5px"}}>
                                
                                <Col md={2} style={{backgroundColor: '#e0eef7', height: 'inherit', maxHeight: '86%'}}>
                                    <div>
                                        <label style={{margin: '0px', fontSize: 'small'}}>{componentsStrings.uploadpatient_examid}</label>
                                        <label id="uploadexam_id" style={{fontSize: 'large', wordBreak: 'break-word', display: 'block'}}> {this.state.patientexamid} </label>
                                        <label style={{margin: '0px', fontSize: 'small'}}>{componentsStrings.uploadpatient_fullname}</label>
                                        <label id="uploadexampatientname_id" style={{fontSize: 'large', wordBreak: 'break-word', display: 'block'}}></label>
                                        <label style={{margin: '0px', fontSize: 'small'}}>{componentsStrings.uploadpatient_gender}</label>
                                        <label id="uploadexampatientgender_id" style={{fontSize: 'large', wordBreak: 'break-word', display: 'block'}}></label>
                                        <label style={{margin: '0px', fontSize: 'small'}}>{componentsStrings.uploadpatient_dateofbirth}</label>
                                        <label id="uploadexampatientdatebirth_id" style={{fontSize: 'large', wordBreak: 'break-word', display: 'block'}}></label>
                                        <label style={{margin: '0px', fontSize: 'small'}}>{componentsStrings.uploadpatient_mrn}</label>
                                        <label id="uploadexampatientmrn_id" style={{fontSize: 'large', wordBreak: 'break-word', display: 'block'}}></label>
                                    </div>
                                </Col>

                                <Col md={10} style={{height: 'inherit', maxHeight: '86%', display: 'flex', flexDirection: 'column', justifyContent: ': flex-start'}}>
                                    
                                    {/* // Images */}

                                    <Row style={{height: '70vh'}}>

                                        {/* // Right / Left */}

                                        <Col md={8} style={{height: '100%'}}>   

                                            <Row style={{alignItems: 'flex-start', height: 'auto', alignContent: 'flex-start'}}> 
                                                <Col md={6}>
                                                    <div style={{flexDirection: 'column'}}>

                                                        <Row>
                                                            <Col md={12} align="center">

                                                                <div style={{minHeight: '40px', display: 'inline-grid'}}>                                                                    
                                                                    {this.state.currentlyawaitingphoto === 'right' && this.cameraIsSelected() === true && <React.Fragment>
                                                                            {this.threeDots()}
                                                                        </React.Fragment>
                                                                    }
                                                                    </div>

                                                                    <div>
                                                                    <label style={{fontSize: 'initial'}}>{componentsStrings.uploadpatient_righteye}</label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <label style={{width: '100%', height: '100%'}} >
                                                            <div className={this.state.bad_r_show} id="uploadpatient_righteye">
                                                                <div className="droppable square" onDragOver={(e)=>this.onDragOver(e)} onDrop={(e)=>this.onDropEye(e, "fileImgRight")} onDragStart = {(e) => this.onDragStartEye(e, "fileImgRight")}>
                                                                    <input style={{display: 'none'}} type={this.state.browsefile} name="fileinputl" onInput={this.onUploadRight} onClick={this.handleClickRight} id="fileinputright" accept="image/*" />
                                                                    <img style={{width: '100%', height: 'auto'}} src={this.state.fileImgRight}/>                                                            
                                                                </div>
                                                            </div>
                                                        </label>

                                                        <div className="filewrapper" style={{fontSize: '13px'}}>
                                                            {componentsStrings.uploadpatient_select_to}

                                                            <div style={{fontSize: '16px'}}>
                                                                {this.state.fileNameRight + this.state.qualitytext_r}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col md={6}>
                                                    <div style={{ flexDirection: 'column'}}>
                                                        <Row>

                                                            <Col md={12} align="center">
                                                                <div style={{minHeight: '40px', display: 'inline-grid'}}>                                                                    
                                                                    {this.state.currentlyawaitingphoto === 'left' && this.cameraIsSelected() === true && <React.Fragment>
                                                                            {this.threeDots()}
                                                                        </React.Fragment>
                                                                    }
                                                                    </div>

                                                                    <div>
                                                                    <label style={{fontSize: 'initial'}}>{componentsStrings.uploadpatient_lefteye}</label>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <label style={{width: '100%', height: '100%'}} >
                                                            <div className={this.state.bad_l_show} id="uploadpatient_lefteye">
                                                                <div className="droppable square" onDragOver={(e)=>this.onDragOver(e)} onDrop={(e)=>this.onDropEye(e, "fileImgLeft")} onDragStart = {(e) => this.onDragStartEye(e, "fileImgLeft")}>
                                                                    <input style={{display: 'none'}} type={this.state.browsefile} name="fileinputl" id="fileinputleft" onInput={this.onUploadLeft} onClick={this.handleClickLeft} accept="image/*" />
                                                                    <img style={{width: '100%', height: 'auto'}} src={this.state.fileImgLeft}/>
                                                                </div>
                                                            </div>
                                                        </label>

                                                        <div className="filewrapper" style={{fontSize: '13px'}}>
                                                            {componentsStrings.uploadpatient_select_to}

                                                            <div style={{fontSize: '16px'}}>
                                                                {this.state.fileNameLeft + this.state.qualitytext_l}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>  

                                            {/* // Buttons */}
                                            <Row className="mt-1">
                                                <Col md={6}>
                                                    <div align="left">
                                                        <Button id="uploadpatient_save" onClick={this.saveAndClosePatient} variant="primary">{componentsStrings.uploadpatient_saveandclose}</Button>{' '}
                                                        <Button id="uploadpatient_cancel" onClick={this.cancelAndClosePatient} variant="primary">{componentsStrings.uploadpatient_cancelandclose}</Button>                                         
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div align="right">
                                                        {this.state.examreadytosubmit == true && this.state.examfailedretry == true && <Button id="uploadpatient_upload_epic" onClick={this.uploadExamPatientRetry} style={{float: 'right'}} variant="primary">{componentsStrings.uploadpatient_retryexam}</Button>}
                                                        {this.state.examreadytosubmit == true && this.state.examfailedretry == false && <Button id="uploadpatient_upload_epic" onClick={this.uploadExamPatient} style={{float: 'right'}} variant="primary">{componentsStrings.uploadpatient_submitexam}</Button>}
                                                        {this.state.examreadytosubmit == false && <Button id="uploadpatient_upload_epic" onClick={this.uploadExamPatient} disabled  style={{float: 'right'}} variant="primary">{componentsStrings.uploadpatient_submitexam}</Button>}
                                                        {(this.state.bad_r === true || this.state.bad_l === true) && <Button id="uploadpatient_recordexam" onClick={this.recordExamPatient} variant="primary" className="mr-1">{componentsStrings.uploadpatient_submitaccept}</Button>}
                                                    </div>
                                                </Col>
                                            </Row>      
                                        </Col>

                                        {/* // Staging */}
                                        <Col md={4}>

                                            <Row>
                                                <div  style={{position: 'absolute', width: '100%', height: '100%', overflowY: 'auto'}}>

                                                    <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row'}}>

                                                            {this.state.currentlyawaitingphoto === 'stage' && this.cameraIsSelected() === true && <div style={{ paddingLeft: '22%', width: '50%', display: 'inline-grid' }}>
                                                                {this.threeDots()}
                                                            </div>}

                                                            {(this.state.currentlyawaitingphoto !== 'stage' || this.cameraIsSelected() === false) && <div style={{ paddingLeft: '22%', width: '50%', display: 'inline-grid' }}>
                                                             
                                                            </div>}
                                                            <DropdownButton disabled={this.state.cameraselectiondisabled} id="dropdown-basic-button" title={this.state.cameraselected}>
                                                                {this.getallcameraslist()}
                                                            </DropdownButton>
                                                        
                                                    </div>

                                                    <div onClick={this.handleClickStage} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', direction: 'rtl', alignItems: 'center', borderStyle: 'solid', borderWidth: '1px', borderColor: '#ccc', marginTop: '5%', borderRadius: '0.25rem'}}>
                                                    
                                                        {this.getUnknownEyeImagesRow()}
                                                    </div>
                                                </div> 
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>  
                        </Col>
                    </Container>
                </div>
            );                                              
        }
    }

    getUnknownEyeImagesRow() {
        
        let container = [];
        this.state.allUntagedImages.forEach((row, index) => {
            const {imagetype, image, badimage} = row;
            let fileImg = 'data:' + imagetype + ';base64, ' + image;
            let border = 'container-drag';
            
            if(row.imageid == 0) {
                container.push(
                    <div className = {border} key={index} style={{margin: '2%', width: '45%', maxHeight: '25%', backgroundColor: '#dee2e6', position: 'relative', paddingBottom: '40%'}}>
                        <div className="droppable" style={{textAlign: 'center', position: 'absolute', height: '100%', width: '100%', display: 'flex', alignItems: 'center'}}>
                            <div style={{fontSize: 'medium', width: '100%', display: 'flex', flexDirection: 'column'}}>
                                <span>{'Last Image'} &#187;</span>
                            </div>
                        </div>
                    </div>
                );  
                return;      
            }

            if(badimage) {
                border = 'container-drag iiqborder';  
            }

            if(index < 8) {
                container.push (
                    <div className = {border + " droppable"} key={index} style={{margin: '2%', overflow: 'hidden', maxWidth: '45%', maxHeight: '25%', textAlign: 'center'}}
                        onDragOver={(e) => this.onDragOver(e, index)} onDrop={(e)=>this.onDropEye(e, fileImg)} onDragStart = {(e) => this.onDragStartUntaged(e, index)}>
                        <img style={{ width: '100%', height: 'auto'}} src={fileImg}></img>
                    </div>
                );
            }
          });

        return (container);
    }

    getallcameraslist() {
        return this.state.allcameras.map((row, index) => {
            const { name, barcode, type} = row;
              return (
                <Dropdown.Item key={barcode} onClick={this.selectcamera} barcode={barcode} type={type} id="fromthelastever" href="javascript:void(0);">{name}</Dropdown.Item>
              );
          });
    }
        
    threeDots() {

        return(
            <React.Fragment>
                <Spinner animation="grow" size="sm" style={{  animationDelay: '-0.32s',  animationDuration: '1.3s', color: 'red', marginRight: '10%', width: '0.7rem', height: '0.7rem'}}/>
                <Spinner animation="grow" size="sm" style={{  animationDelay: '-0.16s',  animationDuration: '1.3s', color: 'red', marginRight: '10%', width: '0.7rem', height: '0.7rem'}}/>
                <Spinner animation="grow" size="sm" style={{  animationDelay: '-0.00s',  animationDuration: '1.3s', color: 'red', marginRight: '10%', width: '0.7rem', height: '0.7rem'}}/>
            </React.Fragment>
        );
    }

    waitForSubmitionDialogBox() {
        return(
            <Modal show={this.state.waitForSubmitionDialog}>
                <Modal.Header>
                    <Modal.Title>{messages.uploadpatient_uploadexamimages_submit_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{display: 'flex', alignItems: 'center'}}>
                        {this.state.waitForSubmitionMsg}
                        
                        <Spinner animation="border" variant="primary" />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.showabortbutton && <Button variant="secondary" onClick={this.abortPatientSubmit}>
                        {componentsStrings.button_abort}
                    </Button>}
                </Modal.Footer>
            </Modal>        
            );
    }
    
    abortingMessageBox() {
        return(
        <Modal show={this.state.abortingDialog}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_abort_exam_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{display: 'flex', alignItems: 'center'}}>
                    {messages.uploadpatient_abort_exam_body}

                    <Spinner animation="border" role="status" style={{height: '1.5rem', width: '1.5rem', marginLeft: '5px'}}>
                            <span className="visually-hidden"></span>
                    </Spinner>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>        
        );
    }

    waitForImageDialogBox() {
        return(
            <Modal show={this.state.waitForImageDialog}>
                <Modal.Header>
                    <Modal.Title>{messages.uploadpatient_checkforimage_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{display: 'flex', alignItems: 'center'}}>
                        {this.state.waitForCameraImageMsg}
                        
                        <Spinner animation="border" variant="primary" />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={this.handleCloseWaitForImageDialog}>
                        {componentsStrings.button_close}
                    </Button> */}
                </Modal.Footer>
            </Modal>        
            );
    }
    
    uploadSuccessMsgBox() {
        return(
            <Modal show={this.state.displayUploadSuccessMsg}>
                <Modal.Header>
                    <Modal.Title>{messages.uploadpatient_uploadimages_title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{messages.uploadpatient_uploadimages_body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseUploadSuccessMsgBox}>
                        {componentsStrings.button_close}
                    </Button>
                </Modal.Footer>
            </Modal>        
            );
    }

    uploadIIQMsgBox() {
    return(
        <Modal show={this.state.displayIIQMsg}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_uploadimages_iiq_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container id="uploadpatient_iiqmsg" style={{display: 'flex', alignItems: 'center'}}>
                    {messages.uploadpatient_uploadimages_iiq_body}
                </Container>
            </Modal.Body>
                
                
            <Modal.Footer>
                <Button id="uploadpatient_iiqmsg_close" variant="secondary" onClick={this.handleCloseIIQMsgBox}>
                    {componentsStrings.button_close}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
    
    cameraIsSelected() {

        if(this.state.cameraselected != '' && this.state.cameraselected != 'None' && this.state.camerawasselected === true) {

            return true;
        }

        return false;
    }

    timeoutMessageBox() {
        return(
        <Modal show={this.state.displayTimeoutMsg} size="lg">
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_timeout_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
            {/* [selected your camera/selected the right eye/selected the left eye] */}
                <p style={{whiteSpace: 'pre-line'}}> {messages.uploadpatient_timeout_body1} {messages.uploadpatient_timeout_body2} </p>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseTimeoutMessageBox}>
                    {componentsStrings.button_ok}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
   
    errorFatalMessageBox() {
        return(
        <Modal show={this.state.displayFatalErrorMessage}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_getoldexam_pleasewait_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messages.uploadpatient_getoldexam_fatalerror_body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseFatalMessageBox}>
                    {componentsStrings.button_close}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }

    errorMessageBox() {
        return(
        <Modal show={this.state.displayErrorMessage}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_uploadimages_invalidsomefield_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messages.uploadpatient_uploadimages_invalidsomefield_body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseMessageBox}>
                    {componentsStrings.button_close}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
    criticalExamProcessMessageBox() {
        return(
            <Modal show={this.state.displayExamProcessCriticalMessage}>
                <Modal.Header>
                    <Modal.Title>{this.state.displayExamProcessCriticalMessageTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.displayExamProcessCriticalMessageBody}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseCriticalExamProcessMessageBox}>
                        {componentsStrings.button_close}
                    </Button>
                </Modal.Footer>
            </Modal>        
        );
    }
    errorExamProcessMessageBox() {
        if(this.state.examfailedretry === false) {
            return(
                <Modal show={this.state.displayExamProcessErrorMessage}>
                    <Modal.Header>
                        <Modal.Title>{this.state.displayExamProcessErrorMessageTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.displayExamProcessErrorMessageBody}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseErrorExamProcessMessageBox}>
                            {componentsStrings.button_close}
                        </Button>
                    </Modal.Footer>
                </Modal>        
            );
        }
        else {
            return(
                <Modal show={this.state.displayExamProcessErrorMessage}>
                    <Modal.Header>
                        <Modal.Title>{this.state.displayExamProcessErrorMessageTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.displayExamProcessErrorMessageBody}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.uploadExamPatientRetry}>
                            {componentsStrings.uploadpatient_retryexam}
                        </Button>
                        <Button variant="secondary" onClick={this.saveAndClosePatient}>
                            {componentsStrings.button_save}
                        </Button>
                    </Modal.Footer>
                </Modal>        
            );
        }
    }
    examProcessFailedMessageBox() {
        return(
            <Modal show={this.state.displayExamProcessFailedMessage}>
                <Modal.Header>
                    <Modal.Title>{this.state.displayExamProcessFailedMessageTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.state.displayExamProcessFailedMessageBody}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.saveAndClosePatient}>
                        {componentsStrings.uploadpatient_saveandclose}
                    </Button>
                </Modal.Footer>
            </Modal>        
        );
    }
    
    recordResultsPleaseWaitMessageBox() {
        return(
        <Modal show={this.state.displayRecordResultProcessMessage}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_recordresults_pleasewait_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{display: 'flex', alignItems: 'center'}} id="uploadpatient_recordresultpleasewaitmsg">
                    {messages.uploadpatient_recordresults_pleasewait_body}

                    <Spinner animation="border" role="status" style={{height: '1.5rem', width: '1.5rem', marginLeft: '5px'}}>
                            <span className="visually-hidden"></span>
                    </Spinner>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleRecordResultsCancelMessageBox}>
                {componentsStrings.button_cancel}
            </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
    
    getOldExamPleaseWaitMessageBox() {
        return(
        <Modal show={this.state.displayGetOldExamMessage}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_getoldexam_pleasewait_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Container style={{display: 'flex', alignItems: 'center'}} id="uploadpatient_pleasewaitmsg">
                    {messages.uploadpatient_getoldexam_pleasewait_body}

                    <Spinner animation="border" role="status" style={{height: '1.5rem', width: '1.5rem', marginLeft: '5px'}}>
                        <span className="visually-hidden"></span>
                    </Spinner>
                </Container>

            </Modal.Body>
            <Modal.Footer>
            {/* <Button variant="secondary" onClick={this.handleGetOldExamCancelMessageBox}>
                {componentsStrings.button_cancel}
            </Button> */}
            </Modal.Footer>
        </Modal>        
        );
    }

    uploadImagesPleaseWaitMessageBox() {
        return(
        <Modal show={this.state.displayUploadImagesMessage}>
            <Modal.Header>
                <Modal.Title>{this.state.displayUploadImagesMessageTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Container style={{display: 'flex', alignItems: 'center'}} id="uploadpatient_pleasewaitmsg">
                    {this.state.displayUploadImagesMessageBody}

                    <Spinner animation="border" role="status" style={{height: '1.5rem', width: '1.5rem', marginLeft: '5px'}}>
                        <span className="visually-hidden"></span>
                    </Spinner>
                </Container>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleUploadImageCancelMessageBox}>
                {componentsStrings.button_cancel}
            </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
    displayWrongImageSideMessageBox() {
        return(
        <Modal show={(this.state.displayWrongImageSideR || this.state.displayWrongImageSideL)}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_wrongimageside_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.displayWrongImageSideMsg}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleWrongSideYesMessageBox}>
                    {componentsStrings.button_yes}
                </Button>
                <Button variant="secondary" onClick={this.handleWrongSideNoMessageBox}>
                    {componentsStrings.button_no}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }

    displayNeedToSelectCameraFirstMessageBox() {
        return(
        <Modal show={(this.state.displayNeedToSelectCameraFirst)}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_needtoselectcamera_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messages.uploadpatient_needtoselectcamera_body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleNeedToSelectCameraFirstOK}>
                    {componentsStrings.button_ok}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }

    displayCameraSessionAbortedMessageBox() {
        let msg = format(messages.uploadpatient_camerasesessionaborted_body, this.state.operatornameSessionAborted);

        return(
        <Modal show={(this.state.displayCameraSessionAborted)}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_camerasesessionaborted_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{msg}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCameraSessionAbortedOK}>
                    {componentsStrings.button_ok}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }

    displayNoInternetConnectionMessageBox() {
        return(
        <Modal show={(this.state.displaynointernetconnection)}>
            <Modal.Header>
                <Modal.Title>{messages.app_nointernetconnection_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messages.app_nointernetconnection_body}</Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>        
        );
    }
    

    displayImagesIdenticalMessageBox() {
        return(
        <Modal show={this.state.identicalnames && !this.state.displayWrongImageSideR && !this.state.displayWrongImageSideL}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_identicalimage_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messages.uploadpatient_identicalimage_body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleIdenticalOKMessageBox}>
                    {componentsStrings.button_ok}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
    
    displayWrongImagTypeMessageBox() {
        return(
        <Modal show={(this.state.displayWrongImageTypeR || this.state.displayWrongImageTypeL)}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_wrongimagetype_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.displayWrongImageTypeMsg}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleWrongTypeOKMessageBox}>
                    {componentsStrings.button_ok}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
    
    recordResultsFatalMessageBox() {
        return(
        <Modal show={this.state.displayRecordResultMessage}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_recordresults_fatal_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messages.uploadpatient_recordresults_fatal_body}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleRecordResultFatalMessageBox}>
                {componentsStrings.button_close}
            </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
    
    uploadFatalMessageBox() {
        return(
        <Modal show={this.state.displayUploadFatalMessage}>
            <Modal.Header>
                <Modal.Title>{messages.uploadpatient_uploadimages_fatal_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messages.uploadpatient_uploadimages_fatal_body}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleUploadFatalMessageBox}>
                {componentsStrings.button_close}
            </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
    
    uploadErrorMessageBox() {
        return(
        <Modal show={this.state.displayUploadErrorMessage}>
            <Modal.Header>
                <Modal.Title>{errormessages.uploadpatient_addNewImagesPatient_error_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.displayUploadErrorMessageMsg}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleUploadErrorMessageBox}>
                {componentsStrings.button_close}
            </Button>
            </Modal.Footer>
        </Modal>        
        );
    }

    
    uploadErrorCloseToMainMessageBox() {
        return(
        <Modal show={this.state.displayUploadErrorCloseToMain}>
            <Modal.Header>
                <Modal.Title>{errormessages.uploadpatient_addNewImagesPatient_error_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.displayUploadErrorMessageMsg}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleUploadErrorCloseToMainMessageBox}>
                {componentsStrings.button_close}
            </Button>
            </Modal.Footer>
        </Modal>        
        );
    }
}

export default UploadPatient;
