import React, { Component } from "react";
import "../../css/general.css"
import "../../css/colors.css"
import "../../css/texts.css"
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactLoading from 'react-loading';


class ExamCard extends Component {

  state = {
    exam: null
  };

  componentDidMount() {
    this.setState({exam: this.props.exam});
  }

  render() {

    let classname = 'exam-card bg-black pt-3 pb-3';
    let inDiagnosis = false;
    let opacity = '1';
    let isLongName = false;

    if(this.props.isDisplayed) {
      classname = 'exam-card-displayed pt-3 pb-3';
    }

    if(this.props.exam && this.props.exam.diagnosesid === 0 && this.props.exam.diagstate !== -1) {
      inDiagnosis = true;
      opacity = '0.5';   
    }

    if(this.props.exam && this.props.exam.examid && this.props.exam.examid.length > 26) {
      isLongName = true;      
    }

    return (
      <React.Fragment>

            <div className={"d-flex justify-content-between align-items-center bottom-border text-nowrap white " + classname} onClick={this.props.onClickPatient} index={this.props.index} id={'exam_card_' + this.props.index} style={{ cursor: 'pointer' }}>

              
              <div className={""} style={{opacity: opacity}}  key={this.props.index} index={this.props.index}>
                
                {isLongName === true && <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 300 }}
                  overlay={<Tooltip className="my-tooltip text"> {this.props.exam.examid} </Tooltip>} >
                  
                  <div className="fs26 text pb-2" index={this.props.index}>
                      {this.props.exam.examid}
                  </div>
                </OverlayTrigger>}

                {isLongName === false && <div className="fs26 text pb-2" index={this.props.index}>
                  {this.props.exam.examid}
                </div>}

                <div className="exam-card-datetext lh24 fs20 text gray_3" index={this.props.index}>
                  {this.props.exam.date} {this.props.exam.time}
                </div>

              </div>

              {inDiagnosis === true && <div className="pr-3" index={this.props.index}>
                  <ReactLoading type={"spokes"} color="#ffffff" index={this.props.index}/>
                </div>}

              {inDiagnosis === false && !this.props.exam.readed && <div className="pr-3" index={this.props.index} style={{position: 'absolute', right: '10%'}}>
                  New
                </div>}
            </div>
      </React.Fragment>
    );

  }

}

export default ExamCard;
