import React, { Component, Fragment } from "react";
import Table from "react-bootstrap/Table";
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Pagination from 'react-bootstrap/Pagination';
import errorcodes from "../errorcodes";
import messages from "../../Messages/Messages";
import componentsStrings from "../../Messages/Components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { Redirect } from "react-router-dom";
import connections from "../../connections"
import PatientView from '../DiagnoseTool/patientview';
import Moment from 'moment/moment'
import Loader from "react-loader-spinner";
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import Container from "react-bootstrap/Container";

class AEYETableRow {
  constructor() {
    this.date = "";
    this.time = "";
    this.patientid = "";
    this.examid = "";
    this.patientdbid = "";
    this.imagedbid = "";
    this.diagnosesid = 0;
    this.diagstate = -9;
    this.clinicname = "";
    this.clinicfullname = "";
    this.patientname = "";
    this.patientstatus = "";
    this.diagnosedate = "";
    this.diagnosedtime = "";
    this.diagnoseby = "";
    this.righteye = "";    
    this.lefteye = "";    
    this.patientseyes = "";
    this.diagnosed = -1;
    this.fullname = "";
    this.uploadedon = "";
    this.diagnosedon = "";
    this.diseasesL = [];
    this.diseasesR = [];
    this.diseasesP = [];
    this.archived = false;
  }
}
class AEYEDisease {
  constructor() {
    this.diagnose = 0;
    this.diagnosis = 0;
    this.disease = 0;
    this.diseasedescription = "";
    this.diseasename = "";
    this.id = 0;
    this.side = "";
  }
}
class AEYETableData {
  constructor() {
    this.data = [];
  }
}

class AEYETable extends Component {
  tofatchData = true;

  state = {
      aeyetabledata: null,
      aeyetabledataChk: null,
      pagesizechanged: false,
      fromindex: 0,
      pagesize: 5,
      pagesizetext: 5,
      currentactivpage: 0,
      maxnumberofitems: 0,
      numberofrows: 0,
      lastpage: 1,
      searchCretiria: "",
      patientdbid: 0,
      imagedbid: 0,
      maintable: true,
      displayclinic: 'none',
      displaydiagnosedby: 'none',
      patientselected: false,
      redirectToUpload: null,
      displaytext: "",
      displayvalue: "all",
      diagnosesid: 0,
      diagstate: -9,
      patientstatus: "",
      diagnosed: false,
      clinicfullname: "",
      diagnoseby: "",
      uploadedon: "",
      diagnosedon: "",
      archived: false,
      showPatientView: false,
      patientname: "",
      timer: null,
      examdbid: 0,
      operatorsession:  null, 
      hasdiseasesselected: true,
      clickalview: false,
      displayDownloadPDFMessage: false,
      displayDownloadPDFErrorMessage: false,
      clinicview: true
  };
  
  constructor() {
    super();
    this.numberOfPagesDisplayed = 5;
  }

  handleDisplayDownloadPDFErrorMessage = event => {
    this.setState({displayDownloadPDFErrorMessage: false})
  }

  handleDoctorView = (event) => {
    
    this.tofatchData = true;
    this.fetchLastDoctorDiagnose(event.target);
  }

  patientView = (event) => {

    if(event!=undefined) {
      let clinicview = true;
      let thetarget = event.target;
      if(event.target === '' || event.attributes != null) {
        clinicview = false;
        thetarget = event;
      }

      if(thetarget.localName == 'svg') {
        thetarget = event.target.parentNode.parentNode;
      }
      let archived = false;
      if(thetarget.attributes["archived"].value == 'true') {
        archived = true;
      }
      let report = false;
      if(thetarget.attributes["report"].value == 'true') {
        report = true;
      }
      let patientdbid = thetarget.attributes["patientdbid"].value ? thetarget.attributes["patientdbid"].value : thetarget.attributes["patientdbid"];
      let imagedbid = thetarget.attributes["imagedbid"].value ? thetarget.attributes["imagedbid"].value : thetarget.attributes["imagedbid"];
      let diagnosesid = thetarget.attributes["diagnosesid"].value ? thetarget.attributes["diagnosesid"].value : thetarget.attributes["diagnosesid"];
      let diagstate = thetarget.attributes["diagstate"].value ? thetarget.attributes["diagstate"].value : thetarget.attributes["diagstate"];
      let clinicfullname = thetarget.attributes["clinicfullname"].value ? thetarget.attributes["clinicfullname"].value : thetarget.attributes["clinicfullname"];
      let diagnoseby = thetarget.attributes["diagnoseby"].value ? thetarget.attributes["diagnoseby"].value : thetarget.attributes["diagnoseby"];
      let uploadedon = thetarget.attributes["uploadedon"].value ? thetarget.attributes["uploadedon"].value : thetarget.attributes["uploadedon"];
      let diagnosedon = thetarget.attributes["diagnosedon"].value ? thetarget.attributes["diagnosedon"].value : thetarget.attributes["diagnosedon"];
      let patientname = thetarget.attributes["patientname"].value ? thetarget.attributes["patientname"].value : thetarget.attributes["patientname"];
      let clickalview = thetarget.attributes["clickalview"].value ? thetarget.attributes["clickalview"].value : thetarget.attributes["clickalview"];
      
      this.setState({ clickalview: clickalview, clinicview: clinicview, showPatientView: true, patientdbid: patientdbid , imagedbid: imagedbid, diagnosesid: diagnosesid, diagstate: diagstate, clinicfullname: clinicfullname, diagnoseby: diagnoseby, uploadedon: uploadedon, diagnosedon: diagnosedon, archived: archived, patientname: patientname});
    }
  }

  closepatientview = () => {
    this.setState({ showPatientView: false});
    this.tofatchData = true;
  };

  handleAddNewPatient = () => {
    this.setState({redirectToUpload: '/legacy/upload'});
  };
  
  handleResearchPatient = () => {
    this.setState({redirectToUpload: '/research'});
  };

  handleArchiveDiagnosis = () => {

    let dataToArchive = [];
    class OneDiag {
      patientdbid = 0;
      imagedbid = 0;
    }

    var table = document.getElementById("aeyetable_mainPatientsTable");
    var ischecked = false;
    for (var i = 1, row; row = table.rows[i]; i++) {
      var col = row.cells[0]
      ischecked = col.childNodes[0].checked
      if(ischecked == true) {
        let onediag = new OneDiag();
        onediag.patientdbid = row.childNodes[0].childNodes[0].attributes.patientdbid.value;
        onediag.imagedbid = row.childNodes[0].childNodes[0].attributes.imagedbid.value;
        dataToArchive.push(onediag);
      }
    }
    {this.archiveDiagnosis(dataToArchive)}
  }
 
  archiveDiagnosis = (dataToArchive) => {
    let toarchive = true;
    if(this.state.displayvalue == "archived") {
      toarchive = false;
    }

    let url = connections.sdkurl;
    fetch(url + "/AEYEAppServer/v4/Patients/archiveDiagnosis", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          toarchive: toarchive,
          diagnosistoarchive: dataToArchive
        }),
      })
        .then(response => response.json())
        .then(data => {
          
          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
            let aeyetabledataChk = this.state.aeyetabledataChk;
            for(var i = 0 ; i < aeyetabledataChk.length ; i++) {
              aeyetabledataChk[i] = false;
            }

            this.tofatchData = true;
            this.setState({aeyetabledataChk: aeyetabledataChk, patientselected: false});
          }
        });
  }

  handlePatientSelected = (event) => {
    var selectedRow = (this.state.currentactivpage * this.state.numberofrows) + Number(event.target.attributes['rowindex'].value)
    this.state.aeyetabledataChk[selectedRow] = event.target.checked;
    var table = document.getElementById("aeyetable_mainPatientsTable");
    var ischecked = false;
    for (var i = 1, row; row = table.rows[i]; i++) {
      var col = row.cells[0]
      ischecked = col.childNodes[0].checked
      if(ischecked == true) {
        break;
      }
    }
    this.setState({ patientselected: ischecked });
  }

  downloadEmployeeData = (imagedbid, datediagnosed) => {
    let url = connections.sdkurl;
    let theurl = url + "/AEYEAppServer/v3/Images/downloadpdf";
    fetch(theurl,  {
        method: "POST",
        body: JSON.stringify({
        logonid: this.props.username,
        session: this.props.session,
        imagedbid: imagedbid,
        operatorid: this.state.operatorid
        })
      }).then(response => {
        this.setState({displayDownloadPDFMessage: false});
        if(response.ok == true && response.status == 200) {
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = this.props.username + '_' + datediagnosed + '.pdf';
            a.click();
          }).catch(e => {
            this.setState({ displayDownloadPDFMessage: false });
          });
        } else if(response.ok == false) {
          if((response.status + errorcodes.errorOK) == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          } else {
            this.setState({displayDownloadPDFErrorMessage: true});
          }
          }
    }).catch(e => {
      this.setState({ displayDownloadPDFMessage: false });
    });
  }

  handleDownloadPDF = (event) => {


    try {
      let diagnosed =event.target.attributes["diagnosed"].value;
      if(diagnosed === '0') {
        return;
      }
      
      this.setState({displayDownloadPDFMessage: true});
      let imagedbid =event.target.attributes["imagedbid"].value;
      let datediagnosed =event.target.attributes["datediagnosed"].value;
      this.downloadEmployeeData(imagedbid, datediagnosed);
    } catch(e) {
      this.setState({ displayDownloadPDFMessage: false });
    }
  }

  handleReDiagnoseImage = (event) => {
    let imagedbid =event.target.attributes["imagedbid"].value;
    this.reDiagnoseImage(imagedbid);
  }

  startTimer() {
    if(this.state.timer == null) {
      let timer = setInterval(() => {
        this.tofatchData = true;
        this.fetchImages();
      }, 3000);
      this.setState({ timer: timer });
    }
  }
  
  stopTimer() {
    if(this.state.timer != null) {
      clearTimeout(this.state.timer);
      this.setState({ timer: null });
    }
  }
  
  componentDidMount() {

    let displaytext = "";
    if(this.props.usertype != 'R') {
      displaytext = messages.table_dropdown_displaytype_allexamstitle;
    } else {
      displaytext = messages.table_dropdown_displaytype_alltitle;
    }
    this.setState({ displaytext: displaytext });

  }
  
  componentDidUpdate(prevProps) {
    if(this.props.diseasesSelected !== prevProps.diseasesSelected) {
      let hasdiseasesselected = true;
      if(this.props.diseasesSelected == null || this.props.diseasesSelected.length <= 0) {
        
        hasdiseasesselected = false;
      }

      this.setState({ timer: null, hasdiseasesselected: hasdiseasesselected });
    }
    this.fetchImages();
  }
  
  componentWillUnmount() {
  }  
  
  handlePageSizeSelection = (eventKey, event) => {
    this.tofatchData = true;
    this.setState({ currentactivpage: 0, pagesizetext: event.target.text, pagesize: parseInt(event.target.attributes["value"].value), fromindex: 0, pagesizechanged: true});
  };
  
  handleDisplaySelection = (eventKey, event) => {
    let displaytext = "";
    if(event.target.attributes["value"].value == "all") {
      if(this.props.usertype == 'R') {
        displaytext = messages.table_dropdown_displaytype_allexamstitle;
      } else {
        displaytext = messages.table_dropdown_displaytype_alltitle;
      }
    } else if(event.target.attributes["value"].value == "active") {
      displaytext = messages.table_dropdown_displaytype_activetitle;
    } else if(event.target.attributes["value"].value == "archived") {
      displaytext = messages.table_dropdown_displaytype_archivedtitle;
    }
    let aeyetabledataChk = this.state.aeyetabledataChk;
    for(var i = 0 ; i < aeyetabledataChk.length ; i++) {
      aeyetabledataChk[i] = false;
    }

    this.tofatchData = true;
    this.setState({ currentactivpage: 0, displaytext: displaytext, displayvalue: event.target.attributes["value"].value, fromindex: 0, aeyetabledataChk: aeyetabledataChk });
  };
  
  handlePaginationNext = () => {
    let fromindex = (this.state.currentactivpage + 1) * this.state.pagesize;
    this.tofatchData = true;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage + 1});
  }
  
  handlePaginationPrev = () => {
    let fromindex = (this.state.currentactivpage - 1) * this.state.pagesize;
    this.tofatchData = true;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage - 1});
  }
  
  handlePaginationFirst = () => {
    let fromindex = (this.state.currentactivpage - this.numberOfPagesDisplayed) * this.state.pagesize;
    this.tofatchData = true;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage - this.numberOfPagesDisplayed});
  }
  
  handlePaginationLast = () => {
    let fromindex = (this.state.currentactivpage + this.numberOfPagesDisplayed) * this.state.pagesize;
    this.tofatchData = true;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage + this.numberOfPagesDisplayed});
  }
  
  handleSearch = event => {
    this.tofatchData = true;
    this.setState({ searchCretiria: event.target.value});
  }
  
  handlePageClick = (numberClicked) => {
    let fromindex = (numberClicked - 1) * this.state.pagesize;
    this.tofatchData = true;
    this.setState({ fromindex: fromindex, currentactivpage: numberClicked - 1});
  }

  reDiagnoseImage = (imageid) => {

    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimageread) {
      
      if(this.props.session != "") {
        this.tofatchData = false;
        let url = connections.sdkurl;

        fetch(url + "/AEYEAppServer/v3/Images/reDiagnoseImage", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            imageid: imageid,
            examdbid: 0,
            examdismiss: false
          })
        })
          .then(response => response.json())
          .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }

            if (data.errorcode == errorcodes.errorOK) {

            }

            setTimeout(this.fetchImages, 2000, this);
            this.tofatchData = true;
          });
      }
    }
  }

  getDesisDetails(badimage, diseases, diagnosed) {
    let desisdetails = messages.table_desisdetails_unknown;
    if(diagnosed != 1) {
      desisdetails = messages.table_desisdetails_nodiagyet;
    } else { 
      if(badimage == 1) {
        desisdetails = messages.table_desisdetails_iiq;
      } else {
        if(diseases.length == 0) {
          desisdetails = messages.table_desisdetails_nodiagyet;
        } else if(diseases.length > 1) {
          var numberofdiseases = 0;
          var numberoffaileddiseases = 0;
          var dataencrypted = false;
          for (const [index, disease] of diseases.entries()) {
            if(disease.diagnosis == -1) {
              dataencrypted = true;
            }
            if(disease.diagnosis == 5) {
              numberoffaileddiseases++;
            }
            if(disease.diagnosis == 1) {
              desisdetails = messages.table_desisdetails_suspiciousfind + disease.diseasedescription;
              numberofdiseases++;
            }          
          }
          if(numberoffaileddiseases > 0 && numberofdiseases == 0) {
            desisdetails = messages.table_desisdetails_failedtofind;
          } else if(numberoffaileddiseases > 0 && numberofdiseases > 0) {
            desisdetails = messages.table_desisdetails_morethanone;
          } else {
            if(numberofdiseases > 1) {
              desisdetails = messages.table_desisdetails_morethanone;
            } else if(numberofdiseases == 0) {
              desisdetails = messages.table_desisdetails_nofindings;
            }
          }
          if(dataencrypted === true) {
            desisdetails = messages.table_desisdetails_dataencrypted;
          }
        } else {
          if(diseases[0].diagnosis == 2) {
            desisdetails = messages.table_desisdetails_nofindings;
          } else if(diseases[0].diagnosis == 5) {
            desisdetails = messages.table_desisdetails_failedtofind + " - " + diseases[0].diseasedescription;
          } else if(diseases[0].diagnosis == -1) {
            desisdetails = messages.table_desisdetails_dataencrypted;
          } else {
            desisdetails = messages.table_desisdetails_suspiciousfind + diseases[0].diseasedescription;
          }
        }
      }
    }
    return desisdetails;
  }
  
  fetchImages = () => {

    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimageread) {
      
      let diagnosedbyfilter = '';
      if(this.props.usertype == 'C' || this.props.usertype == 'D'){

        diagnosedbyfilter = '1';
      }

      if(this.props.session != "" && this.tofatchData == true) {
        this.tofatchData = false;
        let url = connections.sdkurl;

        fetch(url + "/AEYEAppServer/v3/Images/getImages", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            fromindex: this.state.fromindex,
            pagesize: this.state.pagesize,
            search: this.state.searchCretiria,
            displayvalue: this.state.displayvalue,
            clinicid: this.props.clinicid,
            diagnosedbyfilter: diagnosedbyfilter
          })
        })
          .then(response => response.json())
          .then(data => {
            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }

            if (data.errorcode == errorcodes.errorOK) {
              let maxnumberofrows = data.maxnumberofrows;
              let numberofrows = data.numberofrows;
              let aeyetabledata = new AEYETableData();
              let atLeastOneNotDiagnosedYet = false;
              let aeyetabledataChk = this.state.aeyetabledataChk;
              if(maxnumberofrows > 0) {
                if(aeyetabledataChk == null) {
                  aeyetabledataChk = [maxnumberofrows];
                  for(var i = 0 ; i < maxnumberofrows ; i++) {
                    aeyetabledataChk[i] = false;
                  }
                }
                if(data || data.images) {
                  data.images.map((rowdata, index) => {
                    let aeyetablerow = new AEYETableRow();
                    
                    if(rowdata.diseases) {
                      for (const [index, disease] of rowdata.diseases.entries()) {
                        let aeyedisease = new AEYEDisease();
                        aeyedisease.diagnose = disease.diagnose;
                        aeyedisease.diagnosis = disease.diagnosis;
                        aeyedisease.disease = disease.disease;
                        aeyedisease.diseasedescription = disease.diseasedescription;
                        aeyedisease.diseasename = disease.diseasename;
                        aeyedisease.id = disease.id;
                        aeyedisease.side = disease.side;
                        if(disease.side == "L") {
                          aeyetablerow.diseasesL.push(aeyedisease)
                        } else if(disease.side == "R") {
                          aeyetablerow.diseasesR.push(aeyedisease)
                        } else {
                          aeyetablerow.diseasesP.push(aeyedisease)
                        }
                      };
                    }
                    var timecreated = Moment(rowdata.created).format("HH:mm"); 
                    let created = new Date(rowdata.created); 
                    var datecreated = created.toDateString();
                    aeyetablerow.date = datecreated;
                    aeyetablerow.time = timecreated;
                    aeyetablerow.diagnosesid = rowdata.diagnosesid;
                    aeyetablerow.diagstate = rowdata.diagstate;
                    aeyetablerow.patientid = rowdata.patientid;
                    aeyetablerow.examid = rowdata.examid;
                    if(rowdata.examid != null && rowdata.examid.length > 50) {
                      aeyetablerow.examid = messages.table_desisdetails_dataencrypted;
                    }
                    
                    aeyetablerow.patientdbid = rowdata.patientdbid;
                    aeyetablerow.imagedbid = rowdata.imagedbid;
                    aeyetablerow.clinicname = rowdata.clinicname;
                    aeyetablerow.clinicfullname = rowdata.clinicfullname;
                    aeyetablerow.uploadedon = rowdata.uploadedon;
                    aeyetablerow.archived = rowdata.archived;
                    aeyetablerow.diagnosedon = rowdata.diagnosedon;
                    aeyetablerow.patientname = rowdata.patientname;
                    let diagnosedstatus = messages.table_diagstatuc_diag;
                    if(rowdata.diagnosed != 1) {
                      diagnosedstatus = messages.table_diagstatuc_pending;
                    }
                    aeyetablerow.diagnosed = rowdata.diagnosed
                    aeyetablerow.patientstatus = diagnosedstatus;
                    var timediagnosed = Moment(rowdata.diagnosedon).format("HH:mm");
                    var datediagnosed = Moment(rowdata.diagnosedon).format("MMM DD YYYY");  
                    aeyetablerow.diagnosedate = datediagnosed;
                    aeyetablerow.diagnosedtime = timediagnosed;

                    let diagnosedBy = messages.table_diagby_algo;
                    if (rowdata.automatic != 1) {
                      diagnosedBy = rowdata.doctorname;
                    }
                    var fullname = rowdata.doctorname;
                    aeyetablerow.fullname = fullname;
    
                    aeyetablerow.diagnoseby = diagnosedBy;
                    aeyetablerow.righteye = this.getDesisDetails(parseInt(rowdata.bad_r), aeyetablerow.diseasesR, rowdata.diagnosed);    
                    aeyetablerow.lefteye = this.getDesisDetails(parseInt(rowdata.bad_l), aeyetablerow.diseasesL, rowdata.diagnosed);    
                    aeyetablerow.patientseyes = this.getDesisDetails(parseInt(rowdata.bad_p), aeyetablerow.diseasesP, rowdata.diagnosed);
                    if(rowdata.diagnosed == 0) {
                      atLeastOneNotDiagnosedYet = true;
                    }
                    aeyetablerow.hasdoctor = rowdata.hasdoctor;
                    aeyetablerow.doctordate = rowdata.doctordate;
                    var timedoctor= Moment(rowdata.doctordate).format("HH:mm");
                    var datedoctor = Moment(rowdata.doctordate).format("MMM DD YYYY");  
                    aeyetablerow.timedoctor = timedoctor;
                    aeyetablerow.datedoctor = datedoctor;

                    aeyetabledata.data.push(aeyetablerow);
                  });
                }
              }
              let numberofpages = Math.floor(maxnumberofrows / this.state.pagesize);
              if((maxnumberofrows % this.state.pagesize) > 0) {
                numberofpages += 1;
              }
              this.tofatchData = false;
              this.setState({lastpage: numberofpages, maxnumberofitems: maxnumberofrows, numberofrows: numberofrows, aeyetabledataChk: aeyetabledataChk});
              this.setState({ aeyetabledata: aeyetabledata, pagesizechanged: false});
              if(atLeastOneNotDiagnosedYet == true) {
                this.startTimer();
              } else {
                this.stopTimer();
              }
            }
          });
      }
    
    }
  }
  
  fetchLastDoctorDiagnose = (event) => {

    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimageread) {
      let imagedbid = event.attributes["imagedbid"].value;
      let clinicid = 0;
      let doctorid = 0;

      if(this.props.usertype === 'C') {

        clinicid = this.props.userdbid;
      }

      if(this.props.usertype === 'D') {

        doctorid = this.props.userdbid;
      }

      if(this.props.session != "" && this.tofatchData == true) {
        this.tofatchData = false;
        let url = connections.sdkurl;

        fetch(url + "/AEYEAppServer/v3/Images/getLastDoctorDiagnose", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            clinicid: clinicid,
            doctorid: doctorid,
            imagedbid: imagedbid
          })
        })
          .then(response => response.json())
          .then(data => {
            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }

            if (data.errorcode == errorcodes.errorOK) {

                if(data || data.images) {

                  data.images.map((rowdata) => {

                    event.attributes["uploadedon"] = rowdata.uploadedon;
                    event.attributes["diagnosesid"] = rowdata.diagnosesid;
                    event.attributes["diagstate"] = rowdata.diagstate;
                    event.attributes["patientdbid"] = rowdata.patientdbid;
                    event.attributes["exam_id"] = rowdata.exam_id;
                    event.attributes["clinicfullname"] = rowdata.clinicfullname;
                    event.attributes["archived"] = rowdata.archived ? 'true' : 'false';
                    event.attributes["patientname"] = rowdata.patientname;
                    event.attributes["diagnosedon"] = rowdata.diagnosedon;
                  });
                }
            }

            if(!event.attributes["archived"]) {
              event.attributes["archived"] = 'false';
            }

            this.patientView(event);
          });
      }
    }
  }

  render() {

    let lable = componentsStrings.table_col_patientdiagnosis + componentsStrings.table_col_patientdiagnosis_byai;
    if(this.props.username.toLowerCase().includes("grayhawk")) {
      lable = "Image quality";
    }

    if(this.state.redirectToUpload) {
      return <Redirect to={this.state.redirectToUpload} />
    } else if (this.state.showPatientView === true) {
      return (
        <PatientView sessionData={this.props.sessionData} testreport="" clickalview={this.state.clickalview} patientstatus={this.props.patientstatus} showDownloadReport={this.state.clinicview} showSendReport={this.state.clinicview} showArchiveReport={true} showCloseReport={true} closepatientview={this.closepatientview} report={false} diagnosedon={this.state.diagnosedon} uploadedon={this.state.uploadedon} clinicfullname={this.state.clinicfullname} diagnoseby={this.state.diagnoseby} fullname={this.props.fullname} usertype={this.props.usertype} session={this.props.session} username={this.props.username} patientdbid={this.state.patientdbid} imagedbid={this.state.imagedbid} diagnosesid={this.state.diagnosesid} diagstate={this.state.diagstate} archived={this.state.archived} patientname={this.state.patientname}></PatientView>
        );
    } else {
        let displayclinic = 'none';
        let displaydiagnosedby = 'none';
        if(this.props.usertype == 'A' || this.props.usertype == 'D') {
          displayclinic = 'block';
          displaydiagnosedby = 'block';
        }
        if(this.props.usertype == 'C') {
          displaydiagnosedby = 'block';
        }

        return (
          <div id="rcorners3" style={{paddingBottom: '4%'}}>
            {this.downloadPDFWaitMessageBox()}
            {this.downloadPDFErrorMessageBox()}
            <div id="rmenubarwork">
              <Row>
                <Col sm={4}>
                  <div style={{float: "left"}}>
                      {this.getOperetionButton()}
                      {this.getDisplayButton()}
                  </div>
                </Col>
                <Col sm={4}>
                </Col>
                <Col sm={4}>
                    <div style={{float: "right"}}>
                        {this.getUploadButton()}
                    </div>
                </Col>
              </Row>
            </div>
            <div id="rcornerswork1">
              <div style={{backgroundColor: '#ffffff', borderRadius: '25px'}}>
                <React.Fragment>

                    <div style={{paddingBottom: '10px'}}>
                          <Dropdown onSelect={this.handlePageSizeSelection}>
                            <label style={{float: 'right'}}>{messages.table_searchlabel} 
                                  <input id="maintable_search" style={{marginLeft: '10px'}} onChange={this.handleSearch} type="search"></input>
                            </label>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">{this.state.pagesizetext}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item value="5">5</Dropdown.Item>
                                <Dropdown.Item value="10">10</Dropdown.Item>
                                <Dropdown.Item value="50">50</Dropdown.Item>
                                <Dropdown.Item value="-1">{componentsStrings.button_all}</Dropdown.Item>
                            </Dropdown.Menu>
                            <label style={{marginLeft: '10px', marginTop: '5px'}}>{this.getNumberOfImagesTitle()}</label>

                            {this.state.pagesizechanged && <Spinner animation="border" role="status" style={{height: '1.3rem', width: '1.3rem', marginLeft: '15px', color: '#4169E1'}}>
                              <span className="visually-hidden"></span>
                            </Spinner>}

                        </Dropdown>
                    </div>
                    <Table id="aeyetable_mainPatientsTable" responsive style={{fontSize: '12px'}}>
                        <thead>
                            <tr>
                              <th></th>
                              {this.props.usertype == "R" && <th>{componentsStrings.table_col_patient}</th>}
                              {this.props.usertype == "R" && <th>{componentsStrings.table_col_id}</th>}
                              {this.props.usertype != "R" && <th>{componentsStrings.table_col_examid}</th>}
                              <th style={{display: displayclinic}}>{componentsStrings.table_col_clinicname}</th>
                              <th>{componentsStrings.table_col_uploadedtime}</th>
                              <th>{lable}</th>
                              {(this.props.doctorlinkedtoclinic || this.props.usertype != 'C') && <th>{componentsStrings.table_col_patientdiagnosis} {componentsStrings.table_col_patientdiagnosis_bydoc}</th>}
                              <th>Status</th>
                              {(this.props.usertype == "C" || this.props.usertype == "D") && <th>{componentsStrings.table_col_report}</th>}
                            </tr>
                        </thead>
                        <tbody>
                          {this.getTableData()}
                        </tbody>
                    </Table>
                    <div>
                      {this.getPagination()}
                    </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        );  

    }
  }

  getNumberOfImagesTitle() {
    let showpaging = componentsStrings.table_paging_show;
    showpaging = showpaging.replace("$$numberofrows$$", this.state.numberofrows);
    showpaging = showpaging.replace("$$maxnumberofitems$$", this.state.maxnumberofitems);
    if(this.state.numberofrows > 0) {
      return showpaging;
    } else {
      return componentsStrings.table_paging_noentries;
    }
  }
  
  getPagination() {
    if (this.state.pagesize !== -1 && this.state.lastpage > 0) {
      let active = this.state.currentactivpage;
      let currentpagegroup = (Math.floor)(this.state.currentactivpage / 5) + 1;
      let maxpagegroups = (Math.floor)(this.state.lastpage / this.numberOfPagesDisplayed);
      if((this.state.lastpage % this.numberOfPagesDisplayed) > 0) {
        maxpagegroups += 1
      }
      let disableFirst = false;
      if(currentpagegroup == 1) {
        disableFirst = true;
      }
      let disablePrev = false;
      if(active == 0) {
        disablePrev = true;
      }
      let disableLast = false;
      if((active + this.numberOfPagesDisplayed) >= this.state.lastpage) {
        disableLast = true;
      }
      let disableNext = false;
      if(active + 1 == this.state.lastpage) {
        disableNext = true;
      }

      let items = [];
      let lastpage = currentpagegroup * 5
      let firstpage = lastpage - 5
      if(this.state.lastpage < lastpage) {
        lastpage = this.state.lastpage;
      }
      if(currentpagegroup > 1 ) {
        items.push(<Pagination.Item onClick={() => { this.handlePageClick(1) }} key={1} active={0 === active}>{1}</Pagination.Item>);
        items.push(<Pagination.Ellipsis disabled />);
      }
      for (let number = firstpage; number < lastpage; number++) {
        let thenumber = number + 1;
        items.push(<Pagination.Item onClick={() => { this.handlePageClick(thenumber) }} key={thenumber} active={number === active}>{thenumber}</Pagination.Item>,);
      }
      if(currentpagegroup < maxpagegroups ) {
        items.push(<Pagination.Ellipsis disabled />);
        items.push(<Pagination.Item onClick={() => { this.handlePageClick(this.state.lastpage) }} key={this.state.lastpage} active={this.state.lastpage === active}>{this.state.lastpage}</Pagination.Item>);
      }
      return (
        <Pagination>
          <Pagination.First disabled={disableFirst} onClick={() => { this.handlePaginationFirst() }}/>
          <Pagination.Prev disabled={disablePrev} onClick={() => { this.handlePaginationPrev() }}/>
          {items}
          <Pagination.Next disabled={disableNext} onClick={() => { this.handlePaginationNext() }} />
          <Pagination.Last disabled={disableLast} onClick={() => { this.handlePaginationLast() }}/>
        </Pagination>            
    );
    } else {
      return "";
    }
  }    
  
  getTableData() {
    if (this.state.aeyetabledata && this.state.aeyetabledata.data && this.state.aeyetabledata.data.length > 0) {
      let displayclinic = 'none';
      if(this.props.usertype == 'A' || this.props.usertype == 'D') {
        displayclinic = 'block';
      }

      return this.state.aeyetabledata.data.map((row, index) => {
        
        const currentRow = (this.state.currentactivpage * this.state.numberofrows) + index
        const ischecked = this.state.aeyetabledataChk[currentRow];
        let datediagnosed = Moment(row.diagnosedon).format("MMM DD YYYY");

        const { date, time, patientid, examid, patientdbid, imagedbid, clinicname, clinicfullname, diagnosedon, uploadedon, archived, patientname, patientstatus, diagnosedate, diagnosedtime, diagnoseby, fullname, righteye, lefteye, patientseyes, diagnosed, diagnosesid, diagstate, hasdoctor, datedoctor, timedoctor} = row;

          return (
            <tr key={index} id={examid}>
              {this.state.displayvalue != "all" && ischecked == false && <td><input rowindex={index} id="cbPatientSelected" onClick={this.handlePatientSelected} patientdbid={patientdbid} imagedbid={imagedbid} type="checkbox" className="hidden"/></td>}
              {this.state.displayvalue != "all" && ischecked == true && <td><input rowindex={index} id="cbPatientSelected" onClick={this.handlePatientSelected} patientdbid={patientdbid} imagedbid={imagedbid} type="checkbox" className="hidden" checked/></td>}
              {this.state.displayvalue == "all" && <td></td>}
              {this.props.usertype == "R" && <td>{patientname}</td>}
              {this.props.usertype == "R" && <td>{patientid}</td>}
              {this.props.usertype != "R" && <td id="mainatients_examid">{examid}</td>}
              <td style={{display: displayclinic}}>{clinicname}</td>
              <td>{date} {time}</td>
  
              {this.getPatientStatus(patientdbid, imagedbid, diagnosesid, diagstate, patientseyes, diagnosed, clinicfullname, diagnoseby, uploadedon, diagnosedon, archived, patientname)}
              {this.getDoctorStatus(hasdoctor, fullname, datedoctor, timedoctor, imagedbid, diagnoseby, this.handleDoctorView) }
          
              <td>{archived ? 'Archived': 'Active'}</td>
              {(this.props.usertype == "C" || this.props.usertype == "D") && <td>
                <img src="/downloadpdf.png" width="20" height="20" fill="#dc3545" style={{cursor: "pointer"}} onClick={this.handleDownloadPDF} imagedbid={imagedbid} diagnosed={diagnosed} datediagnosed={datediagnosed}/>
              </td>}

              {this.props.usertype == "A" && <td>
                {(diagstate === -1 || diagstate === -9 || diagstate === -10) && <a href="javascript:void(0);">               
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16" onClick={this.handleReDiagnoseImage} imagedbid={imagedbid}>
                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                  </svg>
                </a>}

                {(diagstate !== -1 && diagstate !== -9) && <div></div>}
              </td>}
            </tr>
          );
      });
    } else {
      
    }
  }    
    
  getPatientStatus(patientdbid, imagedbid, diagnosesid, diagstate, patientstatus, diagnosed, clinicfullname, diagnoseby, uploadedon, diagnosedon, archived, patientname) {

      let strarchived = 'false';
      if(archived == true) {
        strarchived = 'true';
      }
      if(diagnosed == 0) {
        return (
          <td>
            <a href="javascript:void(0);" report='false' clickalview='true' uploadedon={uploadedon} diagnosesid={diagnosesid} diagstate={diagstate} patientdbid={patientdbid} imagedbid={imagedbid} clinicfullname={clinicfullname} diagnoseby={diagnoseby} archived={strarchived} patientname={patientname} diagnosedon={diagnosedon} ><Loader type="Grid" color="#00BFFF" height={20} width={20} timeout={0}></Loader>
            </a>
          </td>
        );
      } else {
        return (
            <td>
              <a href="javascript:void(0);" report='false' clickalview='true' uploadedon={uploadedon} diagnosesid={diagnosesid} diagstate={diagstate} patientdbid={patientdbid} imagedbid={imagedbid} clinicfullname={clinicfullname} diagnoseby={diagnoseby} archived={strarchived} patientname={patientname} diagnosedon={diagnosedon} onClick={this.patientView}>{patientstatus}</a>
            </td>
        );
      }
  }    

  getUploadButton() {
      if (this.props.usertype === "R") {
        return (
          <ButtonGroup>
            <Button id="aeyetable_addnew" style={{margin: '10px'}} onClick={this.handleAddNewPatient} disabled = {!this.state.hasdiseasesselected}>{componentsStrings.button_addnew}</Button>
            <Button style={{margin: '10px'}} onClick={this.handleResearchPatient}>{componentsStrings.button_research}</Button>
          </ButtonGroup>
        );
      } else {
        return (
          <ButtonGroup>
            {this.props.addnewpatient == true && <Button id="aeyetable_addnew" style={{margin: '10px'}} onClick={this.handleAddNewPatient} disabled = {!this.state.hasdiseasesselected}>{componentsStrings.button_addnew}</Button>}
          </ButtonGroup>
        );
      }
  }
  
  getDisplayButton() {
      return (
        <div>
          <Dropdown style={{margin: '10px'}} as={ButtonGroup} onSelect={this.handleDisplaySelection}>
            <Dropdown.Toggle id="displaySelect-button" variant="success">{this.state.displaytext}</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item id="displaySelect-all" value="all">{messages.table_dropdown_displaytype_allbutton}</Dropdown.Item>
                <Dropdown.Item id="displaySelect-active" value="active">{messages.table_dropdown_displaytype_activebutton}</Dropdown.Item>
                <Dropdown.Item id="displaySelect-archived" value="archived">{messages.table_dropdown_displaytype_archivedbutton}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
  }
  
  getOperetionButton() {
    if(this.state.patientselected == true && this.state.displayvalue != "all") {
      let archiveButtonText = componentsStrings.button_archive;
      if(this.state.displayvalue == "archived") {
        archiveButtonText = componentsStrings.button_unarchive;
      }
      return (
        <ButtonGroup>
          <Button id="active_archive_button" style={{margin: '10px'}} onClick={() => { this.handleArchiveDiagnosis() }}>{archiveButtonText}</Button>
        </ButtonGroup>
      );
    }
  }
  
  getDiagnosedBy(diagnoseby, diagnosedate, diagnosedtime, patientstatus) {
    let displaydiagnosedby = 'none';
    if(this.props.usertype == 'A' || this.props.usertype == 'D' || this.props.usertype == 'C') {
      displaydiagnosedby = 'block';
    }

    if (patientstatus === "Pending") {
      return (
        <td style={{display: displaydiagnosedby, backgroundColor: '#dee2e6'}}><a>{patientstatus}</a></td>
      );
    } else {
      return (
        <td style={{display: displaydiagnosedby, backgroundColor: '#dee2e6'}}><a>{diagnoseby} / {diagnosedate} {diagnosedtime}</a></td>
      );
    }
  }
 

  getDoctorStatus(hasdoctor, fullname, datedoctor, timedoctor, imagedbid, diagnoseby, onclick) {

    if(this.props.doctorlinkedtoclinic || this.props.usertype != 'C') {

      if(hasdoctor) {

        return (

          <td hidden={!hasdoctor}>{"  ["}{fullname} {"on"} {datedoctor} {timedoctor}]
          <a href="javascript:void(0);" report='false' clickalview='false' imagedbid={imagedbid} diagnoseby={diagnoseby} onClick={onclick}>
            {" More diagnostic info"}
          </a>

        </td>
      );
      }
      else {
        return(
          <td>Not available</td>
        );
      }
    }
  }
    downloadPDFWaitMessageBox() {
        return(
        <Modal show={this.state.displayDownloadPDFMessage}>
            <Modal.Header>
                <Modal.Title>{messages.table_downloadpdf_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Container style={{display: 'flex', alignItems: 'center'}} id="uploadpatient_pleasewaitmsg">
                    {messages.table_downloadpdf_body}

                    <Spinner animation="border" role="status" style={{height: '1.5rem', width: '1.5rem', marginLeft: '5px'}}>
                        <span className="visually-hidden"></span>
                    </Spinner>
                </Container>

            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>        
        );
    }
    downloadPDFErrorMessageBox() {
      return(
      <Modal show={this.state.displayDownloadPDFErrorMessage}>
          <Modal.Header>
              <Modal.Title>{messages.table_errordownloadpdf_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <Container style={{display: 'flex', alignItems: 'center'}} id="uploadpatient_pleasewaitmsg">
                  {messages.table_errordownloadpdf_body}
              </Container>

          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={this.handleDisplayDownloadPDFErrorMessage}>
                {componentsStrings.button_close}
            </Button>
          </Modal.Footer>
      </Modal>        
      );
  }

}

export default AEYETable;