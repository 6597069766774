import React, { Component, Fragment} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import errorcodes from "../errorcodes";
import errormessages from "../../Messages/ErrorMessages";
import connections from "../../connections";
import componentsStrings from "../../Messages/Components";

class SettingsNewUser extends Component {
   
    state = {
        newusername: "",
        password: "",
        email: "",
        fullname: "",
        usertype: "CLinic",
        timezone: "America/New York"
        
      };

    savenewuser = () => {

        if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyuserwrite) {
            const {newuserDone} = this.props;
            let url = connections.sdkurl;
            fetch(url + "/AEYEAppServer/v1/UserRepository/addnewuser", {
                method: "POST",
                body: JSON.stringify({
                    session: this.state.session,
                    logonid: this.state.username,
                    password: this.state.password,
                    email: this.state.email,
                    fullname: this.state.fullname,
                    usertype: this.state.usertype,
                    timezone: this.state.timezone
                })
            }).then(response => response.json()).then(data => {

                if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                    this.props.sessionData.showsessiontimeout();
                    return;
                }

                if (data.errorcode !== errorcodes.errorOK) {
                    this.setState({
                        loginerrormsg: errormessages.settingsformnewuser_failToLogin,
                        redirectToMain: false
                    });
                } else {
                    
                }
            });
        
            newuserDone();
        }
    }

    invitenewuser = () => {
        const {newuserDone} = this.props;

        newuserDone();
    }
    
    componentDidMount() {
    }
    
    handleUsernameChange = event => {
        this.setState({ newusername: event.target.value });
    };
    
    handlePasswordChange = event => {
        this.setState({ password: event.target.value });
    };
    
    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    };
    
    handleFullnameChange = event => {
        this.setState({ fullname: event.target.value });
    };

    handleUserTypeChange = (eventKey, event) => {
        this.setState({ usertype: event.target.text });
    };
    
    handleTimeZoneChange = (eventKey, event) => {
        this.setState({ timezone: event.target.text });
    };
        
    render() {
        const { newuserDone } = this.props;
        return (
            <React.Fragment>
                <Row>
                    <label>{componentsStrings.settingsformnewuser_email_label}</label>
                </Row>
                <Row>
                    <input type="email" placeholder={componentsStrings.settingsformnewuser_email_placeholder} onChange={this.handleEmailChange}></input>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <label>{componentsStrings.settingsformnewuser_username_label}</label>
                        </Row>
                        <Row>
                            <input type="text" placeholder={componentsStrings.settingsformnewuser_username_placeholder} onChange={this.handleUsernameChange}></input>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <label>{componentsStrings.settingsformnewuser_password_label}</label>
                        </Row>
                        <Row>
                            <input type="password" placeholder={componentsStrings.settingsformnewuser_password_placeholder} onChange={this.handlePasswordChange}></input>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <label>{componentsStrings.settingsformnewuser_fullname_label}</label>
                </Row>
                <Row>
                    <input type="text" placeholder={componentsStrings.settingsformnewuser_fullname_placeholder} onChange={this.handleFullnameChange}></input>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <label>{componentsStrings.settingsformnewuser_usertype_label}</label>
                        </Row>
                        <Row>
                            <Dropdown onSelect={this.handleUserTypeChange}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">{this.state.usertype}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>{componentsStrings.addnewuser_usertype_uploadonly}</Dropdown.Item>
                                    <Dropdown.Item>{componentsStrings.addnewuser_usertype_doctor}</Dropdown.Item>
                                    <Dropdown.Item>{componentsStrings.addnewuser_usertype_clinic}</Dropdown.Item>
                                    <Dropdown.Item>{componentsStrings.addnewuser_usertype_administrator}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <label>Time zone</label>
                        </Row>
                        <Row>
                            <Dropdown onSelect={this.handleTimeZoneChange}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic1">{this.state.timezone}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>Asia/Jerusalem</Dropdown.Item>
                                    <Dropdown.Item>America/New York</Dropdown.Item>
                                    <Dropdown.Item>America/Log Angeles</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Row>
                    </Col>
                </Row>
                <Button onClick={this.savenewuser}>{componentsStrings.button_save}</Button>
                <Button onClick={newuserDone}>{componentsStrings.button_cancel}</Button>
                <Button onClick={this.invitenewuser}>{componentsStrings.button_invite}</Button>
            </React.Fragment>
        );
    }
}

export default SettingsNewUser;