import React, { Component } from "react";
import { Redirect } from "react-router";

class UserManualForm extends Component {
  

  componentDidMount() {
    if(this.props.cookiesSession === undefined || this.props.cookiesSession.length < 15) {
      return <Redirect to='/' />
    }
  }


  render() {
    
    if(this.props.cookiesSession === undefined || this.props.cookiesSession.length < 15) {
      return "";
    } else {
      return (
        <React.Fragment>
          
          <object data="https://aeyehelp.s3.us-west-2.amazonaws.com/usermanual.pdf" type="application/pdf" width="100%" height="100%">

          </object>

        </React.Fragment>
      );
    }

  }

}

export default UserManualForm;