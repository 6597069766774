import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import "../css/aeyehealth.css"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import componentsStrings from "../Messages/Components";
import CopyRight from "./copyright";
import { Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import messages from "../Messages/Messages";

class NavBar extends Component {
  state = {
    localDate: '',
    redirectToClose: null,
    displayAboutMsg: false
  };
  componentDidMount() {
    var date = new Date();
    var options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    var localDate = date.toLocaleDateString("en", options)

    this.setState({localDate: localDate})
  }


  render() {

    if (this.state.redirectToClose) {
      return <Redirect to={this.state.redirectToClose} />
    }  

    return (
      <React.Fragment>

        {this.displayAboutMessageBox()}   

        <div id="rcorners2" bg="dark" variant="dark" expand="lg" style={{height: 'auto'}}>
          
          <Row style={{justifyContent: 'space-between', margin: '0px'}}>

            <div style={{display: 'inline-flex'}}>
              <img style={{marginTop: '15px', marginLeft: '60px'}} src="/Main_Logo.png" width="80" height="80"  />

              <div style={{color: '#ffffff', marginLeft: '30px'}}>
                <Row style={{marginTop: '35px'}}>
                </Row>
                <Row>
                  {this.state.localDate}
                </Row>
                <Row style={{marginTop: '5px'}}>
                </Row>
                <CopyRight />              
              </div>
            </div>
              
            <Form style={{marginRight: '85px', marginTop: '30px'}} inline>
              {this.getAboutButton()}
              {this.getCloseButton()}
              {this.getBackButton()}
              <Col className="mr-sm-2"></Col>
              {(this.props.examdbid && this.props.examdbid !== 0) && <div style={{fontSize: '24px'}}>
                {this.props.name}
              </div>}
              {this.getUserButton()}
            </Form>

          </Row>

        </div>

      </React.Fragment>
    );
  }

  getBackButton = () => {
    const { report, ishospitalsession } = this.props;
    if(report == true) {
      return (
        <Link to="/report" className="mr-sm-2">{this.getBackButtom()}</Link>
      );
    } else {

      let usertype = sessionStorage.getItem('usertype');

      if(usertype === 'A') {

        return (
          <Link to="/main" className="mr-sm-2">{this.getBackButtom()}</Link>
        )
      }
      else {
        return (
          <Link to="/legacy/main" className="mr-sm-2">{this.getBackButtom()}</Link>
        );
      }
    }
  }
  getCloseButton = () => {
    const { showClose } = this.props;
    if (showClose) {
      return (
        <Button id="navbar_mainbutton_close" onClick={this.closeApp} variant="primary">{componentsStrings.button_close}</Button>
      );
    } else {
      return "";
    }
  }
  getBackButtom = () => {
    const { showBack } = this.props;
    if (showBack) {
      return <Button id="navbar_mainbutton_back" variant="primary">{componentsStrings.button_back}</Button>;
    } else {
      return "";
    }
  };
  openHelp = () => {
    window.open("https://www.aeyehealth.com/876340tha9259njet-juu9975367", "");
  }
  closeApp = () => {
    const { examdbid } = this.props;

    if(examdbid !== 0) {
      this.setState({redirectToClose: "/legacy/close"})
    }
    else {
      window.opener = null;
      window.open('about:blank','_self');
      window.close();
    }
  }
  getUserButton = () => {
    const { showUserButton, showLogout, showCameras, showusersettings, showReport, showSettings, showHelp, ishospitalsession, showepicexams, showusers, shownewview } = this.props;
    if (showUserButton && ishospitalsession !== true) {
      return <DropdownButton id="navbar_mainbutton" title={this.props.fullname}>
      {showSettings == true && <Dropdown.Item id="navbar_mainbutton_settings" href="/legacy/settings">{componentsStrings.button_settings}</Dropdown.Item>}
      {showReport == true && <Dropdown.Item href="/report">{componentsStrings.button_reports}</Dropdown.Item>}
      <Dropdown.Divider></Dropdown.Divider>
      {showHelp == true && <Dropdown.Item onClick={this.openHelp} href="#">{componentsStrings.button_help}</Dropdown.Item>}
      <Dropdown.Divider></Dropdown.Divider>
      {showLogout == true && <Dropdown.Item id="navbar_logout" href="/logout">{componentsStrings.button_logout}</Dropdown.Item>}
    </DropdownButton>
    } else {
      return "";
      
    }
    
  }
  getAboutButton = () => {
    const { showAbout } = this.props;
    if (showAbout) {
      return (
        <Button id="navbar_mainbutton_about" onClick={this.handleClickAbout} variant="primary">{"Help and about"}</Button>
      );
    } else {
      return "";
    }
  }


  handleClickAbout = () => {
    this.setState({ displayAboutMsg: true });
  }

  displayAboutMessageBox() {
    return(
      <Modal show={this.state.displayAboutMsg} centered dialogClassName={"iiq-popup"}>

        <Modal.Header>

          <Modal.Title> {messages.messagebody_popup_about_labal} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body style={{whiteSpace: 'pre-line'}}>
          {messages.messagebody_popup_about_body}

          <br/>
          <Link to="/usermanual" target="_blank">User Manual</Link>

        </Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleCloseAboutMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>         
    );
  }

  handleCloseAboutMessageBox = () => {
    this.setState({ displayAboutMsg: false });
  }

}

export default NavBar;
