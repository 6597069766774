import React, { Component } from "react";
import "../../css/general.css"
import "../../css/colors.css"
import "../../css/texts.css"
import componentsStrings from "../../Messages/Components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import texts from "./Texts";


class ExamButtons extends Component {

  state = {
    
  };

  componentDidMount() {

  }

  render() {

    let downloadIcon = 'default';
    let icon = 'default';

    if(this.props.handleDownloadDiagnosisReport) {
      downloadIcon = 'pointer';
    }

    if(!this.props.testreport && this.props.handleDownloadDiagnosisReport) {
      icon = 'pointer';
    }

    return (
      <React.Fragment>

        <div className="d-flex align-items-center justify-content-between">
          <div className="fs24 lh30 text blue_1">{componentsStrings.uploadpatient_examid}</div>

          <div className="d-flex">
            
            <div className="ml-3" id="patientview_download" style={{ cursor: downloadIcon}}>

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip className="my-tooltip text"> {texts.display_exam_download_tooltip} </Tooltip>} >

                <img src="/download.png" width="100%" height="auto" onClick={this.props.handleDownloadDiagnosisReport ? this.props.handleDownloadDiagnosisReport : undefined}/>
                
              </OverlayTrigger>

            </div>
            
            <div className="ml-3" style={{ cursor: icon}}>

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip className="my-tooltip text"> {texts.display_exam_send_to_email_tooltip} </Tooltip>} >
                                
                <img src="/share.png" width="20" height="20" onClick={this.props.handleSendDiagnosisReport ? this.props.handleSendDiagnosisReport : undefined}/>
              </OverlayTrigger>    

            </div>
            
            <div className="ml-3" style={{ cursor: icon}}>

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip className="my-tooltip text"> {this.props.isActive ? texts.display_exam_archived_tooltip : texts.display_exam_actice_tooltip} </Tooltip>} >
                
                <img src="/archive_48px.png" width="20" height="20" onClick={this.props.handleArchiveUnarchiveDiagnosis ? this.props.handleArchiveUnarchiveDiagnosis : undefined}/>  

              </OverlayTrigger>    

            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }

}

export default ExamButtons;
