export default class messages {
    static loginform_externallogin = "Logging in. Please wait.";

    // registrationform
    static registrationform_registerUserTitle = "Registration";
    static registrationform_registerUserProcessingBody = "Processing. Please wait.";
    static registrationform_registerUserFailedBody = "Failed to register new user";
    static registrationform_registerUserSuccBody = "Successfully registered new user";

    // Main table
    static table_dropdown_displaytype_alltitle = "Display: all exams";
    static table_dropdown_displaytype_allexamstitle = "Display: all exams";
    static table_dropdown_displaytype_activetitle = "Display: active exams";
    static table_dropdown_displaytype_archivedtitle = "Display: archived exams";
    static table_dropdown_displaytype_allbutton = "All";
    static table_dropdown_displaytype_activebutton = "Active";
    static table_dropdown_displaytype_archivedbutton = "Archived";

    static table_desisdetails_unknown = "Unknown";
    static table_desisdetails_nodiagyet = "Not Diagnosed Yet";
    static table_desisdetails_iiq = "Insufficient Image Quality";
    static table_desisdetails_suspiciousfind = "Suspicious Findings - ";
    static table_desisdetails_morethanone = "Multiple Conditions Detected";
    static table_desisdetails_nofindings = "No Pathological Findings";
    static table_desisdetails_failedtofind = "Failed to Diagnose";
    static table_desisdetails_dataencrypted = "< dataencrypted >";

    static table_diagstatuc_diag = "Diagnosed";
    static table_diagstatuc_pending = "Pending";
    static table_diagby_algo = "Algorithm";

    static table_searchlabel = "Search:";

    static table_downloadpdf_title = "Download PDF";
    static table_downloadpdf_body = "Downloading report PDF, please wait...";
    static table_errordownloadpdf_title = "PDF Download Error";
    static table_errordownloadpdf_body = "Failed to download the report PDF";

    static uploadpatient_checkforimage_processing = "Processing...";
    static uploadpatient_checkforimage_waitleftimage = "Waiting for left eye image...";
    static uploadpatient_checkforimage_waitrightimage = "Waiting for right eye image...";
    static uploadpatient_checkforimage_newimageuploading = "New image is being uploaded...";
    static uploadpatient_checkforimage_imageuploaded = "Image is uploaded, now checking the image quality...";
    static uploadpatient_checkforimage_cehckforiiq = "Analyzing image quality...";
    static uploadpatient_checkforimage_title = "Wait";
    static uploadpatient_uploadexamimages_submit_body = "Image processing in progress. Please wait...";
    static uploadpatient_examreadytowritetoepic_submit_body = "Exam ready, trying to write back to Epic. Please wait.";
    static uploadpatient_examprocessfailed_submit_body = "Failed to write exam result back to Epic, please try again.";
    static uploadpatient_examprocessexpired_submit_body = "Epic session expired, please close the current process and relaunch the patient exam.";
    static uploadpatient_examprocessstoped_title = "Write back to Epic";
    static uploadpatient_uploadexamimages_submit_title = "Analyzing";
    static uploadpatient_examprocesscritical_submit_body = "Critical error encontered while writing back to Epic, please contact administrator";
    static uploadpatient_examprocesscritical_submit_title = "Critical Error";
    static uploadpatient_examprocessexamfailed_submit_body = "Failed to process the exam";
    static uploadpatient_examprocessexamfailed_submit_title = "Processing Exam";

    static uploadpatient_uploadimages_title = "Upload";
    static uploadpatient_uploadimages_body = "Images uploaded successfully. The AEYE diagnostic report will be sent to your email.";

    static uploadpatient_uploadimages_iiq_title = "Insufficient Image Quality";
    static uploadpatient_uploadimages_iiq_body = "One or more image(s) are of insufficient quality";
    static uploadpatient_uploadimages_q_title = "Sufficient Image Quality";

    static uploadpatient_uploadimages_invalidsomefield_title = "Upload";
    static uploadpatient_uploadimages_invalidsomefield_body = "Some fields are missing. Please upload images for the required fields and try again";
    static uploadpatient_uploadimages_fatal_title = "Upload failure";
    static uploadpatient_uploadimages_fatal_body = "An error occured while images were being uploaded. Please contact the vendor.";

    static uploadpatient_uploadimages_pleasewait_title = "Upload";
    static uploadpatient_uploadimages_pleasewait_body = "Uploading images. Please wait.";
    static uploadpatient_examprocess_pleasewait_title = "Starting";
    static uploadpatient_examprocess_pleasewait_body = "Starting exam processing. Please wait.";

    static uploadpatient_recordresults_pleasewait_title = "Record Results";
    static uploadpatient_recordresults_pleasewait_body = "Recording results. Please wait.";
    static uploadpatient_recordresults_fatal_title = "Error Recording Results";
    static uploadpatient_recordresults_fatal_body = "An error occured while trying to record the results. Please contact the vendor.";

    static uploadpatient_wrongimageside_title = "Image appears to be placed in the wrong position"
    static uploadpatient_wrongimageside_right_body = "You are trying to place an image file labelled 'right eye' in the position designated for the left eye. Are you sure you want to continue?"
    static uploadpatient_wrongimageside_left_body = "You are trying to place an image file labelled 'left eye' in the position designated for the right eye. Are you sure you want to continue?"

    static uploadpatient_wrongimagetype_title = "Wrong File Type"
    static uploadpatient_wrongimagetype_body = "You are trying to use a non-image file or an unsupported image file. Please replace the file with a valid image (jpeg, jpg, png)"

    static uploadresultspatient_Results_title = "Patient Results"
    static uploadresultspatient_Results_body = "Getting patient results, please wait..."

    static uploadpatient_identicalimage_title = "Identical images"
    static uploadpatient_identicalimage_body = "Identical images were uploaded. Please upload unique images."

    static uploadpatient_needtoselectcamera_title = "Camera selection"
    static uploadpatient_needtoselectcamera_body = "Please select a camera before uploading images"

    static general_Save_title = "Save Message"
    static general_Save_body = "General settings are saved"

    static epic_Save_title = "Save message"
    static epic_Save_body = "Epic settings are saved"

    static data_not_saved_title = "Data was not saved!"
    static data_not_saved_body = "Continue without saving?"

    static uploadpatient_getoldexam_pleasewait_title = "Old Exam";
    static uploadpatient_getoldexam_pleasewait_body = "Reading old exam data. Please wait.";
    static uploadpatient_getoldexam_fatalerror_body = "Failed to retrieve old exams, please contact your administrator.";

    static uploadresults_warning_text = "The above images are reduced resolution. Do NOT use these images for diagnostic purposes.";

    static uploadpatient_camerasesessionaborted_title = "Session aborted"
    static uploadpatient_camerasesessionaborted_body = "User ID {0} has taken the camera associated with your session."

    static uploadpatient_abort_exam_title = "Abort Exam";
    static uploadpatient_abort_exam_body = "Aborting ";

    static uploadpatient_timeout_title = "Waiting for images";
    static uploadpatient_timeout_body1 = `We noticed that you selected your camera `;
    static uploadpatient_timeout_body2 = ` 5 minutes ago and no image has appeared. Uploading images to the web portal usually takes up to one minute. If you took a picture over a minute ago, please check the following:
                                            \n--> Your computer is connected to the internet. 
                                            \n--> Your camera is connected to the internet.

                                            \nIf you are using the Optomed Aurora, please check the following:
                                            \n--> If you have checked all these things and the image still hasn’t appeared, please contact your administrator.`

    static app_sessiontimeout_title = "Session Timeout"
    static app_sessiontimeout_body = "Your session has timed out"

    static app_nointernetconnection_title = "Internet Connection Error"
    static app_nointernetconnection_body = "Check your internet connection"

    static app_confirm_cameras_title = 'Indicated Camera Confirmation'
    static app_confirm_cameras_body = 'Please confirm that images for analysis were produced by the following indicated camera:'
    static app_confirm_camera_1 = 'Topcon TRC-NW400'

    static report_get_details_error_title = "Permissions Error"
    static report_get_details_error_body = "This user is not authorized to view the test in question. Please ensure that you are using the correct credentials."
    
    static messagebody_popup_about_labal = "Regulatory information";
    static messagebody_popup_about_body = `(DI) XXXXXXXXXXXXXX \n (PI) V1.0.0`;
}
