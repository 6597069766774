export default class sdkvars {
	static waitForNothing = 0;
    static waitForMaculaR = 1;
	static waitForMaculaL = 2;
	static waitForDiscR = 3;
	static waitForDiscL = 4;
	static waitForUnknown = 5;

	static cameraStateNew = 0;
	static cameraStateUsed = 1;
	static cameraStateFiltered = 2;
	static cameraStateUploaded = 3;
	static cameraStateFiltering = 4;

	static ExamStateNotExist = 0;
	static ExamStateNew = 1;
	static ExamStateWaitForEye = 2;
	static ExamStateIdle = 3;
	static ExamStateEnd = 4;
	static ExamStateTokenExpired = 5;
	static ExamStateStartSubmit = 6;
	static ExamStateStartMQ = 7;
	static ExamStateFailed = 8;
	static ExamStateCanceled = 9;
	static ExamStateDismiss = 10;
	static ExamStateAborted = 11;
	static ExamStateMqFinished = 12;

	static DispacherStateNotExist = 0;
	static DispacherStateNew = 1;
	static DispacherStateEnd = 4;
	static DispacherStateExpired = 5;
	static DispacherStateFailed = 6;
	static DispacherStateCritical = 7;

	static EncryptionModeNothing = 0;
	static EncryptionModeAll = 1;
	static EncryptionModeOnlyPatientData = 2;
	static EncryptionModeDiagnosis = 3;

}
