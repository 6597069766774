import React, { Component} from "react";
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from 'react-bootstrap/DropdownButton'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal'
import Select, { components } from 'react-select'
import { Row } from "react-bootstrap";
import errorcodes from "../errorcodes";
import componentsStrings from "../../Messages/Components";
import connections from "../../connections";

class AEYEReportRow {
  constructor() {
    this.id = "";
    this.name = "";
    this.description = "";
    this.settings = "";
    this.schedule = "";
  }
}
class AEYEReportData {
  constructor() {
    this.data = [];
  }
}
class AEYEClinicRow {
  constructor() {
    this.id = -1;
    this.fullname = "";
  }
}
class AEYEDoctorClinics {
  constructor() {
    this.data = [];
  }
}

const days = ['', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sut'];

class ReportMenuBar extends Component {
  state = {
      savetitle: componentsStrings.button_create,
      savetypesave: true,
      settings: "",
      onClose: null,
      runReport: null,
      isDialogOpen: false,
      isScheduleDialogOpen: false,
      isDeleteDialogOpen: false,
      isExecuteReportDialogOpen: false,
      doctorclinics: null,
      aeyereportdata: null,
      numberofreports: 0,
      gendertitle: componentsStrings.reportmenubar_gender_all,
      fromgender: -2,
      fromthelasttitle: componentsStrings.reportmenubar_1month,
      fromthelast: 1,
      fromclinics: [],
      fromclinictitle: "All",
      fromclinicid: -1,
      diablepage: '',
      opacity: '1',
      selectPeriodChecked: false,
      selectDateChecked: true,
      byperiodselected: false,
      bydateselected: true,
      reportidselected: -1,
      tofatchreports: true,
      reportnameselected: "",
      reportdescriptionselected: "",
      reportsettingsselected: "",
      reportscheduleselected: "",
      
      reportfromagedialog: -1,
      reporttoagedialog: -1,

      reportnamedialog: "",
      reportdescriptiondialog: "",
      reportsubjectdialog: "",
      reportfromthelastdialog: 0,
      reportfromclinics: "",
      reportreportbydialog: 0,
      reportstartDatedialog: new Date(),
      reportendDatedialog: new Date(),
      reportreportbydate: false,
      reportreportbyperiod: false,
      reportSchedulereportOnce: true,
      reportSchedulereportOn: false,
      byscheduleonceselected: true,
      byscheduleonselected: false,
      reportschedulereportsendemail: true,
      reportscheduleemailtosendto: "",
      reportscheduleonceDatedialog: null,
      reportscheduleTimedialog: null,
      fromDaysOn: null,
      reportsummeryreport: false
    };
  constructor() {
    super();
  }
  handleSelectSummaryReport = (summaryreport) => {
    this.setState({reportsummeryreport: summaryreport.target.checked});
  }
  handleScheduleTimeChange = date => {
    this.setState({
      reportscheduleTimedialog: date
    });
  }
  handleScheduleOnceChange = date => {
    this.setState({
      reportscheduleonceDatedialog: date
    });
  };  
  handleStartChange = date => {
    this.setState({
      reportstartDatedialog: date
    });
  };  
  handleEndChange = date => {
    this.setState({
      reportendDatedialog: date
    });
  };  
  handleSelectClinicChange = (selectedOption) => {
    let fromclinics = [];
    let clinic = Object;
    for (const [id, row] of selectedOption.entries()) {
      fromclinics.push({clinicid: row.value, clinicname: row.label});
    }
    this.setState({ fromclinics: fromclinics });
  }  
  handleSelectScheduleOnChange = (selectedOption) => {
    let fromDaysOn = [];
    let clinic = Object;
    if(selectedOption != null) {
      for (const [id, row] of selectedOption.entries()) {
        fromDaysOn.push({scheduleon: row.value});
      }
    }
    this.setState({ fromDaysOn: fromDaysOn });
  }
  roundMinutes(date) {

      date.setHours(date.getHours() + Math.round(date.getMinutes()/60));
      date.setMinutes(0, 0, 0);

      return date;
  }
  componentDidMount() {
    var date = new Date();
    this.roundMinutes(date);

    this.setState({onClose: this.props.onClose, runReport: this.props.runReport, reportscheduleonceDatedialog: date, reportscheduleTimedialog: date});
  }
  reportFromTheLast = event => {
    let fromthelast = -1
    if(event.target.id == 'fromthelast1mon') {
      fromthelast = 1;
    } else if(event.target.id == 'fromthelast3mon') {
      fromthelast = 3;
    } else if(event.target.id == 'fromthelast6mon') {
      fromthelast = 6;
    }

    this.setState({ fromthelasttitle: event.target.text, fromthelast: fromthelast });
  };
  reportFromGender = event => {
    let fromgender = -2
    if(event.target.id == 'fromgenderall') {
      fromgender = -2;
    } else if(event.target.id == 'fromgendernone') {
      fromgender = -1;
    } else if(event.target.id == 'fromgendermale') {
      fromgender = 1;
    } else if(event.target.id == 'fromgenderfemale') {
      fromgender = 0;
    } else if(event.target.id == 'fromgenderother') {
      fromgender = 2;
    }

    this.setState({ gendertitle: event.target.text, fromgender: fromgender });
  };
  
  handleSelectScheduleOnce = event => {
    this.setState({ reportSchedulereportOnce: true, byscheduleonceselected: true, reportSchedulereportOn: false, byscheduleonselected: false });
  }
  handleSelectScheduleOn = event => {
    this.setState({ reportSchedulereportOnce: false, byscheduleonceselected: false, reportSchedulereportOn: true, byscheduleonselected: true });
  }

  handleSelectDate = event => {
    this.setState({ selectDateChecked: event.target.checked, selectPeriodChecked: !event.target.checked, byperiodselected: false, bydateselected: true });
  }
  handleSelectPeriod = event => {
    this.setState({ selectPeriodChecked: event.target.checked, selectDateChecked: !event.target.checked, byperiodselected: true, bydateselected: false });
  }
  reportFromClinic = event => {
    let fromclinicid = event.target.id;
    this.setState({ fromclinictitle: event.target.text, fromclinicid: fromclinicid });
  };
  handleEmailToSendToChange = event => {
    this.setState({ reportscheduleemailtosendto: event.target.value });
  }
  reportNameChanged = event => {
    this.setState({ reportidselected: parseInt(event.target.selectedOptions[0].attributes["optid"].value, 10), reportnameselected: event.target.selectedOptions[0].value, reportdescriptionselected: event.target.selectedOptions[0].attributes["description"].value, reportsubjectselected: event.target.selectedOptions[0].attributes["subject"].value, reportsettingsselected: event.target.selectedOptions[0].attributes["reportsettings"].value });
  }
  handleNameChange = event => {
    this.setState({ reportnamedialog: event.target.value });
  };
  handleDescriptionChange = event => {
    this.setState({ reportdescriptiondialog: event.target.value });
  };
  handleFromAgeChange = event => {
    this.setState({ reportfromagedialog: event });
  };
  handleToAgeChange = event => {
    this.setState({ reporttoagedialog: event });
  };
  handleSettingsChange = event => {
    this.setState({ settings: event.target.value });
  };
  handleSubjectChange = event => {
    this.setState({ reportsubjectdialog: event.target.value });
  };
  newAlgoCancel = () => {
    this.state.onClose();
  }
  newAlgoSave = () => {
    this.addNewAlgorithem();
    this.state.onClose();
  }
  onClose = event => {
    this.state.onClose();
  }
  newReport = () => {
    this.setState({ isDialogOpen: true, reportsummeryreport: false, savetypesave: true, savetitle: componentsStrings.button_create, diablepage: 'none', opacity: '0.4', reportnamedialog: "", reportdescriptiondialog: "", reportsubjectdialog: "", reportfromthelastdialog: 0, reportfromclinicsdialog: "", reportreportbydialog: 0, reportstartDatedialog: new Date(), reportendDatedialog: new Date(), reportreportbydate: true, reportreportbyperiod: false, byperiodselected: false, bydateselected: true,  fromthelasttitle: componentsStrings.reportmenubar_1month, fromthelast: 1});
    this.fetchClinics();
  }
  scheduleReport = () => {
    if(this.state.reportscheduleselected != null && this.state.reportscheduleselected != '') {
      let reportschedule = JSON.parse(this.state.reportscheduleselected);

      let reportscheduleonceDatedialog = new Date(reportschedule.reportscheduleonceDatedialog);
      let reportscheduleTimedialog = new Date(reportschedule.reportscheduleTimedialog);

      let fromDaysOn = reportschedule.fromDaysOn;

     
      let byscheduleonceselected = reportschedule.reportSchedulereportOnce;
      let byscheduleonselected = reportschedule.reportSchedulereportOn;

      this.setState({ isScheduleDialogOpen: true, reportschedulereportsendemail: reportschedule.reportschedulereportsendemail, reportscheduleemailtosendto: reportschedule.reportscheduleemailtosendto, reportSchedulereportOnce: reportschedule.reportSchedulereportOnce, reportSchedulereportOn: reportschedule.reportSchedulereportOn, reportscheduleonceDatedialog: reportscheduleonceDatedialog, fromDaysOn: fromDaysOn, byscheduleonceselected: byscheduleonceselected, byscheduleonselected: byscheduleonselected, reportscheduleTimedialog: reportscheduleTimedialog});
    } else {
      this.setState({ isScheduleDialogOpen: true});
    }
  }
  editReport = () => {
    let reportsettings = JSON.parse(this.state.reportsettingsselected);
    let reportreportbydate = true;
    let reportreportbyperiod = false;
    let bydateselected = true;
    let byperiodselected = false;
    if(reportsettings.reportby == 2) {
      reportreportbydate = false;
      reportreportbyperiod = true;
      bydateselected = false;
      byperiodselected = true;
    }
    let fromthelasttitle = componentsStrings.reportmenubar_alltimes;
    if(reportsettings.fromthelast == 1) {
      fromthelasttitle = componentsStrings.reportmenubar_1month;
    } else if(reportsettings.fromthelast == 3) {
      fromthelasttitle = componentsStrings.reportmenubar_3month;
    } if(reportsettings.fromthelast == 6) {
      fromthelasttitle = componentsStrings.reportmenubar_6month;
    }

    let gendertitle = componentsStrings.reportmenubar_gender_all;
    if(reportsettings.fromgender == -1) {
      gendertitle = componentsStrings.reportmenubar_gender_none;
    } else if(reportsettings.fromgender == 1) {
      gendertitle = componentsStrings.reportmenubar_gender_male;
    } else if(reportsettings.fromgender == 0) {
      gendertitle = componentsStrings.reportmenubar_gender_female;
    } else if(reportsettings.fromgender == 2) {
      gendertitle = componentsStrings.reportmenubar_gender_other;
    }

    this.setState({ diablepage: 'none', reportsummeryreport: reportsettings.reportsummeryreport, savetypesave: false, savetitle: componentsStrings.button_update, opacity: '0.4', reportnamedialog: this.state.reportnameselected, reportsubjectdialog: this.state.reportsubjectselected, reportdescriptiondialog: this.state.reportdescriptionselected, reportfromthelastdialog: reportsettings.fromthelast, reportfromclinicsdialog: reportsettings.fromclinics, reportreportbydialog: reportsettings.reportby, reportstartDatedialog: new Date(reportsettings.startDate), reportendDatedialog: new Date(reportsettings.endDate), reportreportbydate: reportreportbydate, reportreportbyperiod: reportreportbyperiod, byperiodselected: byperiodselected, bydateselected: bydateselected, fromthelast: reportsettings.fromthelast, fromthelasttitle: fromthelasttitle, reportfromagedialog: reportsettings.fromage, reporttoagedialog: reportsettings.toage, fromgender: reportsettings.fromgender, gendertitle: gendertitle});
    this.setState({ isDialogOpen: true});
    
    this.fetchClinics();
  }
  deleteReport = () => {
    this.setState({ isDeleteDialogOpen: true, diablepage: 'none', opacity: '0.4' });
  }
  runReport = () => {
    this.state.runReport(this.state.reportidselected);
  }
  noDeleteReport = () => {
		this.setState({ isDeleteDialogOpen: false, diablepage: '', opacity: '1' });
  }
  yesDeleteReport = () => {
    this.runDeleteReport();
		this.setState({ tofatchreports: true, isDeleteDialogOpen: false, diablepage: '', opacity: '1' });
  }
  cancelSaveReport = () => {
		this.setState({ isDialogOpen: false, diablepage: '', opacity: '1' });
  }
  cancelScheduleReport = () => {
		this.setState({ isScheduleDialogOpen: false, diablepage: '', opacity: '1' });
  }
  saveReportonClick = () => {
    this.saveReport();
		this.setState({ isDialogOpen: false, diablepage: '', opacity: '1' , tofatchreports: true});
  }
  saveScheduleReportonClick = () => {
    this.saveScheduleReport();
		this.setState({ isScheduleDialogOpen: false, diablepage: '', opacity: '1' , tofatchreports: true});
  }
  fetchClinics = () => {
    let url = connections.sdkurl;
    fetch(url + "/AEYEAppServer/v7/Clinics/getDoctorClinics", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
        })
      })
        .then(response => response.json())
        .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
            let aeyedoctorclinicsdata = new AEYEDoctorClinics();
            if(data && data.clinics) {
              let aeyeclinicrow = new AEYEClinicRow();
              aeyeclinicrow.id = -1;
              aeyeclinicrow.fullname = "All";
              aeyedoctorclinicsdata.data.push(aeyeclinicrow);
              data.clinics.map((rowdata, index) => {
                let aeyeclinicrow = new AEYEClinicRow();
                aeyeclinicrow.id = rowdata.id;
                aeyeclinicrow.fullname = rowdata.fullname;
                aeyedoctorclinicsdata.data.push(aeyeclinicrow);
              });
            }
              this.setState({ doctorclinics: aeyedoctorclinicsdata });
          }
        });

      }
  fetchReports = () => {
    if(this.state.tofatchreports == true && this.props.username !== '') {
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v6/Reports/getReports", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
        })
      })
        .then(response => response.json())
        .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
            let numberofreports = data.numberofreports;
            let aeyereportdata = new AEYEReportData();
            if(numberofreports > 0) {
              if(data && data.reports && data.reports.length > 0) {
                let rowdataid = 0;
                let rowdataname = "";
                let rowdatadescription = "";
                let rowdatasubject = "";
                let rowdatasettings = "";
                let rowdataschedule = "";

                for (const [index, rowdata] of data.reports.entries()) {
                  let aeyereportrow = new AEYEReportRow();
                  aeyereportrow.id = rowdata.id;
                  aeyereportrow.name = rowdata.name;
                  aeyereportrow.description = rowdata.description;
                  aeyereportrow.subject = rowdata.subject;
                  aeyereportrow.settings = rowdata.settings;
                  aeyereportrow.schedule = rowdata.schedule;

                  aeyereportdata.data.push(aeyereportrow);

                  if(index == 0) {
                    rowdataid = rowdata.id;
                    rowdataname = rowdata.name;
                    rowdatadescription = rowdata.description;
                    rowdatasubject = rowdata.subject;
                    rowdatasettings = rowdata.settings;
                    rowdataschedule = rowdata.schedule;
                  }
                }
                this.setState({reportidselected: rowdataid, reportnameselected: rowdataname, reportdescriptionselected: rowdatadescription, reportsubjectselected: rowdatasubject,  reportsettingsselected: rowdatasettings, reportscheduleselected: rowdataschedule});
              }
            }
            this.setState({numberofreports: numberofreports});
            this.setState({ aeyereportdata: aeyereportdata, tofatchData: false });
          }
          this.setState({tofatchreports: false});
        });
      }
    }
  saveReport = () => {
    if(this.state.savetypesave == true) {
      // Report by start and end date
      let reportby = 1
      if(this.state.selectPeriodChecked == true) {
        // Report by period
        reportby = 2
      }
      let settings = JSON.stringify({
        startDate: this.state.reportstartDatedialog,
        endDate: this.state.reportendDatedialog,
        fromthelast: this.state.fromthelast,
        reportsummeryreport: this.state.reportsummeryreport,
        fromclinics: this.state.fromclinics,
        reportby: reportby,
        fromgender: this.state.fromgender,
        fromage: this.state.reportfromagedialog,
        toage: this.state.reporttoagedialog
      });
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v6/Reports/addNewReport", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          name: this.state.reportnamedialog,
          description: this.state.reportdescriptiondialog,
          subject: this.state.reportsubjectdialog,
          settings: settings
      })
      })
      .then(response => response.json())
      .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
          }
      });
    } else {
      // Report by start and end date
      let reportby = 1
      if(this.state.selectPeriodChecked == true) {
        // Report by period
        reportby = 2
      }
      let settings = JSON.stringify({
        startDate: this.state.reportstartDatedialog,
        endDate: this.state.reportendDatedialog,
        fromthelast: this.state.fromthelast,
        reportsummeryreport: this.state.reportsummeryreport,
        fromclinics: this.state.fromclinics,
        reportby: reportby,
        fromgender: this.state.fromgender,
        fromage: this.state.reportfromagedialog,
        toage: this.state.reporttoagedialog
      });
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v6/Reports/updateReport", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          name: this.state.reportnamedialog,
          description: this.state.reportdescriptiondialog,
          subject: this.state.reportsubjectdialog,
          reportid: this.state.reportidselected,
          settings: settings
      })
      })
      .then(response => response.json())
      .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
          }
      });
    }
  }
  saveScheduleReport = () => {
      // Report by start and end date
      let schedule = JSON.stringify({
        reportSchedulereportOnce: this.state.reportSchedulereportOnce,
        reportscheduleonceDatedialog: this.state.reportscheduleonceDatedialog.toGMTString(),
        reportscheduleTimedialog: this.state.reportscheduleTimedialog.toGMTString(),
        reportSchedulereportOn: this.state.reportSchedulereportOn,
        fromDaysOn: this.state.fromDaysOn,
        reportschedulereportsendemail: this.state.reportschedulereportsendemail,
        reportscheduleemailtosendto: this.state.reportscheduleemailtosendto
      });
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v6/Reports/addSchedule", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          reportid: this.state.reportidselected,
          schedule: schedule
      })
      })
      .then(response => response.json())
      .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
          }
      });
  }
  runDeleteReport = () => {
    let url = connections.sdkurl;
    fetch(url + "/AEYEAppServer/v6/Reports/deleteReport", {
      method: "POST",
      body: JSON.stringify({
        logonid: this.props.username,
        session: this.props.session,
        reportid: this.state.reportidselected
      })
    })
      .then(response => response.json())
      .then(data => {

        if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
          this.props.sessionData.showsessiontimeout();
          return;
        }
        
        if (data.errorcode == errorcodes.errorOK) {
        }
      });
  }
  executeReport = () => {
    this.setState({isExecuteReportDialogOpen: true});
    let url = connections.sdkurl;
    fetch(url + "/AEYEAppServer/v6/Reports/executeReport", {
      method: "POST",
      body: JSON.stringify({
        logonid: this.props.username,
        session: this.props.session,
        reportid: this.state.reportidselected
      })
    })
      .then(response => response.json())
      .then(data => {
        this.setState({isExecuteReportDialogOpen: false});

        if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
          this.props.sessionData.showsessiontimeout();
          return;
        }
        
        if (data.errorcode == errorcodes.errorOK) {
        }
      });
  }

  render() {
      return (
        <React.Fragment>
          {this.fetchReports()}

          {this.showDeleteMsg()}
          {this.showExecuteReportWaitMsg()}

          {this.showNewEditReport()}

          {this.showScheduleReport()}

          {this.showMenuReport()}

        </React.Fragment>
      );
    }
    showScheduleReport() {
      return (
          <Modal show={this.state.isScheduleDialogOpen} onHide={this.cancelScheduleReport} backdrop="static" keyboard={false} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>New</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
              <Row>
                <Form.Group as={Col} md="4">
                  <Form.Check name="selectRunReportSchedule" type="radio" label={componentsStrings.reportmenubar_runreporton_once} defaultChecked={this.state.reportSchedulereportOnce} onClick={this.handleSelectScheduleOnce}/>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Check name="selectRunReportSchedule" type="radio" label={componentsStrings.reportmenubar_runreporton_on} defaultChecked={this.state.reportSchedulereportOn} onClick={this.handleSelectScheduleOn}/>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="4">
                  <DatePicker dateFormat="MMMM d, yyyy hh:mm aa" timeIntervals={60} showTimeSelect disabled={!this.state.byscheduleonceselected} selected={this.state.reportscheduleonceDatedialog} onChange={this.handleScheduleOnceChange} />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Select isDisabled={!this.state.byscheduleonselected} defaultValue={this.getDefaultScheduleOnList()} isMulti name="colors" options={this.getScheduleOnList()} className="basic-multi-select" classNamePrefix="select" onChange={this.handleSelectScheduleOnChange} />
                  <DatePicker timeIntervals={60}  timeCaption="Time" dateFormat="hh:mm aa" timeFormat="hh:mm aa" showTimeSelect showTimeSelectOnly disabled={!this.state.byscheduleonselected} selected={this.state.reportscheduleTimedialog} onChange={this.handleScheduleTimeChange} />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="8">
                  <Form.Check name="selectEmailToSend" type="checkbox" label={componentsStrings.reportmenubar_emailsendreportto_check} defaultChecked={this.state.reportschedulereportsendemail} disabled={true}/>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="8">
                  <Form.Control type="text" placeholder={componentsStrings.reportmenubar_emailto_placeholder} onChange={this.handleEmailToSendToChange} value={this.state.reportscheduleemailtosendto}></Form.Control>
                </Form.Group>
              </Row>
          </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" onClick={() => { this.saveScheduleReportonClick() }}>{this.state.savetitle}</Button>
              <Button variant="primary" type="button" onClick={() => { this.cancelScheduleReport() }}>{componentsStrings.button_cancel}</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    showNewEditReport() {
      return (
          <Modal
            show={this.state.isDialogOpen}
            onHide={this.cancelSaveReport}
            backdrop="static"
            keyboard={false}
            size="lg"
          >
          <Modal.Header closeButton>
            <Modal.Title>New</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
              <Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>{componentsStrings.reportmenubar_reportname_title}</Form.Label>
                  <Form.Control type="text" placeholder={componentsStrings.reportmenubar_reportname_placeholder} onChange={this.handleNameChange} value={this.state.reportnamedialog}></Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>{componentsStrings.reportmenubar_reportdesc_title}</Form.Label>
                  <Form.Control type="text" placeholder={componentsStrings.reportmenubar_reportdesc_placeholder} onChange={this.handleDescriptionChange} value={this.state.reportdescriptiondialog}></Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Check onClick={this.handleSelectSummaryReport} name="selectSummeryReport" type="checkbox" label={componentsStrings.reportmenubar_reportSummary_title} defaultChecked={this.state.reportsummeryreport}/>
                </Form.Group>
              </Row>
              
              <Row>
                <Form.Group as={Col} md="4">
                  {this.displatClinics()}
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md="4">
                  <Form.Check name="selectDate" type="radio" label={componentsStrings.reportmenubar_selectdate_label} defaultChecked={this.state.reportreportbydate} onClick={this.handleSelectDate}/>
                </Form.Group>
                <Form.Group as={Col} md="4">
                </Form.Group>
                <Form.Group as={Col} md="4">
                <Form.Check name="selectDate" type="radio" label={componentsStrings.reportmenubar_selectperiod_label} defaultChecked={this.state.reportreportbyperiod} onClick={this.handleSelectPeriod}/>
                </Form.Group>
              </Row>
              
              <Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>From</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>To</Form.Label>
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <Form.Label>From the last</Form.Label>
                </Form.Group>
              </Row>
              
              <Row>
                <Form.Group as={Col} md="4">
                  <DatePicker disabled={this.state.byperiodselected} selected={this.state.reportstartDatedialog} onChange={this.handleStartChange} />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <DatePicker disabled={this.state.byperiodselected} selected={this.state.reportendDatedialog} onChange={this.handleEndChange} />
                </Form.Group>
                <Form.Group as={Col} md="4">
                  <DropdownButton disabled={this.state.bydateselected} id="dropdown-basic-button" title={this.state.fromthelasttitle}>
                    <Dropdown.Item onClick={this.reportFromTheLast} id="fromthelastever" href="javascript:void(0);">{componentsStrings.reportmenubar_alltimes}</Dropdown.Item>
                    <Dropdown.Item onClick={this.reportFromTheLast} id="fromthelast1mon" href="javascript:void(0);">{componentsStrings.reportmenubar_1month}</Dropdown.Item>
                    <Dropdown.Item onClick={this.reportFromTheLast} id="fromthelast3mon" href="javascript:void(0);">{componentsStrings.reportmenubar_3month}</Dropdown.Item>
                    <Dropdown.Item onClick={this.reportFromTheLast} id="fromthelast6mon" href="javascript:void(0);">{componentsStrings.reportmenubar_6month}</Dropdown.Item>
                  </DropdownButton>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md="4">
                  <Form.Label>{componentsStrings.reportmenubar_reportsubject_title}</Form.Label>
                  <Form.Control type="text" placeholder={componentsStrings.reportmenubar_reportsubject_placeholder} onChange={this.handleSubjectChange} value={this.state.reportsubjectdialog}></Form.Control>
                </Form.Group>
              </Row>

          </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" onClick={() => { this.saveReportonClick() }}>{this.state.savetitle}</Button>
              <Button variant="primary" type="button" onClick={() => { this.cancelSaveReport() }}>{componentsStrings.button_cancel}</Button>
          </Modal.Footer>
        </Modal>

      );
    }
    showMenuReport() {
      return (
        <div style={{ pointerEvents: this.state.diablepage, opacity:this.state.opacity, marginLeft: '18px' }} className='content'>
          <Form>

            <Row>
              
              <Form.Label style={{ marginLeft: '6px' }}>{componentsStrings.reportmenubar_selectreport_title}</Form.Label>

              <Form.Group as={Col} md="1">
                <Form.Control as="select" onChange={this.reportNameChanged}>
                  {this.getReportData()}
                </Form.Control>                
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Button variant="primary" type="button" onClick={() => { this.newReport() }}>New</Button>
                <Button style={{ marginLeft: '6px' }} variant="primary" type="button" onClick={() => { this.editReport() }}>{componentsStrings.button_edit}</Button>
                <Button style={{ marginLeft: '6px' }} variant="primary" type="button" onClick={() => { this.deleteReport() }}>{componentsStrings.button_delete}</Button>
                <Button style={{ marginLeft: '6px' }} variant="primary" type="button" onClick={() => { this.runReport() }}>{componentsStrings.button_run}</Button>
                <Button style={{ marginLeft: '6px' }} variant="primary" type="button" onClick={() => { this.scheduleReport() }}>{componentsStrings.button_schedule}</Button>
                <Button style={{ marginLeft: '6px' }} variant="primary" type="button" onClick={() => { this.executeReport() }}>{componentsStrings.button_execute}</Button>
              </Form.Group>

            </Row>
          </Form>
        </div>
      );
    }
    showDeleteMsg() {
        return (
          <Modal
            show={this.state.isDeleteDialogOpen}
            onHide={this.noDeleteReport}
            backdrop="static"
            keyboard={false}
          >
          <Modal.Header closeButton>
            <Modal.Title>{componentsStrings.reportmenubar_delete_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {componentsStrings.reportmenubar_delete_body}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" onClick={() => { this.yesDeleteReport() }}>{componentsStrings.button_yes}</Button>
            <Button variant="primary" type="button" onClick={() => { this.noDeleteReport() }}>{componentsStrings.button_no}</Button>
          </Modal.Footer>
        </Modal>
      );
    }
    showExecuteReportWaitMsg() {
      return (
        <Modal show={this.state.isExecuteReportDialogOpen} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{componentsStrings.reportmenubar_executereport_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {componentsStrings.reportmenubar_executereport_body}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }
  displatClinics() {
      if(this.props.usertype == 'D') {
        return (
          <Select
            defaultValue={this.getDefaultDoctorClinics()}
            isMulti
            name="colors"
            options={this.getDoctorClinics()}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={this.handleSelectClinicChange}
          />            
        )
      } else {
        return("")
      }
    }
    getScheduleOnList() {
      let colourOptions = [];
      let option = {value: 1, label: days[1], color: '#00B8D9', isFixed: true };
      colourOptions.push(option);
      option = {value: 2, label: days[2], color: '#00B8D9', isFixed: true };
      colourOptions.push(option);
      option = {value: 3, label: days[3], color: '#00B8D9', isFixed: true };
      colourOptions.push(option);
      option = {value: 4, label: days[4], color: '#00B8D9', isFixed: true };
      colourOptions.push(option);
      option = {value: 5, label: days[5], color: '#00B8D9', isFixed: true };
      colourOptions.push(option);
      option = {value: 6, label: days[6], color: '#00B8D9', isFixed: true };
      colourOptions.push(option);
      option = {value: 7, label: days[7], color: '#00B8D9', isFixed: true };
      colourOptions.push(option);
      return colourOptions;
    }
    getDefaultScheduleOnList() {
      let colourOptions = [];
      if(this.state.fromDaysOn) {
        for (const [id, row] of this.state.fromDaysOn.entries()) {
          let option = {value: row.scheduleon, label: days[row.scheduleon] , color: '#00B8D9', isFixed: true };
          colourOptions.push(option);
        }
      }
      return colourOptions;
    }

    getDoctorClinics() {
      let colourOptions = [];
      if(this.state.doctorclinics && this.state.doctorclinics.data) {
        for (const [id, row] of this.state.doctorclinics.data.entries()) {
          let option = {value: row.id, label: row.fullname, color: '#00B8D9', isFixed: true };
          colourOptions.push(option);
        }
      }
      return colourOptions;
    }
    getDefaultDoctorClinics() {
      let colourOptions = [];
      if(this.state.reportfromclinicsdialog) {
        for (const [id, row] of this.state.reportfromclinicsdialog.entries()) {
          let option = {value: row.clinicid, label: row.clinicname, color: '#00B8D9', isFixed: true };
          colourOptions.push(option);
        }
      }
      return colourOptions;
    }

    getReportData() {
      if (this.state.aeyereportdata && this.state.aeyereportdata.data && this.state.aeyereportdata.data.length > 0) {
        return this.state.aeyereportdata.data.map((row, index) => {
          const { id, name, description, subject, settings } = row;
            return (
            <option key={index} optid={id} reportsettings={settings} description={description} subject={subject}>{name}</option>
            );
        });
      } else {
        
      }
    }
}

export default ReportMenuBar;