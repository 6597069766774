import React, { Component} from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import "../../css/aeyehealth.css"
import AEYEDiagnosedDetails from "../Tables/AEYEDetailsTable"
import errorcodes from "../errorcodes";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import html2canvas from 'html2canvas';  
import jsPDF from 'jspdf';
import connections from "../../connections";
import messages from "../../Messages/Messages";
import componentsStrings from "../../Messages/Components";
import Dropdown from "react-bootstrap/Dropdown"
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import { Redirect } from "react-router-dom";
import Moment from 'moment/moment'
import AEYEResearchDiagnosedDetails from "./AEYEResearchDetailsTable";


class AEYEDiagnosticDetails {
    constructor() {
      this.id = 0;
      this.patientid = "";
      this.patientname = "";
      this.diagnosedon = 0;
      this.uploadedon = 0;
      this.diagnoseby = "";
      this.fullname = "";
      this.clinicfullname = "";
      this.comments = "";
      this.doctorid = 0;
      this.automatic = 0;
      this.image_id = 0;
      this.image_file_l = 0;
      this.image_file_r = 0;
      this.image_file_l_name = "";
      this.image_file_r_name = "";
      this.image_content_file_l = 0;
      this.image_content_file_r = 0;
      this.image_file_l_d = 0;
      this.image_file_r_d = 0;
      this.image_file_l_d_name = "";
      this.image_file_r_d_name = "";
      this.image_content_file_l_d = 0;
      this.image_content_file_r_d = 0;
      this.bad_l = 0;
      this.bad_r = 0;
      this.bad_l_d = 0;
      this.bad_r_d = 0;
      this.bad_p = 0;
      this.results = "";
      this.discexist = false;
      this.diseasesL = [];
      this.diseasesR = [];
      this.diseasesP = [];
      this.diseasesL_d = [];
      this.diseasesR_d = [];
      this.diseasesP_two = [];
    }
  }
  class AEYEDisease {
    constructor() {
      this.diagnose = 0;
      this.diagnosis = 0;
      this.disease = 0;
      this.diseasedescription = "";
      this.diseasename = "";
      this.id = 0;
      this.side = "";
      this.chresults = "";
    }
  }
  
class PatientView extends Component {
  
    state = {
        diagnosticdetails: null,
        usertype: "",
        showUploadedBy: 'none',
        archived: false,
        displayDownloadPDFErrorMessage: false,
        displayDownloadDiagnosisMessage: false,
        displaySendDiagnosisPWMessage: false,
        displaySendDiagnosisMessage: false,
        sendDiagnosisMessage: "",
        showSendReport: 'none',
        showArchiveReport: 'inline',
        showCloseReport: 'inline',
        showPrintReport: 'none',
        showDownloadReport: 'none',
        redirectToUpload: null,
        age: '',
        gender: '',
        content_r: "",
        content_l: ""
    };
    handleDisplayDownloadPDFErrorMessage = event => {
      this.setState({displayDownloadPDFErrorMessage: false})
  }
  
    componentDidMount() {
          var showUploadedBy = 'none';
          if(this.props.usertype == "D") {
            showUploadedBy = 'inline';
          }
          let showArchiveReport = 'none';
          if(this.props.showArchiveReport == true) {
            showArchiveReport = 'inline';
          }
          let showCloseReport = 'none';
          if(this.props.showCloseReport == true) {
            showCloseReport = 'inline';
          }
          this.setState({usertype: this.props.usertype, showUploadedBy: showUploadedBy, archived: this.props.archived, showArchiveReport: showArchiveReport, showCloseReport: showCloseReport})

          {this.fetchDetails()}
    }
    
    downloadDocument = () => {
          this.setState({displayDownloadDiagnosisMessage: true});
          let imagedbid = this.props.imagedbid;
          let datediagnosed = Moment(this.props.diagnosedon).format("MMM DD YYYY");
          let clinicfullname = this.props.clinicfullname;
          let url = connections.sdkurl;
          let theurl = url + "/AEYEAppServer/v3/Images/downloadpdf";
          fetch(theurl,  {
              method: "POST",
              body: JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              imagedbid: imagedbid
              })
            }).then(response => {
              this.setState({displayDownloadDiagnosisMessage: false});
              if(response.ok == true && response.status == 200) {
                response.blob().then(blob => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = clinicfullname + '_' + datediagnosed + '.pdf';
                  a.click();
                }).catch(e => {
                  this.setState({ displayDownloadDiagnosisMessage: false });
                });
              } else if(response.ok == false) {
                if((response.status + errorcodes.errorOK) == errorcodes.AEYEHealthSDKSessionTimeout) {
                  this.props.sessionData.showsessiontimeout();
                  return;
                } else {
                  this.setState({displayDownloadPDFErrorMessage: true});
                }
                }
          }).catch(e => {
            this.setState({ displayDownloadDiagnosisMessage: false });
          });
      

    }
    
    sendDocument = (imagedbid, patientdbid) => {
      let url = connections.sdkurl;
      this.setState({displaySendDiagnosisPWMessage: true})

      fetch(url + "/AEYEAppServer/v4/Patients/sendDiagnosis", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            imagedbid: imagedbid,
            patientdbid: patientdbid
          }),
        })
          .then(response => response.json())
          .then(data => {
            this.setState({displaySendDiagnosisPWMessage: false})
           
            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }
      
            if (data.errorcode == errorcodes.errorOK) {
              var sendmsg = componentsStrings.patientview_successsendemail_body;
              sendmsg = sendmsg.replace("$$mailto$$", data.emailto);
              this.setState({sendDiagnosisMessage: sendmsg, displaySendDiagnosisMessage: true});
            } else {
              if(data.emailto == "") {
                this.setState({sendDiagnosisMessage: componentsStrings.patientview_failedsendemail_body, displaySendDiagnosisMessage: true});
              } else {
                let sendmsg = componentsStrings.patientview_failedtosendemail_body;
                sendmsg.replace("$$mailto$$", data.emailto);
                this.setState({sendDiagnosisMessage: sendmsg, displaySendDiagnosisMessage: true});
              }
            }
          });
    }
    
    printDocument = () => {
        const input = document.getElementById('pdfdiv');  
        html2canvas(input, {allowTaint: false, useCORS: true})  
          .then((canvas) => {  
            var imgWidth = 300;  
            var pageHeight = 290;  
            var imgHeight = canvas.height * imgWidth / canvas.width;  
            var heightLeft = imgHeight;  
            const imgData = canvas.toDataURL('image/png');  
            const pdf = new jsPDF('l', 'mm', 'a4')  
            var position = 0;  
            var heightLeft = imgHeight;  
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);  
            pdf.autoPrint();  
            window.open(pdf.output('bloburl'), '_blank');
          });  
    }
    
    handleCloseSendDiagnosisMessage = () => {
      this.setState({displaySendDiagnosisMessage: false});
    }
    
    handleCloseDiagnosisReport = () => {
      this.props.closepatientview();
    }
    
    handleDownloadDiagnosisReport = () => {
      {this.downloadDocument()}
    }
    
    arrayBufferToBase64(buffer) {
      let binary = '';
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    };

    handleSendDiagnosisReport = () => {
      let imagedbid = this.props.imagedbid;
      let patientdbid = this.props.patientdbid;
  
      {this.sendDocument(imagedbid, patientdbid)}
    }
    
    handleSendDiagnosisReportCallback() {

    }    
    
    handlePrintDiagnosisReport = () => {
        {this.printDocument()}
    }
    
    handleArchiveUnarchiveDiagnosis = () => {
      let dataToArchive = [];
      class OneDiag {
        patientdbid = 0;
        imagedbid = 0;
      }
  
      let onediag = new OneDiag();
      onediag.patientdbid = this.props.patientdbid;
      onediag.imagedbid = this.props.imagedbid;
      dataToArchive.push(onediag);

      {this.archiveDiagnosis(dataToArchive)}
    }
    
    archiveDiagnosis = (dataToArchive) => {
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v4/Patients/archiveDiagnosis", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            diagnosistoarchive: dataToArchive,
            toarchive: !this.state.archived
          }),
        })
          .then(response => response.json())
          .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }

            if (data.errorcode == errorcodes.errorOK) {
              this.setState({archived: !this.state.archived});
            }
          });
    }
    
    fetchDetails = () => {

      this.setState({ fatchData: true, content_r: '', content_l: '' });

      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v4/Patients/getDiagnosticDetails", {
            method: "POST",
            body: JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              patientdbid: this.props.patientdbid,
              imagedbid: this.props.imagedbid,
              diagnosesid: this.props.diagnosesid,
              diagstate: this.props.diagstate,
              testreport: this.props.testreport
            })
          })
            .then(response => response.json())
            .then(data => {

              if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
              }

              if (data.errorcode == errorcodes.errorOK) {
                let aeyediagnosticdetails = new AEYEDiagnosticDetails();

                aeyediagnosticdetails.id = data.id;
                aeyediagnosticdetails.patientid = data.patientid;
                aeyediagnosticdetails.patientname = data.patientname;
                aeyediagnosticdetails.diagnosedon = data.diagnosedon;
                aeyediagnosticdetails.uploadedon = data.uploadedon;
                aeyediagnosticdetails.comments = data.comments;
                aeyediagnosticdetails.doctorid = data.doctorid;
                aeyediagnosticdetails.automatic = data.automatic;

                let diagnosedBy = messages.table_diagby_algo;
                if (!Boolean(Number(data.automatic))) {
                  diagnosedBy = data.doctorname;
                }

                aeyediagnosticdetails.diagnoseby = diagnosedBy;
                aeyediagnosticdetails.fullname = data.clinicfullname;
                aeyediagnosticdetails.clinicfullname = data.clinicfullname;

                aeyediagnosticdetails.image_id = data.image_id;
                aeyediagnosticdetails.image_file_l = data.image_file_l;
                aeyediagnosticdetails.image_file_r = data.image_file_r;
                aeyediagnosticdetails.image_file_l_name = data.image_file_l_name;
                aeyediagnosticdetails.image_file_r_name = data.image_file_r_name;
                aeyediagnosticdetails.image_content_file_l = data.image_content_file_l;
                aeyediagnosticdetails.image_content_file_r = data.image_content_file_r;
                aeyediagnosticdetails.bad_l = data.bad_l;
                aeyediagnosticdetails.bad_r = data.bad_r;
                aeyediagnosticdetails.bad_p = data.bad_p;

                aeyediagnosticdetails.discexist = data.discexist;
                if(data.discexist == true) {
                  aeyediagnosticdetails.image_file_l_d = data.image_file_l_d;
                  aeyediagnosticdetails.image_file_r_d = data.image_file_r_d;
                  aeyediagnosticdetails.image_file_l_d_name = data.image_file_l_d_name;
                  aeyediagnosticdetails.image_file_r_d_name = data.image_file_r_d_name;
                  aeyediagnosticdetails.image_content_file_l_d = data.image_content_file_l_d;
                  aeyediagnosticdetails.image_content_file_r_d = data.image_content_file_r_d;
                  aeyediagnosticdetails.bad_l_d = data.bad_l_d;
                  aeyediagnosticdetails.bad_r_d = data.bad_r_d;
                }

                if(this.state.usertype == 'A') {
                  aeyediagnosticdetails.results = data.results;
                  if(data.clinicencryptimage === true) {
                    aeyediagnosticdetails.image_content_file_l = null;
                    aeyediagnosticdetails.image_content_file_r = null;
                  }
                }
                aeyediagnosticdetails.diseasesL = [];
                aeyediagnosticdetails.diseasesR = [];
                aeyediagnosticdetails.diseasesP = [];
                if(data.discexist == true) {
                  aeyediagnosticdetails.diseasesL_d = [];
                  aeyediagnosticdetails.diseasesR_d = [];
                  aeyediagnosticdetails.diseasesP_two = [];
                }
                if(data.diseases) {
                    for (const [index, disease] of data.diseases.entries()) {
                        let aeyedisease = new AEYEDisease();
                        aeyedisease.diagnose = disease.diagnose;
                        aeyedisease.diagnosis = disease.diagnosis;
                        aeyedisease.disease = disease.disease;
                        aeyedisease.diseasedescription = disease.diseasedescription;
                        aeyedisease.diseasename = disease.diseasename;
                        aeyedisease.id = disease.id;
                        aeyedisease.side = disease.side;
                        aeyedisease.chresults = disease.chresults;
                          if(disease.side == "L") {
                              aeyediagnosticdetails.diseasesL.push(aeyedisease)
                          } else if(disease.side == "R") {
                              aeyediagnosticdetails.diseasesR.push(aeyedisease)
                          } else if(disease.side == "P") {
                              aeyediagnosticdetails.diseasesP.push(aeyedisease);
                              aeyediagnosticdetails.comments = disease.comments;
                          } else if(disease.side == "L_d") {
                            aeyediagnosticdetails.diseasesL_d.push(aeyedisease)
                          } else if(disease.side == "R_d") {
                            aeyediagnosticdetails.diseasesR_d.push(aeyedisease)
                          } else {
                            aeyediagnosticdetails.diseasesP_two.push(aeyedisease)
                          }
                    }
                }
                let showSendReport = 'none';
                if(this.props.showSendReport == true) {
                  showSendReport = 'block';
                }
                let showPrintReport = 'none';

                let showDownloadReport = 'none';
                if(this.props.showDownloadReport == true) {
                  showDownloadReport = 'block';
                }

                let showGradeReport = 'none';
                if(this.props.usertype == "D") {
                  showGradeReport = 'block';
                }
      
                if(aeyediagnosticdetails.diagnosedon == "") {
                  showSendReport = 'none';
                  showPrintReport = 'none';
                  showDownloadReport = 'none';
                  showGradeReport = 'none';
                }
      
                this.setState({diagnosticdetails: aeyediagnosticdetails, showSendReport: showSendReport, showPrintReport: showPrintReport, showDownloadReport: showDownloadReport, showGradeReport: showGradeReport},
                  this.fetchImage(data.image_content_file_r, true),
                  this.fetchImage(data.image_content_file_l, false)
                );
              }
            });
    }

    fetchImage = (content_id, isRightEye) => {

      if(this.props.session && content_id > 0) {
  
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v3/Images/getimageNew", {
              method: "POST",
              body: JSON.stringify({
                logonid: this.props.username,
                session: this.props.session,
                image_content_file: content_id 
              })
        })
        .then(response => response.json())
        .then(data => {
  
          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }
  
          if(data.errorcode == errorcodes.AEYEHealthSDKFailedToLogin) {
            this.props.displayFailedToLoginMessageBox();
            return;
          }        
  
          if (data.errorcode == errorcodes.errorOK) {
  
              if(isRightEye === true) {
                this.setState({ content_r: data.content });
              }
              else {
                this.setState({ content_l: data.content });
              }
  
          }
  
        })
        .catch(e => {
          // console.log(e);
        });
  
      }
  
    }


    render() {
      if(this.state.redirectToUpload) {
        return <Redirect to={this.state.redirectToUpload} />
      } else {
        return (
            <React.Fragment>
                {this.sendDiagnosisPleaseWaitMessageBox()}
                {this.downloadDiagnosisPleaseWaitMessageBox()}
                {this.sendDiagnosisMessageBox()}
                {this.getDetails()}
                {this.downloadPDFErrorMessageBox()}
            </React.Fragment>
        );
      }
    }

    sendDiagnosisPleaseWaitMessageBox() {
      return(
      <Modal show={this.state.displaySendDiagnosisPWMessage} id="send_message_box">
          
          <Modal.Header>
              <Modal.Title>{componentsStrings.patientview_sendemail_title}</Modal.Title>
          </Modal.Header>
          
          <Modal.Body>
            
            <Container style={{display: 'flex', alignItems: 'center'}}>
                
                {componentsStrings.patientview_sendemail_body}
                
                <Spinner animation="border" role="status" style={{height: '1.5rem', width: '1.5rem', marginLeft: '5px'}}>
                    <span className="visually-hidden"></span>
                </Spinner>
            </Container>

          </Modal.Body>

      </Modal>        
      );
    }
    
    downloadPDFErrorMessageBox() {
      return(
      <Modal show={this.state.displayDownloadPDFErrorMessage}>
          <Modal.Header>
              <Modal.Title>{messages.table_errordownloadpdf_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <Container style={{display: 'flex', alignItems: 'center'}} id="uploadpatient_pleasewaitmsg">
                  {messages.table_errordownloadpdf_body}
              </Container>

          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={this.handleDisplayDownloadPDFErrorMessage}>
                {componentsStrings.button_close}
            </Button>
          </Modal.Footer>
      </Modal>        
      );
  }

    downloadDiagnosisPleaseWaitMessageBox() {
      return(
      <Modal show={this.state.displayDownloadDiagnosisMessage} id="download_message_box">
          <Modal.Header>
              <Modal.Title>{componentsStrings.patientview_download_title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Container style={{display: 'flex', alignItems: 'center'}}>
                {componentsStrings.patientview_downloading_body}

                <Spinner animation="border" role="status" style={{height: '1.5rem', width: '1.5rem', marginLeft: '5px'}}>
                    <span className="visually-hidden"></span>
                </Spinner>
            </Container>

          </Modal.Body>

      </Modal>        
      );
    }
    
    sendDiagnosisMessageBox() {
      return(
      <Modal show={this.state.displaySendDiagnosisMessage}>
          <Modal.Header>
              <Modal.Title>{componentsStrings.patientview_sendemail_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.sendDiagnosisMessage}</Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseSendDiagnosisMessage} id="send_message_box_close">
              {componentsStrings.button_close}
          </Button>
          </Modal.Footer>
      </Modal>        
      );
    }

    getDetails() {
      let srcR = "";
      let srcL = "";
      let image_file_r = 0;
      let image_file_l = 0;
      let srcR_d = "";
      let srcL_d = "";
      let image_file_r_d = 0;
      let image_file_l_d = 0;
      let bad_r = 0;
      let bad_l = 0;
      let bad_r_d = 0;
      let bad_l_d = 0;
      let bad_p = 0;
      let archived = this.state.archived;
      let results = "";
      let image_l_name = "";
      let image_r_name = "";
      let image_l_d_name = "";
      let image_r_d_name = "";

      let archivedButton = componentsStrings.button_archive;
      if(archived == true) {
        archivedButton = componentsStrings.button_unarchive;
      }

      let imagequalityR = "";
      let imagequalityL = "";
      let imagequalityR_d = "";
      let imagequalityL_d = "";

      let discexist = false;

      let patientname = "";
      let diagnoseby = "";
      let diagnosedon = 0;
      let clinicfullname = "";
      let fullname = "";
      let uploadedon = 0;

      if(this.state.diagnosticdetails) {
          discexist = this.state.diagnosticdetails.discexist; 
         
          let paramsR = JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              image_content_file: this.state.diagnosticdetails.image_content_file_r
          })
          let paramsRBase64 = new Buffer(paramsR).toString('base64');
          
          let paramsL = JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              image_content_file: this.state.diagnosticdetails.image_content_file_l
          })
          let paramsLBase64 = new Buffer(paramsL).toString('base64');
          let url = connections.sdkurl;
          
          
          srcR = 'data:image/' + this.state.diagnosticdetails.image_type_r + ';base64, ' + this.state.content_r;
          srcL = 'data:image/' + this.state.diagnosticdetails.image_type_l + ';base64, ' + this.state.content_l;
          
          // srcR = url + "/AEYEAppServer/v3/Images/getimage/" + paramsRBase64;
          // srcL = url + "/AEYEAppServer/v3/Images/getimage/" + paramsLBase64;
          image_file_r = this.state.diagnosticdetails.image_file_r;
          image_file_l = this.state.diagnosticdetails.image_file_l;
          image_r_name = this.state.diagnosticdetails.image_file_r_name;
          image_l_name = this.state.diagnosticdetails.image_file_l_name;
          bad_r = this.state.diagnosticdetails.bad_r;
          bad_l = this.state.diagnosticdetails.bad_l;
          bad_p = this.state.diagnosticdetails.bad_p;
          results = this.state.diagnosticdetails.results;
          imagequalityR = componentsStrings.patientview_isq;
          imagequalityL = componentsStrings.patientview_isq;
         
          if(bad_l == true) {
            imagequalityL = componentsStrings.patientview_iiq;
          }
         
          if(bad_r == true) {
            imagequalityR = componentsStrings.patientview_iiq;
          }
         
          if(discexist == true) {
            let paramsR_d = JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              image_content_file_d: this.state.diagnosticdetails.image_content_file_r_d
            })
            let paramsRBase64_d = new Buffer(paramsR_d).toString('base64');
            let paramsL_d = JSON.stringify({
                logonid: this.props.username,
                session: this.props.session,
                image_content_file_d: this.state.diagnosticdetails.image_content_file_l_d
            })
            let paramsLBase64_d = new Buffer(paramsL_d).toString('base64');
            let url = connections.sdkurl;
            srcR_d = url + "/AEYEAppServer/v3/Images/getimage/" + paramsRBase64_d;
            srcL_d = url + "/AEYEAppServer/v3/Images/getimage/" + paramsLBase64_d;
            image_file_r_d = this.state.diagnosticdetails.image_file_r_d;
            image_file_l_d = this.state.diagnosticdetails.image_file_l_d;
            image_r_d_name = this.state.diagnosticdetails.image_file_r_d_name;
            image_l_d_name = this.state.diagnosticdetails.image_file_l_d_name;

            bad_r_d = this.state.diagnosticdetails.bad_r_d;
            bad_l_d = this.state.diagnosticdetails.bad_l_d;
            imagequalityR_d = componentsStrings.patientview_isq;
            imagequalityL_d = componentsStrings.patientview_isq;
            if(bad_l_d == true) {
              imagequalityL_d = componentsStrings.patientview_iiq;
            }
            if(bad_r_d == true) {
              imagequalityR_d = componentsStrings.patientview_iiq;
            }
          }

          patientname = this.state.diagnosticdetails.patientname;
       
          if(patientname.length > 50) {
            patientname = messages.table_desisdetails_dataencrypted;
          }
       
          diagnoseby = this.state.diagnosticdetails.diagnoseby;
          diagnosedon = this.state.diagnosticdetails.diagnosedon;
          clinicfullname = this.state.diagnosticdetails.clinicfullname;
          uploadedon = this.state.diagnosticdetails.uploadedon;
          fullname = this.state.diagnosticdetails.fullname;
      }
      return (
        <React.Fragment>
          <div id="patientview_page">
              <div id="rmenubarwork">
                  <Row>
                    <Col md={12}>
                      <div style={{float: 'right', float: 'right', display: 'inline-flex', alignItems: 'center'}}> 
        
                      {this.props.testreport === '' &&  <Dropdown >

                        <Dropdown.Toggle id="patientview_actions">
                          Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
          
                            <Dropdown.Item id="patientview_grade"  style={{display: this.state.showGradeReport}}>
                                <Link to={{pathname: '/legacy/diagnose', params: {bad_r: bad_r, bad_l: bad_l, bad_p: bad_p, image_file_r: image_file_r, image_file_l: image_file_l, srcR: srcR, srcL: srcL, patientdbid: this.props.patientdbid, imagedbid: this.props.imagedbid}}} className="btn" style={{paddingLeft: "unset"}}>
                                  {componentsStrings.button_grade}
                                </Link>
                            </Dropdown.Item>

                            <Dropdown.Item id="patientview_download" style={{display: this.state.showDownloadReport}} onClick={() => { this.handleDownloadDiagnosisReport() }}>
                              {this.state.showDownloadReport == 'block' && componentsStrings.button_download}
                            </Dropdown.Item>

                            <Dropdown.Item id="patientview_send" style={{display: this.state.showSendReport}} onClick={() => { this.handleSendDiagnosisReport() }}>
                              {this.state.showSendReport == 'block' && componentsStrings.button_send}
                            </Dropdown.Item>

                            <Dropdown.Item style={{display: this.state.showPrintReport}} onClick={() => { this.handlePrintDiagnosisReport() }}>
                              {this.state.showPrintReport == 'block' && componentsStrings.button_print}
                            </Dropdown.Item>

                            <Dropdown.Item id="patientview_archived" style={{display: this.state.showArchiveReport}} onClick={() =>{ this.handleArchiveUnarchiveDiagnosis() }}>
                                  {archivedButton}
                            </Dropdown.Item>

                        </Dropdown.Menu>

                      </Dropdown>}

                        <Button id="patientview_close" style={{margin: '10px', display: this.state.showCloseReport}} onClick={() => { this.handleCloseDiagnosisReport() }}>
                          {componentsStrings.button_close}
                        </Button>
  
                      </div>
                    </Col>
                  </Row>
              </div>
              <div id="pdfdiv">
                  <div id="rcornerswork1">
                      <Row>
                          <div id="rcorners3">
                              <div style={{marginLeft: '30px', marginTop: '10px'}}>
                                  <p id="patientview_uploadedby" style={{display: this.state.showUploadedBy}}>{componentsStrings.patientview_uploadedby} {clinicfullname} {uploadedon}</p>
                                  {this.props.diagstate != -1 && <p id="patientview_diagnosedby">{componentsStrings.patientview_diagby} {diagnoseby} on {diagnosedon}</p>}
                                  {this.props.diagstate == -1 && <p id="patientview_diagnosedby">{componentsStrings.patientview_notdiagyet}</p>}
                                  <div id="patientview_patient">{componentsStrings.patientview_patient}: {patientname}</div>
                                  <div>Status: {this.state.archived ? 'Archived': 'Active'}</div>

                                  { this.state.age != '' && 
                                    <div id="diagnosetool_summary_agP">
                                        {componentsStrings.aeyedetailstable_age} : {this.state.age}
                                    </div>}

                                  { this.state.gender != '' && 
                                    <div id="diagnosetool_summary_genP">
                                      {componentsStrings.aeyedetailstable_gender} : {this.state.gender}
                                    </div>
                                  }

                              </div>
                              <div id="rcornerswork">
                                  <div style={{backgroundColor: '#ffffff', borderRadius: '25px'}}>
                                      
                                      {this.props.clickalview == 'false' && <AEYEResearchDiagnosedDetails sessionData={this.props.sessionData} diagstate={this.props.diagstate} diagnoseby={this.props.diagnoseby} bad_r={bad_r} bad_l={bad_l} bad_r_d={bad_r_d} srcR={srcR} srcL={srcL}
                                                                                                                                  bad_l_d={bad_l_d} bad_p={bad_p} discexist={discexist} 
                                                                                                                                  diagnosticdetails={this.state.diagnosticdetails} setAge={this.setAge} setGender={this.setGender}>
                                                                                                    </AEYEResearchDiagnosedDetails>}
                                      
                                      {(!this.props.clickalview || this.props.clickalview == 'true' ) && <AEYEDiagnosedDetails sessionData={this.props.sessionData} diagstate={this.props.diagstate} bad_r={bad_r} bad_l={bad_l} bad_r_d={bad_r_d} bad_l_d={bad_l_d} 
                                                                                                    bad_p={bad_p} discexist={discexist} srcR={srcR} srcL={srcL} diagnosticdetails={this.state.diagnosticdetails}
                                                                                                    usertype={this.props.usertype} fileNameLeft={image_l_name} fileNameRight={image_r_name} >
                                                                                                  </AEYEDiagnosedDetails>}
                                  </div>
                              </div>
                          </div>
                      </Row>
                      {this.props.usertype == 'R' && <Row>
                          <Col>
                              <img class="diagimage" src={srcR} crossorigin="anonymous"></img>
                          </Col>
                          <Col>
                              <div>
                                  <img src="/Report_tick_icon.png" class="reporttick "/>
                                  <label>{imagequalityR}</label>
                              </div>
                              <div>
                                  <label>{image_r_name}</label>
                              </div>
                          </Col>
                          <Col>
                              <img class="diagimage" src={srcL} crossorigin="anonymous"></img>
                          </Col>
                          <Col>
                            <div>
                                <img src="/Report_tick_icon.png" class="reporttick "/>
                                <label>{imagequalityL}</label>
                            </div>
                            <div>
                                  <label>{image_l_name}</label>
                            </div>
                          </Col>
                      </Row>}
                      {discexist && this.props.usertype == 'R' && <Row>
                          <Col>
                              <img class="diagimage" src={srcR} crossorigin="anonymous" onLoad={""}></img>
                          </Col>
                          <Col>
                              <div>
                                  <img src="/Report_tick_icon.png" class="reporttick "/>
                                  <label>{imagequalityR_d}</label>
                              </div>
                              <div>
                                  <label>{image_r_d_name}</label>
                              </div>
                          </Col>
                          <Col>
                              <img class="diagimage" src={srcL} crossorigin="anonymous" onLoad={""}></img>
                          </Col>
                          <Col>
                            <div>
                                <img src="/Report_tick_icon.png" class="reporttick "/>
                                <label>{imagequalityL_d}</label>
                            </div>
                            <div>
                                  <label>{image_l_d_name}</label>
                            </div>
                          </Col>
                      </Row>}

                      {this.props.usertype == 'A' && <Row>
                        <label>{results}</label>
                      </Row>}
                  </div>            
              </div>            
          </div> 
        </React.Fragment>
        );
    }   

    setAge = (age) => {

      if(age != '' && age != this.state.age) {

        this.setState({age: age});
      }
      
    }

    setGender = (gender) => {

      if(gender != '' && gender != this.state.gender) {

        this.setState({gender: gender});
      }

    }

}

export default PatientView;