export default class errormessages {
    static fatalError = "Fatal error, please contact your administrator";
  
    // AEYEUsers
    static inviteUserTitle = "Invite User";
    static inviteUserProcessingBody = "Processing in progress. Please wait.";
    static inviteUserFailedBody = "Failed to invite the user";
    static inviteUserSuccBody = "User invitation successfully sent";
    
    static loginform_failToLogin = "Authentication failed. You don't have permissions to access the system.";
    static uploadCSVImages_failToLogin = "Authentication failed. You don't have permissions to access the system.";
    static settingsformnewuser_failToLogin = "Authentication failed. You don't have permissions to access the system.";
    static forgetform_failToForget = "You don't have permissions to recover your password.";
    static recoverform_failToRecover = "You cannot recover your password twice."
    static loginform_failToLogin_username = "Failed to login. Check your username.";
    static loginform_failToLogin_password = "Failed to login. Check your password.";
    static forgetform_failToLogin_username = "Failed to send email. Check your username.";
    static forgetform_failToLogin_email = "Failed to send email. check your email.";
    static forgetform_failToLogin_captcha = "You have not filled out the captcha, please do so and try again";
    static loginform_failToLogin_soonLoginWillBeBlocked = "You have attempted to log in with the incorrect username and/or password. After 5 tries this account will be blocked and must be unblocked using the “forgot password” option.";
    static loginform_failToLogin_loginBlocked = "This account has been blocked due to a high number of erroneous entry attempts. Please reset your password using the “forgot password” option.";

    static uploadpatient_addNewImagesPatientInvalidID = "Invalid Exam ID. Exam ID must contain alphabetic charecters only.";
    static uploadpatient_addNewImagesPatientInvalidName = "Invalid Patient Name. Patient name must contain only alphabetic charecters, underscores, and spaces.";
    static uploadpatient_addNewImagesPatientDiagnosesFailed = "Failed to diagnose images.";
    static uploadpatient_addNewImagesPatientUploadedDiagnosesFailed = "Images were uploaded for diagnosis";
    static uploadpatient_addNewImagesPatientUploadedDiagnosesTimeout = "Images were uploaded but encountered a timeout. Please try again in a few minutes.";
    static uploadpatient_NoDiseaseWasSet = "No diagnostic modules are defined for the clinic.";
    static uploadpatient_FailToCheckFilters = "Failed to diagnose images.";
    static uploadpatient_addNewImagesPatient_error_title = "Upload Error";
    static uploadpatient_NoDiseaseWasSet_title = "No disease detected";
  
    static deleteUserDoneMsgTitle = "Delete user";
    static deleteUserDoneMsgBodySucc = "User has been successfully deleted";
    static deleteUserDoneMsgBodyFailed = "Failed to delete user";
  
    static loginform_externalloginfailed = "Failed to login";

    static errorform_fatal_nocred_fromdispatcher = "Failed to find AEYE credentials; the dispatcher never logged in to the AEYE System. Please contact your administrator"
    static certnotvalidform_fatal_dispatcher_certrevoked = "Dispatcher certificate is revoked. Please contact your administrator"

    static uploadcvsimages_onecsvonly = "Must upload one csv file."
    static uploadcvsimages_invalidcsvfile = "You are trying to upload an invalid CSV file."

    static loginform_error_sessionexpired = "Session Expired!";
    static loginform_error_failtologin = "Failure to login";
    static loginform_error_processexam = "Error. Another user is currently updating the exam you are trying to access. Please contact your administrator.";

}

