import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Pagination from 'react-bootstrap/Pagination';
import errorcodes from "../errorcodes";
import errormessages from "../../Messages/ErrorMessages";
import AddNewUser from '../Dialogs/AddNewUser'
import connections from "../../connections";
import MessageBox from "../MessageBox/messagebox"
import Modal from 'react-bootstrap/Modal'
import componentsStrings from "../../Messages/Components";


class AEYEDiseaseRow {
  constructor() {
    this.id = 0;
    this.name = "";
    this.description = "";
    this.diagnosis = "";
  }
}

class AEYEDiseasesData {
  constructor() {
    this.data = [];
  }
}

class AEYEPolicyRow {
  constructor() {
    this.id = 0;
    this.title = "";
    this.description = "";
  }
}

class AEYEPoliciesData {
  constructor() {
    this.data = [];
  }
}

class AEYEUsersRow {
  constructor() {
    this.userdbid = 0;
    this.username = "";
    this.fullname = "";
    this.license = "";
    this.type = "";
    this.timezone = "";
    this.email = "";
    this.departmentid = "";
    this.emailresults = "";
    this.lastlogin = "";
    this.camera = "";
    this.algorithem = "";
    this.editable = false;
    this.deletable = false;
    this.authmode = -1;
    this.addnewpatient = false;
    this.internalexam = false;
    this.externalexam = false;
  }
}

class AEYEUsersData {
  constructor() {
    this.data = [];
  }
}

const dialogStyle = {
  width: '60rem',
  height: '20rem',
  boxShadow: 'rgba(0,0,0,.3) 0 0.3rem 1rem',
  background: 'red'
}


class AEYEUsers extends Component {

  state = {
    username: "",
    session: "",
    aeyetabledata: null,
    aeyetablealldata: null,
    diseases: null,
    policytypes: null,
    tofatchData: true,
    tofatchAllData: true,
    tofatchDiseases: true,
    tofatchPolicies: true,
    fromindex: 0,
    pagesize: 5,
    pagesizetext: 5,
    currentactivpage: 0,
    maxnumberofitems: 0,
    numberofrows: 0,
    lastpage: 1,
    searchCretiria: "",
    systemusers: false,
    isDialogOpen: false,
    displayMessage: false,
    messageTitle: "",
    messageBody: "",
    messageCloseButton: false,
    displayConfirmDeleteUser: false,
    displayDeleteUserWait: false,
    edituserdbid: 0,
    currentuserdbid: 0,
    displayDeleteUserDoneMsg: false,
    deleteUserDoneMsgTitle: "",
    deleteUserDoneMsgBody: "",
    existingusers: true,
    callbyclinic: false
  };

  constructor() {
    super();
    this.numberOfPagesDisplayed = 5;
  }

  componentDidMount() {
    this.setState({ systemusers: this.props.systemusers, username: this.props.username, session: this.props.session, callbyclinic: this.props.callbyclinic });
  }

  handlePageSizeSelection = (eventKey, event) => {
    this.setState({ currentactivpage: 0, pagesizetext: event.target.text, pagesize: parseInt(event.target.attributes["value"].value), tofatchData: true });
  };

  handlePaginationNext = () => {
    let fromindex = (this.state.currentactivpage + 1) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage + 1, tofatchData: true });
  }

  handlePaginationPrev = () => {
    let fromindex = (this.state.currentactivpage - 1) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage - 1, tofatchData: true });
  }

  handlePaginationFirst = () => {
    let fromindex = (this.state.currentactivpage - this.numberOfPagesDisplayed) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage - this.numberOfPagesDisplayed, tofatchData: true });
  }

  handlePaginationLast = () => {
    let fromindex = (this.state.currentactivpage + this.numberOfPagesDisplayed) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage + this.numberOfPagesDisplayed, tofatchData: true });
  }

  handleSearch = event => {
    this.setState({ searchCretiria: event.target.value, tofatchData: true });
  }

  handlePageClick = (numberClicked) => {
    let fromindex = (numberClicked - 1) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: numberClicked - 1, tofatchData: true });
  }

  newUser = event => {
    this.setState({ isDialogOpen: true });
  }

  onCloseNewUser = event => {
    this.setState({ isDialogOpen: false, tofatchData: true});
  }

  onCloseEditUser = event => {
    this.setState({ isDialogEdit: false, tofatchData: true });
  }

  inviteUser = event => {
    var userdbid = event.target.attributes['userdbid'].value;
    var email = event.target.attributes['email'].value;
    var username = event.target.attributes['username'].value;

    this.inviteNewUser(userdbid, email, username);
  }

  editUser = event => {
    var userdbid = event.target.attributes['userdbid'].value;

    this.setState({ isDialogEdit: true, edituserdbid: userdbid });
  }

  deleteUser = event => {
    this.setState({ displayConfirmDeleteUser: true, currentuserdbid: event.target.attributes['userdbid'].value });
  }

  handleNoConfirmDeleteUser = event => {
    this.setState({ displayConfirmDeleteUser: false });
  }

  handleYesConfirmDeleteUser = event => {
    this.setState({ displayConfirmDeleteUser: false, displayDeleteUserWait: true });

    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyuserwrite) {
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v1/UserRepository/deleteUser", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          userdbid: this.state.currentuserdbid
        })
      })
        .then(response => response.json())
        .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
            this.setState({ displayDeleteUserDoneMsg: true, displayDeleteUserWait: false, deleteUserDoneMsgTitle: errormessages.deleteUserDoneMsgTitle, deleteUserDoneMsgBody: errormessages.deleteUserDoneMsgBodySucc })
          } else {
            this.setState({ displayDeleteUserDoneMsg: true, displayDeleteUserWait: false, deleteUserDoneMsgTitle: errormessages.deleteUserDoneMsgTitle, deleteUserDoneMsgBody: errormessages.deleteUserDoneMsgBodyFailed })
          }
        });
    }

    this.setState({ tofatchData: true })
  }

  handleDeleteUserDoneMsgOK = event => {
    this.setState({ displayDeleteUserDoneMsg: false })
  }

  inviteNewUser = (userdbid, email, username) => {
    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyuserwrite) {

      this.setState({ messageCloseButton: false, messageTitle: errormessages.inviteUserTitle, messageBody: errormessages.inviteUserProcessingBody, displayMessage: true })

      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v1/UserRepository/inviteNewUser", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          username: username,
          userdbid: userdbid,
          email: email
        })
      })
      .then(response => response.json())
      .then(data => {

        if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
          this.props.sessionData.showsessiontimeout();
          return;
        }

        if (data.errorcode == errorcodes.errorOK) {
          this.setState({ messageCloseButton: true, messageTitle: errormessages.inviteUserTitle, messageBody: errormessages.inviteUserSuccBody })
        } else {
          this.setState({ messageCloseButton: true, messageTitle: errormessages.inviteUserTitle, messageBody: errormessages.inviteUserFailedBody })
        }
      });
    }
  }

  handleCloseMessageBox = event => {
    this.setState({ displayMessage: false })
  }

  fetchUsers = () => {
    
    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyuserread) {

      if (this.state.tofatchData == true && !(this.props.session === "")) {

        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v1/UserRepository/getUsers", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            fromindex: this.state.fromindex,
            pagesize: this.state.pagesize,
            search: this.state.searchCretiria,
            systemusers: this.state.systemusers,
            existingusers: this.state.existingusers,
            callbyclinic: this.state.callbyclinic
          })
        })
          .then(response => response.json())
          .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }

            if (data.errorcode == errorcodes.errorOK) {
              let maxnumberofrows = data.maxnumberofrows;
              let numberofrows = data.numberofrows;
              let aeyetabledata = new AEYEUsersData();
              if (maxnumberofrows > 0) {
                if (data && data.users) {
                  data.users.map((rowdata, index) => {
  
                    let aeyetablerow = new AEYEUsersRow();
                    aeyetablerow.userdbid = rowdata.userdbid;
                    aeyetablerow.authmode = rowdata.authmode;
                    aeyetablerow.username = rowdata.username;
                    aeyetablerow.fullname = rowdata.fullname;
                    aeyetablerow.usertype = rowdata.usertype;
                    aeyetablerow.timezone = rowdata.timezone;
                    aeyetablerow.email = rowdata.email;
                    aeyetablerow.departmentid = rowdata.departmentid;
                    aeyetablerow.lastlogin = rowdata.lastlogin;
                    aeyetablerow.cameratype = rowdata.cameratype;
                    aeyetablerow.license = rowdata.license;
                    aeyetablerow.emailresult = rowdata.emailresult;
                    aeyetablerow.algorithem = rowdata.algorithem;
                    aeyetablerow.clinics = "clinics";
                    aeyetablerow.deletable = rowdata.deletable;
                    aeyetablerow.editable = rowdata.editable;
  
                    aeyetablerow.addnewpatient = rowdata.addnewpatient;
                    aeyetablerow.internalexam = rowdata.internalexam;
                    aeyetablerow.externalexam = rowdata.externalexam;
  
                    aeyetabledata.data.push(aeyetablerow);
                  });
                }
              }
              let numberofpages = Math.floor(this.state.maxnumberofitems / this.state.pagesize);
              if ((this.state.maxnumberofitems % this.state.pagesize) > 0) {
                numberofpages += 1;
              }
              this.setState({ lastpage: numberofpages, maxnumberofitems: maxnumberofrows, numberofrows: numberofrows });
              this.setState({ aeyetabledata: aeyetabledata, tofatchData: false });
            }
          });
      }
  
      if (this.state.tofatchAllData == true && !(this.props.session === "")) {

        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v1/UserRepository/getUsers", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            fromindex: 0,
            pagesize: -1,
            search: "",
            systemusers: this.state.systemusers,
            existingusers: this.state.existingusers,
            callbyclinic: false
          })
        })
          .then(response => response.json())
          .then(data => {
  
            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }
  
            if (data.errorcode == errorcodes.errorOK) {
              let maxnumberofrows = data.maxnumberofrows;
              let numberofrows = data.numberofrows;
              let aeyetabledata = new AEYEUsersData();
              if (maxnumberofrows > 0) {
                if (data && data.users) {
                  data.users.map((rowdata, index) => {
  
                    let aeyetablerow = new AEYEUsersRow();
                    aeyetablerow.userdbid = rowdata.userdbid;
                    aeyetablerow.authmode = rowdata.authmode;
                    aeyetablerow.username = rowdata.username;
                    aeyetablerow.fullname = rowdata.fullname;
                    aeyetablerow.usertype = rowdata.usertype;
                    aeyetablerow.timezone = rowdata.timezone;
                    aeyetablerow.email = rowdata.email;
                    aeyetablerow.departmentid = rowdata.departmentid;
                    aeyetablerow.lastlogin = rowdata.lastlogin;
                    aeyetablerow.cameratype = rowdata.cameratype;
                    aeyetablerow.license = rowdata.license;
                    aeyetablerow.emailresult = rowdata.emailresult;
                    aeyetablerow.algorithem = rowdata.algorithem;
                    aeyetablerow.clinics = "clinics";
                    aeyetablerow.deletable = rowdata.deletable;
                    aeyetablerow.editable = rowdata.editable;
  
                    aeyetabledata.data.push(aeyetablerow);
                  });
                }
              }
              this.setState({ aeyetablealldata: aeyetabledata, tofatchAllData: false });
            }
          });
      }

    }

  }

  fetchDiseases = () => {

    if (this.state.tofatchDiseases == true && !(this.props.session === "")) {
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v12/Diseases/getDiseases", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          search: this.state.searchCretiria,
          systemusers: this.state.systemusers,
          existingusers: this.state.existingusers
        })
      })
        .then(response => response.json())
        .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {

            let numberofrows = data.diseases.length;
            let diseases = new AEYEDiseasesData();

            if (numberofrows > 0) {

              if (data && data.diseases) {

                data.diseases.map((rowdata, index) => {

                  let disease = new AEYEDiseaseRow();
                  disease.id = rowdata.id;
                  disease.name = rowdata.name;
                  disease.description = rowdata.description;
                  disease.diagnosis = rowdata.diagnosis;

                  diseases.data.push(disease);
                });
              }
            }

            this.setState({ diseases: diseases, tofatchDiseases: false });
          }
        });
    }

  }

  fetchPoliciesTypes = () => {

    if (this.state.tofatchPolicies == true && !(this.props.session === "")) {
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v1/UserRepository/getallpoliciestypes", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session
        })
      })
        .then(response => response.json())
        .then(data => {

              if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.showsessiontimeout();
                return;
              }

          if (data.errorcode == errorcodes.errorOK) {
            let policies = new AEYEPoliciesData();

            if (data.policies.length > 0) {

              if (data && data.policies) {

                data.policies.map((rowdata, index) => {

                  let policy = new AEYEPolicyRow();
                  policy.id = rowdata.id;
                  policy.title = rowdata.title;
                  policy.description = rowdata.description;

                  policies.data.push(policy);
                });
              }
            }

            this.setState({ policytypes: policies, tofatchPolicies: false });
          }
        });
    }

  }

  render() {
      return (
      <React.Fragment>
        {this.fetchUsers()}
        {this.fetchDiseases()}
        {this.fetchPoliciesTypes()}

        {this.openNewUser()}
        {this.openEditUser()}

        {this.confirmDeleteUser()}
        {this.deleteUserWait()}
        {this.deleteUserDoneMsg()}

        <div style={{ paddingBottom: '10px' }}>

          <div style={{ float: 'right', display: 'flex' }}>

            <div style={{ paddingLeft: '5px' }}>
              <Button id="users_buttonnew" disabled={this.props.systemusers} onClick={this.newUser}>New User</Button>
            </div>

          </div>

          <div style={{ float: 'left', display: 'flex' }}>

            <Dropdown style={{ float: 'left', marginBottom: '10px' }} onSelect={this.handlePageSizeSelection}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">{this.state.pagesizetext}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item value="5">5</Dropdown.Item>
              <Dropdown.Item value="10">10</Dropdown.Item>
              <Dropdown.Item value="50">50</Dropdown.Item>
              <Dropdown.Item value="-1">All</Dropdown.Item>
            </Dropdown.Menu>
            <label style={{ paddingLeft: '5px' }}>{this.getNumberOfImagesTitle()}</label>
          </Dropdown>

          <div style={{ paddingLeft: '35px' }}>
            </div>

          <div id="users_search">
              <label style={{ paddingRight: '5px' }}>{componentsStrings.aeyeusers_search_label}</label>

              <input onChange={this.handleSearch} type="search"></input>
            </div>


          </div>


        </div>

        <MessageBox closebutton={this.state.messageCloseButton} body={this.state.messageBody} title={this.state.messageTitle} displaymessage={this.state.displayMessage} handleCloseMessageBox={this.handleCloseMessageBox} />

        <Table responsive style={{ fontSize: '12px' }}>
          <thead>
            <tr>
              <th style={{ width: '7%' }}>{componentsStrings.aeyeusers_username_th}</th>
              <th style={{ width: '4%' }}>{componentsStrings.aeyeusers_fullname_th}</th>
              <th style={{ width: '8%' }}>{componentsStrings.aeyeusers_license_th}</th>
              <th style={{ width: '8%' }}>{componentsStrings.aeyeusers_type_th}</th>
              <th style={{ width: '8%' }}>{componentsStrings.aeyeusers_timezone_th}</th>
              <th style={{ width: '6%' }}>{componentsStrings.aeyeusers_email_th}</th>
              <th style={{ width: '7%' }}>{componentsStrings.aeyeusers_emailresults_th}</th>
              <th style={{ width: '4%' }}>{componentsStrings.aeyeusers_lastlogin_th}</th>
              <th style={{ width: '6%' }}>{componentsStrings.aeyeusers_camera_th}</th>
              <th style={{ width: '12%' }}>{componentsStrings.aeyeusers_algos_th}</th>
              <th style={{ width: '12%' }}>{componentsStrings.aeyeusers_clinics_th}</th>
              <th style={{ width: '10%' }}></th>
              <th style={{ width: '10%' }}></th>
              <th style={{ width: '10%' }}></th>
            </tr>
          </thead>
          <tbody id="users_tablebody">
            {this.getTableData()}
          </tbody>
        </Table>
        <div>
          {this.getPagination()}
        </div>
      </React.Fragment>
    );
  }

  getNumberOfImagesTitle() {
    if (this.state.numberofrows > 0) {
      return "Show " + this.state.numberofrows + " out of " + this.state.maxnumberofitems;
    } else {
      return "No entries found";
    }
  }

  getPagination() {
    if (this.state.pagesize !== -1 && this.state.lastpage > 0) {
      let active = this.state.currentactivpage;
      let currentpagegroup = (Math.floor)(this.state.currentactivpage / 5) + 1;
      let maxpagegroups = (Math.floor)(this.state.lastpage / this.numberOfPagesDisplayed);
      if ((this.state.lastpage % this.numberOfPagesDisplayed) > 0) {
        maxpagegroups += 1
      }
      let disableFirst = false;
      if (currentpagegroup == 1) {
        disableFirst = true;
      }
      let disablePrev = false;
      if (active == 0) {
        disablePrev = true;
      }
      let disableLast = false;
      if ((active + this.numberOfPagesDisplayed) >= this.state.lastpage) {
        disableLast = true;
      }
      let disableNext = false;
      if (active + 1 == this.state.lastpage) {
        disableNext = true;
      }

      let items = [];
      let lastpage = currentpagegroup * 5
      let firstpage = lastpage - 5
      if (this.state.lastpage < lastpage) {
        lastpage = this.state.lastpage;
      }
      if (currentpagegroup > 1) {
        items.push(<Pagination.Item onClick={() => { this.handlePageClick(1) }} key={1} active={0 === active}>{1}</Pagination.Item>);
        items.push(<Pagination.Ellipsis disabled />);
      }
      for (let number = firstpage; number < lastpage; number++) {
        let thenumber = number + 1;
        items.push(<Pagination.Item onClick={() => { this.handlePageClick(thenumber) }} key={thenumber} active={number === active}>{thenumber}</Pagination.Item>,);
      }
      if (currentpagegroup < maxpagegroups) {
        items.push(<Pagination.Ellipsis disabled />);
        items.push(<Pagination.Item onClick={() => { this.handlePageClick(this.state.lastpage) }} key={this.state.lastpage} active={this.state.lastpage === active}>{this.state.lastpage}</Pagination.Item>);
      }
      return (
        <Pagination>
          <Pagination.First disabled={disableFirst} onClick={() => { this.handlePaginationFirst() }} />
          <Pagination.Prev disabled={disablePrev} onClick={() => { this.handlePaginationPrev() }} />
          {items}
          <Pagination.Next disabled={disableNext} onClick={() => { this.handlePaginationNext() }} />
          <Pagination.Last disabled={disableLast} onClick={() => { this.handlePaginationLast() }} />
        </Pagination>
      );
    } else {
      return "";
    }
  }

  getTableData() {
    if (this.state.aeyetabledata && this.state.aeyetabledata.data && this.state.aeyetabledata.data.length > 0) {
      return this.state.aeyetabledata.data.map((row, index) => {
        const { username, fullname, license, usertype, timezone, email, emailresults, lastlogin, camera, algorithem, clinics, editable, deletable, authmode, userdbid } = row;
        var usertypedisp = "";
        switch (usertype) {
          case 'C':
            usertypedisp = componentsStrings.aeyeusers_usertypedesc_clinic;
            break;
          case 'A':
            usertypedisp = componentsStrings.aeyeusers_usertypedesc_admin;
            break;
          case 'U':
            usertypedisp = componentsStrings.aeyeusers_usertypedesc_uploadonly;
            break;
          case 'D':
            usertypedisp = componentsStrings.aeyeusers_usertypedesc_doconly;
            break;
          case 'R':
            usertypedisp = componentsStrings.aeyeusers_usertypedesc_researcher;
            break;
          case 'P':
            usertypedisp = componentsStrings.aeyeusers_usertypedesc_partner;
            break;
          case 'H':
            usertypedisp = componentsStrings.aeyeusers_usertypedesc_hospital;
            break;
        }
        return (
          <tr key={index}>
            <td id="users_body_username">{username}</td>
            <td>{fullname}</td>
            <td>{license}</td>
            <td>{usertypedisp}</td>
            <td>{timezone}</td>
            <td>{email}</td>
            <td>{emailresults}</td>
            <td>{lastlogin}</td>
            <td>{camera}</td>
            <td>{algorithem}</td>
            <td>{clinics}</td>
            <td id="users_body_edituser">
              {this.getEditableButton(editable, userdbid)}
            </td>
            <td>
              {this.getDeletableButton(deletable, userdbid)}
            </td>
            <td id="users_body_inviteuser">
              {this.getAuthModeButton(authmode, userdbid, email, username)}
            </td>
          </tr>
        );
      });
    } else {

    }
  }

  getEditableButton = (editable, userdbid) => {
    if (editable) {
      return <img src="/pencil-red-icon.png" width="20" height="20" onClick={this.editUser} userdbid={userdbid} />
    } else {
      return "";
    }
  };

  getDeletableButton = (deletable, userdbid) => {
    if (deletable) {
      return <img src="/Trash-icon.png" width="20" height="20" onClick={this.deleteUser} userdbid={userdbid} />
    } else {
      return "";
    }
  };

  getAuthModeButton = (authmode, userdbid, email, username) => {
    if (authmode == 0) {
      return (
        <img src="/Send-Email.png" width="20" height="20" onClick={this.inviteUser} userdbid={userdbid} email={email} username={username} />
      )
    } else {
      return "";
    }
  };

  openNewUser = (deletable) => {
    if (this.state.isDialogOpen == true) {
      return (
        <AddNewUser sessionData={this.props.sessionData} callbyclinic={this.state.callbyclinic} aeyetablealldata={this.state.aeyetablealldata} policytypes={this.state.policytypes} diseases={this.state.diseases} username={this.props.username} session={this.props.session} isDialogOpen={this.state.isDialogOpen} edituserdbid={0} onClose={this.onCloseNewUser}></AddNewUser>
      );
    } else {
      return ("");
    }
  }

  openEditUser = (deletable) => {
    if (this.state.isDialogEdit == true) {
      return (
        <AddNewUser sessionData={this.props.sessionData} callbyclinic={this.state.callbyclinic} aeyetablealldata={this.state.aeyetablealldata} policytypes={this.state.policytypes} diseases={this.state.diseases} username={this.props.username} session={this.props.session} isDialogOpen={this.state.isDialogEdit} edituserdbid={this.state.edituserdbid} onClose={this.onCloseEditUser}></AddNewUser>
      );
    } else {
      return ("");
    }
  }

  confirmDeleteUser() {
    return (
      <Modal show={this.state.displayConfirmDeleteUser}>
        <Modal.Header>
          <Modal.Title>{componentsStrings.aeyeusers_deleteuser_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{componentsStrings.aeyeusers_deleteuser_body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleNoConfirmDeleteUser}>
          {componentsStrings.button_no}
          </Button>
          <Button variant="secondary" onClick={this.handleYesConfirmDeleteUser}>
          {componentsStrings.button_yes}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  deleteUserWait() {
    return (
      <Modal show={this.state.displayDeleteUserWait}>
        <Modal.Header>
          <Modal.Title>{componentsStrings.aeyeusers_deleteuser_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{componentsStrings.aeyeusers_deleteuserwait_body}</Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    );
  }

  deleteUserDoneMsg() {
    return (
      <Modal show={this.state.displayDeleteUserDoneMsg}>
        <Modal.Header>
          <Modal.Title>{this.state.deleteUserDoneMsgTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.state.deleteUserDoneMsgBody}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleDeleteUserDoneMsgOK}>
            {componentsStrings.button_ok}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default AEYEUsers;