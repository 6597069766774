import React, { Component } from "react";
import "../../css/aeyehealth.css"
import "../../css/colors.css"
import "../../css/texts.css"
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown"
import componentsStrings from "../../Messages/Components";
import { Col, Modal } from "react-bootstrap";
import texts from "./Texts";


const avatarStyle = {
  display: 'inline-block',
  verticalAlign: 'middle', 
  width: '6vh',
  height: '3.5vh',
  borderRadius: '100%',
  fontFamily: 'Helvetica, Arial, sans-serif'
}

const imgStyle = {
  maxWidth: '100%',
  width: '3.5vh',
  height: '3.5vh', 
  borderRadius: '100%'
}


class NavBarNew extends Component {
  
  state = {
    displayAboutMsg: false
  };

  componentDidMount() {
    let pictures = ['/help.png' , '/logout.png' ];

    pictures.forEach((picture) => {
      const img = new Image();
      img.src = picture;
    });
  }


  render() {

    return (
      <React.Fragment>

        {this.displayAboutMessageBox()}   

        <Row className="justify-content-between bg-black bottom-border mr-0" style={{height: "7%"}}>
          
            <div className="d-flex align-items-center">
              <img className="logo-img ml-5" src="/logo_new.png"/>
            </div>

            <div className="d-flex align-items-center pl-2 left-border"> 
              
              <div style={avatarStyle}>
                <img className=" sb-avatar__image" src="/avatar.png" style={imgStyle}/>
              </div>

              <Dropdown>
                <Dropdown.Toggle id="navbar_userbutton_toggle" className="text"> {this.props.fullname} </Dropdown.Toggle>
                
                <Dropdown.Menu className="mt-2" id="navbar_userbutton_menu">

                  {this.props.showReport === true && <Dropdown.Item id="navbar_mainbutton_report" href="/report">
                    <div className="gray_3"> <img src='/help.png' className="mr-2 pr-1" ></img> {componentsStrings.button_reports} </div>
                  </Dropdown.Item>}

                  {this.props.showSettings === true && <Dropdown.Item id="navbar_mainbutton_settings" href="/legacy/settings">
                    <div className="gray_3"> <img src='/help.png' className="mr-2 pr-1" ></img> {componentsStrings.button_settings} </div>
                  </Dropdown.Item>}

                  <Dropdown.Item id="navbar_mainbutton_usermanual" href="/help" target="_blank">
                    <div className="gray_3"> <img src='/help.png' className="mr-2 pr-1" ></img> {componentsStrings.button_help} </div>
                  </Dropdown.Item>

                  <Dropdown.Item id="navbar_mainbutton_about" onClick = {this.handleClickAbout}>
                    <div className="gray_3"> <img src='/about.png' className="mr-2 pr-1" ></img> {componentsStrings.button_about} </div>
                  </Dropdown.Item>

                  <Dropdown.Item id="navbar_logout" href="/logout">                    
                    <div className="gray_3"> <img src='/logout.png' className="mr-2"></img> {componentsStrings.button_logout} </div>                    
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </div>

        </Row>

      </React.Fragment>
    );
  }

  openHelp = () => {
    window.open("https://www.aeyehealth.com/876340tha9259njet-juu9975367", "");
  }

  
  handleClickAbout = () => {
    this.setState({ displayAboutMsg: true });
  }


  displayAboutMessageBox() {
    return(
      <Modal show={this.state.displayAboutMsg} centered dialogClassName={"iiq-popup"}>

        <Modal.Header>

          <Modal.Title> {texts.messagebody_popup_about_labal} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body style={{whiteSpace: 'pre-line'}}>
          {texts.messagebody_popup_about_body}
        </Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleCloseAboutMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>         
    );
  }

  handleCloseAboutMessageBox = () => {
    this.setState({ displayAboutMsg: false });
  }

}

export default NavBarNew;
