import React, { Component } from "react";
import { Col } from "react-bootstrap";
import "../../css/general.css"
import "../../css/eye_input.css"
import "../../css/colors.css"
import "../../css/texts.css"
import messages from "../../Messages/Messages";
import ReactLoading from 'react-loading';
import texts from "../New/Texts";


class EyeInput extends Component {

  state = {
    showZoom: false
  };

  componentDidMount() {

  }

  openZoom = () => {

    this.setState({showZoom: true});
  }

  closeZoom = () => {

    this.setState({showZoom: false});
  }

  render() {
    let backgroundImage = this.props.image && this.props.image.background ? this.props.image.background : '/eye_frame.png';
    let opacity = '1';
    let isLoaded = false;

    if(this.props.image && this.props.image.loaded === true) {
      isLoaded = true;
    }

    if(this.props.identicalnames === true) {
      backgroundImage = '/identicalnames_frame.png';
    }

    if(this.props.image && this.props.image.iiq) {
      backgroundImage = '/iiq_frame.png';
      opacity = '0.5';
    }

    const imageBackground = {
      backgroundImage: "url('" + backgroundImage + "')"
    }

    let displaySpinner = this.props.displayUploadImagesSpinner === true || (this.props.diagnosed === 0 && this.props.exam && this.props.exam.diagstate !== -1) || isLoaded === true;
    let displayIIQ_text = this.props.isDisplayMode == false && this.props.displayUploadImagesSpinner === false && this.props.image && this.props.image.iiq == 1;
    let displayIiqStatus = this.props.image && this.props.image.name && this.props.image.iiq != undefined;

    let labelClassName = "fs26 text blue_1 mt-1 mb-0"
    let imageNameClassName = "text-center fs18 gray_3"

    if(this.props.identicalnames === true) {
      imageNameClassName = "text-center fs18 red"
    }

    if(this.props.image && this.props.image.iiq) {
      labelClassName = "fs26 lh30 text red mb-2"
    }



    return (
      <React.Fragment>

        {this.state.showZoom === true && <div>

          <div class="backdrop zoom"></div>

          <div className="zoom-window-location" style={imageBackground}>
            
            <img className="overflow-hidden" src={this.props.image && this.props.image.src} alt={''} style={{ height: 'auto', width: 'inherit' }} />

            <div className="close-zoom-icon-location">
              <img src={"/X.png"} height={'20'} width={'auto'} alt={''} onClick={this.closeZoom} style={{cursor: 'pointer' }}/>
            </div>

          </div>

        </div>}

        <Col className={this.props.colStyle}>
        
          <div className="d-flex flex-column align-items-center" style={{ width: '89.3%' }}>

            <label className={"d-flex flex-column bg-image mb-3 pb-3 "} style={ imageBackground } onDragOver={this.props.onDragOver} onDrop={this.props.onDrop} onDragStart = {this.props.onDragStart}>
              
              {this.props.isDisplayMode == false && this.state.showZoom === false && <input className="d-none" type="file" id={this.props.inputid} onInput={this.props.onUpload} onClick={this.props.handleClick} accept="image/*" />}
              

              <img className="overflow-hidden " style={{ opacity: opacity, width: '98%'}} src={this.props.image ? this.props.image.src : ''} alt={''} onLoad={this.props.onLoad}/>
              

              {displayIIQ_text && <div className="text iiq-upload-image">  {texts.mainscreen_on_eye_iiq_text} </div> }
              {displaySpinner && <div className="spinner-location">
                  <ReactLoading type={"spokes"} color="#ffffff"  />
                </div>}

              {this.props.image && this.props.image.content && !isLoaded && <div className="zoom-icon-location">
                <img src={"/zoom.png"} height={'30'} width={'auto'} alt={''} onClick={this.openZoom} style={{cursor: 'pointer' }}/>
              </div>}

            </label>

            <label className={labelClassName}>{this.props.label}</label>

            <div className={imageNameClassName}>
              {!displayIiqStatus && this.props.image && this.props.image.name}
              {displayIiqStatus && !isLoaded && (this.props.image.name + (this.props.image.iiq ? ' - ' + messages.uploadpatient_uploadimages_iiq_title : ' - ' + messages.uploadpatient_uploadimages_q_title))}
            </div>

          </div>

        </Col>

      </React.Fragment>
    );
  }

}

export default EyeInput;
