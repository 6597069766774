import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../css/aeyehealth.css"
import Nav from "react-bootstrap/Nav";
import AEYEUsers from "../Tables/AEYEUsers"
import SettingsNewUser from '../settings/settingsformnewuser'
import componentsStrings from "../../Messages/Components";

class SettingsForm extends Component {
    state = {
        username: "",
        session: "",
        settingtype: "u",
        activeSettingsUsers: false,
        activeSettingsSystemUser: false,
        activeSettingsAlgorithms: false,
        activeSettingsCameras: false,
        diaplaySettings: 'none',
        diaplaySettingsUsers: 'none',
        diaplaySystemUser: 'none',
        diaplayAlgorithms: 'none',
        diaplayCameras: 'none',
        diaplaySettingsUsersNewUser: 'none'
    };
        
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.setState({username: this.props.username, session: this.props.session});
        this.setState({diaplaySettings: 'block'});
        this.setState({activeSettingsUsers: true});
        this.setState({diaplaySettingsUsers: 'block'});
        this.setState({diaplaySettingsUsersNewUser: 'None'});
    }
    newuserdone = () => {
        this.setState({diaplaySettings: 'block'});
        this.setState({activeSettingsUsers: true});
        this.setState({diaplaySettingsUsers: 'block'});
        this.setState({diaplaySettingsUsersNewUser: 'None'});
    }
    navUsers = (eventKey, event) => {
        this.setState({diaplaySettings: 'block'});
        this.setState({diaplaySettingsUsers: 'block'});
        this.setState({diaplaySettingsUsersNewUser: 'none'});
        this.setState({diaplaySystemUser: 'none'});
        this.setState({diaplayAlgorithms: 'none'});
        this.setState({diaplayCameras: 'none'});

        this.setState({activeSettingsUsers: true});
        this.setState({activeSettingsSystemUser: false});
        this.setState({activeSettingsAlgorithms: false});
        this.setState({activeSettingsCameras: false});
    };
    navSystemUser = (eventKey, event) => {
        this.setState({diaplaySettings: 'block'});
        this.setState({diaplaySettingsUsers: 'none'});
        this.setState({diaplaySettingsUsersNewUser: 'none'});
        this.setState({diaplaySystemUser: 'block'});
        this.setState({diaplayAlgorithms: 'none'});
        this.setState({diaplayCameras: 'none'});

        this.setState({activeSettingsUsers: false});
        this.setState({activeSettingsSystemUser: true});
        this.setState({activeSettingsAlgorithms: false});
        this.setState({activeSettingsCameras: false});
    };
    navAlgorithems = (eventKey, event) => {
        this.setState({diaplaySettings: 'block'});
        this.setState({diaplaySettingsUsers: 'none'});
        this.setState({diaplaySettingsUsersNewUser: 'none'});
        this.setState({diaplaySystemUser: 'none'});
        this.setState({diaplayAlgorithms: 'block'});
        this.setState({diaplayCameras: 'none'});

        this.setState({activeSettingsUsers: false});
        this.setState({activeSettingsSystemUser: false});
        this.setState({activeSettingsAlgorithms: true});
        this.setState({activeSettingsCameras: false});
    };
    navCameras = (eventKey, event) => {
        this.setState({diaplaySettings: 'block'});
        this.setState({diaplaySettingsUsers: 'none'});
        this.setState({diaplaySettingsUsersNewUser: 'none'});
        this.setState({diaplaySystemUser: 'none'});
        this.setState({diaplayAlgorithms: 'none'});
        this.setState({diaplayCameras: 'block'});

        this.setState({activeSettingsUsers: false});
        this.setState({activeSettingsSystemUser: false});
        this.setState({activeSettingsAlgorithms: false});
        this.setState({activeSettingsCameras: true});
    };
    navNewUser = (eventKey, event) => {
        this.setState({diaplaySettings: 'none'});
        this.setState({diaplaySettingsUsers: 'none'});
        this.setState({diaplaySettingsUsersNewUser: 'block'});
        this.setState({diaplaySystemUser: 'none'});
        this.setState({diaplayAlgorithms: 'none'});
        this.setState({diaplayCameras: 'none'});

        this.setState({activeSettingsUsers: true});
        this.setState({activeSettingsSystemUser: false});
        this.setState({activeSettingsAlgorithms: false});
        this.setState({activeSettingsCameras: false});
    };
    
    render() {
        return (
            <div id="rcorners3" style={{overflow: 'auto'}}>
                <div style={{padding: '100px', display: this.state.diaplaySettingsUsersNewUser}}>
                    <Row>
                        <Col style={{flexBasis: '17%'}}>
                            <Nav variant="pills" defaultActiveKey="/settings?type=newuser" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link active={true} eventKey="users" href="/settings?type=newuser">{componentsStrings.settingsform_newuser_navlink}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col style={{flexBasis: '1%'}}>
                            <div style={{borderLeft: '6px solid green', height: '100%'}}></div>
                        </Col>
                        <Col style={{flexBasis: '80%', display: true}}>
                            <SettingsNewUser sessionData={this.props.sessionData} session={this.props.session} username={this.props.username} newuserDone={this.newuserdone}></SettingsNewUser>
                        </Col>
                    </Row>
                </div>
                <div style={{padding: '100px', display: this.state.diaplaySettings}}>
                    <Row>
                        <Col md="auto">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link active={this.state.activeSettingsUsers} eventKey="users" onSelect={this.navUsers}>{componentsStrings.settingsform_users_navlink}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link active={this.state.activeSettingsSystemUser} eventKey="systemuser" onSelect={this.navSystemUser}>{componentsStrings.settingsform_systemuser_navlink}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link active={this.state.activeSettingsAlgorithms} eventKey="algorithms" onSelect={this.navAlgorithems}>{componentsStrings.settingsform_algo_navlink}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link active={this.state.activeSettingsCameras} eventKey="cameras" onSelect={this.navCameras}>{componentsStrings.settingsform_cameras_navlink}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col md="auto">
                            <div style={{borderLeft: '6px solid green', height: '100%'}}></div>
                        </Col>
                        <Col md="auto" style={{display: this.state.diaplaySettingsUsers}}>
                            <AEYEUsers sessionData={this.props.sessionData} systemusers={false} callbyclinic={false} session={this.props.session} username={this.props.username} sessionData={this.props.sessionData}></AEYEUsers>
                        </Col>
                        <Col md="auto" style={{display: this.state.diaplaySystemUser}}>
                            <AEYEUsers sessionData={this.props.sessionData} systemusers={true} callbyclinic={false} session={this.props.session} username={this.props.username} sessionData={this.props.sessionData}></AEYEUsers>
                        </Col>
                        <Col md="auto" style={{display: this.state.diaplayAlgorithms}}>
                            {/* <AEYEAlgos sessionData={this.props.sessionData} session={this.props.session} username={this.props.username}></AEYEAlgos> */}
                        </Col>
                        <Col md="auto" style={{display: this.state.diaplayCameras, width: '80%'}}>
                            {/* <AEYEAllCameras sessionData={this.props.sessionData} session={this.props.session} username={this.props.username}></AEYEAllCameras> */}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default SettingsForm;
