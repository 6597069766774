import React, { Component } from "react";
import errorcodes from "../errorcodes";
import errormessages from "../../Messages/ErrorMessages";
import componentsStrings from "../../Messages/Components";
import connections from "../../connections";
import { Col, Form, Row } from "react-bootstrap";
import texts from "../New/Texts";
import "../../css/colors.css"
import "../../css/texts.css"
import { Redirect } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import properties from "../../properties";


const recaptchaRef = React.createRef();


class ForgetNew extends Component {

  state = {
    username: "",
    email: "",
    forgotenabled: false,
    emailSent: false,
    failUsernameMsg: "",
    failEmailMsg: "",
    failCaptchaMsg: "",
    redirectToLogin: false ,
    captchaValue: ""
  };

  onChangeRecaptcha  = event => {
    this.setState({ captchaValue: event, failCaptchaMsg: ""});
  }

  handleSubmit = event => {
    
    this.setState({ failUsernameMsg: "", failEmailMsg: "" });

    event.preventDefault();

    if(this.state.captchaValue === '') {
      this.setState({ failCaptchaMsg: errormessages.forgetform_failToLogin_captcha });
    //   return;
    } 
    else {
      let url = connections.sdkurl;
      let mythis = this;
      fetch(url + "/AEYEAppServer/v1/UserRepository/forgetNew", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.state.username,
          email: this.state.email,
          url: connections.forgotpasswordurl,
          captchavalue: this.state.captchaValue
        })
      })
        .then(response => response.json())
        .then(data => {
          if (data.errorcode !== errorcodes.errorOK) {
            this.setState({failEmailMsg: errormessages.forgetform_failToLogin_email, failUsernameMsg: errormessages.forgetform_failToLogin_username});
          } else {
            this.setState({ emailSent: true, failEmailMsg: "", failUsernameMsg: "" });
          }
        }).catch(function(error) {
          mythis.setState({failEmailMsg: errormessages.forgetform_failToLogin_email, failUsernameMsg: errormessages.forgetform_failToLogin_username });
        });
    }

  };

  handleUsernameChange = event => {
    if(event.target.value === "" || this.state.email === "") {
      this.setState({ forgotenabled: false });
    } else {
      this.setState({ forgotenabled: true });
    }
    this.setState({ username: event.target.value, failEmailMsg: "", failUsernameMsg: "" });
  };

  handleEmailChange = event => {
    if(event.target.value === "" || this.state.username === "") {

      this.setState({ forgotenabled: false });
    } else {
      this.setState({ forgotenabled: true });
    }
    this.setState({ email: event.target.value, failEmailMsg: "", failUsernameMsg: "" });
  };

  handleBackToLoginPageClick = event => {

    this.setState({ redirectToLogin: true });
  };


  render() {

    if(this.state.redirectToLogin) {
      return <Redirect to={"/"} />
    }

    return (
      <React.Fragment>
        {this.getMainWindow()}
    </React.Fragment>
    );
  }

  getMainWindow() {

    if(this.state.emailSent === true) {

      let bodytext = texts.forgotscreen_mailsent_text1;
      bodytext = bodytext.replace("$$email$$", this.state.email);

      return (
        <React.Fragment>
          <div className="login-bg">
  
            <Col md={6} style={{paddingTop: '24vh'}}>

              <Row className="pb-4">
                <Col align="center">
                  <img className="login-logo" src={"/logo_new.png"}></img>
                </Col>
              </Row>     
  
              <Row className="pb-5">
                <Col align="center">
  
                  <div className="text white lh44 fs40"> {texts.forgotscreen_mailsent_title} </div>
                  <div className="text gray_3 lh24 fs20 pt-4"> {bodytext}  <br/> {texts.forgotscreen_mailsent_text2} </div>

                </Col>            
              </Row> 

              <Row>
                <Col align="center">

                  <button class="pwd-container form-field btn btn-primary button" type="submit" onClick={this.handleBackToLoginPageClick}> Back to login page </button>
               
                </Col>       
              </Row>

            </Col>          

          </div>
        </React.Fragment>
      );
    }
    else {

      return (
        <React.Fragment>

          <div className="login-bg" style={{overflowY: 'auto'}}>
          
            <Col md={6} style={{paddingTop: '24vh'}}>

              <Row className="pb-4">
                <Col align="center">
                  <img className="login-logo" src={"/logo_new.png"}></img>
                </Col>
              </Row>     
  
              <Row className="pb-5">
                <Col align="center">
                  <div className="text white lh44 fs40"> {texts.forgotscreen_requestmail_title} </div>
                </Col>            
              </Row> 
  
              <Row>
                <Col align="center">
  
                    <Form onSubmit={this.handleSubmit}>

                      <Form.Group className="pwd-container form-field pb-3">
    
                        <input class="login-input" id="forgetform_username" type="text" placeholder={componentsStrings.messagebody_forgotmsg_username_placeholder} onChange={this.handleUsernameChange}/>
                        <div align="left" class="gray_3"> {texts.forgotscreen_requestmail_textusername} </div>

                        {this.state.failUsernameMsg != '' && <div align="left" >
                          <img src={"/exclamation_mark.png"} style={{position: 'unset'}}/>
                          <div class="d-md-inline-flex pt-1 pl-1 red " id="staticEmail" > {this.state.failUsernameMsg} </div>
                        </div>}

                      </Form.Group>

                      <Form.Group className="pwd-container form-field pb-3">
  
                        <input class="login-input" id="forgetform_email" type="email" placeholder={componentsStrings.messagebody_forgotmsg_email_placeholder} onChange={this.handleEmailChange}/>
                        <div align="left" class="gray_3"> {texts.forgotscreen_requestmail_textemail} </div>

                        {this.state.failEmailMsg != '' && <div align="left" >
                          <img src={"/exclamation_mark.png"} style={{position: 'unset'}}/>
                          <div class="d-md-inline-flex pt-1 pl-1 red " id="staticEmail" > {this.state.failEmailMsg} </div>
                        </div>}

                      </Form.Group>

                      <Form.Group className="pwd-container form-field pb-3">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={properties.recaptcha_sitekey}
                          onChange={this.onChangeRecaptcha}
                        />

                      </Form.Group>

                      <Form.Group className="pwd-container form-field pb-3">
                        
                        {this.state.failCaptchaMsg != '' && <div class="d-inline-flex pb-3" align="left" style={{float: 'left'}}>
                          <img src={"/exclamation_mark.png"} style={{position: 'unset', height: 'min-content', marginTop: '4px'}}/>
                          <div class="d-md-inline-flex pt-1 pl-1 red" id="staticEmail" > {this.state.failCaptchaMsg} </div>
                        </div>}
                        
                        <button disabled={!this.state.forgotenabled} class="pwd-container form-field btn btn-primary button" type="submit"> {componentsStrings.messagebody_forgotmsg_request} </button>
                        
                      </Form.Group>
                      
                      
                    </Form>
  
                </Col>  
              </Row> 

            </Col>
  
          </div>
        </React.Fragment>
      );
    }

  }
}

export default ForgetNew;
