import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Spinner from 'react-bootstrap/Spinner'

import errorcodes from "../errorcodes";
import errormessages from "../../Messages/ErrorMessages";
import messages from "../../Messages/Messages";
import componentsStrings from "../../Messages/Components";
import "../../css/colors.css"
import "../../css/texts.css"

import { Redirect } from "react-router-dom";
import connections from "../../connections";
import showPass from '../../showPass.png';
import hidePass from '../../hidePass.png';
import { Modal } from "react-bootstrap";


class LoginFormNew extends Component {
  
  state = {
    loginerrormsg: "",
    username: "",
    fullname: "",
    password: "",
    clearpassword: "",
    redirectToMain: false,
    redirectToUpload: false,
    redirectToError: false,
    errormessage: "",
    loginenabled: false,
    isRevealPwd: false,
    externalloginmsg: "",
    funcres: "", 
    policies: null,
    failUsernameMsg: "",
    failPasswordMsg: "",
    displayLoginfailedError: false,
    loginfailedErrorMessage: ""
  };

  componentDidMount = () => {
    sessionStorage.clear();
    if(this.props.clinicuser && this.props.accesskey && this.props.epicuserid && this.props.epicdepid && this.props.orderid && this.props.oauth2state && this.props.dispatcherurl) {
      this.setState({ externalloginmsg: messages.loginform_externallogin });
    }
  }
  
  handleUsernameChange = event => {
    if(event.target.value === "" || this.state.password === "") {
      this.setState({ loginenabled: false });
    } else {
      this.setState({ loginenabled: true });
    }
    this.setState({ username: event.target.value, failUsernameMsg: "", failPasswordMsg: "" });
  };
  
  handlePasswordChange = event => {
    if(event.target.value === "" || this.state.username === "") {
      this.setState({ loginenabled: false });
    } else {
      this.setState({ loginenabled: true });
    }
    let clearpassword = event.target.value;
    
    this.setState({ clearpassword: clearpassword, password: clearpassword, failUsernameMsg: "", failPasswordMsg: "" });
  };

  revealPwd = event => {
    this.setState({isRevealPwd: true});
  } 
  
  hidePwd = event => {
    this.setState({isRevealPwd: false});
  } 
  
  handleSubmit = event => {
    event.preventDefault();
    let url = connections.sdkurl;
    let s = url + "/AEYEAppServer/v1/UserRepository/login";
    
    if(this.props.testreport) {
      s = url + "/AEYEAppServer/v1/UserRepository/loginreport";
    }
    
    let mythis = this;
    fetch(s , {
      method: "POST",
      body: JSON.stringify({
        logonid: this.state.username,
        password: this.state.password,
        appversion: "",
        ip: this.props.ipdata
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.errorcode !== errorcodes.errorOK) {

          if(data.errorcode === errorcodes.AEYEHealthSDKSoonLoginWillBeBlocked) {

            this.setState({
              displayLoginfailedError: true,
              loginfailedErrorMessage: errormessages.loginform_failToLogin_soonLoginWillBeBlocked
            });
          }
          else if(data.errorcode === errorcodes.AEYEHealthSDKSoonLoginBlocked) {

            this.setState({
              displayLoginfailedError: true,
              loginfailedErrorMessage: errormessages.loginform_failToLogin_loginBlocked
            });
          }
          else {

            this.setState({
              failUsernameMsg: errormessages.loginform_failToLogin_username,
              failPasswordMsg: errormessages.loginform_failToLogin_password,
              redirectToMain: false
            });
          }

        } else {
          this.getuserpolicies(data.session);
        }
      }).catch(function(error) {
        mythis.setState({
          failUsernameMsg: errormessages.loginform_failToLogin_username,
          failPasswordMsg: errormessages.loginform_failToLogin_password,
          redirectToMain: false
        });
      });
  };
  
  getUser = (session) => {

    let url = connections.sdkurl;
    fetch(url + "/AEYEAppServer/v1/UserRepository/getuser", {
      method: "POST",
      body: JSON.stringify({
        logonid: this.state.username,
        session: session
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.errorcode !== errorcodes.errorOK) {
          this.setState({
            redirectToMain: false
          });
        } else {
          let userdbid = data.id;
          this.props.OnLogin(session, this.state.username, this.state.username, null, 0, 0, data, null, null, this.state.policies);
          if(data.usertype == 'U') {
            this.setState({ redirectToUpload: true });
          } else {
            this.setState({ redirectToMain: true });
          }
        }
      }
    )

  }

  getuserpolicies = (session) => {

    let url = connections.sdkurl;
    fetch(url + "/AEYEAppServer/v1/UserRepository/getuserpolicies", {
      method: "POST",
      body: JSON.stringify({
        logonid: this.state.username,
        session: session
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.errorcode !== errorcodes.errorOK) {
          this.setState({redirectToMain: false});
        } else {
          let policies = data.policies;
          this.setState({ policies: policies });
          this.getUser(session);
        }
      }
    )

  }
  

  renderRedirect = () => {
    if (this.state.redirectToMain) {

      if(this.state.username.toLowerCase().includes("grayhawk") || this.props.usertype === 'D') {
        return <Redirect to="/legacy/main" />;
      }
      return <Redirect to="/main" />;
    }
    if (this.state.redirectToError) {
      let a = "/error/" + this.state.errormessage;
      return <Redirect to={a} />;
    }
  };
 
  render() {

      return (
        <React.Fragment>

          {this.renderRedirect()}
          {this.loginErrorMessageBox()}          

          <div className="login-bg">

            <Col md={6} style={{paddingTop: '24vh'}}>
              
              <Row className="pb-4">
                <Col align="center">
                  <img className="login-logo" src={"/logo_new.png"}></img>
                </Col>
              </Row>   

              <Row className="pb-5">
                <Col align="center">
                  <div className="text white lh44 fs40"> {componentsStrings.button_login} </div>
                </Col>            
              </Row> 

              <Row>
                <Col align="center">
                  <Form onSubmit={this.handleSubmit}>

                    <Form.Group className="pwd-container form-field pb-3">

                      <input className="login-input" type="text" id="loginform_username" placeholder={componentsStrings.textfiled_placeholder_username} onChange={this.handleUsernameChange}/>

                      {this.state.failUsernameMsg != '' && <div align="left" >
                          <img src={"/exclamation_mark.png"} style={{position: 'unset'}}/>
                          <div class="d-md-inline-flex pt-1 pl-1 red "> {this.state.failUsernameMsg} </div>
                      </div>}

                    </Form.Group>

                    <Form.Group className="pwd-container form-field pb-3">

                      <input className="login-input" type={this.state.isRevealPwd ? "text" : "password"}  id="loginform_userpassword" placeholder={componentsStrings.textfiled_placeholder_password} onChange={this.handlePasswordChange} value={this.state.clearpassword}/>
                      <img style={{marginTop: this.state.isRevealPwd ? '0.3125em': "0px"}} title={this.state.isRevealPwd ? componentsStrings.hideshowicon_showpassword : componentsStrings.hideshowicon_hidepassword} src={!this.state.isRevealPwd ? hidePass : showPass} onMouseDown={this.revealPwd} onMouseUp={this.hidePwd}/>

                      {this.state.failPasswordMsg != '' && <div align="left" >
                          <img src={"/exclamation_mark.png"} style={{position: 'unset'}}/>
                          <div className="d-md-inline-flex pt-1 pl-1 red "> {this.state.failPasswordMsg} </div>
                      </div>}

                    </Form.Group>

                    <Form.Group className="pwd-container form-field pb-3">

                      <button id="loginform_login" disabled={!this.state.loginenabled} className="pwd-container form-field btn btn-primary button w-100" type="submit">{componentsStrings.button_login}</button>

                    </Form.Group>

                    <Form.Group className="form-field">

                      <label type="text" className="form-control-plaintext" readOnly id="loginform_forgotpassword">
                        <u>
                          <a className="text white" href="/forgot">{componentsStrings.link_forgotpwd_new} </a>
                        </u>
                      </label>

                    </Form.Group>

                  </Form>
                </Col>  
              </Row> 

            </Col>
            
          </div>
        </React.Fragment>
      );
    
  }

  loginErrorMessageBox() {
    return(
      <Modal show={this.state.displayLoginfailedError} centered dialogClassName={"iiq-popup"}>
            
        <Modal.Header>

          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>

        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {errormessages.loginform_externalloginfailed} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{this.state.loginfailedErrorMessage}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleLoginErrorMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>      
    );
  }

  handleLoginErrorMessageBox = event => {
    this.setState({
      displayLoginfailedError: false,
      loginfailedErrorMessage: ""    
    })
  }

}

export default LoginFormNew;
