import React, { Component, Fragment} from "react";
import Table from "react-bootstrap/Table";
import Dropdown from 'react-bootstrap/Dropdown'
import Pagination from 'react-bootstrap/Pagination';
import errorcodes from "../errorcodes";
import connections from "../../connections";
import PatientView from '../DiagnoseTool/patientview';
import componentsStrings from "../../Messages/Components";

class AEYEReportRow {
  constructor() {
    this.date = "";
    this.time = "";
    this.diagnosesid = 0;
    this.patientid = "";
    this.clinicfullname = "";
    this.patientdbid = 0;
    this.imagedbid = 0;
    this.clinicname = "";
    this.patientname = "";
    this.patientstatus = "";
    this.diagnosedate = "";
    this.diagnosedtime = "";
    this.diagnoseby = "";
    this.righteye = "";    
    this.lefteye = "";    
    this.patientseyes = "";
    this.archived = false;
  }
}
class AEYEReportData {
  constructor() {
    this.data = [];
  }
}
class AEYETable extends Component {
  state = {
      username: "",
      session: "",
      aeyereportdata: null,
      tofatchData: true,
      fromindex: 0,
      pagesize: 5,
      pagesizetext: 5,
      currentactivpage: 0,
      maxnumberofitems: 0,
      numberofrows: 0,
      lastpage: 1,
      searchCretiria: "",
      reportidselected: -1,
      displayvalue: "All",
      diagnosesid: 0,
      patientstatus: "",
      diagnosed: false,
      clinicfullname: "",
      diagnoseby: "",
      uploadedon: "",
      diagnosedon: "",
      archived: false,
      showPatientView: false,
      patientname: ""
    };
  constructor() {
    super();
    this.numberOfPagesDisplayed = 5;
  }
  componentDidMount() {
      this.setState({username: this.props.username, session: this.props.session});
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.execreport == true) {
      this.setState({reportidselected: this.props.reportidselected, tofatchData: true});
      this.props.reportExecuted();
    }
  }
  handleShowPatientView = (show, patientname, patientdbid, imagedbid, diagnosesid, patientstatus, diagnosed, clinicfullname, diagnoseby, uploadedon, diagnosedon, archived) => {
    this.setState({ showPatientView: show, patientname: patientname, patientdbid: patientdbid, imagedbid: imagedbid, diagnosesid: diagnosesid, patientstatus: patientstatus, diagnosed: diagnosed, clinicfullname: clinicfullname, diagnoseby: diagnoseby, uploadedon: uploadedon, diagnosedon: diagnosedon, archived: archived});
  };
  closepatientview = () => {
    this.setState({ showPatientView: false});
  };
  handlePageSizeSelection = (eventKey, event) => {
    this.setState({ currentactivpage: 0, pagesizetext: event.target.text, pagesize: parseInt(event.target.attributes["value"].value), tofatchData: true });
  };
  handlePaginationNext = () => {
    let fromindex = (this.state.currentactivpage + 1) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage + 1, tofatchData: true});
  }
  handlePaginationPrev = () => {
    let fromindex = (this.state.currentactivpage - 1) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage - 1, tofatchData: true});
  }
  handlePaginationFirst = () => {
    let fromindex = (this.state.currentactivpage - this.numberOfPagesDisplayed) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage - this.numberOfPagesDisplayed, tofatchData: true});
  }
  handlePaginationLast = () => {
    let fromindex = (this.state.currentactivpage + this.numberOfPagesDisplayed) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: this.state.currentactivpage + this.numberOfPagesDisplayed, tofatchData: true});
  }
  handleSearch = event => {
    this.setState({ searchCretiria: event.target.value, tofatchData: true });    
  }
  handlePageClick = (numberClicked) => {
    let fromindex = (numberClicked - 1) * this.state.pagesize;
    this.setState({ fromindex: fromindex, currentactivpage: numberClicked - 1 , tofatchData: true});
  }

    getDesisDetails(desiscode) {
      let desisdetails = componentsStrings.aeyereporttable_desis_unknown;
      switch (desiscode) {
        case 0:
          desisdetails = componentsStrings.aeyereporttable_desis_nothing;
          break;

        case 1:
          desisdetails = componentsStrings.aeyereporttable_desis_dr;
          break;

        case 3:
          desisdetails = componentsStrings.aeyereporttable_desis_iiq;
          break;
    
        case 4:
          desisdetails = componentsStrings.aeyereporttable_desis_missing;
          break;
          
      }
      return desisdetails;
    }
    fetchImages = () => {

      if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimageread) {
        
        if(this.state.tofatchData == true) {
          let url = connections.sdkurl;
          fetch(url + "/AEYEAppServer/v3/Images/getImages", {
            method: "POST",
            body: JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              fromindex: this.state.fromindex,
              pagesize: this.state.pagesize,
              search: this.state.searchCretiria,
              reportid: this.props.reportidselected,
              displayvalue: this.state.displayvalue,
              diagnosedbyfilter: ''
            })
          })
            .then(response => response.json())
            .then(data => {

              if(data.errorcode == errorcodes.AEYEHealthSDKFailedToLogin) {
                this.props.sessionData.showsessiontimeout();
                return;
              }
              
              if (data.errorcode == errorcodes.errorOK) {
                let maxnumberofrows = data.maxnumberofrows;
                let numberofrows = data.numberofrows;
                let aeyereportdata = new AEYEReportData();
                if(maxnumberofrows > 0) {
                  if(data || data.images) {
                    data.images.map((rowdata, index) => {

                      let aeyereportrow = new AEYEReportRow();
                      let created = new Date(rowdata.created); 
                      var timecreated = created.getHours() + ":" + created.getMinutes();
                      var datecreated = created.toDateString();
                      aeyereportrow.date = datecreated;
                      aeyereportrow.time = timecreated
                      aeyereportrow.archived = rowdata.archived;
                      aeyereportrow.clinicfullname = rowdata.clinicfullname;
                      aeyereportrow.diagnosesid = rowdata.diagnosesid
                      aeyereportrow.patientid = rowdata.patientid;
                      aeyereportrow.patientdbid = rowdata.patientdbid;
                      aeyereportrow.imagedbid = rowdata.imagedbid;
                      aeyereportrow.clinicname = rowdata.clinicname;
                      aeyereportrow.patientname = rowdata.patientname;
                      let diagnosedstatus = componentsStrings.aeyereporttable_desisstatus_diagnosed;
                      if(rowdata.diagnosed && parseInt(rowdata.diagnosed) != 1) {
                        diagnosedstatus = componentsStrings.aeyereporttable_desisstatus_pending;
                      }
                      aeyereportrow.patientstatus = diagnosedstatus;
                      let diagnosed = new Date(rowdata.diagnosedon); 
                      var timediagnosed = diagnosed.getHours() + ":" + diagnosed.getMinutes();
                      var datediagnosed = diagnosed.toDateString();
                      aeyereportrow.diagnosedate = datediagnosed;
                      aeyereportrow.diagnosedtime = timediagnosed;

                      let diagnosedBy = componentsStrings.aeyereporttable_diagnosedby_algorithem;
                      if (!Boolean(Number(rowdata.automatic))) {
                        diagnosedBy = rowdata.doctorname;
                      }
      
                      if(rowdata.diseases) {
                        for (const [index, disease] of rowdata.diseases.entries()) {
                        
                          if(disease && disease.diseasename === 'diagDiabeticRetinopathy') {
                            if(disease.side == "L") {
                              aeyereportrow.lefteye = this.getDesisDetails(parseInt(disease.diagnosis));
                            } else if(disease.side == "R") {
                              aeyereportrow.righteye = this.getDesisDetails(parseInt(disease.diagnosis));
                            } else {
                              aeyereportrow.patientseyes = this.getDesisDetails(parseInt(disease.diagnosis));
                            }
                          }
                        };
                      }

                      aeyereportrow.diagnoseby = diagnosedBy;

                      aeyereportdata.data.push(aeyereportrow);
                    });
                  }
                }
                let numberofpages = Math.floor(this.state.maxnumberofitems / this.state.pagesize);
                if((this.state.maxnumberofitems % this.state.pagesize) > 0) {
                  numberofpages += 1;
                }
                this.setState({lastpage: numberofpages, maxnumberofitems: maxnumberofrows, numberofrows: numberofrows});
                this.setState({ aeyereportdata: aeyereportdata, tofatchData: false });
              }
            });
        }
      }
    }
    
    render() {
      if (this.state.showPatientView === true) {
        return (
          <PatientView testreport="" showSendReport={true} showArchiveReport={true} showCloseReport={true} closepatientview={this.closepatientview} report={false} diagnosedon={this.state.diagnosedon} uploadedon={this.state.uploadedon} clinicfullname={this.state.clinicfullname} diagnoseby={this.state.diagnoseby} fullname={this.state.clinicfullname} usertype={this.props.usertype} session={this.props.session} username={this.props.username} patientdbid={this.state.patientdbid} imagedbid={this.state.imagedbid} diagnosesid={this.state.diagnosesid} archived={this.state.archived}></PatientView>
          );
      } else {
        return (
            <React.Fragment>
                {this.fetchImages()}

                <div style={{paddingBottom: '10px'}}>
                      <Dropdown onSelect={this.handlePageSizeSelection}>
                        <label style={{float: 'right'}}>{componentsStrings.aeyereporttable_search_label} 
                            <input onChange={this.handleSearch} type="search"></input>
                        </label>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">{this.state.pagesizetext}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item value="5">5</Dropdown.Item>
                            <Dropdown.Item value="10">10</Dropdown.Item>
                            <Dropdown.Item value="50">50</Dropdown.Item>
                            <Dropdown.Item value="-1">All</Dropdown.Item>
                        </Dropdown.Menu>
                        <label>{this.getNumberOfImagesTitle()}</label>
                    </Dropdown>
                </div>
                <Table responsive style={{fontSize: '12px'}}>
                    <thead>
                        <tr>
                        <th style={{width: '8%'}}>{componentsStrings.aeyereporttable_patient_th}</th>
                        <th style={{width: '7%'}}>{componentsStrings.aeyereporttable_date_th}</th>
                        <th style={{width: '4%'}}>{componentsStrings.aeyereporttable_time_th}</th>
                        <th style={{width: '8%'}}>{componentsStrings.aeyereporttable_patientid_th}</th>
                        <th style={{width: '8%'}}>{componentsStrings.aeyereporttable_clinicname_th}</th>
                        <th style={{width: '8%'}}>{componentsStrings.aeyereporttable_clinicfullname_th}</th>
                        <th style={{width: '8%'}}>{componentsStrings.aeyereporttable_clinicemail_th}</th>
                        <th style={{width: '6%'}}>{componentsStrings.aeyereporttable_status_th}</th>
                        <th style={{width: '7%'}}>{componentsStrings.aeyereporttable_diagnosedate_th}</th>
                        <th style={{width: '4%'}}>{componentsStrings.aeyereporttable_diagnosetime_th}</th>
                        <th style={{width: '6%'}}>{componentsStrings.aeyereporttable_diagnoseby_th}</th>
                        <th style={{width: '12%'}}>{componentsStrings.aeyereporttable_reye_th}</th>
                        <th style={{width: '15%'}}>{componentsStrings.aeyereporttable_leye_th}</th>
                        <th style={{width: '15%'}}>{componentsStrings.aeyereporttable_patient_th}</th>
                        </tr>
                    </thead>
                    <tbody>
                      {this.getTableData()}
                    </tbody>
                </Table>
                <div>
                  {this.getPagination()}
                </div>
            </React.Fragment>
        );
      }
    }
    getNumberOfImagesTitle() {
      if(this.state.numberofrows > 0) {
        return "Show " + this.state.numberofrows + " out of " + this.state.maxnumberofitems;
      } else {
        return "No entries found";
      }
    }
    getPagination() {
      if (this.state.pagesize !== -1 && this.state.lastpage > 0) {
        let active = this.state.currentactivpage;
        let currentpagegroup = (Math.floor)(this.state.currentactivpage / 5) + 1;
        let maxpagegroups = (Math.floor)(this.state.lastpage / this.numberOfPagesDisplayed);
        if((this.state.lastpage % this.numberOfPagesDisplayed) > 0) {
          maxpagegroups += 1
        }
        let disableFirst = false;
        if(currentpagegroup == 1) {
          disableFirst = true;
        }
        let disablePrev = false;
        if(active == 0) {
          disablePrev = true;
        }
        let disableLast = false;
        if((active + this.numberOfPagesDisplayed) >= this.state.lastpage) {
          disableLast = true;
        }
        let disableNext = false;
        if(active + 1 == this.state.lastpage) {
          disableNext = true;
        }

        let items = [];
        let lastpage = currentpagegroup * 5
        let firstpage = lastpage - 5
        if(this.state.lastpage < lastpage) {
          lastpage = this.state.lastpage;
        }
        if(currentpagegroup > 1 ) {
          items.push(<Pagination.Item onClick={() => { this.handlePageClick(1) }} key={1} active={0 === active}>{1}</Pagination.Item>);
          items.push(<Pagination.Ellipsis disabled />);
        }
        for (let number = firstpage; number < lastpage; number++) {
          let thenumber = number + 1;
          items.push(<Pagination.Item onClick={() => { this.handlePageClick(thenumber) }} key={thenumber} active={number === active}>{thenumber}</Pagination.Item>,);
        }
        if(currentpagegroup < maxpagegroups ) {
          items.push(<Pagination.Ellipsis disabled />);
          items.push(<Pagination.Item onClick={() => { this.handlePageClick(this.state.lastpage) }} key={this.state.lastpage} active={this.state.lastpage === active}>{this.state.lastpage}</Pagination.Item>);
        }
        return (
          <Pagination>
            <Pagination.First disabled={disableFirst} onClick={() => { this.handlePaginationFirst() }}/>
            <Pagination.Prev disabled={disablePrev} onClick={() => { this.handlePaginationPrev() }}/>
            {items}
            <Pagination.Next disabled={disableNext} onClick={() => { this.handlePaginationNext() }} />
            <Pagination.Last disabled={disableLast} onClick={() => { this.handlePaginationLast() }}/>
          </Pagination>            
      );
      } else {
        return "";
      }
    }    

      getTableData() {
        if (this.state.aeyereportdata && this.state.aeyereportdata.data && this.state.aeyereportdata.data.length > 0) {
          return this.state.aeyereportdata.data.map((row, index) => {
            const { date, time, patientid, clinicname, patientname, patientstatus, archived, diagnosedate, diagnosedtime, diagnoseby, righteye, lefteye, patientseyes,patientdbid, imagedbid, diagnosesid, clinicfullname, uploadedon, diagnosedon, diagnosed, clinicemail } = row;
              return (
                    <tr>
                    <td>{patientname}</td>
                    <td>{date}</td>
                    <td>{time}</td>
                    <td>{patientid}</td>
                    <td>{clinicname}</td>
                    <td>{clinicfullname}</td>
                    <td>{clinicemail}</td>
                    {this.getPatientStatus(patientname, patientdbid, imagedbid, diagnosesid, patientstatus, diagnosed, clinicfullname, diagnoseby, uploadedon, diagnosedon, archived)}
                    <td>{diagnosedate}</td>
                    <td>{diagnosedtime}</td>
                    <td>{diagnoseby}</td>
                    <td>{righteye}</td>
                    <td>{lefteye}</td>
                    <td>{patientseyes}</td>
                    </tr>
              );
          });
        } else {
          return ("");
        }
      }

      getPatientStatus(patientname, patientdbid, imagedbid, diagnosesid, patientstatus, diagnosed, clinicfullname, diagnoseby, uploadedon, diagnosedon, archived) {
        return (
          <td><a href="javascript:void(0);" onClick={() => { this.handleShowPatientView(true, patientname, patientdbid, imagedbid, diagnosesid, patientstatus, diagnosed, clinicfullname, diagnoseby, uploadedon, diagnosedon, archived) }}>{patientstatus}</a></td>
        );
      }
}

export default AEYETable;