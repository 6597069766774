import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import "../../css/aeyehealth.css"
import UploadPatient from "../upload/uploadpatient"


class UploadForm extends Component {
    state = {
        username: "",
        session: "",
        usertype: "",
        userdbid: 0,
        activeUploadPatient: true
    };
        
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.setState({username: this.props.username, session: this.props.session, usertype: this.props.usertype, userdbid: this.props.userdbid});
    }
    navUploadPatient = (eventKey, event) => {
        this.setState({diaplayUploadPathient: 'block'});

        this.setState({activeUploadPatient: true});
    };

    render() {
        return (
            <React.Fragment>
                <Col style={{ height: '100%', display: this.state.diaplayUploadPathient}}>
                    <UploadPatient epicdepid={this.props.epicdepid} sessionData={this.props.sessionData} dispatcherurl={this.props.dispatcherurl} newexam={this.props.newexam} oauth2state={this.props.oauth2state} clinicuser={this.props.clinicuser} patientid={this.props.patientid} patientname={this.props.patientname} examid={this.props.examid} operatordbid={this.props.operatordbid} examdbid={this.props.examdbid} username={this.props.username} session={this.props.session} usertype={this.props.usertype} userdbid={this.props.userdbid} cookies={this.props.cookies}></UploadPatient>
                </Col>
            </React.Fragment>
        );
    }
}

export default UploadForm;
