import React, { Component } from "react";
import NavBar from "./components/navbar";
import "./App.css";
import MainForm from "./components/Main/mainform";
import LogoutForm from "./components/logoutform";
import UploadForm from "./components/upload/uploadform";
import { BrowserRouter, Switch, Route, useParams } from "react-router-dom";
import SettingsForm from "./components/settings/settingsform"
import { useLocation } from 'react-router-dom';
import {withCookies} from 'react-cookie';
import { Redirect } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import messages from "./Messages/Messages";
import componentsStrings from "./Messages/Components";
import NavBarNew from "./components/New/navbarnew";
import MainFormNew from "./components/New/mainformNew";
import LoginFormNew from "./components/New/loginform_new";
import RegistrationFormNew from "./components/New/registerationformNew";
import ForgetNew from "./components/New/forgetformNew";
import RecoverNew from "./components/New/recoverformNew";
import UserManualForm from "./components/userManualForm";
import ReportForm from "./components/Report/reportform";


class App extends Component {
  
  state = {
    showSignUp: false,
    showForget: true,
    showLogout: false,
    showHelp: false,
    showUserButton: false,
    showReport: false,
    showSettings: false,
    showCameras: false,
    showusersettings: false,
    showReports: false,
    showBack: false,
    showClose: false,
    showEpicExams: false,
    showusers: false,
    username: "",
    examid: "",
    examdbid: 0,
    patientname: null,
    patientid: null,
    operatordbid: 0,
    clinicuser: null,
    fullname: "",
    session: "",
    userdbid: 0,
    usertype: "",
    uploadtype: "",
    settingtype: "",
    htmlhelp: "",
    addnewpatient: false,
    internalexam: false,
    externalexam: false,
    oauth2state: "",
    timezone: '',
    doctorlinkedtoclinic: false,
    dispatcherurl: null,
    ishospitalsession: false,
    sessionData: null,
    newexam: true,
    epicdepid: "",
    displaySessionTimeout: false,
    getipdata: false,
    ipdata: "",
    testreport: null,
    fromnewmain: null,
    newmainclinicname: '', 
    newmaindataondate: '',
    newmaindiagnose: ''
  };
  
  componentDidMount() {

    const {cookies} = this.props;
    let cookiesSession = cookies.get('session');

    var navigationType = (window.performance.getEntriesByType("navigation")[0]).type;

    if(navigationType == 'back_forward' || (navigationType == 'reload' && (cookiesSession === undefined || cookiesSession === '')) ) {
      sessionStorage.setItem('session', '');
      sessionStorage.setItem('userdbid', 0);
      sessionStorage.setItem('usertype', '');
      sessionStorage.setItem('username', '');
    }

    const history = this.props.history;
    window.addEventListener("popstate", () => {
      history.go(1);
    });

    if (window.performance) {
      if (performance.navigation.type == 1) {

        let clinicuser = null;
        let accesskey = null;
        let epicuserid = null;
        let epicdepid = null;
        let orderid = null;
        let dispatcherurl = null;
        let state = null;
        let ipdata = null;
        if(sessionStorage.getItem("clinicuser") != null) {
          clinicuser = sessionStorage.getItem("clinicuser")
        }
        if(sessionStorage.getItem("accesskey") != null) {
          accesskey = sessionStorage.getItem("accesskey")
        }
        if(sessionStorage.getItem("epicuserid") != null) {
          epicuserid = sessionStorage.getItem("epicuserid")
        }
        if(sessionStorage.getItem("epicdepid") != null) {
          epicdepid = sessionStorage.getItem("epicdepid")
        }
        if(sessionStorage.getItem("orderid") != null) {
          orderid = sessionStorage.getItem("orderid")
        }
        if(sessionStorage.getItem("dispatcherurl") != null) {
          dispatcherurl = sessionStorage.getItem("dispatcherurl")
        }
        if(sessionStorage.getItem("state") != null) {
          state = sessionStorage.getItem("state")
        }
        if(sessionStorage.getItem("ipdata") != null) {
          ipdata = sessionStorage.getItem("ipdata")
        }
    
        if(clinicuser && accesskey && epicuserid && epicdepid && orderid && dispatcherurl) {
          window.location.replace("/" + clinicuser + "/" + accesskey + "/" + epicuserid + "/" + epicdepid + "/" + orderid + "/" + dispatcherurl + "/" + state + "/" + ipdata);
        }
      } else {
      }
    }

    let showCameras = cookies.get('showCameras');
    if(showCameras == undefined || showCameras == 'false') {
      showCameras = false;
    } else {
      showCameras = true;
    }
    let showusersettings = cookies.get('showusersettings');
    if(showusersettings == undefined || showusersettings == 'false') {
      showusersettings = false;
    } else {
      showusersettings = true;
    }
    let showReports = cookies.get('showReports');
    if(showReports != undefined && showReports == 'true') {
      showReports = true;
    } else {
      showReports = false;
    }
    let showSettings = cookies.get('showSettings');
    if(showSettings == undefined || showSettings == 'false') {
      showSettings = false;
    } else {
      showSettings = true;
    }
    let supportepicexams = cookies.get('supportepicexams');
    if(supportepicexams == undefined || supportepicexams == 'false') {
      supportepicexams = false;
    } else {
      supportepicexams = true;
    }
    let supportusers = cookies.get('supportusers');
    if(supportusers == undefined || supportusers == 'false') {
      supportusers = false;
    } else {
      supportusers = true;
    }
    
    let sessionData = cookies.get('sessionData');
    if(sessionData == undefined) {
      sessionData = null;
    }
    else{
      sessionData.showsessiontimeout = this.showSessionTimeoutMessageBox;
    }


    let session = sessionStorage.getItem('session');
    if(session == undefined) {
      session = "";
    }

    let userdbid = sessionStorage.getItem('userdbid');
    if(userdbid == undefined) {
      userdbid = 0;
    } else {
      userdbid = parseInt(userdbid, 10);
    }

    let usertype = sessionStorage.getItem('usertype');
    if(usertype == undefined) {
      usertype = "";
    }

    let username = sessionStorage.getItem('username');
    if(username == undefined) {
      username = "";
    }
    let fullname = sessionStorage.getItem('fullname');
    if(fullname == undefined) {
      fullname = "";
    }

    this.setState({ fullname: fullname, username: username, session: session, userdbid: userdbid, usertype: usertype, showSettings: showSettings, showusersettings: showusersettings, showCameras: showCameras, showReports: showReports, showEpicExams: supportepicexams, showusers: supportusers, sessionData: sessionData});
  }
  

  handleLogin = (session, clinicuser, username, examid, examdbid, operatordbid, data, patientname, patientid, policies) => {

    const {cookies} = this.props;

    let userdbid = data.id;
    let usertype = data.usertype;
    let fullname = data.fullname;
    let doctorlinkedtoclinic = data.doctorlinkedtoclinic;

    let showcameras = false;
    let showsettings = false;
    let showusersettings = false;
    let supportepicexams = false;
    let supportusers = false;
    let showreports = false;
    if(usertype == "A") {
      showsettings = true;
    }

    showcameras = data.supportcameras;
    showreports = false;
    showusersettings = data.supportsettings;
    supportepicexams = data.supportepicexams;
    supportusers = data.addnewuser;

    if(usertype == "A") {
      showreports = true;
    }

    let addnewpatient = data.addnewpatient;
    let internalexam = data.internalexam;
    let externalexam = data.externalexam;
    let timezone = data.timezone;

    let sessionData = {};
    sessionData.username = username;
    sessionData.userdbid = userdbid;
    sessionData.usertype = usertype;
    sessionData.timezone = timezone;
    sessionData.showsessiontimeout = this.showSessionTimeoutMessageBox;
    
    sessionData.userpolicies = this.getUserPolicies(policies);

    cookies.set('examid', examid, { path: '/', maxAge: 3600 });
    cookies.set('examdbid', examdbid, { path: '/', maxAge: 3600 });
    cookies.set('operatordbid', operatordbid, { path: '/', maxAge: 3600 });
    cookies.set('clinicuser', clinicuser, { path: '/', maxAge: 3600 });
    cookies.set('session', session, { path: '/', maxAge: 3600 });
    cookies.set('showSettings', showsettings, { path: '/', maxAge: 3600 });
    cookies.set('showCameras', showcameras, { path: '/', maxAge: 3600 });
    cookies.set('showusersettings', showusersettings, { path: '/', maxAge: 3600 });
    cookies.set('showReports', showreports, { path: '/', maxAge: 3600 });
    cookies.set('supportepicexams', supportepicexams, { path: '/', maxAge: 3600 });
    cookies.set('supportusers', supportusers, { path: '/', maxAge: 3600 });

    cookies.set('addnewpatient', addnewpatient, { path: '/', maxAge: 3600 });
    cookies.set('internalexam', internalexam, { path: '/', maxAge: 3600 });
    cookies.set('externalexam', externalexam, { path: '/', maxAge: 3600 });
    cookies.set('doctorlinkedtoclinic', doctorlinkedtoclinic, { path: '/', maxAge: 3600 });
    cookies.set('sessionData', sessionData, { path: '/', maxAge: 3600 });

    sessionStorage.setItem("session", session);
    sessionStorage.setItem("userdbid", userdbid);
    sessionStorage.setItem("usertype", usertype);
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("fullname", fullname);
    sessionStorage.setItem("timezone", timezone);
    sessionStorage.setItem("displayConfirmCameras", true);

    this.setState({ policies: policies, addnewpatient: addnewpatient, internalexam: internalexam, externalexam: externalexam, fullname: fullname, 
                    clinicuser: clinicuser, operatordbid: operatordbid, patientname: patientname, patientid: patientid, 
                    examdbid: examdbid, examid: examid, username: username, session: session, userdbid: userdbid, usertype: usertype, 
                    showSettings: showsettings, showCameras: showcameras, showusersettings: showusersettings, showReports: showreports, 
                    doctorlinkedtoclinic: doctorlinkedtoclinic, showEpicExams: supportepicexams, showusers: supportusers, sessionData: sessionData});
  };
  
  
  render() {
    
    const location = window.location.pathname;
    let history = this.props.history;
    history.push(location);
    let className="position-fixed p-0"

    if(this.state.username.toLowerCase().includes("grayhawk")) {

      className="position-relative w-100 h-100 p-0"
    }

    return (
      <React.Fragment>
        {this.displaySessionTimeoutMessageBox(location)}

        {/* new ui */}

        <div>
          
          <BrowserRouter>

              <Col className={className} style={{height: "100vh", overflowX: 'hidden', overflowY: 'auto'}}>

                <Switch>

                  <Route path="/isalive" component={this.getIsAliveNav}></Route>

                  <Route path="/main" component={this.getNewMainNav}></Route>
                  <Route path="/main/:clinicname/:dataondate" exact component={this.getNewMainNav}></Route>
                  <Route path="/getreport/:testreport/" exact component={this.getNewMainNav}></Route>

                  {/* legacy */}
                  <Route path="/legacy/main/" component={this.getMainNav}></Route>
                  <Route path="/legacy/upload" component={this.getUploadNav}></Route>
                  <Route path="/legacy/diagnose" component={this.getMainDiagnoseNav}></Route>
                  <Route path="/report" component={this.getReportNav}></Route>
                  <Route path="/legacy/settings" component={this.getSettingsNav}></Route>
                </Switch>

                <Switch>

                  <Route path="/isalive" exact component={this.getLiveData}></Route>

                  <Route path="/" exact component={this.getNewLoginForm}></Route>
                  <Route path="/register/:username/:password" component={this.getNewRegisterForm}></Route>
                  <Route path="/recover/:username/:password" component={this.getNewRecoverForm}></Route>
                  <Route path="/forgot" exact component={this.getNewForgetForm}></Route>
                  <Route path="/logout" exact component={this.getNewLogoutForm}></Route>

                  <Route path="/main" exact component={this.getNewMainForm}></Route>
                  <Route path="/main/:clinicname/:dataondate" exact component={this.getNewMainForm}></Route>
                  <Route path="/main/:clinicname/:dataondate/:diagnose" exact component={this.getNewMainForm}></Route>
                  <Route path="/getreport/:testreport" exact component={this.getNewMainForm}></Route>
                  <Route path="/help" exact component={this.getUserManualorm}></Route>

                  {/* legacy */}
                  <Route path="/legacy/main" exact component={this.getMainForm}></Route>
                  <Route path="/legacy/upload" exact component={this.getUploadForm}></Route>
                  <Route path="/legacy/diagnose" exact component={this.getMainDiagnoseForm}></Route>
                  <Route path="/report" component={this.getReportForm}></Route>
                  <Route path="/legacy/settings" exact component={this.getSettingsForm}></Route>
                </Switch>
              </Col>
      
          </BrowserRouter>

        </div>

      </React.Fragment>
    );
  }


  showSessionTimeoutMessageBox = () => {
    this.setState({displaySessionTimeout: true});
  }

  displaySessionTimeoutMessageBox(pathname) {

    return(
      <Modal show={this.state.displaySessionTimeout} centered dialogClassName={"iiq-popup"}>

        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {messages.app_sessiontimeout_title} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>

          {<div>                
            {this.state.app_sessiontimeout_body}
          </div>}

        </Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleCloseSessionTimeoutMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>        
    );
    
  }

  handleCloseSessionTimeoutMessageBox = event => {
    this.setState({displaySessionTimeout: false});
    this.getLogoutForm();
  }

  getIsAliveNav = () => {
    return "";
  }
  

  getNav = () => {
    return <NavBar sessionData={this.props.sessionData} report={false} showSignUp={false} showForget={false} showLogout={false} showHelp={false} showUserButton={false} showBack={false} showReport={false} showSettings={false} showCameras={false} showusersettings={false} showepicexams={false}/>;
  };
  
  getMainNav = () => {
    return <NavBar sessionData={this.props.sessionData} report={false} showSignUp={false} showForget={false} showLogout={true} showHelp={true} showUserButton={true} showBack={this.state.ishospitalsession} ishospitalsession={this.state.ishospitalsession} showReport={this.state.showReports} showSettings={this.state.showSettings} showCameras={this.state.showCameras} fullname={this.state.fullname} showusersettings={this.state.showusersettings} showepicexams={this.state.showEpicExams} showusers={this.state.showusers === true && this.state.usertype === 'C'} shownewview={this.state.usertype === 'A'}/>;
  };
  
  getNewMainNav = () => {
    return <NavBarNew sessionData={this.props.sessionData} report={false} showSignUp={false} showForget={false} showLogout={true} showHelp={true} showUserButton={true} showBack={this.state.ishospitalsession} ishospitalsession={this.state.ishospitalsession} showReport={this.state.showReports} showSettings={this.state.showSettings} showCameras={this.state.showCameras} fullname={this.state.fullname} showusersettings={this.state.showusersettings} showepicexams={this.state.showEpicExams} showusers={this.state.showusers === true && this.state.usertype === 'C'}/>;
  };

  showBack = (value) =>{

    if(value === true) {
          
      this.setState({ ishospitalsession: true });
    }
  }

  getMainDiagnoseNav = () => {
    const location = useLocation();
    return <NavBar sessionData={this.props.sessionData} showSignUp={false} showForget={false} showLogout={false} showHelp={false} showUserButton={false} showBack={true} showReport={false} showSettings={false} showCameras={false} showusersettings={false} showepicexams={false}/>;
  
  };
  
  getUploadNav = () => {
    if(this.state.usertype == 'U') {
      return <NavBar sessionData={this.props.sessionData} report={false} showSignUp={false} showForget={false} showLogout={true} showHelp={false} showUserButton={true} showBack={false} showReport={false} showSettings={false} showCameras={false} fullname={this.state.fullname} showusersettings={false} showepicexams={false}/>;
    } else {
      return <NavBar sessionData={this.props.sessionData} name={this.state.username} report={false} showAbout={false} showSignUp={false} showForget={false} showLogout={false} showHelp={true} showUserButton={false} showBack={this.state.examdbid === 0 && true} showReport={false} showSettings={false} showCameras={false} showusersettings={false} showepicexams={false}/>;
    }
  };
  
  getSettingsNav = () => {
    return <NavBar sessionData={this.props.sessionData} report={false} showSignUp={false} showForget={false} showLogout={false} showHelp={false} showUserButton={false} showBack={true} showReport={false} showSettings={false} showCameras={false} showusersettings={false} showepicexams={false}/>;
  };
  
  getReportNav = () => {
    return <NavBar sessionData={this.props.sessionData} report={false} showSignUp={false} showForget={false} showLogout={false} showHelp={false} showUserButton={false} showBack={true} showReport={false} showSettings={false} showCameras={false} showusersettings={false} showepicexams={false}/>;
  };
  
  getMainForm = () => {
    const {cookies} = this.props;
    let session = sessionStorage.getItem('session');
    let userdbid = sessionStorage.getItem('userdbid');
    let timezone = sessionStorage.getItem('timezone');

    let addnewpatient = (cookies.get('addnewpatient') === 'true');
    let internalexam = (cookies.get('internalexam') === 'true');
    let externalexam = (cookies.get('externalexam') === 'true');
    let doctorlinkedtoclinic = (cookies.get('doctorlinkedtoclinic') === 'true');
    let supportepicexams = (cookies.get('supportepicexams') === 'true');
    
    if(this.state.testreport != null) {
      let url = "/getreport/" + this.state.testreport;
      this.setState({testreport: null});
      return <Redirect to = {url}/>
    }

    if(this.state.fromnewmain != null) {
      let url = "/legacy/main";

      if(this.state.newmainclinicname && this.state.newmainclinicname != '') {
        url = url + "/" + this.state.newmainclinicname;

        if(this.state.newmaindataondate && this.state.newmaindataondate != '') {
          url = url + "/" + this.state.newmaindataondate;
        }
      }

      this.setState({fromnewmain: null});
      return <Redirect to = {url}/>
    }

    if(session == undefined || session == '') {
      return <Redirect to='/' />
    } else {

      return (

        <MainForm
          session={this.state.session}
          username={this.state.username}
          usertype={this.state.usertype}
          diagnose={false}
          fullname={this.state.fullname}
          addnewpatient={addnewpatient}
          internalexam={internalexam}
          externalexam={externalexam}
          userdbid={userdbid}
          doctorlinkedtoclinic={doctorlinkedtoclinic}
          sessionData={this.state.sessionData}
        />
      );
      
    }
  };


  getLogoutForm = () => {
    const {cookies} = this.props;

    cookies.set('session', '', { path: '/', maxAge: 3600 });
    cookies.set('showSettings', false, { path: '/', maxAge: 3600 });
    cookies.set('showusersettings', false, { path: '/', maxAge: 3600 });
    sessionStorage.setItem('session', '');
    sessionStorage.setItem('userdbid', 0);
    sessionStorage.setItem('usertype', '');
    sessionStorage.setItem('username', '');
    sessionStorage.setItem('fullname', '');

    return (
      <LogoutForm
        redirectToRoot='/'
      />
    );
  };
  getMainDiagnoseForm = () => {
    let session = sessionStorage.getItem('session');

    const location = useLocation();
    if(session == undefined || session == '' || location.params == undefined) {
      return <Redirect to='/legacy/main' />
    } else {
      return (
        <MainForm
          session={this.state.session}
          username={this.state.username}
          usertype={this.state.usertype}
          diagnose={true}
          fullname={this.state.fullname}
          patientdbid={location.params.patientdbid}
          imagedbid={location.params.imagedbid}
          srcR={location.params.srcR}
          srcL={location.params.srcL}
          image_file_r ={location.params.image_file_r}
          image_file_l ={location.params.image_file_l}
          userdbid={this.state.userdbid}
          bad_r ={location.params.bad_r}
          bad_l ={location.params.bad_l}
          bad_p ={location.params.bad_p}
          sessionData={this.state.sessionData}
        />
      );
    }
  };
 
  getUploadForm = (match, location) => {
    const {cookies} = this.props;
    let session = sessionStorage.getItem('session');

    if(session == undefined || session == '') {
      return <Redirect to='/' />
    } else {
      session = this.state.session;

      return (
        <UploadForm
          session={session}
          username={this.state.username}
          clinicuser={this.state.clinicuser}
          usertype={this.state.usertype}
          userdbid={this.state.userdbid}
          newexam={this.state.newexam}
          dispatcherurl={this.state.dispatcherurl}
          examid={this.state.examid}
          epicdepid={this.state.epicdepid}
          examdbid={this.state.examdbid}
          patientname={this.state.patientname}
          patientid={this.state.patientid}
          operatordbid={this.state.operatordbid}
          oauth2state={this.state.oauth2state}
          cookies={cookies}
          sessionData={this.state.sessionData}
        />
      );
    }
  };

  getSettingsForm = (match, location) => {
    let session = sessionStorage.getItem('session');
    let username = sessionStorage.getItem('username');

    if(session == undefined || session == '') {
      return <Redirect to='/' />
    } else {
      return (
        <SettingsForm
          session={session}
          username={username}
          sessionData={this.state.sessionData}
        />
      );
    }
  };

  getReportForm = (match, location) => {
    let session = sessionStorage.getItem('session');
    let username = sessionStorage.getItem('username');

    if(session == undefined || session == '') {
      return <Redirect to='/' />
    } else {
      return (
        <ReportForm
          session={this.state.session}
          username={this.state.username}
          usertype={this.state.usertype}
          sessionData={this.state.sessionData}
        />
      );
    }
  };

  getLiveData = () => {
    return "test";
  };

  getUserPolicies = (policies) => {

    let userpolicies = {};
    let haspolicyuserread = this.hasPolicy(policies, "usersread");
    userpolicies.haspolicyuserread = haspolicyuserread;
    let haspolicyuserwrite = this.hasPolicy(policies, "userswrite");
    userpolicies.haspolicyuserwrite = haspolicyuserwrite;

    let haspolicysettingsread = this.hasPolicy(policies, "settingsread");
    userpolicies.haspolicysettingsread = haspolicysettingsread;
    let haspolicysettingwrite = this.hasPolicy(policies, "settingswrite");
    userpolicies.haspolicysettingwrite = haspolicysettingwrite;

    let haspolicycamerasread = this.hasPolicy(policies, "camerasread");
    userpolicies.haspolicycamerasread = haspolicycamerasread;
    let haspolicycameraswrite = this.hasPolicy(policies, "cameraswrite");
    userpolicies.haspolicycameraswrite = haspolicycameraswrite;

    let haspolicyimageread = this.hasPolicy(policies, "imagesread");
    userpolicies.haspolicyimageread = haspolicyimageread;
    let haspolicyimagewrite = this.hasPolicy(policies, "imageswrite");
    userpolicies.haspolicyimagewrite = haspolicyimagewrite;

    return userpolicies;
  }

  hasPolicy = (policies, policytype) => {

    if(policies) {

      for (let i in policies) {

          let policy = policies[i];

          if(policy["type"] === policytype) {
            return true;
          }
      }
    }

    return false;
  }

  getUserManualorm = () => {
    const {cookies} = this.props;
    let cookiesSession = cookies.get('session');

    if(cookiesSession == undefined || cookiesSession.length < 15) {
      return <Redirect to='/' />
    } else {
      
      return (

        <UserManualForm cookiesSession={cookiesSession}>
        </UserManualForm>
      );      
    }
  };



  getNewLoginForm = () => {
    let ipdata = this.state.ipdata;
    if(this.state.getipdata == true) {
      let succ = false;
      let numberoftime = 0;
      do {
        try {
          numberoftime++;
          let theUrl = "https://geolocation-db.com/json/"
          var xmlHttp = new XMLHttpRequest();
          xmlHttp.open( "GET", theUrl, false );
          xmlHttp.send( null );
          ipdata = xmlHttp.responseText;
          succ = true;
        } catch {
          succ = false
        }
      } while(succ == false && numberoftime < 5)
      if(numberoftime == 5) {
        ipdata = "";
      }
      this.setState({getipdata: false, ipdata: ipdata});
    }
    return <LoginFormNew ipdata={ipdata} OnLogin={this.handleLogin} testreport={this.state.testreport} usertype={this.state.usertype}/>;
  };

  getNewLogoutForm = () => {
    const {cookies} = this.props;

    cookies.set('session', '', { path: '/', maxAge: 3600 });
    cookies.set('showSettings', false, { path: '/', maxAge: 3600 });
    cookies.set('showusersettings', false, { path: '/', maxAge: 3600 });

    sessionStorage.setItem('session', '');
    sessionStorage.setItem('userdbid', 0);
    sessionStorage.setItem('usertype', '');
    sessionStorage.setItem('username', '');
    sessionStorage.setItem('fullname', '');

    return (
      <LogoutForm
        redirectToRoot='/'
      />
    );
  };

  getNewRegisterForm = () => {
    let { username, password } = useParams();
    return <RegistrationFormNew username={username} password={password} OnCancle={this.handleCancle} />;
  };
  
  getNewRecoverForm = () => {
    let { username, password } = useParams();
    return <RecoverNew username={username} password={password} OnCancle={this.handleCancle} />;
  };
  
  getNewForgetForm = () => {
    return <ForgetNew OnCancle={this.handleCancle} />;
  };

  getNewMainForm = () => {
    let { clinicname, dataondate, diagnose, testreport } = useParams();

    let session = sessionStorage.getItem('session');
    let userdbid = sessionStorage.getItem('userdbid');

    if(session == undefined || session == '') {
      this.setState({fromnewmain: true, newmainclinicname: clinicname, newmaindataondate: dataondate, newmaindiagnose: diagnose, testreport: testreport});
      return <Redirect to='/' />
    } else {

      if(this.state.newmainclinicname != '') {
        clinicname = this.state.newmainclinicname; 
      }

      if(this.state.newmaindataondate != '') {
        dataondate = this.state.newmaindataondate;
      }

      if(this.state.newmaindiagnose != '') {
        diagnose = this.state.newmaindiagnose;
      }

      if(this.state.fromnewmain != null) {
        let url = "/main";
  
        if(this.state.newmainclinicname && this.state.newmainclinicname != '') {
          url = url + "/" + this.state.newmainclinicname;
  
          if(this.state.newmaindataondate && this.state.newmaindataondate != '') {
            url = url + "/" + this.state.newmaindataondate;

            if(this.state.newmaindiagnose && this.state.newmaindiagnose != '') {
              url = url + "/" + this.state.newmaindiagnose;
            }
          }
        }
  
        if (this.state.testreport) {
          url = "/getreport/" + this.state.testreport;
        }

        this.setState({fromnewmain: null});
        return <Redirect to = {url}/>
      }
      
      return (

        <MainFormNew sessionData={this.state.sessionData}
                  fullname={this.state.fullname}
                  usertype={this.state.usertype}
                  session={this.state.session}
                  username={this.state.username}
                  clinicid={this.state.clinicid}
                  diseasesSelected={this.state.diseasesSelected}
                  userdbid={userdbid}
                  testreport={testreport}
                  clinicname={clinicname}
                  dataondate={dataondate}
                  diagnose={diagnose}
                  onLogout={this.getLogoutForm}>
        </MainFormNew>
      );      
    }
  };
 
}
export default withCookies(App);
