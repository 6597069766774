import React, { Component, Fragment} from "react";
import Button from 'react-bootstrap/Button'
import errorcodes from "../errorcodes";
import DialogBox from 'react-modeless'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import connections from "../../connections";
import { Multiselect } from 'multiselect-react-dropdown';
import  AEYEUsersRow from '../Class/AEYEUserRow';
import Modal from 'react-bootstrap/Modal'
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import componentsStrings from "../../Messages/Components";
import sdkvars from "../sdkVariables";
import { Row } from "react-bootstrap";


const dialogStyle = {
  position: 'fixed',
  width: '75%',
  transform: 'translate(-50%, -40%)',
  boxShadow: 'rgba(0,0,0,.3) 0 0.3rem 1rem',
  background: '#f2f2f2',
  borderRadius: '25px',
  zIndex: '1000',
  maxHeight: '75%',
  overflowY: 'scroll'
}

class AddNewUser extends Component {
  
  state = {
      name: "",
      fullname: "",
      email: "",
      departmentid: null,
      usertype: "C",
      timezone: "",
      encryptionmode: 0,
      onClose: null,
      allclinics: [],
      clinicsSelected: [],
      showClinics: false,
      currentuserinfo: null,
      tofatchData: true,
      editmode: false,
      sendreport: false,
      addnewuser: false,
      displayAddNewUserFailedMessage: false,
      displayAddNewUserAlreadyExistMessage: false,
      displayEnableReInvitionSucsses: false,
      addnewpatient: true,
      internalexam: null,
      externalexam: null,
      supportdepartments: false,
      supportcameras: false,
      supportepicexams: false,
      supportreports: false,
      supportsettings: false,
      diseases: [],
      diseasesSelected: [],
      policiesSelected: [],
      authmode: 0,
      showaddnewpatient : true,
      showsupportdepartment : false,
      showsupportepicexams : false,
      showsupportreports : false,
      showsupportcameras : false,
      showsendreport : false,
      showsendsettings : false,
      showsupportdepartmentfields: false,
      showDiseases: false,
      showaddnewuser: false,
      showgetdiagnosis: false,
      showencryptionmode : false,
      callbyclinic: false
  };
  
  constructor() {
    super();
  }
  
  componentDidMount() {
    var editmode = false;

    if(this.props.aeyetablealldata != null) {
      let allclinics = [];
      for(var x = 0 ; x < this.props.aeyetablealldata.data.length ; x++) {
        let clinic = this.props.aeyetablealldata.data[x];
        if(clinic.usertype == 'C') {
          allclinics.push({name: clinic.username, id: clinic.userdbid});
        }
      }

      this.setState({allclinics: allclinics});
    }

    if(this.props.diseases != null) {
      let diseases = [];
      let diseasesSelected = [];

      for(var x = 0 ; x < this.props.diseases.data.length ; x++) {

        let disease = this.props.diseases.data[x];
          diseases.push({name: disease.name, description: disease.description, id: disease.id});

          if(disease.name == "diagFilters" || disease.name == "diagDiabeticRetinopathy") {
            diseasesSelected.push({name: disease.name, description: disease.description, id: disease.id});
          }
      }

      this.setState({diseases: diseases, diseasesSelected: diseasesSelected});
    }
    
    if(this.props.policytypes != null) {
      let policies = [];
      for(var x = 0 ; x < this.props.policytypes.data.length ; x++) {

        let policy = this.props.policytypes.data[x];
        policies.push({description: policy.description, id: policy.id});
      }

      this.setState({policytypes: policies, policiesSelected: policies});
    }

    if(this.props.edituserdbid != 0) {
      editmode = true;
    }
    if(editmode == true && this.state.tofatchData == true) {
      this.getUserInfo();
    }

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({onClose: this.props.onClose, editmode: editmode, timezone: timezone, callbyclinic: this.props.callbyclinic});

    this.initFieldsByUserType(this.state.usertype, this.state.supportdepartments);
  }

  handleClinicSelect = (clinicsSelected) => {
    this.setState({clinicsSelected: clinicsSelected});
  }
  
  handleClinicRemove = (clinicsSelected) => {
    this.setState({clinicsSelected: clinicsSelected});
  }

  handleDiseaseSelect = (diseasesSelected) => {
    this.setState({diseasesSelected: diseasesSelected});
  }
  
  handleDiseaseRemove = (diseasesSelected) => {
    this.setState({diseasesSelected: diseasesSelected});
  }

  handlePolicySelect = (policiesSelected) => {
    this.setState({policiesSelected: policiesSelected});
  }
  
  handlePolicyRemove = (policiesSelected) => {
    this.setState({policiesSelected: policiesSelected});
  }
  
  handleSendReportChange = (sendreport) => {
    this.setState({sendreport: sendreport.target.checked});
  }
  
  handleAddNewUserChange = (event) => {
    this.setState({addnewuser: event.target.checked});
  }

  handleSupportNewPatient = (addnewpatient) => {
    this.setState({addnewpatient: addnewpatient.target.checked});
  }
  
  handleSupportEpicExams = (supportepicexams) => {
    this.setState({supportepicexams: supportepicexams.target.checked});
  }

  handleSupportInternalExam = (internalexam) => {
    this.setState({internalexam: internalexam.target.checked});
  }
  
  handleSupportExternalExam = (externalexam) => {
    this.setState({externalexam: externalexam.target.checked});
  }
  
  handleSupportDepartments = (supportdepartments) => {
    this.setState({supportdepartments: supportdepartments.target.checked});
    this.initFieldsByUserType(this.state.usertype, supportdepartments.target.checked)
  }
  
  handleSupportCameras = (supportcameras) => {
    this.setState({supportcameras: supportcameras.target.checked});
  }
  

  handleSupportSettings = (supportsettings) => {
    this.setState({supportsettings: supportsettings.target.checked});
  }
  
  handleSupportReports = (supportreports) => {
    this.setState({supportreports: supportreports.target.checked});
  }

  
  handleUserChange = event => {
    this.setState({ name: event.target.value });
  };
  
  handleUserFullnameChange = event => {
    this.setState({ fullname: event.target.value });
  };
  
  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };
  
  handleDepartmentChange = event => {
    this.setState({ departmentid: event.target.value });
  };
  
  handleUserTypeChange = event => {
    let usertype = event.target.selectedOptions[0].attributes['usertype'].value;
    
    let supportdepartments = false;
    let showClinics = false;
    if(usertype == 'D') {
      showClinics = true;
    } else {
      this.setState({clinicsSelected: [] });
    }
    this.setState({ usertype: usertype, showClinics: showClinics, supportdepartments: supportdepartments });
    this.initFieldsByUserType(usertype, supportdepartments);
  };
  
  handleZoneChange = event => {
    this.setState({ timezone: event });
  };
  
  handleEncryptionMode = event => {

    let encryptionmode = event.target.selectedOptions[0].attributes['encryptionmode'].value;

    this.setState({ encryptionmode: encryptionmode });
  };

  newUserCancel = () => {
    this.state.onClose();
  }
  
  enableReInvitaion = () => {
    let url = connections.sdkurl;

    fetch(url + "/AEYEAppServer/v1/UserRepository/enableReInvitaion", {
      method: "POST",
      body: JSON.stringify({
        logonid: this.props.username,
        session: this.props.session,
        edituserdbid: this.props.edituserdbid,
      })
    })
      .then(response => response.json())
      .then(data => {

        if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
          this.props.sessionData.showsessiontimeout();
          return;
        }

        if (data.errorcode == errorcodes.errorOK) {
          this.setState({displayEnableReInvitionSucsses: true});
        }
      });
    
  }  

  onClose = event => {
    this.state.onClose();
  }
  
  getUserInfo = () => {

    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyuserread) {
      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v1/UserRepository/getuserbyadmin", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          userdbid: this.props.edituserdbid
        })
      })
        .then(response => response.json())
        .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {
              let aeyetablerow = new AEYEUsersRow();
              aeyetablerow.username = data.username;
              aeyetablerow.fullname = data.fullname;
              aeyetablerow.usertype = data.usertype;
              aeyetablerow.timezone = data.timezone;
              aeyetablerow.sendreport = data.send_algo_mail;
              aeyetablerow.email = data.email;
              aeyetablerow.departmentid = data.departmentid;
              aeyetablerow.addnewpatient = data.addnewpatient;
              aeyetablerow.internalexam = data.internalexam;
              aeyetablerow.externalexam = data.externalexam;
              aeyetablerow.supportdepartments = data.supportdepartments;
              aeyetablerow.supportcameras = data.supportcameras;
              aeyetablerow.supportsettings = data.supportsettings;
              aeyetablerow.supportreports = data.supportreports;
              aeyetablerow.supportepicexams = data.supportepicexams;
              aeyetablerow.addnewuser = data.addnewuser;
              aeyetablerow.encryptionmode = data.encryptionmode;
  
              aeyetablerow.authmode = data.authmode;

              let clinicsSelected = [];

              if(data.permissions != null) {
                for(var x = 0 ; x < data.permissions.length ; x++) {
                  clinicsSelected.push({name: data.permissions[x].targetname, id: data.permissions[x].targetid});
                }
              }

              let diseasesSelected = [];

              if(data.diseasesSelected != null && this.state.diseases != null) {

                for(var x = 0 ; x < data.diseasesSelected.length ; x++) {

                  for(var j = 0 ; j < this.state.diseases.length ; j++) {
                    
                    if(this.state.diseases[j].id == data.diseasesSelected[x].disease) {

                      diseasesSelected.push({name: this.state.diseases[j].name, description: this.state.diseases[j].description, id: data.diseasesSelected[x].disease});
                    }
                  } 
                }
              }


              let policiesSelected = [];

              if(data.userpolicies != null && this.state.policytypes != null) {

                for(var x = 0 ; x < data.userpolicies.length ; x++) {

                  for(var j = 0 ; j < this.state.policytypes.length ; j++) {
                    
                    if(this.state.policytypes[j].id == data.userpolicies[x].policytypeid) {

                      policiesSelected.push({description: this.state.policytypes[j].description, id: data.userpolicies[x].policytypeid});
                    }
                  } 
                }
              }

              this.setState({ addnewpatient: aeyetablerow.addnewpatient, internalexam: aeyetablerow.internalexam, supportepicexams: aeyetablerow.supportepicexams, addnewuser: aeyetablerow.addnewuser, getdiagnosis: aeyetablerow.getdiagnosis,
                              supportdepartments: aeyetablerow.supportdepartments, supportcameras: aeyetablerow.supportcameras, supportreports: aeyetablerow.supportreports, supportsettings: aeyetablerow.supportsettings,
                              externalexam: aeyetablerow.externalexam, clinicsSelected: clinicsSelected, diseasesSelected: diseasesSelected, policiesSelected: policiesSelected, 
                              currentuserinfo: aeyetablerow, tofatchData: false, sendreport: aeyetablerow.sendreport, email: aeyetablerow.email, departmentid: aeyetablerow.departmentid,
                              fullname: aeyetablerow.fullname, name: aeyetablerow.username, usertype: aeyetablerow.usertype, encryptionmode: aeyetablerow.encryptionmode,
                              timezone: aeyetablerow.timezone, showClinics: data.usertype=='D', authmode: aeyetablerow.authmode});

              this.initFieldsByUserType(aeyetablerow.usertype, aeyetablerow.supportdepartments);
            
          }
        });
    }
  }

  initFieldsByUserType = (usertype, supportdepartments) => {
    let showaddnewpatient = false;
    let showsupportdepartment = false;
    let showsupportreports = false;
    let showsupportcameras = false;
    let showsupportepicexams = false;
    let showsendreport = false;
    let showsendsettings = false;
    let showClinics = false;
    let showsupportdepartmentfields = false;
    let showDiseases = false;
    let showaddnewuser = false;
    let showencryptionmode = false;

    if(supportdepartments == true) {
      showsupportdepartmentfields = true;
    }

    if(usertype == 'U') {
      showsendreport = true;
      showDiseases = true;
    } else {
      
      if(usertype === 'D') {
        showClinics = true;
        showencryptionmode = true;
      } else {
        if(usertype != 'P' && usertype != 'H') {
          if(usertype != 'H') {
            showsupportdepartment = true;
            showsupportcameras = true;
          }
          showsendsettings = true;
        }
      }
      
      if(usertype !== 'H') {
        showDiseases = true;
        showsendreport = true;
        showaddnewpatient = true;
      }
      else {
        showsupportepicexams = true;
        showsendsettings = true;
      }

      if(usertype === 'C') {
        showaddnewuser = true;
        showencryptionmode = true;
      }
      
      showsupportreports = true;

      if(this.state.editmode == false) {
        this.setState({clinicsSelected: []});
      }
    }

    this.setState({showDiseases: showDiseases, showsupportdepartmentfields: showsupportdepartmentfields, 
                  showClinics: showClinics, showaddnewpatient: showaddnewpatient, 
                  showsupportdepartment: showsupportdepartment, showsupportreports: showsupportreports, 
                  showsupportcameras: showsupportcameras, showsendreport: showsendreport, 
                  showsendsettings: showsendsettings, showsupportepicexams: showsupportepicexams, 
                  showaddnewuser: showaddnewuser, showencryptionmode, showencryptionmode}); 
  }

  saveUser = () => {

    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyuserwrite) {

      let url = connections.sdkurl;
      if(this.state.editmode == true) {
        fetch(url + "/AEYEAppServer/v1/UserRepository/edituser", {
          method: "POST",
          body: JSON.stringify({
            logonid: this.props.username,
            session: this.props.session,
            edituserdbid: this.props.edituserdbid,
            name: this.state.name,
            fullname: this.state.fullname,
            email: this.state.email,
            departmentid: this.state.departmentid,
            usertype: this.state.usertype,
            timezone: this.state.timezone,
            sendreport: this.state.sendreport,
            clinicsSelected: this.state.clinicsSelected,
            diseasesSelected: this.state.diseasesSelected,
            addnewpatient: this.state.addnewpatient,
            internalexam: this.state.internalexam,
            externalexam: this.state.externalexam,
            supportdepartments: this.state.supportdepartments,
            supportcameras: this.state.supportcameras,
            supportsettings: this.state.supportsettings,
            supportreports: this.state.supportreports,
            supportepicexams: this.state.supportepicexams,
            addnewuser: this.state.addnewuser,
            getdiagnosis: this.state.getdiagnosis,
            policiesSelected: this.state.policiesSelected,
            encryptionmode: this.state.encryptionmode
          })
        })
          .then(response => response.json())
          .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }

            if (data.errorcode == errorcodes.errorOK) {
              this.state.onClose();
            }
          });
      } else {
        fetch(url + "/AEYEAppServer/v1/UserRepository/addnewuser", {
            method: "POST",
            body: JSON.stringify({
              logonid: this.props.username,
              session: this.props.session,
              name: this.state.name,
              fullname: this.state.fullname,
              email: this.state.email,
              departmentid: this.state.departmentid,
              usertype: this.state.usertype,
              timezone: this.state.timezone,
              sendreport: this.state.sendreport,
              clinicsSelected: this.state.clinicsSelected,
              diseasesSelected: this.state.diseasesSelected,
              addnewpatient: this.state.addnewpatient,
              internalexam: this.state.internalexam,
              externalexam: this.state.externalexam,
              supportdepartments: this.state.supportdepartments,
              supportcameras: this.state.supportcameras,
              supportsettings: this.state.supportsettings,
              supportreports: this.state.supportreports,
              supportepicexams: this.state.supportepicexams,
              addnewuser: this.state.addnewuser,
              getdiagnosis: this.state.getdiagnosis,
              policiesSelected: this.state.policiesSelected,
              encryptionmode: this.state.encryptionmode
            })
        })
        .then(response => response.json())
        .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }
          
          if (data.errorcode == errorcodes.errorOK) {
            this.state.onClose();
          } else  if (data.errorcode == errorcodes.AEYEHealthSDKFailedToAddNewUserAlreadyExist) {
            this.setState({displayAddNewUserAlreadyExistMessage: true});
          } else {
            this.setState({displayAddNewUserFailedMessage: true});
          }
        });
      }
    }
  }
  
  handleCloseAddNewUserFailedMessageBox = event => {
    this.setState({displayAddNewUserFailedMessage: false})
  }
  
  handleCloseAddNewUserAlreadyExistMessageBox = event => {
    this.setState({displayAddNewUserAlreadyExistMessage: false})
  }
  
  handleCloseEnableReInvitionSucsses = event => {
    this.setState({displayEnableReInvitionSucsses: false})
  }

  handleSubmit = () => {
    
    if(this.state.name == '' || this.state.fullname == '' || !this.isValidEmail() ) {

      return;
    }
    else{

      this.saveUser();
    }

  };

  render() {
      let timezone = this.state.timezone;
      if( timezone == "") {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      }

      return (
        <React.Fragment>
            {this.addNewUserFailedMessageBox()}
            {this.addNewUserAlreadyExistMessageBox()}
            {this.showEnableReInvitionSucsses()}

            <div id="newuserform">
              <DialogBox zIndex={5} isOpen={this.props.isDialogOpen && !this.state.displayAddNewUserAlreadyExistMessage} onClose={this.onClose} style={dialogStyle} containerClassName={'container'} noBackdrop={true} clickBackdropToClose={false}>
                  
                      <Form style={{padding: '20px'}}>

                          <Row>

                              <Form.Group as={Col} md="4">
                                  <Form.Label>{componentsStrings.addnewuser_username_label}</Form.Label>
                                  <Form.Control id="newuserform_username" required isValid = {this.state.name != ''} isInvalid = {this.state.name == ''} type="text" placeholder={componentsStrings.addnewuser_username_placeholder} onChange={this.handleUserChange} value={this.state.name} readOnly={this.state.editmode}/>
                              </Form.Group>
                             
                              <Form.Group as={Col} md="4">
                                  <Form.Label>{componentsStrings.addnewuser_fullname_label}</Form.Label >
                                  <Form.Control id="newuserform_fullname" required isValid = {this.state.fullname != ''} isInvalid = {this.state.fullname == ''} type="text" placeholder={componentsStrings.addnewuser_fullname_placeholder} onChange={this.handleUserFullnameChange} value={this.state.fullname}/>
                              </Form.Group>

                              {this.state.authmode == 2 && <Form.Group as={Col} md="4">
                                <Button id="newuserform_reinvitaion" style={{marginTop: '31px'}} variant="warning" type="button" onClick={() => { this.enableReInvitaion() }}>{componentsStrings.addnewuser_enablereinvite_button}</Button>
                              </Form.Group>}

                          </Row>
                          
                          <Row>

                              <Form.Group as={Col} md="4">
                                  <Form.Label>{componentsStrings.addnewuser_email_label}</Form.Label>
                                  <Form.Control id="newuserform_email" required isInvalid = {!this.isValidEmail()} isValid = {this.isValidEmail()}  type="text" placeholder={componentsStrings.addnewuser_email_placeholder} onChange={this.handleEmailChange} value={this.state.email}/>
                              </Form.Group>
                             
                              <Form.Group as={Col} md="4">
                                  <Form.Label>{componentsStrings.addnewuser_timezone_label}</Form.Label>
                                 
                                  <Row>
                                    <TimezonePicker id="newuserform_timezone" absolute={true} value={timezone} placeholder={componentsStrings.addnewuser_timezone_placeholder} onChange={this.handleZoneChange}></TimezonePicker>                            
                                  </Row>

                              </Form.Group>

                              {this.state.showsupportdepartmentfields == true && <Form.Group as={Col} md="4">
                                  <Form.Label>{componentsStrings.addnewuser_departmentid_label}</Form.Label>
                                  <Form.Control id="newuserform_departmentid" type="text" placeholder={componentsStrings.addnewuser_departmentid_placeholder} onChange={this.handleDepartmentChange} value={this.state.departmentid}/>
                              </Form.Group>}

                          </Row>
                          
                          <Row>

                              <Form.Group as={Col} md="4">
                                  <Form.Label>{componentsStrings.addnewuser_usertype_label}</Form.Label>
                                  <Form.Control id="newuserform_usertype" as="select" onChange={this.handleUserTypeChange} disabled={this.state.editmode || this.state.callbyclinic}>
                                      {this.getOptionsOfUsertype()}
                                  </Form.Control>                
                              </Form.Group>
                              
                              {this.state.showClinics && <Form.Group as={Col} md="4">
                                  <Form.Label>{componentsStrings.addnewuser_clinics_label}</Form.Label>
                                  <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Multiselect id="newuserform_clinics" searchable={true} showTags={true} multiSelect={true} width="500px" selectedValues={this.state.clinicsSelected} onSelect={this.handleClinicSelect} onRemove={this.handleClinicRemove} options={this.state.allclinics} displayValue="name"/>
                                  </div>
                              </Form.Group>}

                              {this.state.showDiseases == true && <Form.Group as={Col} md="4">
                                  <Form.Label>{componentsStrings.addnewuser_diseases_label}</Form.Label>
                                  <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Multiselect id="newuserform_diseases" searchable={true} showTags={true} multiSelect={true} width="500px" selectedValues={this.state.diseasesSelected} onSelect={this.handleDiseaseSelect} onRemove={this.handleDiseaseRemove} options={this.state.diseases} displayValue="description"/>
                                  </div>
                              </Form.Group>}

                          </Row>

                          {!this.state.callbyclinic && <Row>

                              {this.state.showsupportdepartment == true && <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_supportdepartments" type="checkbox" label={componentsStrings.addnewuser_addnewuser_supportdepartment} onClick={this.handleSupportDepartments} checked={this.state.supportdepartments}/>   
                              </Form.Group>}
                              
                              {this.state.showsupportcameras == true && <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_supportcameras" type="checkbox" label={componentsStrings.addnewuser_addnewuser_supportcameras} onClick={this.handleSupportCameras} checked={this.state.supportcameras}/>   
                              </Form.Group>}
                              
                              {this.state.showsendsettings == true && <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_supportsettings" type="checkbox" label={componentsStrings.addnewuser_addnewuser_supportsettings} onClick={this.handleSupportSettings} checked={this.state.supportsettings}/>
                              </Form.Group>}
                              
                          </Row>}
                          
                          {!this.state.callbyclinic && <Row>

                            {this.state.showsupportreports == true && <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_supportreports" type="checkbox" label={componentsStrings.addnewuser_addnewuser_supportreports} onClick={this.handleSupportReports} checked={this.state.supportreports}/>
                              </Form.Group>}
                              
                              {this.state.showsendreport && <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_supportsendreport" type="checkbox" label={componentsStrings.addnewuser_addnewuser_sendreport} onClick={this.handleSendReportChange} checked={this.state.sendreport}/>
                              </Form.Group>}


                              {this.state.showgetdiagnosis == true && <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_getdiagnosis" type="checkbox" label={componentsStrings.addnewuser_addnewuser_getdiagnosis} onClick={this.handleGetDiagnosis} checked={this.state.getdiagnosis}/>   
                              </Form.Group>}

                          </Row>}
                        

                          {!this.state.callbyclinic && true==false && <Row>
                            <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_supportinternalexam" type="checkbox" label={componentsStrings.addnewuser_addnewuser_supportnewpatient} onClick={this.handleSupportInternalExam} checked={this.state.internalexam}/>
                              </Form.Group>
                              
                              <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_supportexternalexam" type="checkbox" label={componentsStrings.addnewuser_addnewuser_supportepicexam} onClick={this.handleSupportExternalExam} checked={this.state.externalexam}/>
                              </Form.Group>
                              
                          </Row>}

                          {!this.state.callbyclinic && <Row>

                            {this.state.showsupportepicexams == true && <Form.Group as={Col} md="4">
                              <Form.Check id="newuserform_supportepicexams" type="checkbox" label={componentsStrings.addnewuser_addnewuser_supportepicexam} onClick={this.handleSupportEpicExams} checked={this.state.supportepicexams}/>
                            </Form.Group>}

                            {this.state.showaddnewpatient == true && <Form.Group as={Col} md="4">
                              <Form.Check id="newuserform_supportnewpatient" type="checkbox" label={componentsStrings.addnewuser_addnewuser_supportnewpatient} onClick={this.handleSupportNewPatient} checked={this.state.addnewpatient}/>
                            </Form.Group>}

                            {this.state.showaddnewuser && <Form.Group as={Col} md="4">
                                <Form.Check id="newuserform_supportaddnewuser" type="checkbox" label={componentsStrings.addnewuser_addnewuser_addnewuser} onClick={this.handleAddNewUserChange} checked={this.state.addnewuser}/>
                              </Form.Group>}

                          </Row>}

                          {<Row>

                            {this.state.showencryptionmode == true && <Form.Group as={Col} md="4">

                                  <Form.Label>{componentsStrings.addnewuser_addnewuser_encryptipnmode}</Form.Label>
                                  <Form.Control id="newuserform_encryptionmode" as="select" onChange={this.handleEncryptionMode} disabled={this.state.editmode}>
                                      {this.getOptionsOfEncryptionMode()}
                                  </Form.Control>                

                            </Form.Group>}

                          </Row>}
                         
                          <Row>

                              <Form.Group as={Col} md="12">
                                  <Form.Label>Policies</Form.Label>
                                  <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Multiselect id="newuserform_policies" searchable={true} showTags={true} multiSelect={true} width="500px" selectedValues={this.state.policiesSelected} onSelect={this.handlePolicySelect} onRemove={this.handlePolicyRemove} options={this.state.policytypes} displayValue="description"/>
                                  </div>
                              </Form.Group>

                          </Row>
                          
                          <Row>
                              <Button id="newuserform_submit" style={{margin: '5px'}} variant="primary" onClick={this.handleSubmit} >{componentsStrings.button_save}</Button>
                              <Button id="newuserform_cancel" style={{margin: '5px'}} variant="primary" type="button" onClick={() => { this.newUserCancel() }}>{componentsStrings.button_cancel}</Button>
                          </Row>
                          
                      </Form>
                 
              </DialogBox>   
            </div>

        </React.Fragment>
      );
  }

  showEnableReInvitionSucsses() {
    return(
    <Modal show={this.state.displayEnableReInvitionSucsses} onHide={this.handleCloseEnableReInvitionSucsses}>
        <Modal.Header closeButton>
            <Modal.Title>{componentsStrings.addnewuser_enablereinvite_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{componentsStrings.addnewuser_enablereinvite_body}</Modal.Body>
    </Modal>        
    );
  }

  addNewUserAlreadyExistMessageBox() {
    return(
    <Modal zIndex={2} show={this.state.displayAddNewUserAlreadyExistMessage}>
        <Modal.Header>
            <Modal.Title>{componentsStrings.addnewuser_addnewuser_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{componentsStrings.addnewuser_addnewuser_body}</Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={this.handleCloseAddNewUserAlreadyExistMessageBox}>
            Close
        </Button>
        </Modal.Footer>
    </Modal>        
    );
  }

  addNewUserFailedMessageBox() {
        return(
        <Modal show={this.state.displayAddNewUserFailedMessage}>
            <Modal.Header>
                <Modal.Title>{componentsStrings.addnewuser_addnewuserfail_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{componentsStrings.addnewuser_addnewuserfail_body}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseAddNewUserFailedMessageBox}>
            {componentsStrings.button_close}
            </Button>
            </Modal.Footer>
        </Modal>        
        );
  }

  isValidEmail = () => {

    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var validEmail = pattern.test(String(this.state.email).toLowerCase());

    if (validEmail) {

      return true;
    }

    return false;
  }

  getOptionsOfUsertype = () => {

    if(this.state.callbyclinic) {
      return(
        <option usertype='USER' selected={this.state.usertype=='USER'}>USER</option>
      );
    }
    else {
      return(
        <React.Fragment>
          <option usertype='USER' selected={this.state.usertype=='USER'}>{componentsStrings.addnewuser_usertype_user}</option>
          <option usertype='U' selected={this.state.usertype=='U'}>{componentsStrings.addnewuser_usertype_uploadonly}</option>
          <option usertype='D' selected={this.state.usertype=='D'}>{componentsStrings.addnewuser_usertype_doctor}</option>
          <option usertype='C' selected={this.state.usertype=='C'}>{componentsStrings.addnewuser_usertype_clinic}</option>
          <option usertype='P' selected={this.state.usertype=='P'}>{componentsStrings.addnewuser_usertype_partner}</option>
          <option usertype='H' selected={this.state.usertype=='H'}>{componentsStrings.addnewuser_usertype_hospital}</option>
          <option usertype='R' selected={this.state.usertype=='R'}>{componentsStrings.addnewuser_usertype_research}</option>
        </React.Fragment>
      );
    }
  }

  getOptionsOfEncryptionMode = () => {

    return(
      <React.Fragment>
        <option encryptionmode='0' selected={this.state.encryptionmode==sdkvars.EncryptionModeNothing}>{componentsStrings.addnewuser_encryptipnmode_nothing}</option>
        <option style={{backgroundColor: 'lightgray'}} disabled encryptionmode='1' selected={this.state.encryptionmode==sdkvars.EncryptionModeAll}>{componentsStrings.addnewuser_encryptipnmode_all}</option>
        <option encryptionmode='2' selected={this.state.encryptionmode ==sdkvars.EncryptionModeOnlyPatientData}>{componentsStrings.addnewuser_encryptipnmode_onlypatientdata}</option>
        <option style={{backgroundColor: 'lightgray'}} disabled encryptionmode='3' selected={this.state.encryptionmode ==sdkvars.EncryptionModeDiagnosis}>{componentsStrings.addnewuser_encryptipnmode_diagnosis}</option>
      </React.Fragment>
    );
  }

}

export default AddNewUser;