import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import "../../css/aeyehealth.css"
import Modal from 'react-bootstrap/Modal'

class MessageBox extends Component {
    
    state = {
    };

    componentDidMount() {
    }
    
    render() {
        return(
            <Modal show={this.props.displaymessage}>
                <Modal.Header>
                  <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body id="messagebox_body">{this.props.body}</Modal.Body>
                {this.getCloseButton()}
              </Modal>        
                );
    }
    
    getCloseButton() {
      if(this.props.closebutton == true) {
        return (
        <Modal.Footer>
          <Button id="messagebox_close" variant="secondary" onClick={this.props.handleCloseMessageBox}>
            Close
          </Button>
        </Modal.Footer>
        )
      }
    }

}

export default MessageBox;
