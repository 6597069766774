import React, { Component } from "react";
import Button from 'react-bootstrap/Button'
import errorcodes from "../errorcodes";
import messages from "../../Messages/Messages";
import Col from "react-bootstrap/Col";
import connections from "../../connections"
import Moment from 'moment/moment'
import { Dropdown, FormControl, InputGroup, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import "../../css/general.css"
import "../../css/colors.css"
import "../../css/texts.css"
import ExamCard from "./examCard";
import ExamDisplayForm from "./examDisplayForm";
import ExamCreationForm from "./examCreationForm";
import ReactLoading from 'react-loading';
import texts from "../New/Texts";
import { Redirect } from "react-router";
import componentsStrings from "../../Messages/Components";


class AEYETableRow {
  constructor() {
    this.date = "";
    this.time = "";
    this.patientid = "";
    this.examid = "";
    this.patientdbid = "";
    this.imagedbid = "";
    this.diagnosesid = 0;
    this.diagstate = -9;
    this.clinicname = "";
    this.clinicfullname = "";
    this.patientname = "";
    this.patientstatus = "";
    this.diagnosedate = "";
    this.diagnosedtime = "";
    this.diagnoseby = "";
    this.righteye = "";    
    this.lefteye = "";    
    this.patientseyes = "";
    this.diagnosed = -1;
    this.fullname = "";
    this.uploadedon = "";
    this.diagnosedon = "";
    this.diseasesL = [];
    this.diseasesR = [];
    this.diseasesP = [];
    this.archived = false;
    this.readed = true;
  }
}

class AEYEDisease {
  constructor() {
    this.diagnose = 0;
    this.diagnosis = 0;
    this.disease = 0;
    this.diseasedescription = "";
    this.diseasename = "";
    this.id = 0;
    this.side = "";
  }
}

class AEYETableData {
  constructor() {
    this.data = [];
  }
}

class MainFormNew extends Component {
  tofatchData = true;
  controller = new AbortController();

  state = {
      localDate: '',
      aeyetabledata: null,
      fromindex: 0,
      pagesize: 20,
      searchCretiria: "",
      displayvalue: "all",
      displaytext: texts.mainscreen_searchdropdown_option1,
      exam: null, 
      imagedbid: 0,
      displayLoadingImages: false,
      timer: null,
      waitToDiagnose: false,
      diagnosisIsOver: false,
      redirectToLogin: false,
      indexDisplayed: 0,
      showFailedToLoginMsg: false,
      showFailedToGetImagesMsg: false,      
      allImagesReceived: false
  };
  
  constructor() {
    super();
    this.numberOfPagesDisplayed = 5;
  }

  componentDidMount() {

    var date = new Date();
    var options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: false };
    var localDate = date.toLocaleDateString("en", options)

    this.setState({ username: this.props.username, userdbid: this.props.userdbid, session: this.props.session, localDate: localDate });
    // this.getDiseasesSelected();
  }

  componentDidUpdate() {

    if(!this.props.testreport) {

      this.fetchImages(true);
    }
  }

  patientView = (event) => {

    if(event!=undefined) {
      let thetarget = event.target;

      let index = thetarget.attributes["index"] && thetarget.attributes["index"].value ? thetarget.attributes["index"].value : thetarget.attributes["index"];

      if(index == undefined) {
        thetarget = event.currentTarget;
        index = thetarget.attributes["index"] && thetarget.attributes["index"].value ? thetarget.attributes["index"].value : thetarget.attributes["index"];
      }

      let exam = this.state.aeyetabledata.data[index];
      
      if(exam) {
        exam.readed = true;
      }
      
      this.setState({ indexDisplayed: index, exam: exam });
    }
  }

  handleAddNewExam = () => {

    if(this.state.exam != null) {
      this.setState({ indexDisplayed: -1, exam: null });
    }    
  
  };
  
  handleScroll = (e) => {
    // const bottom1 = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    const bottom = e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight-1;

    if (bottom && this.state.allImagesReceived === false) {

        if(!this.props.dataondate && !this.props.clinicname && !this.props.diagnose && this.tofatchData === false) {
          this.tofatchData = true;
          this.fetchImages(false);
        }
    }
  }

  handleSearch = event => {
    this.controller.abort();
    this.setState({ searchCretiria: event.target.value, aeyetabledata: null}, () => { this.tofatchData = true; this.fetchImages(true, true); } );
  }

  handleClearSearch = event => {

    this.setState({ searchCretiria: "", aeyetabledata: null}, () => { this.tofatchData = true; this.fetchImages(true, true); } );
  }

  handleDisplaySelection = (eventKey, event) => {
    let displaytext = texts.mainscreen_searchdropdown_option1;
    let displayvalue = event.target.attributes["value"].value; 

    if(displayvalue == "all") {
      displaytext = texts.mainscreen_searchdropdown_option1;
    } else if(displayvalue == "active") {
      displaytext = texts.mainscreen_searchdropdown_option2;
    } else if(displayvalue == "archived") {
      displaytext = texts.mainscreen_searchdropdown_option3;
    }


    if(this.state.displayvalue != displayvalue) {

      this.setState({ displayvalue: displayvalue, displaytext: displaytext, aeyetabledata: null }, () => { this.tofatchData = true; this.fetchImages(true); } );
    }

  };

  onCancelCreateExam = () => {

    let indexDisplayed = 0;
    let exam = this.state.aeyetabledata.data[indexDisplayed];

    this.setState({ indexDisplayed: indexDisplayed, exam: exam });
  }

  onStartUploadingExam = () => {

    if(this.tofatchData === false) {

      this.setState({ diagnosisIsOver: false } );
    } 
  }

  onDoneUploadingExam = () => {

    if(this.tofatchData === false) {

      this.setState({ waitToDiagnose: true, diagnosisIsOver: false, searchCretiria: "" }, () => { this.tofatchData = true; this.fetchImages(true, true); } );
    } 
  }

  onDoneCancelingExam = () => {

    if(this.state.displayvalue !== 'all') {

      this.tofatchData = true;
      this.fetchImages(true);
    }

  }

  getDiseasesSelected = () => {

  if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyuserread) {

    let url = connections.sdkurl;
    fetch(url + "/AEYEAppServer/v1/UserRepository/getdiseasesselected", {
      method: "POST",
      body: JSON.stringify({
        logonid: this.props.username,
        session: this.props.session,
        userdbid: this.props.userdbid
      })
    })
      .then(response => response.json())
      .then(data => {

        if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
          this.props.sessionData.showsessiontimeout();
          return;
        }

        if (data.errorcode == errorcodes.errorOK) {

            this.setState({diseasesSelected: data.diseasesSelected});
        }
    });
  }
  }

  getDesisDetails(badimage, diseases, diagnosed) {
    let desisdetails = messages.table_desisdetails_unknown;
    if(diagnosed != 1) {
      desisdetails = messages.table_desisdetails_nodiagyet;
    } 
    if(badimage == 1) {
      desisdetails = messages.table_desisdetails_iiq;
    } else {
      if(diseases.length == 0) {
        desisdetails = messages.table_desisdetails_iiq;
      } else if(diseases.length > 1) {
        var numberofdiseases = 0;
        var numberoffaileddiseases = 0;
        var dataencrypted = false;
        for (const [index, disease] of diseases.entries()) {
          if(disease.diagnosis == -1) {
            dataencrypted = true;
          }
          if(disease.diagnosis == 5) {
            numberoffaileddiseases++;
          }
          if(disease.diagnosis == 1) {
            desisdetails = messages.table_desisdetails_suspiciousfind + disease.diseasedescription;
            numberofdiseases++;
          }          
        }
        if(numberoffaileddiseases > 0 && numberofdiseases == 0) {
          desisdetails = messages.table_desisdetails_failedtofind;
        } else if(numberoffaileddiseases > 0 && numberofdiseases > 0) {
          desisdetails = messages.table_desisdetails_morethanone;
        } else {
          if(numberofdiseases > 1) {
            desisdetails = messages.table_desisdetails_morethanone;
          } else if(numberofdiseases == 0) {
            desisdetails = messages.table_desisdetails_nofindings;
          }
        }
        if(dataencrypted === true) {
          desisdetails = messages.table_desisdetails_dataencrypted;
        }
      } else {
        if(diseases[0].diagnosis == 2) {
          desisdetails = messages.table_desisdetails_nofindings;
        } else if(diseases[0].diagnosis == 5) {
          desisdetails = messages.table_desisdetails_failedtofind + " - " + diseases[0].diseasedescription;
        } else if(diseases[0].diagnosis == -1) {
          desisdetails = messages.table_desisdetails_dataencrypted;
        } else {
          desisdetails = messages.table_desisdetails_suspiciousfind + diseases[0].diseasedescription;
        }
      }
    }
    return desisdetails;
  }
  
  startTimer() {
    if(this.state.timer == null) {
      let timer = setInterval(() => {
        this.tofatchData = true;
        this.fetchImages(true);
      }, 4000);
      this.setState({ timer: timer });
    }
  }
  
  stopTimer() {
    if(this.state.timer != null) {
      clearTimeout(this.state.timer);
      this.setState({ timer: null });
    }
  }

  fetchImages = (firstRecords, doneUploadingExam = false) => {

    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyimageread) {
      
      let diagnosedbyfilter = '';
      if(this.props.usertype == 'C' || this.props.usertype == 'D'){

        diagnosedbyfilter = '1';
      }

      if(this.props.session != "" && this.tofatchData == true && !this.props.testreport) {
        this.tofatchData = false;
        let url = connections.sdkurl;
        let fromindex = this.state.fromindex;

        if(firstRecords === true) {
          fromindex = 0;
        }

        this.setState({ displayLoadingImages: true });

        this.controller = new AbortController();
        const { signal } = this.controller;

        fetch(url + "/AEYEAppServer/v3/Images/getImagesNew", {
          signal,
          method: "POST",
          body: JSON.stringify({            
            logonid: this.props.username,
            session: this.props.session,
            fromindex: fromindex,
            pagesize: this.state.pagesize,
            search: this.state.searchCretiria,
            displayvalue: this.state.displayvalue,
            clinicid: this.props.clinicid,
            clinicname: this.props.clinicname,
            dataondate: this.props.dataondate,
            diagnose: this.props.diagnose,
            diagnosedbyfilter: diagnosedbyfilter
          })
        })
          .then(response => response.json())
          .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
              this.props.sessionData.showsessiontimeout();
              return;
            }

            if (data.errorcode == errorcodes.loginFailed) {
              this.setState({ redirectToLogin: true });
            }

            if (data.errorcode == errorcodes.errorOK) {

              let atLeastOneNotDiagnosedYet = false;

              let aeyetabledata = new AEYETableData();
              if(this.state.aeyetabledata != null && firstRecords === false) {
                aeyetabledata.data = this.state.aeyetabledata.data;
              }

              if(data && data.images && data.images.length > 0) {
                data.images.map((rowdata, index) => {
                // const { row } = rowdata;
                  let aeyetablerow = new AEYETableRow();
                  
                  if(rowdata.diseases) {
                    for (const [index, disease] of rowdata.diseases.entries()) {
                    //rowdata.diseases.map((disease, diseaseindex) => {
                      let aeyedisease = new AEYEDisease();
                      aeyedisease.diagnose = disease.diagnose;
                      aeyedisease.diagnosis = disease.diagnosis;
                      aeyedisease.disease = disease.disease;
                      aeyedisease.diseasedescription = disease.diseasedescription;
                      aeyedisease.diseasename = disease.diseasename;
                      aeyedisease.id = disease.id;
                      aeyedisease.side = disease.side;
                      if(disease.side == "L") {
                        aeyetablerow.diseasesL.push(aeyedisease)
                      } else if(disease.side == "R") {
                        aeyetablerow.diseasesR.push(aeyedisease)
                      } else {
                        aeyetablerow.diseasesP.push(aeyedisease)
                      }
                    };
                  }
                  var timecreated = Moment(rowdata.created).format("HH:mm"); 
                  let created = new Date(rowdata.created); 
                  //var timecreated = created.getHours() + ":" + created.getMinutes();
                  var datecreated = created.toDateString();
                  aeyetablerow.date = datecreated;
                  aeyetablerow.time = timecreated;
                  aeyetablerow.diagnosesid = rowdata.diagnosesid;
                  aeyetablerow.diagstate = rowdata.diagstate;
                  aeyetablerow.patientid = rowdata.patientid;
                  aeyetablerow.examid = rowdata.examid;
                  if(rowdata.examid != null && rowdata.examid.length > 50) {
                    aeyetablerow.examid = messages.table_desisdetails_dataencrypted;
                  }
                  
                  aeyetablerow.patientdbid = rowdata.patientdbid;
                  aeyetablerow.imagedbid = rowdata.imagedbid;
                  aeyetablerow.clinicname = rowdata.clinicname;
                  aeyetablerow.clinicfullname = rowdata.clinicfullname;
                  aeyetablerow.uploadedon = rowdata.uploadedon;
                  aeyetablerow.archived = rowdata.archived;
                  aeyetablerow.diagnosedon = rowdata.diagnosedon;
                  aeyetablerow.patientname = rowdata.patientname;
                  let diagnosedstatus = messages.table_diagstatuc_diag;
                  if(rowdata.diagnosed != 1) {
                    diagnosedstatus = messages.table_diagstatuc_pending;
                  }
                  aeyetablerow.diagnosed = rowdata.diagnosed
                  aeyetablerow.patientstatus = diagnosedstatus;
                  var timediagnosed = Moment(rowdata.diagnosedon).format("HH:mm");
                  var datediagnosed = Moment(rowdata.diagnosedon).format("MMM DD YYYY");  
                  aeyetablerow.diagnosedate = datediagnosed;
                  aeyetablerow.diagnosedtime = timediagnosed;

                  let diagnosedBy = messages.table_diagby_algo;
                  if (rowdata.automatic != 1) {
                    diagnosedBy = rowdata.doctorname;
                  }
                  var fullname = rowdata.doctorname;
                  aeyetablerow.fullname = fullname;
  
                  aeyetablerow.diagnoseby = diagnosedBy;
                  aeyetablerow.righteye = this.getDesisDetails(parseInt(rowdata.bad_r), aeyetablerow.diseasesR, rowdata.diagnosed);    
                  aeyetablerow.lefteye = this.getDesisDetails(parseInt(rowdata.bad_l), aeyetablerow.diseasesL, rowdata.diagnosed);    
                  aeyetablerow.patientseyes = this.getDesisDetails(parseInt(rowdata.bad_p), aeyetablerow.diseasesP, rowdata.diagnosed);
                  if(index === 0 && rowdata.diagnosed == 0) {
                    atLeastOneNotDiagnosedYet = true;
                  }
                  aeyetablerow.hasdoctor = rowdata.hasdoctor;
                  aeyetablerow.doctordate = rowdata.doctordate;
                  var timedoctor= Moment(rowdata.doctordate).format("HH:mm");
                  var datedoctor = Moment(rowdata.doctordate).format("MMM DD YYYY");  
                  aeyetablerow.timedoctor = timedoctor;
                  aeyetablerow.datedoctor = datedoctor;
                  aeyetablerow.readed = rowdata.readed;

                  aeyetabledata.data.push(aeyetablerow);
                });
              }

              if(data && data.images && data.images.length === 0) {
                
                this.setState({ allImagesReceived: true });
              }

              this.tofatchData = false;
              let updateExam = true;
              let pullIsOver = false;

              if(this.state.waitToDiagnose === true) {

                if(atLeastOneNotDiagnosedYet == true) {
                  updateExam = false;

                  if(doneUploadingExam) {
                    updateExam = true;
                  }

                  this.startTimer();
                } else {

                  if(firstRecords === true) {
                    this.stopTimer();
                    this.setState({ waitToDiagnose: false, diagnosisIsOver: true });
                    pullIsOver = true;
                  }
                }  
              }


              if(updateExam === true) {
                this.setState({ aeyetabledata: aeyetabledata, fromindex: fromindex + this.state.pagesize });
                
                if(firstRecords) {

                  let update = true;

                  if (pullIsOver) {
                    
                    if(aeyetabledata.data[0].diagstate != 2 && (this.state.exam === null || this.state.exam.uploadedon !== aeyetabledata.data[0].uploadedon)) {

                      update = false;
                    }
                  }

                  if(update) {

                    let aeyetablerow = null, indexDisplayed = -1;
                    if(aeyetabledata && aeyetabledata.data.length > 0) {
                      indexDisplayed = 0;
                      aeyetablerow = aeyetabledata.data[indexDisplayed];

                      aeyetabledata.data[indexDisplayed].readed = true;
                      this.setState({ aeyetabledata: aeyetabledata });
                    }
                    this.setState({ indexDisplayed: indexDisplayed, exam: aeyetablerow });
                  }
                }

                this.setState({ displayLoadingImages: false });
              }

            }
            else {
              this.setState({ showFailedToGetImagesMsg: true, displayLoadingImages: false });
            }
          }).catch(e => {
            // console.log(e);
        });;
      }
    
    }
    else {
      this.setState({ redirectToLogin: true });
    }
  }

  displayFailedToLoginMessageBox =() => {
    this.setState({showFailedToLoginMsg: true});
  }

  failedToLoginMessageBox() {
    return(
      <Modal show={this.state.showFailedToLoginMsg} centered dialogClassName={"iiq-popup"}>
        
        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {texts.uploadpatient_failToLogin_label} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{texts.uploadpatient_failToLogin_body}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleFailedToLoginCloseMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>         
    );
  }

  handleFailedToLoginCloseMessageBox =() => {
    this.setState({redirectToLogin: true, showFailedToLoginMsg: false});
  }

  failedToGetImagesMessageBox() {
    return(
      <Modal show={this.state.showFailedToGetImagesMsg} centered dialogClassName={"iiq-popup"}>
        
        <Modal.Header>
          <Modal.Title>
            <img src={"/exclamation_mark.png"} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Header>

          <Modal.Title> {texts.getimages_failed_label} </Modal.Title>
        
        </Modal.Header>

        <Modal.Body>{texts.getimages_failed_body}</Modal.Body>

        <Row className="mt-2 w-100" style={{ textAlignLast: 'center', borderTop: '1px solid #D2D2D2' }}>

          <Col style={{ borderRight: '1px solid #D2D2D2', padding: '2%', cursor: 'default' }} onClick={this.handleFailedToGetImagesCloseMessageBox}>
            {componentsStrings.button_close}
          </Col>

        </Row>

      </Modal>         
    );
  }

  handleFailedToGetImagesCloseMessageBox =() => {
    this.setState({showFailedToGetImagesMsg: false});
  }

  render() {

    if(this.state.redirectToLogin) {
      return <Redirect to={"/"} />
    }

    return(
      <React.Fragment>

        {this.failedToLoginMessageBox()}
        {this.failedToGetImagesMessageBox()}

        {this.state.displayLoadingImages === true && this.state.waitToDiagnose == false && <div className="backdrop"></div>}

        <Row style={{height: "93%"}}>

          {!this.props.testreport && <Col className="p-0 right-border bg-black" style={{maxWidth: "22.5%"}}>

            <Row className="align-content-center ml-0 pr-3 mt-3 pb-3 bottom-border w-100" style={{ paddingLeft:'12%', height: "8%" }}>
               
                <div className="d-flex">
                  
                  {/* dropdown */}
                  <Dropdown onSelect={this.handleDisplaySelection} style={{width: '60%'}}>
                    <Dropdown.Toggle id="active_arcived_button" className="text"> {this.state.displaytext} </Dropdown.Toggle>

                    <Dropdown.Menu variant="dark" id="active_arcived_button_menu">
                      <Dropdown.Item href="" className="text white" value="all"> {texts.mainscreen_searchdropdown_option1} </Dropdown.Item>
                      <Dropdown.Item href="" className="text white" value="active"> {texts.mainscreen_searchdropdown_option2} </Dropdown.Item>
                      <Dropdown.Item href="" className="text white" value="archived"> {texts.mainscreen_searchdropdown_option3} </Dropdown.Item>

                    </Dropdown.Menu>
                  </Dropdown>

                  {/* search */}
                  <InputGroup className="align-items-center">
                    <img src="/search.png" width="14" height="14" className="ml-2 position-absolute" style={{zIndex: '1030' }}/>
                    <FormControl type="search" id="mainlist_search" className="text pl-4" placeholder="Search" onChange={this.handleSearch}
                                  style={{backgroundColor: 'black', color: 'white', borderColor: 'white', borderRadius: '0px 4px 4px 0px'}}
                                  value={this.state.searchCretiria}>
                    </FormControl>
                    {this.state.searchCretiria && <img src="/X.png" width="14" height="14" className="mr-2 position-absolute" style={{right:'0px', zIndex: '1030' }} onClick={this.handleClearSearch} />}
                  </InputGroup>

                  {/* '+' button and tooltip*/}
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip className="my-tooltip text"> {texts.mainscreen_searchbutton_tooltip} </Tooltip>}
                  >
                    
                    <Button className="ml-2 btn btn-primary button" id="new_exam" onClick={this.handleAddNewExam} variant="primary"> + </Button> 

                  </OverlayTrigger>                
                
                </div>  

            </Row>

            <ListGroup as="ol" numbered="true" className="scroll position-absolute p-0 w-100" style={{height: "-webkit-fill-available", overflow: 'hidden auto'}} onScroll={this.handleScroll} >

              {this.getTableData()}

              {this.state.displayLoadingImages === true && this.state.waitToDiagnose == false && 
              <div className="p-4 bottom-border" style={{ textAlign: '-webkit-center', zIndex: '1045'}}>
                <ReactLoading width={'5vh'} height={'5vh'} type={"spokes"} color="#ffffff" />
              </div>}

            </ListGroup>

          </Col>}

          {(this.state.exam || this.props.testreport) && <ExamDisplayForm session={this.props.session} sessionData={this.props.sessionData}
                     username={this.props.username} clinicid={this.props.clinicid} testreport={this.props.testreport} exam={this.state.exam}
                     onDoneCancelingExam={this.onDoneCancelingExam} displayFailedToLoginMessageBox={this.displayFailedToLoginMessageBox}>

          </ExamDisplayForm>}


          {!this.state.exam && !this.props.testreport && <ExamCreationForm session={this.props.session} sessionData={this.props.sessionData}
                     username={this.props.username} clinicid={this.props.clinicid} onCancelCreateExam={this.onCancelCreateExam} 
                     onStartUploadingExam={this.onStartUploadingExam} onDoneUploadingExam={this.onDoneUploadingExam} 
                     diagnosisIsOver={this.state.diagnosisIsOver}>
            
          </ExamCreationForm>}

        </Row>

      </React.Fragment>
    )
  }

  getTableData() {
    if (this.state.aeyetabledata && this.state.aeyetabledata.data && this.state.aeyetabledata.data.length > 0) {

      return this.state.aeyetabledata.data.map((row, index) => {
          
          return (
            
            <ExamCard key={index} index={index} exam={row} onClickPatient={this.patientView} isDisplayed={index == this.state.indexDisplayed}>

            </ExamCard>
            
          );
      });
    } else {
      
    }
  }    
    
}

export default MainFormNew;