class AEYEUsersRow {
    constructor() {
      this.userdbid = 0;
      this.username = "";
      this.fullname = "";
      this.license = "";
      this.type = "";
      this.timezone = "";
      this.email = "";
      this.emailresults = "";
      this.lastlogin = "";
      this.camera = "";
      this.algorithem = "";
      this.sendreport = false;    
      this.editable = false;
      this.deletable = false;
      this.authmode = -1;

      this.addnewpatient = null;
      this.internalexam = null;
      this.externalexam = null;
    }
}
export default AEYEUsersRow;  
