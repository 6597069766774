import React, { Component } from "react";
import errorcodes from "../errorcodes";
import messages from "../../Messages/Messages";
import componentsStrings from "../../Messages/Components";

import "../../css/colors.css"
import "../../css/texts.css"
import connections from "../../connections";
import { Redirect } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import texts from "../New/Texts";


class RegistrationFormNew extends Component {

  state = {
    username: "",
    registrationpassword: "",
    userpassword: "",
    registrationenabled: false,
    redirectToLogin: false,
    registrationmsg: "",
    passwordUpdated: false
  };

  componentDidMount() {
    this.setState({username: this.props.username, registrationpassword: this.props.password});
  }
  
  handlePasswordChange = event => {

    if(event.target.value === "") {
      this.setState({ registrationenabled: false });
    } else {
      this.setState({ registrationenabled: true });
    }

    this.setState({ userpassword: event.target.value });
  };
  
  isDisabled = () => {
    if (
      this.state.username.length === 0 ||
      this.state.userpassword.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  handleSubmit = event => {
    this.setState({registrationmsg: ""})

    event.preventDefault();
    let url = connections.sdkurl;
    let mythis = this;
    fetch(url + "/AEYEAppServer/v1/UserRepository/register", {
      method: "POST",
      body: JSON.stringify({
        logonid: this.state.username,
        registrationpassword: this.state.registrationpassword,
        userpassword: this.state.userpassword
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.errorcode !== errorcodes.errorOK) {
          this.setState({messageTitle: messages.registrationform_registerUserTitle, registrationmsg: messages.registrationform_registerUserFailedBody})
        } else {
          this.setState({passwordUpdated: true, messageTitle: messages.registrationform_registerUserTitle, registrationmsg: ""})
        }
      }).catch(function(error) {
        mythis.setState({messageTitle: messages.registrationform_registerUserTitle, registrationmsg: messages.registrationform_registerUserFailedBody});
      });
  };
  
  redirectToLogin = () => {
    this.setState({ redirectToLogin: true });
  };
  
  renderRedirect = () => {
    if (this.state.redirectToLogin === true) {
      return <Redirect to="/" />;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.renderRedirect()}
        {this.getMainWindow()}
      </React.Fragment>
    );
  }
  
  getMainWindow() {

    if(this.state.passwordUpdated === true) {

      return (
        <React.Fragment>

          <div className="login-bg">
            
            <Col md={6} style={{paddingTop: '24vh'}}>
              
              <Row className="pb-4">
                <Col align="center">
                  <img className="login-logo" src={"/logo_new.png"}></img>
                </Col>
              </Row>     
  
              <Row className="pb-5">
                <Col align="center">
  
                  <div className="text white lh44 fs40"> {texts.registerscreen_registered_title} </div>
                  <div className="text gray_3 lh24 fs20 pt-4"> {texts.registerscreen_registered_text} </div>

                </Col>            
              </Row> 
              
              <Row>
                <Col align="center">

                  <Form.Group className="pwd-container pb-3">

                    <button className="pwd-container form-field btn btn-primary button" type="button" onClick={this.redirectToLogin}>{componentsStrings.button_login}</button>

                  </Form.Group>
                    
                </Col>  
              </Row> 

            </Col>
          
          </div>
          
          
        </React.Fragment>
      );
    }
    else {

      return (
        <React.Fragment>

          <div className="login-bg">

            <Col md={6} style={{paddingTop: '24vh'}}>

              <Row className="pb-4">
                <Col align="center">
                  <img className="login-logo" src={"/logo_new.png"}></img>
                </Col>
              </Row>     

              <Row className="pb-5">
                <Col align="center">
                  <div className="text white lh44 fs40"> Create new password </div>
                </Col>            
              </Row> 

              <Row>
                <Col align="center">

                    <Form onSubmit={this.handleSubmit}>
    
                      <Form.Group className="pwd-container form-field pb-3">
                      
                        <input  id="registerform_email" class="login-input" type="text" value={this.state.username} disabled = {true}/>

                      </Form.Group>

                      <Form.Group className="pwd-container form-field pb-3">

                        <input  id="registerform_password" class="login-input" type="password" placeholder={componentsStrings.textfiled_placeholder_createpassword} onChange={this.handlePasswordChange}/>
                        <div align="left" class="gray_3"> {texts.registerscreen_request_password} </div>

                      </Form.Group>

                      <Form.Group className="pwd-container form-field pb-3">

                        <button disabled={!this.state.registrationenabled || this.isDisabled()} class="pwd-container form-field btn btn-primary button" type="submit"> {componentsStrings.button_register} </button>
                        
                        {this.state.registrationmsg != '' && <div align="left" >
                          <img src={"/exclamation_mark.png"} style={{position: 'unset'}}/>
                          <div class="d-md-inline-flex pt-1 pl-1 red " id="staticEmail" > {this.state.registrationmsg} </div>
                        </div>}

                      </Form.Group>
                      
                    </Form>

                </Col>  
              </Row> 
            
            </Col>

          </div>

        </React.Fragment>
      );
    }

  }

}

export default RegistrationFormNew;
