import React, { Component } from "react";
import AEYETable from "../Tables/AEYETable"
import "../../css/aeyehealth.css"
import DiagnoseTool from '../DiagnoseTool/diagnosetool';
import connections from "../../connections";
import errorcodes from "../errorcodes";


class LiteMain extends Component {
  state = {
    username: "",
    session: "",
    clinicid: 0,
    userdbid: 0,
    diseasesSelected: []
  };
  
  componentDidMount() {
    this.setState({username: this.props.username, userdbid: this.props.userdbid, session: this.props.session});
    this.getDiseasesSelected();
  }

  render() {
    if(this.props.diagnose == true) {
      return (
        <div id="rcorners3">
            <div id="rcornerswork">
              <div style={{backgroundColor: '#ffffff', borderRadius: '25px'}}>
                <DiagnoseTool sessionData={this.props.sessionData} usertype={this.props.usertype} session={this.props.session} username={this.props.username} diagnose={this.props.diagnose} fullname={this.props.fullname} patientdbid={this.props.patientdbid} imagedbid={this.props.imagedbid} srcR={this.props.srcR} srcL={this.props.srcL} image_file_r={this.props.image_file_r} image_file_l={this.props.image_file_l} doctorid={this.props.userdbid} bad_r={this.props.bad_r} bad_l={this.props.bad_l} bad_p={this.props.bad_p}></DiagnoseTool>
              </div>
            </div>
        </div>
      );
    } else {
      return (
        <AEYETable sessionData={this.props.sessionData} fullname={this.props.fullname} usertype={this.props.usertype} session={this.props.session} username={this.props.username} clinicid={this.state.clinicid} addnewpatient={this.props.addnewpatient} internalexam={this.props.internalexam} externalexam={this.props.externalexam} diseasesSelected={this.state.diseasesSelected} userdbid={this.props.userdbid} doctorlinkedtoclinic={this.props.doctorlinkedtoclinic} ></AEYETable>
      );
    }
  }

  getDiseasesSelected = () => {

    if(this.props.sessionData && this.props.sessionData.userpolicies && this.props.sessionData.userpolicies.haspolicyuserread) {

      let url = connections.sdkurl;
      fetch(url + "/AEYEAppServer/v1/UserRepository/getdiseasesselected", {
        method: "POST",
        body: JSON.stringify({
          logonid: this.props.username,
          session: this.props.session,
          userdbid: this.props.userdbid
        })
      })
        .then(response => response.json())
        .then(data => {

          if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
            this.props.sessionData.showsessiontimeout();
            return;
          }

          if (data.errorcode == errorcodes.errorOK) {

              this.setState({diseasesSelected: data.diseasesSelected});
          }
      });
    }
  }

}

export default LiteMain;
