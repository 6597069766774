import React, { Component} from "react";
import Button from 'react-bootstrap/Button'
import "../../css/aeyehealth.css"
import errorcodes from "../errorcodes";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ToggleSwitch from '../ToggleSwitch/toggleswitch';
import connections from "../../connections";
import Modal from 'react-bootstrap/Modal'
import { Redirect } from "react-router-dom";
import componentsStrings from "../../Messages/Components";


const typeOfDiag = {
    IIQ: 100,
    AgeRelatedMacularDegeneration: 1,
    DiabeticRetinopathy: 2,
    Glaucoma: 3,
    RetinalVascularOcclusion: 4,
    HypertensiveRetinopathy: 5,
    Papilledema: 6,
    Other: 7,
    Cataract: 8,
    DiabeticMacularEdema: 9
}

class DiagnoseTool extends Component {
    text = [componentsStrings.button_yes, componentsStrings.button_no];

    state = {
        displayErrorMessage: false,
        tabActiveKey: "RightEye",
        IIQEnableR: false,
        IIQR: 0,
        iiqr_show: "d-none",
        allr_show: "d-none",
        AgeRelatedMacularDegenerationR: 0,
        DiabeticRetinopathyR: 0,
        GlaucomaR: 0,
        RetinalVascularOcclusionR: 0,
        DiabeticMacularEdemaR: 0,
        HypertensiveRetinopathyR: 0,
        PapilledemaR: 0,
        CataractR: 0,
        OtherR: 0,
        CommentsR: "",
        IIQEnableL: false,
        IIQL: 0,
        iiql_show: "d-none",
        alll_show: "d-none",
        AgeRelatedMacularDegenerationL: 0,
        DiabeticRetinopathyL: 0,
        GlaucomaL: 0,
        RetinalVascularOcclusionL: 0,
        DiabeticMacularEdemaL: 0,
        HypertensiveRetinopathyL: 0,
        PapilledemaL: 0,
        CataractL: 0,
        OtherL: 0,
        CommentsL: "",
        CommentsP: "",
        redirectToMain: null
        };

    handleDoneDiagnosys = () => {
        let url = connections.sdkurl;
        fetch(url + "/AEYEAppServer/v4/Patients/setDoctorDiagnosis", {
            method: "POST",
            body: JSON.stringify({
                logonid: this.props.username,
                session: this.props.session,
                patientdbid: this.props.patientdbid,
                imagedbid: this.props.imagedbid,
                image_file_r: this.props.image_file_r,
                image_file_l: this.props.image_file_l,
                bad_r: Boolean(this.state.IIQR),
                bad_l: Boolean(this.state.IIQL),
                bad_p: this.state.IIQR == true || this.state.IIQL == true,
                usertype: this.props.usertype,
                AgeRelatedMacularDegenerationR: this.state.AgeRelatedMacularDegenerationR,
                DiabeticRetinopathyR: this.state.DiabeticRetinopathyR,
                GlaucomaR:this.state.GlaucomaR,
                RetinalVascularOcclusionR: this.state.RetinalVascularOcclusionR,
                dmeR: this.state.DiabeticMacularEdemaR,
                HypertensiveRetinopathyR: this.state.HypertensiveRetinopathyR,
                PapilledemaR: this.state.PapilledemaR,
                CataractR: this.state.CataractR,
                OtherR: this.state.OtherR,
                CommentsR: this.state.CommentsR,
                AgeRelatedMacularDegenerationL: this.state.AgeRelatedMacularDegenerationL,
                DiabeticRetinopathyL: this.state.DiabeticRetinopathyL,
                GlaucomaL: this.state.GlaucomaL,
                RetinalVascularOcclusionL: this.state.RetinalVascularOcclusionL,
                dmeL: this.state.DiabeticMacularEdemaL,
                HypertensiveRetinopathyL: this.state.HypertensiveRetinopathyL,
                PapilledemaL: this.state.PapilledemaL,
                CataractL: this.state.CataractL,
                OtherL: this.state.OtherL,
                CommentsL: this.state.CommentsL,
                CommentsP: this.state.CommentsP,
                laserR: 0,
                laserL: 0,
                doctorid: this.props.doctorid
            })
        }).then(response => response.json())
        .then(data => {

            if(data.errorcode == errorcodes.AEYEHealthSDKSessionTimeout) {
                this.props.sessionData.setDoctorDiagnosis();
                return;
              }
              
            if (data.errorcode !== errorcodes.errorOK) {
                this.setState({displayErrorMessage: true});
            } else {
                this.setState({ redirectToMain: "/legacy/main" });
            }
        });
    }
    
    handleCloseMessageBox = event => {
        this.setState({displayErrorMessage: false})
    }
    
    handleBackDiagnosys = () => {
        this.setState({ tabActiveKey: "LeftEye"});
    }
    
    handleNextR = () => {
        this.setState({ tabActiveKey: "LeftEye"});
    }
    
    handleBackL = () => {
        this.setState({ tabActiveKey: "RightEye"});
    }
    
    handleNextL = () => {
        this.setState({ tabActiveKey: "SummaryEyes"});
    }
    
    handleToggleSwitchR = (diag, currentval) => {
        let newval = 0;
        let enableIIQ = false;
        let iiqr_show = "d-none";
        let allr_show = "d-inline";
        if(currentval == 0) {
            iiqr_show = "d-inline";
            allr_show = "d-none";
            newval = 1;
            enableIIQ = true;
        }
        switch (diag) {
            case typeOfDiag.IIQ:
                this.setState({ IIQR: newval, IIQEnableR: enableIIQ, iiqr_show: iiqr_show, allr_show: allr_show});
                return;
            case typeOfDiag.AgeRelatedMacularDegeneration:
                this.setState({ AgeRelatedMacularDegenerationR: newval});
                return;
            case typeOfDiag.DiabeticRetinopathy:
                this.setState({ DiabeticRetinopathyR: newval});
                return;
            case typeOfDiag.Glaucoma:
                this.setState({ GlaucomaR: newval});
                return;
            case typeOfDiag.RetinalVascularOcclusion:
                this.setState({ RetinalVascularOcclusionR: newval});
                return;
            case typeOfDiag.DiabeticMacularEdema:
                this.setState({ DiabeticMacularEdemaR: newval});
                return;
            case typeOfDiag.HypertensiveRetinopathy:
                this.setState({ HypertensiveRetinopathyR: newval});
                return;
            case typeOfDiag.Papilledema:
                this.setState({ PapilledemaR: newval});
                return;
            case typeOfDiag.Cataract:
                this.setState({ CataractR: newval});
                return;
            case typeOfDiag.Other:
                this.setState({ OtherR: newval});
                return;
        }
    }
    
    handleToggleSwitchL = (diag, currentval) => {
        let newval = 0;
        let enableIIQ = false;
        let iiql_show = "d-none";
        let alll_show = "d-inline";
        if(currentval == 0) {
            iiql_show = "d-inline";
            alll_show = "d-none";
            newval = 1;
            enableIIQ = true;
        }
        switch (diag) {
            case typeOfDiag.IIQ:
                this.setState({ IIQL: newval, IIQEnableL: enableIIQ, iiql_show: iiql_show, alll_show: alll_show});
                return;
            case typeOfDiag.AgeRelatedMacularDegeneration:
                this.setState({ AgeRelatedMacularDegenerationL: newval});
                return;
            case typeOfDiag.DiabeticRetinopathy:
                this.setState({ DiabeticRetinopathyL: newval});
                return;
            case typeOfDiag.Glaucoma:
                this.setState({ GlaucomaL: newval});
                return;
            case typeOfDiag.RetinalVascularOcclusion:
                this.setState({ RetinalVascularOcclusionL: newval});
                return;
            case typeOfDiag.DiabeticMacularEdema:
                this.setState({ DiabeticMacularEdemaL: newval});
                return;
            case typeOfDiag.HypertensiveRetinopathy:
                this.setState({ HypertensiveRetinopathyL: newval});
                return;
            case typeOfDiag.Papilledema:
                this.setState({ PapilledemaL: newval});
                return;
            case typeOfDiag.Cataract:
                this.setState({ CataractL: newval});
                return;
            case typeOfDiag.Other:
                this.setState({ OtherL: newval});
                return;
        }
    }
    
    handleCommentsR = event => {
        this.setState({ CommentsR: event.target.value });
    };
    
    handleCommentsL = event => {
        this.setState({ CommentsL: event.target.value });
    };
        
    handleCommentsP = event => {
      this.setState({ CommentsP: event.target.value });
    };

    render() {
        if (this.state.redirectToMain) {
            return <Redirect to={this.state.redirectToMain} />
          }        

        return (
            <React.Fragment>
                    {this.errorMessageBox()}
                    {this.getDiagnoseTool()}
            </React.Fragment>
        );
    }

    getDiagnoseTool() {
        return (
            <React.Fragment>
                <Tabs defaultActiveKey="RightEye" activeKey={this.state.tabActiveKey}>
                {this.getRightEyeControls()}
                {this.getLeftEyeControls()}
                {this.getSummaryEyesControls()}
                </Tabs>
            </React.Fragment>
            );
    }    

    getRightEyeControls() {
        return (
            <Tab eventKey="RightEye" title={componentsStrings.diagnosetool_righteye} disabled={true}>
            <Row>
                <Col md="auto">
                    <Row>
                        <img src={this.props.srcR} height='620' />
                    </Row>
                </Col>
                <Col md="auto">
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_iiq}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.IIQ, this.state.IIQR) }} id="diagnosetool_IIQR" Text={this.text} disabled={false} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_dr}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.DiabeticRetinopathy, this.state.DiabeticRetinopathyR) }} id="diagnosetool_DiabeticRetinopathyR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_glaucoma}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.Glaucoma, this.state.GlaucomaR) }} id="diagnosetool_GlaucomaR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_arm}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.AgeRelatedMacularDegeneration, this.state.AgeRelatedMacularDegenerationR) }} id="diagnosetool_AgeRelatedMacularDegenerationR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_hr}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.HypertensiveRetinopathy, this.state.HypertensiveRetinopathyR) }} id="diagnosetool_HypertensiveRetinopathyR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_p}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.Papilledema, this.state.PapilledemaR) }} id="diagnosetool_PapilledemaR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_rvo}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.RetinalVascularOcclusion, this.state.RetinalVascularOcclusionR) }} id="diagnosetool_RetinalVascularOcclusionR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_dme}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.DiabeticMacularEdema, this.state.DiabeticMacularEdemaR) }} id="diagnosetool_DiabeticMacularEdemaR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_cataract}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.Cataract, this.state.CataractR) }} id="diagnosetool_CataractR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_other}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchR(typeOfDiag.Other, this.state.OtherR) }} id="diagnosetool_OtherR" Text={this.text} disabled={this.state.IIQEnableR} />
                        </Col>
                    </Row>
                    
                    <Row>
                        {/* <Col md="10"><input type="text" onChange={this.handleCommentsR} id="diagnosetool_CommentsR" placeholder={componentsStrings.aeyedetailstable_comments}></input></Col> */}
                    </Row>
                    <Row style={{paddingTop: '20px'}}>
                    </Row>
                    <Row>
                        <Col xs lg="2">
                            <Button onClick={() => { this.handleNextR() }} id="diagnosetool_NextR" variant="primary">{componentsStrings.button_next}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Tab>
        );
    }

    getLeftEyeControls() {
        return (
            <Tab eventKey="LeftEye" title={componentsStrings.diagnosetool_lefteye} disabled={true}>
            <Row>
                <Col md="auto">
                    <img src={this.props.srcL} height='620' />
                </Col>
                <Col md="auto">
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_iiq}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.IIQ, this.state.IIQL) }} id="diagnosetool_IIQL" Text={this.text} disabled={false} />
                        </Col>
                    </Row>
                 
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_dr}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.DiabeticRetinopathy, this.state.DiabeticRetinopathyL) }} id="diagnosetool_DiabeticRetinopathyL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_glaucoma}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.Glaucoma, this.state.GlaucomaL) }} id="diagnosetool_GlaucomaL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_arm}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.AgeRelatedMacularDegeneration, this.state.AgeRelatedMacularDegenerationL) }} id="diagnosetool_AgeRelatedMacularDegenerationL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_hr}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.HypertensiveRetinopathy, this.state.HypertensiveRetinopathyL) }} id="diagnosetool_HypertensiveRetinopathyL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_p}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.Papilledema, this.state.PapilledemaL) }} id="diagnosetool_PapilledemaL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_rvo}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.RetinalVascularOcclusion, this.state.RetinalVascularOcclusionL) }} id="diagnosetool_RetinalVascularOcclusionL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_dme}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.DiabeticMacularEdema, this.state.DiabeticMacularEdemaL) }} id="diagnosetool_DiabeticMacularEdemaL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>

                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_cataract}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.Cataract, this.state.CataractL) }} id="diagnosetool_CataractL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="10">{componentsStrings.aeyedetailstable_other}</Col>
                        <Col xs lg="2">
                            <ToggleSwitch onChange={() => { this.handleToggleSwitchL(typeOfDiag.Other, this.state.OtherL) }} id="diagnosetool_OtherL" Text={this.text} disabled={this.state.IIQEnableL} />
                        </Col>
                    </Row>
                    
                    <Row>
                        {/* <Col md="10"><input type="text" onChange={this.handleCommentsL} id="diagnosetool_CommentsL" placeholder={componentsStrings.aeyedetailstable_comments}></input></Col> */}
                    </Row>
                    
                    <Row style={{paddingTop: '20px'}}>
                    </Row>
                    
                    <Row>
                        <Col xs lg="2">
                            <Button onClick={() => { this.handleBackL() }} id="diagnosetool_BackL" variant="primary">{componentsStrings.button_back}</Button>
                        </Col>
                        <Col xs lg="2">
                            <Button onClick={() => { this.handleNextL() }} id="diagnosetool_NextL" variant="primary">{componentsStrings.button_next}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Tab>
        );
    }
    
    getSummaryEyesControls() {
        return (
            <Tab eventKey="SummaryEyes" title={componentsStrings.diagnosetool_summaryeye} disabled={true}>
                <Container>
                    <Row>
                        <Col md="6">
                            <Row style={{paddingTop: '20px'}}>
                            </Row>
                            {this.state.iiqr_show == "d-none" && <Row>
                                <Col md="4">
                                    <Row>{componentsStrings.diagnosetool_righteye}</Row>
                                    <Row><img src={this.props.srcR} className="imgAutoSize" height="128"/></Row>
                                </Col>
                                <Col md="4">
                                    <Row style={{paddingTop: '20px'}}>
                                    </Row>
                                    <Row>{componentsStrings.aeyedetailstable_dr}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_glaucoma}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_arm}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_hr}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_p}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_rvo}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_dme}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_cataract}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_other}</Row>
                                </Col>
                                <Col md="4">
                                    <Row style={{paddingTop: '20px'}}>
                                    </Row>
                                    
                                    <Row id="diagnosetool_summary_drR">{this.state.DiabeticRetinopathyR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_gR">{this.state.GlaucomaR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_armdR">{this.state.AgeRelatedMacularDegenerationR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_hrR">{this.state.HypertensiveRetinopathyR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_pR">{this.state.PapilledemaR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_rvoR">{this.state.RetinalVascularOcclusionR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_dmeR">{this.state.DiabeticMacularEdemaR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_catR">{this.state.CataractR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>  
                                    <Row id="diagnosetool_summary_oR">{this.state.OtherR ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                </Col>
                            </Row>}
                            {this.state.iiqr_show == "d-inline" && <Row className={this.state.iiqr_show}>
                                <Col>
                                    <Row>{componentsStrings.diagnosetool_righteye}</Row>
                                    <Row>
                                        <img src={this.props.srcR} className="imgAutoSize" height="128"/>
                                        {componentsStrings.aeyedetailstable_iiq}
                                    </Row>
                                </Col>
                            </Row>}
                        </Col>
                        <Col md="6">
                            <Row style={{paddingTop: '20px'}}>
                            </Row>
                            {this.state.iiql_show == "d-none" && <Row>
                                <Col md="4">
                                    <Row>{componentsStrings.diagnosetool_lefteye}</Row>
                                    <Row><img src={this.props.srcL} className="imgAutoSize" height="128" /></Row>
                                </Col>
                                <Col md="4">
                                    <Row style={{paddingTop: '20px'}}>
                                    </Row>
                                    <Row>{componentsStrings.aeyedetailstable_dr}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_glaucoma}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_arm}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_hr}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_p}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_rvo}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_dme}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_cataract}</Row>
                                    <Row>{componentsStrings.aeyedetailstable_other}</Row>
                                </Col>
                                <Col md="4">
                                    <Row style={{paddingTop: '20px'}}>
                                    </Row>
                                    <Row id="diagnosetool_summary_drL">{this.state.DiabeticRetinopathyL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_gL">{this.state.GlaucomaL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_armdL">{this.state.AgeRelatedMacularDegenerationL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_hrL">{this.state.HypertensiveRetinopathyL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_pL">{this.state.PapilledemaL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_rvoL">{this.state.RetinalVascularOcclusionL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_dmeL">{this.state.DiabeticMacularEdemaL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_catL">{this.state.CataractL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                    <Row id="diagnosetool_summary_oL">{this.state.OtherL ? componentsStrings.button_yes : componentsStrings.button_no}</Row>
                                </Col>
                            </Row>}
                            {this.state.iiql_show == "d-inline" && <Row className={this.state.iiql_show}>
                                <Col>
                                    <Row>{componentsStrings.diagnosetool_lefteye}</Row>
                                    <Row>
                                        <img src={this.props.srcL} className="imgAutoSize" height="128"/>
                                        {componentsStrings.aeyedetailstable_iiq}
                                    </Row>
                                </Col>
                            </Row>}
                        </Col>
                    </Row>
                    <Row style={{paddingTop: '20px'}}>
                    </Row>
                    <Row>
                        <Col>
                            <textarea cols = "70" rows="3" id="diagnosetool_CommentsP" onChange={this.handleCommentsP} placeholder={componentsStrings.aeyedetailstable_comments}>
                            </textarea>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: '20px'}}>
                        <Button style={{marginRight: '10px'}} onClick={() => { this.handleBackDiagnosys() }} id="diagnosetool_BackDiag" variant="primary">{componentsStrings.button_back}</Button>
                        <Button onClick={() => { this.handleDoneDiagnosys() }} id="diagnosetool_DoneDiag" variant="primary">{componentsStrings.button_donediag}</Button>
                    </Row>
                </Container>
            </Tab>
        );
    }

    errorMessageBox() {
        return(
        <Modal show={this.state.displayErrorMessage}>
            <Modal.Header>
                <Modal.Title>{componentsStrings.diagnosetool_failtodiag_title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{componentsStrings.diagnosetool_failtodiag_body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseMessageBox}>
                {componentsStrings.button_close}
                </Button>
            </Modal.Footer>
        </Modal>        
        );
    }

}

export default DiagnoseTool;