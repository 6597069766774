import React, { Component } from "react";
import "../css/aeyehealth.css"
import Row from "react-bootstrap/Row";
import componentsStrings from "../Messages/Components"

class CopyRight extends Component {
  state = {
    localDate: ''
  };
  componentDidMount() {
    var date = new Date();
    var options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    var localDate = date.toLocaleDateString("en", options)

    this.setState({localDate: localDate})
  }


  render() {
    let copyright = componentsStrings.copyright_copyright;
    copyright = copyright.replace("$$date$$", new Date().getFullYear());
    return (
      <Row style={{marginTop: '0px'}}>
        <p style={{fontSize: '10px'}}>{copyright}</p>
      </Row>
    )
  }
}

export default CopyRight;
