export default class texts {

    static forgotscreen_mailsent_title = "Email sent ";
    static forgotscreen_mailsent_text1 = "We sent an email to $$email$$ with a link ";
    static forgotscreen_mailsent_text2 = "to reset your password. ";

    static forgotscreen_requestmail_title = "Forgot your password?";
    static forgotscreen_requestmail_textusername = "The username you registered with";
    static forgotscreen_requestmail_textemail = "The email you registered with";

    static recoverscreen_updated_title = "Password Updated";
    static recoverscreen_updated_text = "Your password has been changed successfully";
    static recoverscreen_request_password = "Use 8 or more characters with a mix of letters, numbers, and symbols";

    static registerscreen_registered_title = "Successfully Registered";
    static registerscreen_registered_text = "Successfully registered new user";
    static registerscreen_request_password = "Use 8 or more characters with a mix of letters, numbers, and symbols";


    static mainscreen_searchdropdown_option1 = "All exams";
    static mainscreen_searchdropdown_option2 = "Active";
    static mainscreen_searchdropdown_option3 = "Archived";

    static mainscreen_searchbutton_tooltip = "Press the ‘+’ button to add an exam";

    static mainscreen_on_eye_iiq_text = "UPLOAD IMAGE";

    static display_exam_download_tooltip = "Download";
    static display_exam_send_to_email_tooltip = "Export";
    static display_exam_actice_tooltip = "Activate";
    static display_exam_archived_tooltip = "Archive";
    static display_exam__iiq = "INSUFFICIENT IMAGE QUALITY";



    static getimages_failed_label = 'Get images failed!';
    static getimages_failed_body = 'Please contact your administrator.';

    static uploadpatient_addNewImagesPatientInvalidID = "Invalid Exam ID. Exam ID must contain alphabetic charecters only.";
    static uploadpatient_addNewImagesPatientInvalidName = "Invalid Patient Name. Patient name must contain alphabetic charecters, underscores, and spaces only.";
    static uploadpatient_addNewImagesPatientDiagnosesFailed = "Failed to diagnose images.";
    static uploadpatient_addNewImagesPatientFiltersFailed = "Image filters failed to process images";
    static uploadpatient_addNewImagesPatientUploadedDiagnosesFailed = `Please try to upload again. \n Please contact support@aeyehealth.com with the error number in question.`;
    static uploadpatient_addNewImagesPatientUploadedDiagnosesTimeout = "Images were uploaded but encountered a timeout. Please try again in a few minutes.";
    static uploadpatient_NoDiseaseWasSet = "No image processing modules configured for your clinic, please contact your administrator.";
    static uploadpatient_FailToCheckFilters = "Failed to diagnose images.";
    static uploadpatient_recordresults_fatal_body = "Failed to record result, please try again.";
    static uploadpatient_examid_exist_body = "Exam ID already exists, please choose another exam ID";

    static uploadpatient_failToLogin_label = "Multiple Login";
    static uploadpatient_failToLogin_body = "Someone else has logged into this user and your session has been terminated." +
                                            " If you would like to log back in, you may do so from the login screen. If you believe you are seeing this error incorrectly, please contact your administrator.";

    static messagebody_iiq = 'Low image quality. Try to upload a new image.';
    static messagebody_submit_anyway = 'Record Result';
    static messagebody_replace_image = 'Replace Image';

    static messagebody_cancel_images_label = 'Cancel Exam?';
    static messagebody_cancel_images_body = 'You are about to remove the details and cancel the exam.';

    static messagebody_reset_images_label = 'Reset Images?';
    static messagebody_reset_images_body = 'You are about to remove the images and reset the page.';

    static messagebody_popup_characters = `The patient ID can only contain alphanumeric characters, spaces, and the following symbols: ‘._-’.
                                            \n The patient ID must also be unique. 
                                            \n Consider using a unique patient identifier and the exam date`;

    static messagebody_popup_about_labal = "Regulatory information";
    static messagebody_popup_about_body = `(DI) XXXXXXXXXXXX \n (PI) XXXXXXXXXXXX`;

}
  