import React, { Component } from "react";
import AEYEReportTable from "../Tables/AEYEReportTable"
import ReportMenuBar from "../Dialogs/ReportMenuBar"
import "../../css/aeyehealth.css"

class ReportForm extends Component {

  state = {
    username: "",
    session: "",
    usertype: "",
    reportidselected: -1,
    execreport: false
  };
  
  componentDidMount() {
    this.setState({rerender: false, username: this.props.username, session: this.props.session, usertype: this.props.usertype, renderReportTable: false});
  }

  reportExecuted = () => {
    this.setState({execreport: false});
  }

  runReport = (reportidselected) => {
    this.setState({reportidselected: reportidselected, execreport: true});
  }

  render() {
    return (
      <div id="rcorners3">
          <div style={{margin: '10px'}}>
            <ReportMenuBar sessionData={this.props.sessionData} runReport={this.runReport} session={this.props.session} username={this.props.username} usertype={this.props.usertype}></ReportMenuBar>
          </div>
          <div id="rcornerswork">
            <div style={{backgroundColor: '#ffffff', borderRadius: '25px'}}>
              <AEYEReportTable sessionData={this.props.sessionData} reportExecuted={this.reportExecuted} session={this.props.session} username={this.props.username} reportidselected={this.state.reportidselected} execreport={this.state.execreport}></AEYEReportTable>
            </div>
          </div>
      </div>
    );
  }
  
}

export default ReportForm;
